import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Filter from "components/Admin/Users/Filter";
import user from "components/Admin/Columnas/User";
import { useUser } from "hooks/Admin/Users/useUser";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";

const ListCompany = () => {
  const { state, actions } = useUser();
  const { userIsLoading } = state;

  const { getAllInitial, handleRowClicked, filteredItems } = actions;

  useEffect(() => {
    getAllInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_users">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de usuarios">
          <DataTableCustomer
            columns={user.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={userIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListCompany;

import React, { Fragment, useState } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import TableOrden from "components/Soluciones/FechaCreacion/TablaDataCreacion";
import { useDispatch, useSelector } from "react-redux";
import {
  searchOrdenFechaCreacion,
  saveDateUpdatedCreatedOrder,
} from "actions/solutionsOrdenAction";
import TitlePrincipal from "components/Layouts/TitlePrincipal";
import { useForm } from "react-hook-form";
import { validateOnlyOrden, onPaste } from "utils/validationInput";

const EditarOrdenFechaCreacion = ({ toast, isLoad }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, getValues, setValue, control } = useForm();
  const { listOrdenesFechaCreacion } = useSelector((state) => state.solutions);

  const addOrden = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (getValues("numero_orden") === "") {
      toast.error("Ingrese el número de orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    const existeOrden = listOrdenesFechaCreacion.find(
      (item) => item.numero_orden === getValues("numero_orden")
    );
    if (existeOrden) {
      toast.error("La orden se encuentra en la lista", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    const data = {
      numero_orden: getValues("numero_orden"),
      type: "date_created",
    };
    const response = await dispatch(searchOrdenFechaCreacion(data));
    if (!response.status) {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    } else {
      setValue("numero_orden", "");
    }
    setLoading(false);
  };

  const onKeyOrden = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      addOrden(event);
    }
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    const validate = data.ordenes.filter(
      (item) => item.fecha_orden_now === undefined
    );
    if (validate.length > 0) {
      toast.error("Debe seleccionar las fechas en todas las ordenes", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return false;
    }

    let response = await dispatch(saveDateUpdatedCreatedOrder(data.ordenes));
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setIsLoading(false);
  };

  return (
    <Fragment>
      <TitlePrincipal title="INGRESE LAS ORDENES A EDITAR LA FECHA DE CREACIÓN" />
      <Form
        className="mt-3"
        onSubmit={handleSubmit(onSubmit)}
        id="frmFechaCreacion"
      >
        <Row className="mt-3" onSubmit={handleSubmit(onSubmit)}>
          <Col md={3} sm={6}>
            <Form.Group as={Col} controlId="numero_orden">
              <Form.Label>N° de Orden</Form.Label>
              <Form.Control
                type="text"
                name="numero_orden"
                ref={register}
                onKeyDown={(event) => onKeyOrden(event)}
                maxLength={30}
                onKeyPress={(event) => validateOnlyOrden(event)}
                onPaste={(event) => onPaste(event)}
              />
            </Form.Group>
          </Col>
          <Col md={3} sm={6}>
            <Form.Group as={Col} className="mt-4">
              <Button
                variant="warning"
                disabled={loading}
                type="button"
                size="sm"
                block
                onClick={(event) => addOrden(event)}
              >
                {loading ? "Agregando..." : "AGREGAR"}
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <TableOrden register={register} toast={toast} control={control} />

        <Row className="m-3">
          <Col className="text-right">
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="solution-btn"
            >
              {isLoading ? "Procesando..." : "EDITAR"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default EditarOrdenFechaCreacion;

import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import ModalFactura from "components/Factura/ModalFactura";
import FormOrden from "components/Encomienda/Credito/Formulario";
import TablaOrden from "components/Encomienda/Credito/TableOrden";
import PanelDetalle from "components/Layouts/PanelDetalle";
import TableSuccess from "components/Encomienda/TableSuccess";
import { useDispatch, useSelector } from "react-redux";
import { facturaACredito } from "actions/orderAction";
import { useForm } from "react-hook-form";

const FacturaCredito = ({ toast }) => {
  const dispatch = useDispatch();
  const { listEntregada } = useSelector((state) => state.transportOrders);
  const { register, handleSubmit, errors } = useForm();
  const [hidden, setHidden] = useState(false);
  const [infoData, setInfoData] = useState({});

  const onSubmit = (data, event) => {
    event.preventDefault();

    if (!data.hasOwnProperty("ordenes")) {
      toast.error("No tiene ordenes por devolver", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const information = {
      ordenes: data.ordenes,
      rut_remitente: data.ordenes[0].rut_remitente,
      rut_destinatario: data.ordenes[0].rut_destinatario,
    };
    setHidden(true);
    setInfoData(information);
  };

  const funcFacturar = async (information) => {
    const data = {
      ordenes: information.ordenes.map((orden) => orden.orden_flete_id),
      factura: {
        rut: information?.factura?.rut_factura,
        razon_social: information?.factura?.razon_social_factura,
        direccion: information?.factura?.direccion_factura,
        telefono: information?.factura?.contacto_factura,
        email: information?.factura?.email_factura,
        sucursal_id: information?.factura?.ciudad_factura,
        comuna_id: information?.factura?.comuna_factura,
        rubro: information?.factura?.giro_factura,
      },
      nro_documento: information?.factura?.numero_documento ?? "",
    };

    const response = await dispatch(facturaACredito(data));
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      document.getElementById("frmFacturaCredito").reset();
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  return (
    <>
      <PanelDetalle
        title="DETALLE DE LA ORDENES"
        subtitle="INGRESO DE DATOS"
        check={true}
      >
        <FormOrden toast={toast} />
        <Form id="frmFacturaCredito" onSubmit={handleSubmit(onSubmit)}>
          <TablaOrden register={register} errors={errors} />

          <Form.Row className="m-3">
            <Col className="text-right">
              <Button variant="warning" type="submit">
                FACTURAR
              </Button>
            </Col>
          </Form.Row>
        </Form>
        {listEntregada.length > 0 && <TableSuccess />}
      </PanelDetalle>
      {hidden && (
        <ModalFactura
          setHidden={setHidden}
          hidden={hidden}
          data={infoData}
          toast={toast}
          funcFactura={funcFacturar}
          title={"FACTURA A NOMBRE DE:"}
          isVisibleFactura={true}
          nameModulo="entrega"
        />
      )}
    </>
  );
};

export default FacturaCredito;

import { useState } from "react";
import { resultTotalesIncomplete } from "../../actions/orderAction";
import { updateOrdenIncomplete } from "actions/transportOrdersAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

export const useFormIncomplete = (toast, setShow) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, getValues, setValue, control } =
    useForm();
  const [statusValidate, setStatusValidate] = useState(false);

  const { documentacionsCarga, formaPagos } = useSelector(
    (state) => state.transportOrders
  );

  const onSubmit = async (data, numero_orden) => {
    try {
      setLoading(true);

      const invalid = data?.cargas?.filter(
        (item) =>
          item.total === "0.00" ||
          item.total === 0 ||
          item.alto === "0.00" ||
          item.alto === "0" ||
          item.alto === 0 ||
          item.ancho === "0.00" ||
          item.ancho === "0" ||
          item.ancho === 0 ||
          item.bulto === "0.00" ||
          item.bulto === "0" ||
          item.bulto === 0 ||
          item.largo === "0.00" ||
          item.largo === "0" ||
          item.largo === 0 ||
          item.metraje_cubico === "0.00" ||
          item.metraje_cubico === "0" ||
          item.metraje_cubico === 0 ||
          item.peso === "0.00" ||
          item.peso === "0" ||
          item.peso === 0
      );
      if (
        data?.total === "" ||
        data?.valor_neto === "" ||
        data?.valor_iva === "" ||
        data?.subtotal_orden === "" ||
        invalid.length > 0
      ) {
        setLoading(false);
        toast.error("Debe ingresar los montos de cada carga", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      } else {
        applyDescuento();
        const response = await dispatch(
          updateOrdenIncomplete(data, numero_orden)
        );

        if (response.status) {
          setShow(false);
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoading(false);
      }
    } catch (error) {
      toast.error("Hubo un error al actualizar los datos de la orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleChangeTotal = (event, idCarga, idOrdenFlete) => {
    if (event.target.value !== "") {
      const descuento = getValues("descuento");

      const carga = {
        id: idCarga,
        valor_neto: event.target.value.replaceAll(".", "").replaceAll(",", "."),
      };

      const result = dispatch(resultTotalesIncomplete(carga, descuento));

      if (Object.keys(result).length > 0) {
        setValue("total", result?.total);
        setValue("total_bultos", result?.total_bultos);
        setValue("total_kilos", result?.total_kilos);
        setValue("total_metro_cubico", result?.total_metro_cubico);
        setValue("subtotal_orden", result?.subtotal_orden);
        setValue("valor_neto", result?.valor_neto);
        setValue("valor_iva", result?.valor_iva);
        setValue("valor_descuento", result?.montoDiferencia);
      }
    }
  };

  const applyDescuento = () => {
    let dataOrden = getValues();

    const result = dispatch(
      resultTotalesIncomplete(dataOrden?.cargas, getValues("descuento"))
    );

    if (Object.keys(result).length > 0) {
      setValue("total", result?.total);
      setValue("total_bultos", result?.total_bultos);
      setValue("total_kilos", result?.total_kilos);
      setValue("total_metro_cubico", result?.total_metro_cubico);
      setValue("subtotal_orden", result?.subtotal_orden);
      setValue("valor_neto", result?.valor_neto);
      setValue("valor_iva", result?.valor_iva);
      setValue("valor_descuento", result?.montoDiferencia);
    }
  };

  const handleTipoDoc = (event) => {
    let pago_descripcion = document.querySelector(".change_documentacion_id");

    pago_descripcion =
      pago_descripcion.options[pago_descripcion.selectedIndex].text;

    if (pago_descripcion.toLowerCase() === "sin documento") {
      setStatusValidate((statusValidate) => false);
      setValue("numero_documento_carga", "");
    } else {
      setStatusValidate((statusValidate) => true);
    }
  };

  const formElement = {
    onSubmit,
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    getValues,
  };

  const state = {
    loading,
    statusValidate,
    documentacionsCarga,
    formaPagos,
  };

  const actions = {
    handleChangeTotal,
    applyDescuento,
    handleTipoDoc,
  };

  return {
    state,
    formElement,
    actions,
  };
};

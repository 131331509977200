import {
  LIST_DATA_COUNTRY,
  SELECTED_ROWS_COUNTRY,
  DATA_ACTIVE_COUNTRY,
  LOADING_COUNTRY,
} from "types/admin/countryType";

const INITIAL_STATE = {
  listCountries: [],
  contryIsLoading: true,
  countryData: null,
};

const countryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_COUNTRY:
      return {
        ...state,
        contryIsLoading: action.payload.loading,
        countryData: action.payload.data,
      };
    case LIST_DATA_COUNTRY:
      return {
        ...state,
        listCountries: action.payload,
        contryIsLoading: false,
      };
    case SELECTED_ROWS_COUNTRY:
      return {
        ...state,
        countryData: action.payload.data,
      };
    case DATA_ACTIVE_COUNTRY:
      return {
        ...state,
        countryData: action.payload.data,
        listCountries: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default countryReducer;

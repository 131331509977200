import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import Permisos from "components/Admin/Roles/Permisos";
import { validateOnlyLetter, onPaste } from "utils/validationInput";
import { useFormRole } from "hooks/Admin/Roles/useFormRole";
import { Link } from "react-router-dom";

const Formulario = ({ rolId }) => {
  const { state, actions, formElement } = useFormRole();

  const { isLoading, register, handleSubmit, errors, onSubmit } = formElement;

  const {
    validPermissionAdmin,
    validatePermission,
    listPermissions,
    setVisibleRol,
    getRolById,
  } = actions;

  const { visibleRol, dataRol } = state;

  useEffect(() => {
    getRolById(rolId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolId]);

  useEffect(() => {
    if (visibleRol === "si") {
      validPermissionAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleRol]);

  return (
    <Form id="frmCliente" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Group controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  autoFocus
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.name && (
                  <Form.Text className="text-danger">
                    {errors?.name?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Form.Group controlId="visible_other_companies">
                <Form.Label>
                  ¿Desea que este rol lo visualice las otras casas matrices?
                </Form.Label>
                <div className="mt-2">
                  <Form.Check
                    inline
                    label="Si"
                    name="visible_other_companies"
                    type="radio"
                    id={`inline-si`}
                    onChange={() => setVisibleRol("si")}
                    checked={visibleRol === "si" ? true : false}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="visible_other_companies"
                    type="radio"
                    id={`inline-no`}
                    onChange={() => setVisibleRol("no")}
                    checked={visibleRol === "no" ? true : false}
                  />
                </div>
              </Form.Group>
              <hr />
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Form.Group controlId="permissions">
                <Form.Label>Permisos</Form.Label>
                <Form.Text className="text-muted pb-3 pt-3">
                  Selecciona los permisos para cada ROL.
                </Form.Text>
                <Row className="pl-4 pr-4">
                  {Object?.keys(listPermissions)?.length > 0 &&
                    Object.entries(listPermissions).map((permission, key) => (
                      <Permisos
                        key={key}
                        index={key}
                        list={permission[1]}
                        register={register}
                        permissions={
                          dataRol === null ? [] : dataRol?.permissions
                        }
                        validatePermission={validatePermission}
                      />
                    ))}
                  {Object?.keys(listPermissions)?.length === 0 && (
                    <p>Cargando los permisos...</p>
                  )}
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group as={Col}>
            <Link to="/admin/roles">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : rolId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Activities/Formulario";
import { useParams } from "react-router-dom";
import { useContries } from "hooks/Admin/Countries/useContries";

const CreateActivity = () => {
  const { countryId } = useParams();

  const { state, actions } = useContries();

  const { countryData } = state;

  const { getCountry } = actions;

  useEffect(() => {
    getCountry(countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_countries">
      <div className="pl-3 pr-3">
        <CardRetiro
          title={`${
            countryData !== null ? countryData?.name : "País"
          } - Crear giro`}
        >
          <Formulario activityId={null} countryId={countryId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default CreateActivity;

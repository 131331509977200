import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import FormOrden from "components/Encomienda/Devolucion/FormOrden";
import TablaOrden from "components/Encomienda/Devolucion/TablaOrden";
import PanelDetalle from "components/Layouts/PanelDetalle";
import { useDispatch } from "react-redux";
import { devolverOrden } from "actions/transportOrdersAction";
import { useForm } from "react-hook-form";

const Devolucion = ({ toast }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    setLoading(true);

    if (!data.hasOwnProperty("ordenes")) {
      toast.error("No tiene ordenes por devolver", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    const listMotivo = data.ordenes.filter(
      (orden) => orden.delivery_failure_detail === ""
    );
    if (listMotivo.length > 0) {
      toast.error("Debe de completar el detalle de las ordenes", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    const response = await dispatch(devolverOrden(data));
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      event.target.reset();
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setLoading(false);
  };

  return (
    <>
      <PanelDetalle
        title="DETALLE DE LA ÓRDENES"
        subtitle="INGRESO DE DATOS"
        check={true}
      >
        <FormOrden toast={toast} />
        <Form id="frmDevolucion" onSubmit={handleSubmit(onSubmit)}>
          <TablaOrden register={register} errors={errors} />

          <Form.Row className="m-3">
            <Col className="text-right">
              <Button variant="warning" type="submit" disabled={loading}>
                {!loading ? "PENDIENTE DE DESPACHO" : "PROCESANDO..."}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </PanelDetalle>
    </>
  );
};

export default Devolucion;

import React from "react";
import { Link } from "react-router-dom";
//import { formatMonto } from "../../../config/formatInput"
//import { formatNumberCurrency } from "utils/validationInput";

const TableOrden = ({
  numero_orden,
  fecha_orden_search,
  cliente_remitente,
  oficina_origen,
  oficina_destino,
  sucursal_origen,
  sucursal_destino,
  cliente_destinatario,
  direccion_destinatario,
  total_bultos,
  tipo_envio,
  forma_pago,
  total,
  estado,
  documento_pago,
  nro_documento_pago,
  usuario_entrego_app_mobile,
  camion_app_mobile,
  numero_nomina,
}) => {
  return (
    <tr>
      <td>
        <Link to={`/transport-orders/${numero_orden}/edit`}>
          {numero_orden}
        </Link>
      </td>
      <td>{estado}</td>
      <td>{fecha_orden_search}</td>
      <td>{cliente_remitente}</td>
      <td>{oficina_origen}</td>
      <td>{oficina_destino}</td>
      <td>{sucursal_origen}</td>
      <td>{sucursal_destino}</td>
      <td>{cliente_destinatario}</td>
      <td>{direccion_destinatario}</td>
      <td>{total_bultos}</td>
      <td>{tipo_envio}</td>
      <td>{forma_pago}</td>
      <td>{total}</td>
      <td>{documento_pago}</td>
      <td>{nro_documento_pago}</td>
      <td>{numero_nomina}</td>
      <td>{usuario_entrego_app_mobile}</td>
      <td>{camion_app_mobile}</td>
    </tr>
  );
};

export default TableOrden;

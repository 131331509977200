import API from "config/config-api";
import {
  LIST_DATA_CORRELATIVE,
  SELECTED_ROWS_CORRELATIVE,
  DATA_ACTIVE_CORRELATIVE,
  LOADING_CORRELATIVE,
} from "types/admin/correlativeType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_CORRELATIVE, payload));
};

export const getListCorrelativeAll = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.correlative.list);
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_CORRELATIVE, data));
    return { status: status, data: data, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRowsCorrelativo = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_CORRELATIVE, payload));
};

export const addCorrelative = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.correlative.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editCorrelative =
  (body, correlativeId) => async (dispatch, getState) => {
    try {
      let response = await API.put(
        endPoints.correlative.edit(correlativeId),
        body
      );
      const { status, message } = response.data;

      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const searchCorrelativeById =
  (correlativeId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.correlative.getOne(correlativeId));

      const { status, message, data } = response.data;

      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const deleteCorrelative =
  (correlativeId) => async (dispatch, getState) => {
    try {
      const { listCorrelatives } = getState().activities;

      let response = await API.delete(
        endPoints.correlative.delete(correlativeId)
      );
      const { status, message } = response.data;
      if (status) {
        const payload = {
          listAll: [...listCorrelatives].filter(
            (country) => country.id !== correlativeId
          ),
          data: null,
        };

        dispatch(requestSuccess(DATA_ACTIVE_CORRELATIVE, payload));
      }
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/ParentCities/Filter";
import parentCity from "components/Admin/Columnas/ParentCity";
import { useParams } from "react-router-dom";
import { useParentCity } from "hooks/Admin/ParentCities/useParentCity";
import { useContries } from "hooks/Admin/Countries/useContries";

const ListActivity = () => {
  const { countryId } = useParams();

  const { state, actions } = useParentCity();
  const { parentCityLoading } = state;
  const { getAll, filteredItems, handleRowClicked } = actions;

  const { actions: actionsCountry, state: stateCountry } = useContries();
  const { countryData } = stateCountry;
  const { getCountry } = actionsCountry;

  useEffect(() => {
    getAll(countryId);
    getCountry(countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_countries">
      <div className="pl-3 pr-3">
        <CardRetiro
          title={`${
            countryData !== null ? countryData?.name : "País"
          } - Listado de sucursales globales`}
        >
          <DataTableCustomer
            columns={parentCity.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={parentCityLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListActivity;

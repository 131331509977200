import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addOffice,
  editOffice,
  searchOfficeById,
} from "actions/admin/officeAction";
import { getListParentOfficeAll } from "actions/admin/parentOfficeAction";
import { searchCityById } from "actions/admin/cityAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormOffice = (cityId) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      parent_office_id: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataOffice, setDataOffice] = useState(null);

  const { officeData } = useSelector((state) => state.offices);
  const { listParentOffice } = useSelector((state) => state.parentOffices);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      ...data,
      name: data?.name?.toUpperCase(),
      city_id: cityId,
    };

    if (dataOffice !== null) {
      response = await dispatch(editOffice(body, dataOffice?.id));
    } else {
      response = await dispatch(addOffice(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);

      setTimeout(() => {
        history.push(`/admin/cities/${cityId}/offices`);
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getOfficeById = async (officeId) => {
    let data = officeData;

    if (cityId !== null) {
      const result = await dispatch(searchCityById(cityId));
      await dispatch(
        getListParentOfficeAll(result?.data?.parent_city_id, "active")
      );
    }

    if (officeId !== null) {
      if (officeData === null) {
        const result = await dispatch(searchOfficeById(officeId));
        data = result?.data;
      }
    }

    reset({
      parent_office_id: data?.parent_office_id ?? "",
      name: data?.name ?? "",
    });

    setDataOffice(officeId !== null ? data ?? null : null);
  };

  const actions = {
    getOfficeById,
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
  };

  const state = {
    listParentOffice,
    dataOffice,
  };

  return {
    formElement,
    actions,
    state,
  };
};

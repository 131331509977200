import {
  LIST_DATA_CORRELATIVE,
  SELECTED_ROWS_CORRELATIVE,
  DATA_ACTIVE_CORRELATIVE,
  LOADING_CORRELATIVE,
} from "types/admin/correlativeType";

const INITIAL_STATE = {
  listCorrelatives: [],
  correlativeIsLoading: true,
  correlativeData: null,
};

const correlativeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_CORRELATIVE:
      return {
        ...state,
        correlativeIsLoading: action.payload.loading,
        correlativeData: action.payload.data,
      };
    case LIST_DATA_CORRELATIVE:
      return {
        ...state,
        listCorrelatives: action.payload,
        correlativeIsLoading: false,
      };
    case SELECTED_ROWS_CORRELATIVE:
      return {
        ...state,
        correlativeData: action.payload,
      };
    case DATA_ACTIVE_CORRELATIVE:
      return {
        ...state,
        listCorrelatives: action.payload.listAll,
        correlativeData: action.payload.data,
      };
    default:
      return state;
  }
};

export default correlativeReducer;

import {
  LOGIN,
  LOGOUT,
  LIST_DATA_USER,
  DATA_ACTIVE_USER,
  SELECTED_ROWS_USER,
  DATA_ADD_USER_VEHICLE,
  LOADING_USER,
} from "types/admin/userType";

const INITIAL_STATE = {
  name: "",
  city_id: "",
  office_id: "",
  listUsers: [],
  userIsLoading: true,
  userDatos: null,
  email: "",
  uIsVehicle: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        name: action.payload.name,
        city_id: action.payload.city_id,
        office_id: action.payload.office_id,
        email: action.payload.email,
      };
    case LOGOUT:
      return {
        ...state,
        name: "",
        city_id: "",
        office_id: "",
      };
    case LIST_DATA_USER:
      return {
        ...state,
        listUsers: action.payload,
        userIsLoading: false,
      };
    case SELECTED_ROWS_USER:
      return {
        ...state,
        userDatos: action.payload.data,
      };
    case DATA_ACTIVE_USER:
      return {
        ...state,
        listUsers: action.payload.listAll,
        userDatos: action.payload.data,
      };
    case DATA_ADD_USER_VEHICLE:
      return {
        ...state,
        listUsers: action.payload.listAll,
        userDatos: action.payload.data,
        userBtnActive: action.payload.btnActive,
      };
    case LOADING_USER:
      return {
        ...state,
        userIsLoading: action.payload.loading,
        userDatos: action.payload.data,
      };
    default:
      return state;
  }
};

export default userReducer;

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Roles/Formulario";
import { useParams } from "react-router-dom";
import { useRole } from "hooks/Admin/Roles/useRole";

const EditRole = () => {
  const { rolId } = useParams();

  const { actions } = useRole();
  const { getPermissions } = actions;

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_roles">
      <div className="pl-3 pr-3">
        <CardRetiro title="Editar rol">
          <Formulario rolId={rolId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default EditRole;

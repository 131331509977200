const generateHeaders = (endMethod) => {
  const token = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).token
    : null;
  return {
    method: endMethod,
    headers: {
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
};

const setData = (endMethod, data) => {
  const headers = generateHeaders(endMethod);
  return {
    ...headers,
    body: JSON.stringify(data),
  };
};

const API = {
  putFile(endPoint, body) {
    return fetch(endPoint, setData("POST", body));
  },
};

export default API;

export const defaultValuesForm = {
  fecha_orden: new Date(),
  city_origin_id: "",
  order_number: "",
  office_origin_id: "",
  city_destination_id: "",
  office_destination_id: "",
  shipment_type_id: "",
  user_name: "",
  estado_orden: "",
  nro_nomina: "",
  cliente_existe: "creado",
  codigo_interno: "no",
  codigo_interno_nro: "",
  cliente_rut: "",
  razon_social: "",
  tipo_cliente: "remitente",
  rut_remitente: "",
  razon_social_remitente: "",
  rut_destinatario: "",
  razon_social_destinatario: "",
  direccion_destinatario: "",
  telefono_avisas: "",
  documentacion_id: "",
  numero_documento_carga: "",
  valor_declarado_carga: "",
  with_price: "with_price",
  nro_bultos: "",
  nro_kilos: "",
  largo: "",
  ancho: "",
  alto: "",
  nro_m3: "",
  detalle_carga: "",
  total_carga: "",
  email_notification: "",
  comments: "",
  cargas: [],
  total_bultos: 0,
  total_kilos: 0,
  total_metro_cubico: 0,
  subtotal_orden: 0,
  descuento: "No",
  valor_neto: 0,
  valor_iva: 0,
  total: 0,
  forma_pago_id: "",
  documentacion_id_pago: "",
  numero_documento: "",
  rut_factura: "",
  razon_social_factura: "",
};

import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import { usePassword } from "hooks/Admin/Users/usePassword";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { Link } from "react-router-dom";

const FormChangePassword = ({ userId }) => {
  const { state, actions, formElement } = usePassword();
  const { register, handleSubmit, errors, getValues } = formElement;

  const {
    onSubmit,
    setTypeInput,
    setTypeInputRep,
    validatePassword,
    setDataInicial,
  } = actions;

  const { isLoading, typeInput, typeInputRep } = state;

  useEffect(() => {
    setDataInicial(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Form id="frmClave" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Header as="h6" className="bg-warn-blue text-dark">
          CAMBIAR CONTRASEÑA
        </Card.Header>
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="fullName">
                <Form.Label>Nombre y Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  //defaultValue={datos?.fullName || ""}
                  disabled
                  ref={register}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  //defaultValue={datos?.email || ""}
                  disabled
                  ref={register}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="password">
                <Form.Label>Nueva Contraseña</Form.Label>
                <div className="mb-3 p-relative">
                  <Form.Control
                    type={!typeInput ? "password" : "text"}
                    name="password"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                      minLength: { value: 9, message: "Mínimo 9 caracteres" },
                      maxLength: {
                        value: 15,
                        message: "Máximo 15 caracteres",
                      },
                      validate: validatePassword,
                      //eslint-disable-next-line
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
                        message:
                          "La contraseña debe tener: al menos 9 caracteres, 1 número, 1 caracter en minúscula, 1 caracter en mayúscula y 1 caracter especial",
                      },
                    })}
                    maxLength={15}
                  />
                  <div
                    className="visible-password"
                    onClick={() => setTypeInput(!typeInput)}
                  >
                    {!typeInput ? (
                      <BiHide style={{ fontSize: "20px" }} />
                    ) : (
                      <BiShow style={{ fontSize: "20px" }} />
                    )}
                  </div>
                </div>
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors?.password?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="repPassword">
                <Form.Label>Repetir Contraseña</Form.Label>
                <div className="mb-3 p-relative">
                  <Form.Control
                    type={!typeInputRep ? "password" : "text"}
                    name="repPassword"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                      minLength: { value: 8, message: "Mínimo 8 caracteres" },
                      maxLength: {
                        value: 15,
                        message: "Máximo 15 caracteres",
                      },
                      validate: (value) => {
                        if (value === getValues()["password"]) {
                          return true;
                        } else {
                          return "Las claves no coinciden";
                        }
                      },
                    })}
                    maxLength={15}
                  />
                  <div
                    className="visible-password"
                    onClick={() => setTypeInputRep(!typeInputRep)}
                  >
                    {!typeInputRep ? (
                      <BiHide style={{ fontSize: "20px" }} />
                    ) : (
                      <BiShow style={{ fontSize: "20px" }} />
                    )}
                  </div>
                </div>
                {errors.repPassword && (
                  <Form.Text className="text-danger">
                    {errors?.repPassword?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to="/admin/users">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading ? "Procesando..." : "Modificar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default FormChangePassword;

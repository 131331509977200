import { useDispatch, useSelector } from "react-redux";
import {
  getListParentCities,
  changeStatus,
  deleteParentCity,
  getSelectedRows,
  getLoading,
} from "actions/admin/parentCityAction";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useParentCity = () => {
  const dispatch = useDispatch();
  const {
    listParentCities,
    parentCityLoading,
    parentCityData,
    parentCityBtnActive,
  } = useSelector((state) => state.parentCities);

  const getAll = (countryId) => {
    if (countryId !== null) {
      dispatch(getLoading(true));
      dispatch(getListParentCities(countryId, "all"));
      dispatch(getSelectedRows(null));
    }
  };

  const handleActivarDes = () => {
    if (parentCityData !== null) {
      const statusName = parentCityData.active ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} el estado?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(parentCityData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (parentCityData !== null) {
      Swal.fire({
        title: "¿Desea eliminar el estado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteParentCity(parentCityData?.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listParentCities.filter(
      (item) =>
        item.name &&
        item.name
          .toString()
          ?.toLowerCase()
          .includes(filterText.toString()?.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRows(rows));
  };

  const state = {
    listParentCities,
    parentCityLoading,
    parentCityData,
    parentCityBtnActive,
  };

  const actions = {
    getAll,
    handleActivarDes,
    handleDelete,
    filteredItems,
    handleRowClicked,
  };

  return {
    state,
    actions,
  };
};

const DepositAccount = {
  columnas: [
    {
      name: "Sucursal",
      selector: (row, i) => row.city.name,
      sortable: true,
    },
    {
      name: "Banco",
      selector: (row, i) => row.name_bank,
      sortable: true,
    },
    {
      name: "Nro cuenta",
      selector: (row, i) => row.account_number,
      sortable: true,
    },
    {
      name: "Activo",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: true,
    },
  ],
};

export default DepositAccount;

import {
  SEARCH_CUSTOMER_CODE,
  LIST_CUSTOMER,
  SELECTED_ROWS_CLI,
  ACTIVE_CUSTOMER_CODE,
  LOADING_CUSTOMER,
} from "types/admin/customerType";

const INITIAL_STATE = {
  disabledCrearCliente: true,
  listCustomers: [],
  customerData: null,
  customerIsLoading: null,
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_CUSTOMER:
      return {
        ...state,
        customerIsLoading: action.payload.loading,
        customerData: action.payload.data,
      };
    case SEARCH_CUSTOMER_CODE:
      return {
        ...state,
        disabledCrearCliente: action.payload.status,
      };
    case LIST_CUSTOMER:
      return {
        ...state,
        listCustomers: action.payload.listAll,
        customerIsLoading: false,
      };
    case SELECTED_ROWS_CLI:
      return {
        ...state,
        customerData: action.payload.data,
      };
    case ACTIVE_CUSTOMER_CODE:
      return {
        ...state,
        listCustomers: action.payload.listAll,
        customerData: action.payload.data,
      };
    default:
      return state;
  }
};

export default customerReducer;

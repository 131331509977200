const DetalleTablaMensual = ({
  numero_orden,
  fecha_orden,
  oficina_origen,
  oficina_destino,
  sucursal_origen,
  sucursal_destino,
  bultos,
  kilos,
  tipo_entrega,
  total,
  estado,
  fecha_estado,
}) => {
  return (
    <tr>
      <td>{numero_orden}</td>
      <td>{fecha_orden}</td>
      <td>{fecha_estado}</td>
      <td>{oficina_origen}</td>
      <td>{oficina_destino}</td>
      <td>{sucursal_origen}</td>
      <td>{sucursal_destino}</td>
      <td>{estado}</td>
      <td>{bultos}</td>
      <td>{kilos}</td>
      <td>{total}</td>
      <td>{tipo_entrega}</td>
    </tr>
  );
};

export default DetalleTablaMensual;

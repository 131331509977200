import React, { forwardRef } from "react";
//import { formatMonto } from "../../../config/formatInput"
import { InputGroup, FormControl, Button } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { isAfter, getDateYMD } from "utils/formatFecha";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { BiCalendar } from "react-icons/bi";
import { formatNumberCurrency } from "utils/validationInput";
registerLocale("es", es);

const TbodyOrden = ({
  index,
  numero_orden,
  fecha_orden,
  sucursal_origen,
  fecha_orden_now,
  sucursal_destino,
  total,
  register,
  forma_pago_name,
  control,
  fecha_orden_format,
}) => {
  const validateDateBlur = (props, event) => {
    const { message, error, dateSelected } = getDateYMD(event.target.value);

    if (error) {
      toast.error(message, { position: toast.POSITION.TOP_RIGHT });
      props.onChange(new Date());
      return false;
    }

    if (isAfter(dateSelected, dayjs())) {
      toast.error(
        "La fecha ingresada no puede ser superior a la fecha actual",
        { position: toast.POSITION.TOP_RIGHT }
      );
      props.onChange(new Date());
      return false;
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl name="fecha_orden_c" value={value} readOnly />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={onClick} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <tr key={index}>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].numero_orden`}
          value={numero_orden}
          ref={register}
        />
        {numero_orden}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].fecha_orden`}
          value={fecha_orden}
          ref={register}
        />
        {fecha_orden}
      </td>
      <td>
        <Controller
          control={control}
          name={`ordenes[${index}].fecha_orden_now`}
          //defaultValue={fecha_orden_format}

          render={(props) => (
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="Seleccione la fecha"
              onChange={(e) => props.onChange(e)}
              selected={props.value}
              className="form-control"
              id={`ordenes[${index}].fecha_orden_now`}
              maxDate={new Date()}
              readonly
              locale="es"
              onBlur={(e) => validateDateBlur(props, e)}
              customInput={<ExampleCustomInput />}
            />
          )}
        />
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].sucursal_origen`}
          value={sucursal_origen}
          ref={register}
        />
        {sucursal_origen}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].sucursal_destino`}
          value={sucursal_destino}
          ref={register}
        />
        {sucursal_destino}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].total`}
          value={total}
          ref={register}
        />
        {formatNumberCurrency(total)}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].forma_pago_name`}
          value={forma_pago_name}
          ref={register}
        />
        {forma_pago_name}
      </td>
    </tr>
  );
};

export default TbodyOrden;

import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Layout from "components/Layouts/Layout";
import TitlePrincipal from "components/Layouts/TitlePrincipal";
import Search from "components/Rendicion/Search";
import DetalleSucursal from "components/Rendicion/DetalleSucursal";

import DetalleDeposito from "components/Rendicion/Depositos/DetalleDeposito";
import DetalleObservacion from "components/Rendicion/Observaciones/DetalleObservacion";
import DetalleEdicion from "components//Rendicion/EdicionManual/DetalleEdicion";
import LoadingSearch from "components/Utilidades/LoadingSearch";

import { usePermission } from "hooks/Permission/usePermission";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addRendirCaja,
  exportarExcelCaja,
  reabrirCajaCerrada,
} from "actions/rendirAction";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const Rendir = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, getValues, control } =
    useForm();
  const { validarPermiso } = usePermission();

  const { rendir } = useSelector((state) => {
    return {
      rendir: state.rendir,
    };
  });

  const { listCities } = useSelector((state) => state.cities);
  const {
    listadoVenta,
    listadoRecepcion,
    rendirCtdo,
    rendirPorPagar,
    rendirTotal,
    listadoDepositos,
    isCajaCerrada,
    isInitial,
    edicionManual,
    search,
    diferencia,
  } = rendir;

  const [isVisibleMessage, setIsVisibleMessage] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);

  useEffect(() => {
    if (listCities.length === 0) {
      history.push("/cash-accountability");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data, event) => {
    let message = "";

    if (diferencia <= 0) {
      message = "¿Seguro desea cerrar la caja?";
    } else if (diferencia > 0) {
      message =
        "La caja esta descuadrada, ¿Está seguro que desea cerrar la caja?";
    }

    Swal.fire({
      title: message,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsVisibleMessage(true);
        const information = {
          ...data,
          depositos: listadoDepositos,
          sucursal_id: document.getElementById("sucursal_id").value,
          fecha: document.getElementById("fecha").value,
        };

        const response = await dispatch(addRendirCaja(information));
        if (response.status) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setValue("comentarios", "");
          //dispatch(cleanListadoSucursal())
        } else {
          toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        }
      }
      setIsVisibleMessage(false);
    });
  };

  const exportarExcel = async () => {
    setIsLoadingExcel(true);
    const data = {
      sucursal_id: document.getElementById("sucursal_id").value,
      fecha: document.getElementById("fecha").value,
    };

    await dispatch(exportarExcelCaja(data));
    setIsLoadingExcel(false);
  };

  const reabrirCaja = async (event) => {
    event.preventDefault();

    Swal.fire({
      title: "¿Seguro desea abrir la caja?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoadingOpen(true);
        const data = {
          sucursal_id: document.getElementById("sucursal_id").value,
          fecha: document.getElementById("fecha").value,
        };
        const response = await dispatch(reabrirCajaCerrada(data));
        if (response.status) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        }
      }
      setIsLoadingOpen(false);
    });
  };

  return (
    <Layout
      titlePanel="Rendición de caja"
      isIcon={true}
      ruta="cash-accountability"
      modulo="cerrar_caja"
    >
      <TitlePrincipal title="USUARIO O SUCURSAL" />
      <Row className="mb-1">
        <Col className="contenedor-cliente bg-soft-blue">
          <Form.Row>
            <Search
              toast={toast}
              setIsLoadingSearch={setIsLoadingSearch}
              searchData={search}
              isEdit={false}
            />
          </Form.Row>
        </Col>
      </Row>
      {isLoadingSearch && <LoadingSearch />}
      {!isLoadingSearch && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <DetalleSucursal
              title="VENTAS SUCURSAL"
              clase="pr-4"
              listado={listadoVenta}
              rendirCtdo={rendirCtdo}
              rendirPorPagar={rendirPorPagar}
              rendirTotal={rendirTotal}
              aplica={true}
              register={register}
            />
            <DetalleSucursal
              title="RECEPCIÓN DEL DIA SUCURSAL"
              listado={listadoRecepcion}
              aplica={false}
              register={register}
            />
          </Row>
          {!isInitial && (
            <>
              {edicionManual?.length > 0 && (
                <DetalleEdicion edicionManual={edicionManual} />
              )}
              <DetalleDeposito
                register={register}
                errors={errors}
                toast={toast}
                control={control}
              />
              <DetalleObservacion
                register={register}
                errors={errors}
                toast={toast}
                getValues={getValues}
                setValue={setValue}
              />
              <Row className="bg-soft-blue">
                <Col md={{ span: 6, offset: 3 }} xs={12}>
                  <Form.Row className="pt-3 d-flex justify-content-center">
                    {isCajaCerrada === false ? (
                      <>
                        {validarPermiso("reabrir_caja") && (
                          <Form.Group as={Col} className="mt-3 pl-0">
                            <Button
                              variant="outline-warning"
                              type="button"
                              disabled={isLoadingOpen}
                              block
                              onClick={(event) => reabrirCaja(event)}
                            >
                              {isLoadingOpen ? "ABRIENDO..." : "ABRIR CAJA"}
                            </Button>
                          </Form.Group>
                        )}
                        <Form.Group as={Col} className="mt-3 pl-0">
                          <Button
                            variant="outline-warning"
                            type="button"
                            disabled={isLoadingExcel}
                            block
                            onClick={(event) => exportarExcel(event)}
                          >
                            {isLoadingExcel
                              ? "DESCARGANDO..."
                              : "EXPORTAR A EXCEL"}
                          </Button>
                        </Form.Group>
                      </>
                    ) : (
                      <Form.Group as={Col} className="mt-3 pl-0 col-md-8">
                        <Button
                          variant="outline-warning"
                          type="submit"
                          block
                          disabled={isVisibleMessage}
                        >
                          {isVisibleMessage
                            ? "PROCESANDO..."
                            : "CERRAR RENDICIÓN DE CAJA"}
                        </Button>
                      </Form.Group>
                    )}
                  </Form.Row>
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}
      <ToastContainer />
    </Layout>
  );
};

export default Rendir;

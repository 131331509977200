import { useDispatch, useSelector } from "react-redux";
import {
  getListActivityAll,
  changeStatus,
  deleteActivity,
  getSelectedRowsActivity,
  getLoading,
} from "actions/admin/activityAction";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useActivities = () => {
  const dispatch = useDispatch();
  const { listActivities, activityIsLoading, activityData } = useSelector(
    (state) => state.activities
  );

  const getAll = async (countryId) => {
    if (!countryId || countryId === null) {
      return;
    }

    dispatch(getLoading(true));
    dispatch(getListActivityAll(countryId));
    dispatch(getSelectedRowsActivity(null));
  };

  const handleActivarDes = () => {
    if (activityData !== null) {
      const statusName = activityData.active ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} el giro?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(activityData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (activityData !== null) {
      Swal.fire({
        title: "¿Desea eliminar el giro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let id = activityData.id;
          const response = await dispatch(deleteActivity(id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listActivities.filter(
      (item) =>
        item.description &&
        item.description
          .toString()
          ?.toLowerCase()
          .includes(filterText.toString()?.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRowsActivity(rows));
  };

  const state = {
    listActivities,
    activityIsLoading,
    activityData,
  };

  const actions = {
    getAll,
    handleActivarDes,
    handleDelete,
    filteredItems,
    handleRowClicked,
  };

  return {
    state,
    actions,
  };
};

import { useEffect } from "react";
import { Form, Button, Col } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import Sucursales from "components/Orders/Sucursales";
import Clientes from "components/Orders/Clientes/Principal";
import DetalleCarga from "components/Orders/Carga/DetalleCarga";
import Observaciones from "components/Orders/Observaciones";
import Pagos from "components/Orders/Pagos";
import ModalFactura from "components/Factura/ModalFactura";
import { useCreateTransportOrder } from "hooks/Orders/useCreateTransportOrder";

const CreatedOrder = () => {
  const { actions, formElement, state } = useCreateTransportOrder();

  const { isLoading, hidden, companyDataBasic, listCities } = state;
  const { register, handleSubmit, errors } = formElement;
  const { onSubmit, getDatInitial } = actions;

  useEffect(() => {
    getDatInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      titlePanel="Orden de flete manual"
      isIcon={false}
      ruta="home"
      modulo="orders_create"
    >
      <Form id="frmOrders" onSubmit={handleSubmit(onSubmit)}>
        <Sucursales
          formElement={formElement}
          stateOrden={state}
          initial={false}
          editOrden={false}
          listCities={listCities}
          actionsGlobal={actions}
        />
        <Clientes
          formElement={formElement}
          editOrden={false}
          stateOrden={state}
          companyDataBasic={companyDataBasic}
        />
        <DetalleCarga
          formElement={formElement}
          stateOrden={state}
          actionsOrden={actions}
          addOrden={true}
        />
        <Pagos
          formElement={formElement}
          actionsOrden={actions}
          stateOrden={state}
          editOrden={false}
        />
        <Observaciones register={register} errors={errors} />
        <Form.Row className="mb-5 ml-2 mr-2 mt-3">
          <Col sm={12} className="d-group-container">
            <div className="d-group-button">
              <Button
                variant="outline-warning"
                className="btn-orden mr-2"
                type="button"
                block
                disabled={true}
              >
                ANULAR ORDEN
              </Button>
              <Button
                variant="outline-warning"
                className="btn-orden mt-0"
                type="button"
                block
                disabled={true}
              >
                IMPRIMIR ORDEN
              </Button>
            </div>
            <Button
              className="btn-size"
              variant="warning"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "PROCESANDO..." : "GUARDAR"}
            </Button>
          </Col>
        </Form.Row>
      </Form>

      {hidden && (
        <ModalFactura
          title={"FACTURA A NOMBRE DE:"}
          nameModulo="orden"
          stateOrder={state}
          actionsOrder={actions}
        />
      )}
    </Layout>
  );
};

export default CreatedOrder;

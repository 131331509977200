import API from "config/config-api";
import {
  LIST_DATA_OFFICE,
  SELECTED_ROWS_OFFICE,
  ACTIVE_OFFICE,
  LOADING_OFFICE,
} from "types/admin/officeType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_OFFICE, payload));
};

export const getListOfficeAll = (city_id) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.office.list(city_id));
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_OFFICE, data));
    return { status: status, data: data, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRowsOffice = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_OFFICE, payload));
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listOffices, officeData } = getState().offices;

    const body = {
      type: "estatus",
      option: officeData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.office.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = listOffices.map((office) => {
        if (office.id === id) {
          return { ...office, active: !officeData.active };
        }
        return office;
      });

      const payload = {
        data: {
          ...officeData,
          active: !officeData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(ACTIVE_OFFICE, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteOffice = (id) => async (dispatch, getState) => {
  try {
    const { listOffices } = getState().offices;

    let response = await API.delete(endPoints.office.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: [...listOffices].filter((country) => country.id !== id),
        data: null,
      };

      dispatch(requestSuccess(ACTIVE_OFFICE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addOffice = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.office.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editOffice = (body, officeId) => async (dispatch, getState) => {
  try {
    let response = await API.put(endPoints.office.edit(officeId), body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchOfficeById = (officeId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.office.getOne(officeId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

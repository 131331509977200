import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addTruck,
  editTruck,
  searchTruckById,
} from "actions/admin/truckAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormTruck = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTruck, setDataTruck] = useState(null);

  const { truckData } = useSelector((state) => state.trucks);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;
    if (data?.internal_number?.startsWith("0")) {
      setIsLoading(false);
      toast.error("El número interno no debe comenzar con 0", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (data?.plate_number?.startsWith("0")) {
      setIsLoading(false);
      toast.error("La palca no debe comenzar con 0", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const body = {
      ...data,
      plate_number: data?.plate_number?.toUpperCase(),
    };

    if (dataTruck?.id) {
      response = await dispatch(editTruck(body, dataTruck?.id));
    } else {
      response = await dispatch(addTruck(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);

      setTimeout(() => {
        history.push("/admin/trucks");
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getTruckById = async (truckId) => {
    let data = truckData;

    if (truckId !== null) {
      if (truckData === null) {
        const result = await dispatch(searchTruckById(truckId));
        data = result?.data;
      }
    }
    reset({
      plate_number: data?.plate_number ?? "",
      internal_number: data?.internal_number ?? "",
    });

    setDataTruck(truckId !== null ? data ?? null : null);
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };

  const actions = {
    getTruckById,
  };

  return {
    formElement,
    actions,
  };
};

import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import logo from "assets/images/logo.png";
import { useResetPassword } from "hooks/Auth/useResetPassword";
import MessageDanger from "components/Utilidades/MessageDanger";
import { Link } from "react-router-dom";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import circle_success from "assets/images/circle-success.svg";

const ResetPasswordUser = () => {
  const { actions, state, formElement } = useResetPassword();
  const {
    data,
    typeInputRep,
    typeInput,
    isSuccess,
    validToken,
    loadingSearch,
  } = state;
  const {
    getVerifyToken,
    setTypeInput,
    setTypeInputRep,
    goToLogin,
    validatePassword,
  } = actions;
  const {
    register,
    handleSubmit,
    onSubmit,
    loading,
    errors,
    dangerMessage,
    getValues,
  } = formElement;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    getVerifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md={7} sm={12} className="col-login--image order-md-0 order-1">
          <Image src={logo} fluid className="img-login mb-5" />
          <h1 className="text-white">Verificación de Usuario</h1>
        </Col>
        {loadingSearch && (
          <Col md={5} sm={12} className="col-login-form my-auto text-center">
            <div>
              <div>
                <Spinner animation="grow" variant="dark" />
                <Spinner animation="grow" variant="dark" />
                <Spinner animation="grow" variant="dark" />
                <Spinner animation="grow" variant="dark" />
              </div>
              <p>Espere unos segundos...</p>
            </div>
          </Col>
        )}
        {!isSuccess && validToken?.status && (
          <Col
            md={5}
            sm={12}
            className="col-login-form my-auto order-md-1 order-0"
          >
            <h3>Bienvenido, {data?.fullName}</h3>
            <p>
              Por favor, complete su verificación para tener acceso al sistema
              de gestión de paquetes de {data?.company}.
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {dangerMessage && !dangerMessage.status && (
                <MessageDanger color="danger" message={dangerMessage.message} />
              )}
              <Form.Group controlId="password">
                <Form.Label>Contraseña</Form.Label>

                <div className="p-relative">
                  <Form.Control
                    type={!typeInput ? "password" : "text"}
                    name="password"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                      minLength: { value: 9, message: "Mínimo 9 caracteres" },
                      maxLength: {
                        value: 15,
                        message: "Máximo 15 caracteres",
                      },
                      //eslint-disable-next-line
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
                        message:
                          "La contraseña debe tener: al menos 9 caracteres, 1 número, 1 carácter en minúscula, 1 carácter en mayúscula y 1 carácter especial",
                      },
                      validate: validatePassword,
                    })}
                    maxLength={15}
                  />
                  <div
                    className="visible-password"
                    onClick={() => setTypeInput(!typeInput)}
                  >
                    {!typeInput ? (
                      <BiHide style={{ fontSize: "20px" }} />
                    ) : (
                      <BiShow style={{ fontSize: "20px" }} />
                    )}
                  </div>
                </div>
                {errors.password && (
                  <Form.Text className="text-danger mt-0">
                    {errors?.password?.message}
                  </Form.Text>
                )}
                <Link to="/home" className="text-secondary d-none">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Form.Group>

              <Form.Group controlId="repPassword">
                <Form.Label>Repetir contraseña</Form.Label>

                <div className="p-relative">
                  <Form.Control
                    type={!typeInputRep ? "password" : "text"}
                    name="repPassword"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                      minLength: { value: 8, message: "Mínimo 8 caracteres" },
                      maxLength: {
                        value: 15,
                        message: "Máximo 15 caracteres",
                      },
                      validate: (value) => {
                        if (value === getValues()["password"]) {
                          return true;
                        } else {
                          return "Las claves no coinciden";
                        }
                      },
                    })}
                    maxLength={15}
                  />
                  <div
                    className="visible-password"
                    onClick={() => setTypeInputRep(!typeInputRep)}
                  >
                    {!typeInputRep ? (
                      <BiHide style={{ fontSize: "20px" }} />
                    ) : (
                      <BiShow style={{ fontSize: "20px" }} />
                    )}
                  </div>
                </div>
                {errors.repPassword && (
                  <Form.Text className="text-danger mt-0">
                    {errors?.repPassword?.message}
                  </Form.Text>
                )}
                <Link to="/home" className="text-secondary d-none">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Form.Group>
              <Form.Group className="mt-5">
                <Button
                  variant="warning"
                  type="submit"
                  block
                  disabled={loading}
                >
                  {loading ? "PROCESANDO..." : "ESTABLECER CONTRASEÑA"}
                </Button>
              </Form.Group>
            </Form>
          </Col>
        )}
        {isSuccess && validToken?.status && (
          <Col
            md={5}
            sm={12}
            className="col-login-form my-auto order-md-1 order-0 text-center"
          >
            <h3 className="mb-3">Su usuario fue verificado exitosamente.</h3>
            <div className={"mx-auto-circle"}>
              <img src={circle_success} alt="Login" width={230} height={128} />
            </div>
            <p>{dangerMessage.message}</p>
          </Col>
        )}
        {!validToken?.status && (
          <Col md={5} sm={12} className="col-login-form my-auto text-center">
            <h5>{validToken?.message}</h5>
            <Button
              variant="warning"
              type="button"
              block
              className="mt-5"
              onClick={goToLogin}
            >
              Ir al inicio de sesión
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ResetPasswordUser;

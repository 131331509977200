import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateEntregarOrden } from "actions/transportOrdersAction";
//import { formatMonto } from "config/formatInput"
import { validarRut } from "utils/rut";
import { searchCustomer } from "actions/admin/customerAction";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  validateOnlyLetter,
  validateOnlyNumber,
  handlePasteOnlyLetter,
  handlePasteOnlyNumber,
} from "utils/validationInput";

const TbodyOrden = ({
  index,
  numero_orden,
  orden_flete_id,
  total,
  documentacion_id,
  nro_documento,
  check,
  estado,
  register,
  documentacions,
  forma_pago,
  changeTotales,
  metodo_pago_id,
  nro_operacion,
  rut_recepcion,
  nombre_recepcion,
  metodo_pago,
  toast,
  rut_remitente,
  rut_destinatario,
  forma_pago_id,
  control,
}) => {
  const dispatch = useDispatch();

  const { companyDataBasic } = useSelector((state) => state.companies);

  const handleChange = (event, orden_flete_id) => {
    const param = event.target.name.split(".")[1];
    const valor = event.target.value;

    dispatch(updateEntregarOrden(orden_flete_id, param, valor));
  };

  const onKeyDownHandler = (event, orden_flete_id) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const rut = event.target.value;
      buscarCliente(rut, orden_flete_id);
    }
  };

  const onBlurHandle = (event) => {
    const rut = event.target.value;
    buscarCliente(rut, orden_flete_id);
  };

  const buscarCliente = async (rut, orden_flete_id) => {
    let search;
    if (rut.length > 0) {
      const isValid = validarRut(rut);
      if (isValid.status) {
        search = { search_type: "rut_cliente", value: rut };
      } else {
        toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
        return false;
      }

      const result = await dispatch(searchCustomer(search));

      if (result.status) {
        dispatch(
          updateEntregarOrden(
            orden_flete_id,
            "nombre_recepcion",
            result.data.full_name
          )
        );
      } else {
        dispatch(updateEntregarOrden(orden_flete_id, "nombre_recepcion", ""));
      }
    }
  };

  const handleRemoveEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <tr key={index}>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].orden_flete_id`}
          value={orden_flete_id}
          ref={register}
        />
        {numero_orden}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].forma_pago`}
          value={forma_pago}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].forma_pago_id`}
          value={forma_pago_id}
          ref={register}
        />
        {forma_pago}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].total`}
          value={total}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].rut_remitente`}
          value={rut_remitente}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].rut_destinatario`}
          value={rut_destinatario}
          ref={register}
        />
        {total}
      </td>
      <td>
        {!check ? (
          <Form.Control
            as="select"
            name={`ordenes[${index}].documentacion_id`}
            value={documentacion_id}
            onChange={(event) => handleChange(event, orden_flete_id)}
            ref={register}
          >
            <option value="">Seleccione</option>
            {documentacions.length > 0 &&
              documentacions.map((documents) => (
                <option key={documents.id} value={documents.id}>
                  {documents.description}
                </option>
              ))}
          </Form.Control>
        ) : (
          documentacion_id
        )}
      </td>
      <td>
        {!companyDataBasic?.invoice && !check ? (
          <Form.Control
            type="text"
            name={`ordenes[${index}].nro_documento`}
            value={nro_documento || ""}
            onChange={(event) => handleChange(event, orden_flete_id)}
            ref={register}
            onKeyPress={(event) => validateOnlyNumber(event)}
            maxLength={25}
            onPaste={(event) => handlePasteOnlyNumber(event)}
          />
        ) : (
          nro_documento
        )}
      </td>
      <td>
        <Controller
          render={(props) => (
            <MaskedInput
              mask={RutTextMask}
              className="form-control"
              name={`ordenes[${index}].rut_recepcion`}
              value={props.value}
              defaultValue={rut_recepcion || ""}
              maxLength={12}
              onChange={(value) => {
                props.onChange(value);
                handleChange(value, orden_flete_id);
              }}
              onKeyDown={(event) => onKeyDownHandler(event, orden_flete_id)}
              onBlur={(event) => onBlurHandle(event, orden_flete_id)}
              onKeyPress={(event) => {
                if (!/[0-9Kk.-]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          )}
          control={control}
          name={`ordenes[${index}].rut_recepcion`}
          ref={register}
        />
        {/**<Form.Control 
			    	type="text"
	                name={`ordenes[${index}].rut_recepcion`}
	                value={rut_recepcion || ''}
	                onChange = {(event) => handleChange(event, orden_flete_id)}
	                ref={register}
	                onKeyDown={(event) => onKeyDownHandler(event, orden_flete_id)}
	                onBlur={(event) => onBlurHandle(event, orden_flete_id)}
			    /> */}
      </td>
      <td>
        <Form.Control
          type="text"
          name={`ordenes[${index}].nombre_recepcion`}
          value={nombre_recepcion || ""}
          onChange={(event) => handleChange(event, orden_flete_id)}
          onKeyDown={(event) => handleRemoveEnter(event)}
          ref={register}
          onKeyPress={(event) => validateOnlyLetter(event)}
          onPaste={(event) => handlePasteOnlyLetter(event)}
        />
      </td>
      <td>
        {!check ? (
          <Form.Control
            as="select"
            name={`ordenes[${index}].metodo_pago_id`}
            value={metodo_pago_id}
            onChange={(event) => handleChange(event, orden_flete_id)}
            ref={register}
          >
            <option value="">Seleccione</option>
            {metodo_pago.length > 0 &&
              metodo_pago.map((pago) => (
                <option key={pago.id} value={pago.id}>
                  {pago.description}
                </option>
              ))}
          </Form.Control>
        ) : (
          ""
        )}
      </td>
      <td>
        {!check ? (
          <Form.Control
            type="text"
            name={`ordenes[${index}].nro_operacion`}
            value={nro_operacion || ""}
            onChange={(event) => handleChange(event, orden_flete_id)}
            onKeyDown={(event) => handleRemoveEnter(event)}
            ref={register}
            onKeyPress={(event) => validateOnlyNumber(event)}
            onPaste={(event) => handlePasteOnlyNumber(event)}
          />
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

export default TbodyOrden;

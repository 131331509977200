import API from "config/config-api";
import {
  LIST_DATA_DEPOSIT_ACCOUNT,
  SELECTED_ROWS_DEPOSIT_ACCOUNT,
  DATA_ACTIVE_DEPOSIT_ACCOUNT,
  LOADING_DEPOSIT_ACCOUNT,
} from "types/admin/depositAccountType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_DEPOSIT_ACCOUNT, payload));
};

export const getListAccount =
  (typeFilter, city_id = 0) =>
  async (dispatch, getState) => {
    try {
      let response = await API.get(
        endPoints.depositAccount.list(typeFilter, city_id)
      );

      const { status, message, data } = response.data;
      dispatch(requestSuccess(LIST_DATA_DEPOSIT_ACCOUNT, data));
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_DEPOSIT_ACCOUNT, payload));
};

export const addAccount = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.depositAccount.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editAccount = (body, accountId) => async (dispatch, getState) => {
  try {
    let response = await API.put(
      endPoints.depositAccount.edit(accountId),
      body
    );
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listAccount, accountData } = getState().accounts;

    const body = {
      type: "status",
      option: accountData.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.depositAccount.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = listAccount.map((account) => {
        if (account.id === id) {
          return { ...account, active: !accountData.active };
        }
        return account;
      });

      const payload = {
        data: {
          ...accountData,
          active: !accountData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(DATA_ACTIVE_DEPOSIT_ACCOUNT, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteAccount = (id) => async (dispatch, getState) => {
  try {
    const { listAccount } = getState().accounts;

    let response = await API.delete(endPoints.depositAccount.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listAccount.filter((account) => account.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_ACTIVE_DEPOSIT_ACCOUNT, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchAccountById = (accountId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.depositAccount.getOne(accountId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/Drives/Filter";
import drive from "components/Admin/Columnas/Drive";
import { useDrive } from "hooks/Admin/Users/useDrive";

const ListDrive = () => {
  const { state, actions } = useDrive();
  const { driveIsLoading } = state;
  const { getAll, filteredItems, handleRowClicked } = actions;

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_drives">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de conductores">
          <DataTableCustomer
            columns={drive.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={driveIsLoading}
            FilterComponent={Filter}
            selectableRows={false}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListDrive;

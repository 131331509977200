import API from "config/config-api";
import {
  LIST_DATA_ACTIVITY,
  SELECTED_ROWS_ACTIVITY,
  DATA_ACTIVE_ACTIVITY,
  LOADING_ACTIVITY,
} from "types/admin/activityType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_ACTIVITY, payload));
};

export const getListActivityAll =
  (country_id) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.activity.list(country_id));
      const { status, message, data } = response.data;
      dispatch(requestSuccess(LIST_DATA_ACTIVITY, data));
      return { status: status, data: data, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const addActivity = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.activity.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editActivity =
  (body, activityId) => async (dispatch, getState) => {
    try {
      let response = await API.put(endPoints.activity.edit(activityId), body);
      const { status, message } = response.data;

      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const searchActivityById =
  (activityId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.activity.getOne(activityId));

      const { status, message, data } = response.data;

      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const getSelectedRowsActivity = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_ACTIVITY, payload));
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listActivities, activityData } = getState().activities;

    const body = {
      type: "status",
      option: activityData.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.activity.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = [...listActivities].map((activity) => {
        if (activity.id === id) {
          return { ...activity, active: !activityData.active };
        }
        return activity;
      });

      const payload = {
        data: {
          ...activityData,
          active: !activityData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(DATA_ACTIVE_ACTIVITY, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteActivity = (id) => async (dispatch, getState) => {
  try {
    const { listActivities } = getState().activities;

    let response = await API.delete(endPoints.activity.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: [...listActivities].filter((activity) => activity.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_ACTIVE_ACTIVITY, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

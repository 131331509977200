import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getListTaxes, addTax } from "actions/admin/taxAction";
import { toast } from "react-toastify";

export const useTaxes = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    //getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      tasa: "",
    },
  });

  const { listTaxes, taxData, taxtIsLoading } = useSelector(
    (state) => state.taxes
  );

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let response = await dispatch(addTax(data));

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setIsLoading(false);
  };

  const setDataInicial = async (data) => {
    if (data !== null && Object.keys(data).length > 0) {
      reset({
        tasa: data?.tasa ?? "",
      });
    }
  };

  const getAll = async () => {
    await dispatch(getListTaxes());
  };

  return {
    isLoading,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
    setDataInicial,
    taxData,
    listTaxes,
    getAll,
    taxtIsLoading,
  };
};

const Camion = {
  columnas: [
    {
      name: "Placa",
      selector: (row, i) => row.plate_number,
      sortable: true,
    },
    {
      name: "Número interno",
      selector: (row, i) => row.internal_number,
      sortable: true,
    },
    {
      name: "Activo",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: true,
    },
  ],
};

export default Camion;

import { useDispatch, useSelector } from "react-redux";

import {
  getListRole,
  getListPermission,
  deleteRole,
  getSelectedRowsRole,
  getLoading,
} from "actions/admin/roleAction";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useRole = () => {
  const dispatch = useDispatch();
  const { listRole, roleIsLoading, rolData, listPermissions } = useSelector(
    (state) => state.roles
  );
  const filteredItems = (filterText) => {
    return listRole.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(filterText.toString()?.toLowerCase())
    );
  };

  const getAllInitial = () => {
    dispatch(getLoading(true));
    dispatch(getListRole("all"));
    getPermissions();
    window.scrollTo(0, 0);
  };

  const getPermissions = () => {
    if (listPermissions?.length === 0) {
      dispatch(getListPermission());
    }
  };

  const handleDelete = () => {
    if (rolData !== null) {
      Swal.fire({
        title: "¿Desea eliminar el rol?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let id = rolData.id;
          const response = await dispatch(deleteRole(id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRowsRole(rows));
  };

  const state = {
    listRole,
    roleIsLoading,
    rolData,
    filteredItems,
  };

  const actions = {
    getAllInitial,
    handleDelete,
    handleRowClicked,
    getPermissions,
  };

  return {
    state,
    actions,
  };
};

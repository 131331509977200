const Cliente = {
  columnas: [
    {
      name: "Código interno",
      selector: (row, i) =>
        row.accountNumbers
          ?.map((account) => account.internal_number)
          .join(", "),
      sortable: true,
    },
    {
      name: "RUT",
      selector: (row, i) => row.customer?.rut,
      sortable: true,
    },
    {
      name: "Nombre y Apellido",
      selector: (row, i) => row.customer?.full_name,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: (row, i) => row.customer?.email,
      sortable: true,
    },
    {
      name: "Dirección",
      selector: (row, i) => row.customer?.address,
      sortable: true,
    },
    {
      name: "Teléfono",
      selector: (row, i) => row.customer?.phone_number,
      sortable: true,
    },
    {
      name: "Rubro",
      selector: (row, i) => row.customer?.tax_draft,
      sortable: true,
    },
    {
      name: "Activo",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: true,
    },
  ],
};

export default Cliente;

import {
  LIST_DATA_PARENT_CITY,
  SELECTED_ROWS_PARENT_CITY,
  DATA_ACTIVE_PARENT_CITY,
  LOADING_PARENT_CITY,
} from "types/admin/parentCityType";

const INITIAL_STATE = {
  listParentCities: [],
  parentCityLoading: true,
  parentCityData: null,
};

const parentCityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_PARENT_CITY:
      return {
        ...state,
        parentCityLoading: action.payload.loading,
        parentCityData: action.payload.data,
      };
    case LIST_DATA_PARENT_CITY:
      return {
        ...state,
        listParentCities: action.payload,
        parentCityLoading: false,
      };
    case SELECTED_ROWS_PARENT_CITY:
      return {
        ...state,
        parentCityData: action.payload.data,
      };
    case DATA_ACTIVE_PARENT_CITY:
      return {
        ...state,
        parentCityData: action.payload.data,
        listParentCities: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default parentCityReducer;

import axios from "axios";

const getToken = () => {
  let token = null;

  if (typeof window !== "undefined") {
    try {
      token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;
    } catch (error) {
      token = null;
    }
  }

  return token;
};

const axiosInstance = axios.create({
  validateStatus() {
    return true;
  },
  withCredentials: false,
});

const getHeaders = (isFormData = false, notApplyJson = false) => {
  let headers = {
    "Content-type": !isFormData
      ? "application/json"
      : "application/x-www-form-urlencoded",
  };
  if (notApplyJson) {
    headers = { ...headers, "X-Requested-With": "XMLHttpRequest" };
  }
  const token = getToken();

  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  return { headers };
};

const API = {
  get: (endPoint) => {
    return axiosInstance.get(endPoint, getHeaders());
  },
  post: (endPoint, body, isFormData, notApplyJson) => {
    return axiosInstance.post(endPoint, body, getHeaders(isFormData));
  },
  postDownload: (endPoint, body, isFormData) => {
    return axiosInstance.post(endPoint, body, getHeaders(isFormData));
  },
  put: (endPoint, body, isFormData) => {
    return axiosInstance.put(endPoint, body, getHeaders(isFormData));
  },
  patch: (endPoint, body, isFormData) => {
    return axiosInstance.patch(endPoint, body, getHeaders(isFormData));
  },
  delete: (endPoint) => {
    return axiosInstance.delete(endPoint, getHeaders());
  },
};

export default API;

import { useDispatch, useSelector } from "react-redux";
import {
  getListCities,
  changeStatus,
  deleteCity,
  getSelectedRows,
  getLoading,
} from "actions/admin/cityAction";
import { getListParentCities } from "actions/admin/parentCityAction";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useCities = () => {
  const dispatch = useDispatch();
  const { listCities, cityIsLoading, cityData } = useSelector(
    (state) => state.cities
  );
  const { listParentCities } = useSelector((state) => state.parentCities);
  const { companyDataBasic } = useSelector((state) => state.companies);

  const getAll = () => {
    dispatch(getLoading(true));
    dispatch(getListCities("all"));
  };

  const getAllParentCities = () => {
    if (companyDataBasic !== null) {
      dispatch(getListParentCities(companyDataBasic?.country?.id, "active"));
    }
  };

  const handleActivarDes = () => {
    if (cityData !== null) {
      const statusName = cityData.active ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} la sucursal?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(cityData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (cityData !== null) {
      Swal.fire({
        title: "¿Desea eliminar la sucursal?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteCity(cityData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listCities.filter(
      (item) =>
        item.name &&
        item.name
          .toString()
          ?.toLowerCase()
          .includes(filterText.toString()?.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRows(rows));
  };

  const state = {
    listCities,
    cityIsLoading,
    cityData,
    listParentCities,
    companyDataBasic,
  };

  const actions = {
    getAll,
    handleActivarDes,
    handleDelete,
    filteredItems,
    handleRowClicked,
    getAllParentCities,
  };

  return {
    state,
    actions,
  };
};

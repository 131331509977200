import React, { Fragment, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { searchOrdenesInd } from "actions/transportOrdersAction";
import { useForm } from "react-hook-form";
import { handlePasteOnlyOrden, validateOnlyOrden } from "utils/validationInput";

const FormOrden = ({ toast }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setLoading(true);
    let ordenFormat = data?.numero_orden.replace("'", "-");
    setValue("numero_orden", ordenFormat);

    const response = await dispatch(searchOrdenesInd(ordenFormat, "devolver"));
    if (!response.status) {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    } else {
      setValue("numero_orden", "");
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Col md={3} sm={6}>
            <Form.Group as={Col} controlId="numero_orden">
              <Form.Label>N° de Orden</Form.Label>
              <Form.Control
                type="text"
                name="numero_orden"
                maxLength={15}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onKeyPress={(event) => validateOnlyOrden(event)}
                onPaste={(event) => handlePasteOnlyOrden(event)}
              />
              {errors.numero_orden && (
                <Form.Text className="text-danger">
                  {errors?.numero_orden?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={3} sm={6}>
            <Form.Group as={Col} controlId="numero_orden" className="mt-4">
              <Button
                variant="warning"
                type="submit"
                size="sm"
                block
                disabled={loading}
              >
                {loading ? "BUSCANDO" : "AGREGAR"}
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </Fragment>
  );
};

export default FormOrden;

const Drive = {
  columnas: [
    {
      name: "RUT",
      selector: (row, i) => row.rut,
      sortable: true,
    },
    {
      name: "Nombre y Apellido",
      selector: (row, i) => row.full_name,
      sortable: true,
    },
    {
      name: "Licencia conducir",
      selector: (row, i) => row.driver_license,
      sortable: true,
      cell: (row) => (
        <>
          {row.url_photo && (
            <a
              href={row.url_photo}
              target="_blank"
              rel="noopener noreferrer"
              key={row.id}
            >
              <img
                className="img-foto-licencia mr-2"
                src={row.url_photo}
                alt="licencia"
              />
              <span>{row.driver_license}</span>
            </a>
          )}
        </>
      ),
    },
    {
      name: "Vencimiento",
      selector: (row, i) => row.license_expiration_date,
      sortable: true,
    },
    {
      name: "Activo",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: true,
    },
  ],
};

export default Drive;

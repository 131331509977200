import { Row, Form, Col, Button, Card } from "react-bootstrap";
import { useFormContry } from "hooks/Admin/Countries/useFormContry";
import { Link } from "react-router-dom";

const Formulario = ({ toast, datos, paisesAll, countryId }) => {
  const { formElement, state, actions } = useFormContry();

  const { isLoading, register, handleSubmit, errors, onSubmit } = formElement;

  const { changePais } = actions;

  const { listCountry } = state;

  return (
    <Form id="frmPais" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="country_id">
                <Form.Label>País</Form.Label>
                <Form.Control
                  as="select"
                  name="country_id"
                  ref={register}
                  onChange={(event) => changePais(event)}
                >
                  <option value="">Seleccione</option>
                  {listCountry?.length > 0 &&
                    listCountry?.map((pais) => (
                      <option key={pais.id} value={pais.id}>
                        {pais.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.country_id && (
                  <Form.Text className="text-danger">
                    {errors?.country_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="phone_code">
                <Form.Label>Código teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="phone_code"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  defaultValue={datos?.phone_code || ""}
                  readOnly
                />
                {errors.phone_code && (
                  <Form.Text className="text-danger">
                    {errors?.phone_code?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="iso_code">
                <Form.Label>ISO code</Form.Label>
                <Form.Control
                  type="text"
                  name="iso_code"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  defaultValue={datos?.iso_code || ""}
                  readOnly
                />
                {errors.iso_code && (
                  <Form.Text className="text-danger">
                    {errors?.iso_code?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to={`/admin/countries`}>
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading ? "Procesando..." : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

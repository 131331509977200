import { Row, Button, Col, ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import Layout from "components/Layouts/Layout";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";
const baseURL = process.env.REACT_APP_API_URL;

const PreviewOrder = (props) => {
  const {
    match: { params: numero },
  } = props;
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(10);
  const { companyDataBasic } = useSelector((state) => state.companies);

  function startAnimation() {
    let value = 0;

    let timerId = setInterval(() => {
      value = value + 10;
      if (value < 80) {
        setCount((count) => value);
      } else {
        clearInterval(timerId);
      }
    }, 1000);
  }

  useEffect(() => {
    if (companyDataBasic !== null) {
      let iframe = document.querySelector("#printFrame");
      iframe.onload = function () {
        setLoading(false);
        setCount(0);
        clearInterval(startAnimation);
      };
      startAnimation();
    }
  }, [companyDataBasic]);

  return (
    <Layout titlePanel="Orden de flete manual" modulo="orders_create">
      <Row>
        <Col xs={12} className="mb-3 mt-3 text-right">
          <Link to={`/transport-orders/${numero.numero}/edit`}>
            <Button variant="warning" type="button">
              Regresar
            </Button>
          </Link>
          <Button
            variant="warning"
            className="ml-2"
            href="/transport-orders/create"
          >
            Crear nueva orden
          </Button>
        </Col>
        {loading && (
          <Col xs={12}>
            <ProgressBar animated now={count} />
            <p className="text-center">
              {loading ? "Cargando ticket de la orden..." : ""}
            </p>
          </Col>
        )}

        {companyDataBasic !== null && (
          <Col xs={12}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                id="printFrame"
                title="Preview Orden"
                className="embed-responsive-item"
                src={`${baseURL}/transport-orders/print/${numero.numero}/matriz/${companyDataBasic?.id}`}
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default withRouter(PreviewOrder);

import { Form } from "react-bootstrap";
import ModalFactura from "components/Factura/ModalFactura";
import DetalleTabla from "components/Encomienda/Intermediario/DetalleTabla";
import { useDelivertOtherOrder } from "hooks/Orders/useDelivertOtherOrder";

import TableSuccess from "components/Encomienda/TableSuccess";

const EntregaOtraPersona = ({ toast }) => {
  const { state, actions, formElement } = useDelivertOtherOrder();

  const { isLoading, hidden, listEntregada, companyDataBasic } = state;

  const {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  } = formElement;

  return (
    <>
      <Form id="frmEntregaIntermedio" onSubmit={handleSubmit(onSubmit)}>
        <DetalleTabla
          register={register}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          toast={toast}
          isLoad={isLoading}
          control={control}
        />

        {listEntregada.length > 0 && companyDataBasic?.invoice && (
          <TableSuccess />
        )}

        {hidden && (
          <ModalFactura
            title={"FACTURA A NOMBRE DE:"}
            nameModulo="orden"
            stateOrder={state}
            actionsOrder={actions}
          />
        )}
      </Form>
    </>
  );
};

export default EntregaOtraPersona;

import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import { useFormTruck } from "hooks/Admin/Trucks/useFormTruck";
import {
  validateOnlyNumber,
  onPaste,
  validateOnlyLetterNumberAll,
} from "utils/validationInput";
import { Link } from "react-router-dom";

const Formulario = ({ truckId }) => {
  const { actions, formElement } = useFormTruck();
  const { isLoading, register, handleSubmit, errors, onSubmit } = formElement;

  const { getTruckById } = actions;

  useEffect(() => {
    getTruckById(truckId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckId]);

  return (
    <Form id="frmCamion" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="plate_number">
                <Form.Label>Placa</Form.Label>
                <Form.Control
                  type="text"
                  name="plate_number"
                  autoFocus
                  //defaultValue={datos?.plate_number || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyLetterNumberAll(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={20}
                  className="text-uppercase"
                />
                {errors.plate_number && (
                  <Form.Text className="text-danger">
                    {errors?.plate_number?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="internal_number">
                <Form.Label>Número interno</Form.Label>
                <Form.Control
                  type="text"
                  name="internal_number"
                  //defaultValue={datos?.internal_number || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={5}
                />
                {errors.internal_number && (
                  <Form.Text className="text-danger">
                    {errors?.internal_number?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to="/admin/trucks">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden "
            >
              {isLoading
                ? "Procesando..."
                : truckId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

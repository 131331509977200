import {
  Form,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";
import { Fragment, useEffect } from "react";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  validateOnlyRUT,
  validateOnlyLetter,
  onPaste,
  validateOnlyNumber,
} from "utils/validationInput";
import ModalSelectNroCuenta from "components/Orders/Clientes/ModalSelectNroCuenta";
import { useTransportOrderOffice } from "hooks/Orders/useTransportOrderOffice";

const ClienteRemitente = ({ formElement, actions, state }) => {
  const { register, errors, setValue, control } = formElement;

  const {
    validarPermiso,
    validatePhone,
    cambiarFormaPago,
    onKeyDownRemitente,
    selectCodeClient,
    handleOpenCloseCta,
    onBlurRUT,
  } = actions;

  const {
    dataRetiro,
    listCities,
    office_id,
    formaPagos,
    openCloseCta,
    clienteSelected,
    isValidate,
    companyDataBasic,
    readonlyRemitente,
    city_id,
  } = state;

  const { actions: actionsOffice, state: stateOffice } =
    useTransportOrderOffice();

  const { loadOffices, setOfficeOrigin } = actionsOffice;

  const { officeOrigin } = stateOffice;

  useEffect(() => {
    if (listCities?.length > 0) {
      setValue("sucursal_origen_id", city_id);
      const resultOrigin = loadOffices(
        undefined,
        "origen",
        city_id,
        listCities
      );

      setOfficeOrigin(resultOrigin);

      setValue("comuna_origen_id", office_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCities?.length]);

  return (
    <Fragment>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <h6 className="mb-4">Datos del remitente</h6>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="rut_remitente">
                <Form.Label>RUT</Form.Label>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-largo">
                      Presione <strong>enter</strong> para realizar la búsqueda
                    </Tooltip>
                  }
                >
                  <Controller
                    render={(props) => (
                      <MaskedInput
                        mask={RutTextMask}
                        className="form-control"
                        id="rut_remitente"
                        name="rut_remitente"
                        value={props.value}
                        maxLength={12}
                        defaultValue={dataRetiro?.rut_remitente || ""}
                        onChange={(value) => {
                          props.onChange(value);
                        }}
                        onBlur={(event) => onBlurRUT(event)}
                        onKeyDown={(event) => onKeyDownRemitente(event)}
                        onKeyPress={(event) => validateOnlyRUT(event)}
                      />
                    )}
                    control={control}
                    name="rut_remitente"
                    rules={{ required: { value: true, message: "Requerido" } }}
                  />
                </OverlayTrigger>
                {errors.rut_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.rut_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="codigo_interno">
                <Form.Label>Cod. interno</Form.Label>
                <Form.Control
                  type="text"
                  name="codigo_interno"
                  readOnly={true}
                  maxLength={3}
                  ref={register({
                    required: { value: isValidate, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.codigo_interno && (
                  <Form.Text className="text-danger">
                    {errors?.codigo_interno?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="razon_social_remitente">
                <Form.Label>Remitente</Form.Label>
                <Form.Control
                  type="text"
                  name="razon_social_remitente"
                  maxLength={160}
                  defaultValue={dataRetiro?.razon_social_remitente || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  readOnly={readonlyRemitente}
                />
                {errors.razon_social_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.razon_social_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="telefono_remitente">
                <Form.Label>Contacto (Teléfono)</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {companyDataBasic?.country?.code}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="telefono_remitente"
                    defaultValue={dataRetiro?.telefono_remitente || ""}
                    maxLength={15}
                    disabled={
                      companyDataBasic?.country?.code === "" ? true : false
                    }
                    ref={register({
                      minLength: { value: 9, message: "Mínimo 9 dígitos" },
                      required: { value: true, message: "Requerido" },
                      //eslint-disable-next-line
                      pattern: {
                        value: /^([0-9]+)$/,
                        message: "Teléfono inválido",
                      },
                      validate: validatePhone,
                    })}
                    autoComplete="off"
                    onKeyPress={(event) => validateOnlyNumber(event)}
                    onPaste={(event) => onPaste(event)}
                    readOnly={readonlyRemitente}
                  />
                </InputGroup>
                {errors.telefono_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.telefono_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email_remitente">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  name="email_remitente"
                  maxLength={150}
                  className="mb-0"
                  defaultValue={dataRetiro?.email_remitente || ""}
                  ref={register({
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                  })}
                  onPaste={(event) => onPaste(event)}
                  readOnly={readonlyRemitente}
                />
                {errors.email_remitente && (
                  <Form.Text className="text-danger">
                    {errors?.email_remitente?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <h6 className="mb-4">Dirección del remitente</h6>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="sucursal_origen_id">
                <Form.Label>Sucursal retiro</Form.Label>
                <Controller
                  control={control}
                  name="sucursal_origen_id"
                  rules={{ required: { value: true, message: "Requerido" } }}
                  render={(props) => {
                    return (
                      <select
                        value={props.value}
                        className="form-control"
                        id="sucursal_origen_id"
                        onChange={(event) => {
                          props.onChange(event.target.value);
                          const result = loadOffices(
                            event,
                            "origin",
                            "",
                            listCities
                          );
                          setOfficeOrigin(result);
                        }}
                        disabled={!validarPermiso("cambiar_sucursal")}
                      >
                        <option value="">Seleccione</option>
                        {listCities.length > 0 &&
                          listCities.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                  defaultValue={""}
                />
                {errors.sucursal_origen_id && (
                  <Form.Text className="text-danger">
                    {errors?.sucursal_origen_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="comuna_origen_id">
                <Form.Label>Comuna retiro</Form.Label>
                <Controller
                  control={control}
                  name="comuna_origen_id"
                  rules={{ required: { value: true, message: "Requerido" } }}
                  render={(props) => {
                    return (
                      <select
                        value={props.value}
                        className="form-control"
                        id="comuna_origen_id"
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                        disabled={!validarPermiso("cambiar_sucursal")}
                      >
                        <option value="">Seleccione</option>
                        {officeOrigin.length > 0 &&
                          officeOrigin.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                  defaultValue={""}
                />
                {errors.comuna_origen_id && (
                  <Form.Text className="text-danger">
                    {errors?.comuna_origen_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Form.Group controlId="direccion_retiro">
                <Form.Label>Dirección retiro</Form.Label>
                <Form.Control
                  type="text"
                  name="direccion_retiro"
                  maxLength={255}
                  defaultValue={dataRetiro?.direccion_retiro || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                />
                {errors.direccion_retiro && (
                  <Form.Text className="text-danger">
                    {errors?.direccion_retiro?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="forma_pago_id">
                <Form.Label>Forma de pago</Form.Label>
                <Form.Control
                  as="select"
                  name="forma_pago_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onChange={(event) => cambiarFormaPago(event)}
                >
                  <option value="">Seleccione</option>
                  {formaPagos.length > 0 &&
                    formaPagos
                      ?.filter((item) => item.description !== "Contado")
                      .map((pago) => (
                        <option key={pago.id} value={pago.id}>
                          {pago.description}
                        </option>
                      ))}
                </Form.Control>
                {errors.forma_pago_id && (
                  <Form.Text className="text-danger">
                    {errors?.forma_pago_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      {openCloseCta && (
        <ModalSelectNroCuenta
          handleOpenCloseCta={handleOpenCloseCta}
          openCloseCta={openCloseCta}
          cliente={clienteSelected}
          selectCodeClient={selectCodeClient}
        />
      )}
    </Fragment>
  );
};

export default ClienteRemitente;

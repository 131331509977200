import { useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, searchUserById } from "actions/admin/userAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const usePassword = (datos) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userDatos } = useSelector((state) => state.users);

  const { register, handleSubmit, errors, getValues, reset } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [typeInput, setTypeInput] = useState(false);
  const [typeInputRep, setTypeInputRep] = useState(false);
  const [dataUser, setDataUser] = useState(null);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response = await dispatch(changePassword(data, dataUser?.id));

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);
      setTimeout(() => {
        history.push(`/admin/users`);
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const validatePassword = (value) => {
    if (value !== getValues("password")) {
      return "Las contraseña deben ser iguales";
    }

    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/.test(
        value
      )
    ) {
      return "La contraseña debe tener: al menos 9 caracteres, 1 número, 1 carácter en minúscula, 1 carácter en mayúscula y 1 carácter especial";
    }
  };

  const setDataInicial = async (userId) => {
    let data = userDatos;

    if (userId !== null) {
      if (userDatos === null) {
        const result = await dispatch(searchUserById(userId));

        data = result?.data;
      }
    }

    if (data !== null) {
      reset({
        fullName: data.full_name,
        email: data.email,
      });
    }
    setDataUser(userId !== null ? data ?? null : null);
  };

  const formElement = {
    register,
    handleSubmit,
    errors,
    getValues,
  };

  const actions = {
    onSubmit,
    setTypeInput,
    setTypeInputRep,
    validatePassword,
    setDataInicial,
  };

  const state = {
    dataUser,
    isLoading,
    typeInput,
    typeInputRep,
  };

  return {
    state,
    actions,
    formElement,
  };
};

import { useState, useEffect } from "react";

import { getDataInitialOrder } from "actions/transportOrdersAction";
import {
  searchRetiroByNro,
  selectedPreordenByRetiro,
} from "actions/retiroAction";
import { searchCustomer } from "actions/admin/customerAction";
import { getListCities } from "actions/admin/cityAction";
import { useSelector, useDispatch } from "react-redux";

export const useDetailRetiro = (numero_retiro, toast) => {
  const dispatch = useDispatch();

  const { detRetiro, preordenSelected, isLoadingDetRetiro, titleDetailRetiro } =
    useSelector((state) => state.retiros);
  const { documentacionsCarga, formaPagos, tipoEnvios } = useSelector(
    (state) => state.transportOrders
  );
  const { listCities } = useSelector((state) => state.cities);

  const [show, setShow] = useState(false);
  const [completedModal, setCompletedModal] = useState(false);

  const openCloseModal = (value) => {
    setShow(value);
  };

  const handleSelectedPreOrden = (item) => {
    openCloseModal(true);
    dispatch(selectedPreordenByRetiro(item));
  };

  const getDataInitial = async (numero_retiro) => {
    await dispatch(searchRetiroByNro(numero_retiro));
    if (listCities.length === 0) {
      await dispatch(getListCities("active"));
    }
    if (formaPagos.length === 0 && documentacionsCarga.length === 0) {
      await dispatch(getDataInitialOrder());
    }
  };

  const openCloseModalCompleted = (value) => {
    setCompletedModal(value);
  };

  const handleCompletedPreOrden = (item) => {
    openCloseModalCompleted(true);
    dispatch(selectedPreordenByRetiro(item));
  };

  useEffect(() => {
    getDataInitial(numero_retiro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numero_retiro]);

  return {
    detRetiro,
    preordenSelected,
    isLoadingDetRetiro,
    documentacionsCarga,
    listCities,
    formaPagos,
    openCloseModal,
    show,
    handleSelectedPreOrden,
    handleCompletedPreOrden,
    openCloseModalCompleted,
    completedModal,
    setShow,
    setCompletedModal,
    titleDetailRetiro,
    searchCliente: searchCustomer,
    tipoEnvios,
  };
};

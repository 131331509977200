import { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import Progress from "components/Layouts/Progress";

const DataTableCustomer = ({
  columns,
  filteredItems,
  handleRowClicked,
  isCargando,
  FilterComponent,
  selectableRows = true,
}) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  return (
    <div className="table-component">
      <DataTable
        columns={columns}
        data={filteredItems(filterText)}
        dense
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows={selectableRows}
        persistTableHead
        responsive
        selectableRowsNoSelectAll
        selectableRowsRadio="checkbox"
        selectableRowsSingle
        selectableRowsVisibleOnly
        progressPending={isCargando}
        progressComponent={<Progress />}
        onSelectedRowsChange={handleRowClicked}
        noDataComponent={<div>No hay datos registros disponible.</div>}
        paginationComponentOptions={paginationComponentOptions}
        paginationPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 150, 200]}
      />
    </div>
  );
};

export default DataTableCustomer;

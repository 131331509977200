export const LISTAR_DATA_INICIO = "LISTAR_DATA_INICIO";
export const DATA_SEARCH_ORDEN = "DATA_SEARCH_ORDEN";
export const CLEAN_FORM_ORDER = "CLEAN_FORM_ORDER";
export const DATA_ADD_ORDEN = "DATA_ADD_ORDEN";
export const TOTALES_UPDATE = "TOTALES_UPDATE";
export const SAVE_DETALLE_CARGA = "SAVE_DETALLE_CARGA";
export const FILTERED_ORDERS = "FILTERED_ORDERS";
export const UPDATE_SELECTED_ORDEN_NOM = "UPDATE_SELECTED_ORDEN_NOM";
export const TOTAL_SELECTED_ORDEN_NOM = "TOTAL_SELECTED_ORDEN_NOM";
export const CLEAN_SELECTED_ORDEN_NOM = "CLEAN_SELECTED_ORDEN_NOM";
export const LOADING_SEARCH_ORDERS_NOM = "LOADING_SEARCH_ORDERS_NOM";
export const CLEAN_NAME_DESTINO_NOM = "CLEAN_NAME_DESTINO_NOM";
export const CANCEL_ORDEN = "CANCEL_ORDEN";
export const SEARCH_CLIENTE_ORDENES = "SEARCH_CLIENTE_ORDENES";
export const UPDATE_CLIENTE_ORDENES = "UPDATE_CLIENTE_ORDENES";
export const UPDATE_CLIENTE_ORDENES_INPUT = "UPDATE_CLIENTE_ORDENES_INPUT";
export const CLEAR_TOTAL_ORDEN_ENTREGA = "CLEAR_TOTAL_ORDEN_ENTREGA";
export const UPDATE_INPUT_DOCUMENTO = "UPDATE_INPUT_DOCUMENTO";
export const UPDATE_TOTAL_MONTO = "UPDATE_TOTAL_MONTO";
export const PREVIEW_PDF_ORDENES = "PREVIEW_PDF_ORDENES";
export const BLOQUEAR_CLIENTE = "BLOQUEAR_CLIENTE";
export const GENERAR_NOTA_CREDITO = "GENERAR_NOTA_CREDITO";
export const DISABLED_GENERATE_DOCUMENT = "DISABLED_GENERATE_DOCUMENT";
export const LOADING_ORDEN_RECEIVE = "LOADING_ORDEN_RECEIVE";
export const SEARCH_ORDEN_RECEIVE = "SEARCH_ORDEN_RECEIVE";
export const LIST_ORDER_INCOMPLETE = "LIST_ORDER_INCOMPLETE";
export const SELECTED_ORDEN_INCOMPLETE = "SELECTED_ORDEN_INCOMPLETE";
export const UPDATE_ORDEN_INCOMPLETE = "UPDATE_ORDEN_INCOMPLETE";
export const EDIT_CARGA_WITH_PRICE_ORDEN = "EDIT_CARGA_WITH_PRICE_ORDEN";

import { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";

const BodyCarga = ({
  index,
  id,
  alto,
  ancho,
  largo,
  bulto,
  peso,
  register,
  orden_flete_id,
  handleChangeTotal,
  valor_neto,
  isAdmin,
  metraje_cubico,
  setValue,
  getValues,
  control,
  errors,
}) => {
  const [totalPagar, setTotalPagar] = useState(valor_neto);
  const [nroPeso, setNroPeso] = useState(peso);
  const [nroLargo, setNroLargo] = useState(largo);
  const [nroAncho, setNroAncho] = useState(ancho);
  const [nroAlto, setNroAlto] = useState(alto);
  const [nroM3, setNroM3] = useState(metraje_cubico);

  useEffect(() => {
    setTotalPagar(valor_neto);
    setNroPeso(peso);
    setNroLargo(largo);
    setNroAncho(ancho);
    setNroAlto(alto);
    setNroM3(metraje_cubico);
  }, [valor_neto, peso, largo, ancho, alto, metraje_cubico]);

  const editM3 = (index) => {
    let total = 0;
    if (nroAlto !== "") {
      total = nroAlto;
    }
    if (nroAncho !== "") {
      total = total * nroAncho;
    }
    if (nroLargo !== "") {
      total = total * nroLargo;
    }

    setValue(`cargas[${index}].metraje_cubico`, total ?? 0);
    setNroM3(total);
  };

  return (
    <tr key={index}>
      <td>
        <input
          type="hidden"
          name={`cargas[${index}].id`}
          className="form-control"
          defaultValue={id}
          ref={register}
        />
        <OverlayTrigger
          overlay={<Tooltip id={`cargas[${index}].alto`}>m</Tooltip>}
        >
          <Controller
            control={control}
            name={`cargas[${index}].alto`}
            rules={{ required: { value: true, message: "Requerido" } }}
            defaultValue={nroAlto}
            render={(props) => (
              <NumericFormat
                allowNegative={false}
                className="form-control"
                decimalScale={2}
                placeholder="Alto"
                name={props.name}
                id={props.name}
                fixedDecimalScale={false}
                isAllowed={({ floatValue }) => {
                  // Define your validation rules here
                  return true;
                }}
                onPaste={(e) => e.preventDefault()}
                decimalSeparator={","}
                value={props.value}
                onBlur={() => editM3(index)}
                readOnly={!isAdmin}
                onValueChange={(values) => {
                  props.onChange(values.floatValue);
                  setNroAlto(values.floatValue);
                }}
                maxLength={15}
              />
            )}
            autoComplete="off"
          />
        </OverlayTrigger>
        {errors[`cargas[${index}].alto`] && (
          <span className="text-danger">
            {errors[`cargas[${index}].alto`].message}
          </span>
        )}
      </td>
      <td>
        <OverlayTrigger
          overlay={<Tooltip id={`cargas[${index}].ancho`}>m</Tooltip>}
        >
          <Controller
            control={control}
            name={`cargas[${index}].ancho`}
            rules={{ required: { value: true, message: "Requerido" } }}
            defaultValue={nroAncho}
            render={(props) => (
              <NumericFormat
                allowNegative={false}
                className="form-control"
                decimalScale={2}
                placeholder="Ancho"
                name={props.name}
                id={props.name}
                fixedDecimalScale={false}
                isAllowed={({ floatValue }) => {
                  // Define your validation rules here
                  return true;
                }}
                onPaste={(e) => e.preventDefault()}
                decimalSeparator={","}
                value={props.value}
                onValueChange={(values) => {
                  props.onChange(values.floatValue);
                  setNroAncho(values.floatValue);
                }}
                onBlur={() => editM3(index)}
                readOnly={!isAdmin}
                maxLength={15}
              />
            )}
            autoComplete="off"
          />
        </OverlayTrigger>
      </td>
      <td>
        <OverlayTrigger
          overlay={<Tooltip id={`cargas[${index}].largo`}>m</Tooltip>}
        >
          <Controller
            control={control}
            name={`cargas[${index}].largo`}
            rules={{ required: { value: true, message: "Requerido" } }}
            defaultValue={nroLargo}
            render={(props) => (
              <NumericFormat
                allowNegative={false}
                className="form-control"
                decimalScale={2}
                placeholder="Largo"
                name={props.name}
                id={props.name}
                fixedDecimalScale={false}
                isAllowed={({ floatValue }) => {
                  // Define your validation rules here
                  return true;
                }}
                onPaste={(e) => e.preventDefault()}
                decimalSeparator={","}
                value={props.value}
                onValueChange={(values) => {
                  props.onChange(values.floatValue);
                  setNroLargo(values.floatValue);
                }}
                onBlur={() => editM3(index)}
                readOnly={!isAdmin}
                maxLength={15}
              />
            )}
            autoComplete="off"
          />
        </OverlayTrigger>
      </td>
      <td>
        <input
          type="hidden"
          name={`cargas[${index}].metraje_cubico`}
          defaultValue={nroM3}
          ref={register}
          className="form-control"
          step="0.01"
        />
        <NumericFormat
          displayType="text"
          className="text-padding"
          decimalScale={2}
          fixedDecimalScale={false}
          decimalSeparator={","}
          thousandSeparator={"."}
          value={nroM3}
        />
      </td>
      <td>
        <input
          type="hidden"
          name={`cargas[${index}].bulto`}
          className="form-control"
          step="0.01"
          defaultValue={bulto}
          ref={register({ required: { value: true, message: "Requerido" } })}
          readOnly={!isAdmin}
        />
        {bulto}
      </td>
      <td>
        <OverlayTrigger
          overlay={<Tooltip id={`cargas[${index}].peso`}>kg</Tooltip>}
        >
          <Controller
            control={control}
            name={`cargas[${index}].peso`}
            rules={{ required: { value: true, message: "Requerido" } }}
            defaultValue={nroPeso}
            render={(props) => (
              <NumericFormat
                allowNegative={false}
                className="form-control"
                decimalScale={2}
                placeholder="Peso"
                name={props.name}
                id={props.name}
                fixedDecimalScale={false}
                isAllowed={({ floatValue }) => {
                  // Define your validation rules here
                  return true;
                }}
                onPaste={(e) => e.preventDefault()}
                decimalSeparator={","}
                value={props.value}
                onValueChange={(values) => {
                  props.onChange(values.floatValue);
                  setNroPeso(values.floatValue);
                }}
                onBlur={() => editM3(index)}
                readOnly={!isAdmin}
                maxLength={15}
              />
            )}
            autoComplete="off"
          />
        </OverlayTrigger>
      </td>
      <td>
        <Controller
          control={control}
          name={`cargas[${index}].total`}
          rules={{ required: { value: true, message: "Requerido" } }}
          defaultValue={totalPagar}
          render={(props) => (
            <NumericFormat
              allowNegative={false}
              className="form-control"
              decimalScale={2}
              placeholder="Total"
              name={props.name}
              id={props.name}
              fixedDecimalScale={false}
              isAllowed={({ floatValue }) => {
                // Define your validation rules here
                return true;
              }}
              onPaste={(e) => e.preventDefault()}
              decimalSeparator={","}
              thousandSeparator={"."}
              value={props.value}
              onValueChange={(values) => {
                props.onChange(values.floatValue);
                setTotalPagar(values.floatValue);
              }}
              readOnly={!isAdmin}
              onBlur={(event) => handleChangeTotal(event, id, orden_flete_id)}
              maxLength={15}
            />
          )}
          autoComplete="off"
        />
      </td>
    </tr>
  );
};

export default BodyCarga;

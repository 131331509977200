import {
  LIST_DATA_ROLE,
  LIST_DATA_PERMISSION,
  DATA_DELETE_ROLE,
  RESET_ROW_SELECTED,
  LOADING_ROLE,
} from "types/admin/roleType";

const INITIAL_STATE = {
  rol: null,
  listRole: [],
  roleIsLoading: true,
  rolData: null,
  listPermissions: [],
};

const roleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ROLE:
      return {
        ...state,
        roleIsLoading: action.payload.loading,
        rolData: action.payload.data,
      };
    case LIST_DATA_ROLE:
      return {
        ...state,
        listRole: action.payload.roles,
        roleIsLoading: false,
      };
    case LIST_DATA_PERMISSION:
      return {
        ...state,
        listPermissions: action.payload.permissions,
      };
    case DATA_DELETE_ROLE:
      return {
        ...state,
        listRole: action.payload.list,
        rolData: action.payload.data,
      };
    case RESET_ROW_SELECTED:
      return {
        ...state,
        rolData: action.payload.data,
      };
    default:
      return state;
  }
};

export default roleReducer;

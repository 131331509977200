import API from "config/config-api";
import { LISTAR_DATA_PAGO, DISABLED_METODO_PAGO } from "types/metodoPagoType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getListPayments = (body) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.payments.list);
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LISTAR_DATA_PAGO, data));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const updateDisabledBtn = (estado) => (dispatch, getState) => {
  dispatch(requestSuccess(DISABLED_METODO_PAGO, estado));
};

import API from "config/config-api";
import {
  LIST_DATA_ROLE,
  LIST_DATA_PERMISSION,
  DATA_DELETE_ROLE,
  RESET_ROW_SELECTED,
  LOADING_ROLE,
} from "types/admin/roleType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_ROLE, payload));
};

const generateItems = (listItems) => {
  let objPermisos = {};

  for (let index in listItems) {
    let item = listItems[index];
    if (!objPermisos.hasOwnProperty(item?.key_category)) {
      objPermisos[item?.key_category] = { title: item?.category, options: [] };
    }
    objPermisos[item?.key_category] = {
      ...objPermisos[item?.key_category],
      options: [...objPermisos[item?.key_category]?.options, item],
    };
  }

  return objPermisos;
};

export const getListRole = (typeFilter) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.role.list(typeFilter));
    const { data, status, message } = response.data;

    const payload = {
      roles: data,
    };

    dispatch(requestSuccess(LIST_DATA_ROLE, payload));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getListPermission = (body) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.role.permissions);
    const { data } = response.data;

    let listPermissionsMenu = data.permissions.filter(
      (item) => item.options === true
    );

    let listPermissionsOptions = data.permissions.filter(
      (item) => item.options === false
    );

    listPermissionsMenu = [...listPermissionsMenu, ...listPermissionsOptions];

    const payload = {
      permissions: generateItems(listPermissionsMenu),
    };

    dispatch(requestSuccess(LIST_DATA_PERMISSION, payload));
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteRole = (id) => async (dispatch, getState) => {
  try {
    const { listRole } = getState().roles;

    let response = await API.delete(endPoints.role.delete(id));

    const { status, message } = response.data;
    if (status) {
      const payload = {
        list: listRole.filter((rol) => rol.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_DELETE_ROLE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addRole = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.role.add, body);

    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editRole = (body, roleId) => async (dispatch, getState) => {
  try {
    let response = await API.put(endPoints.role.edit(roleId), body);
    const { status, message } = response.data;
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchRoleById = (roleId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.role.getOne(roleId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRowsRole = (rows) => (dispatch, getState) => {
  dispatch(
    requestSuccess(RESET_ROW_SELECTED, {
      data: rows,
    })
  );
};

import { Link } from "react-router-dom";

const TableRetiro = ({ listRetiros, isLink, isLoading }) => {
  return (
    <div className="table-responsive table-retiro-responsive">
      <table className="table table-bordered table-sm bg-white table-retiro">
        <thead className="bg-warn-blue">
          <tr>
            <th className="text-center"># Retiro</th>
            <th className="text-center">Estado</th>
            <th className="text-center">Fecha</th>
            <th className="text-center">RUT Remitente</th>
            <th className="text-center">Remitente</th>
            <th className="text-center">Dirección Retiro</th>
            <th className="text-center">Sucursal</th>
            <th className="text-center">Comuna</th>
            <th className="text-center">Teléfono</th>
            <th className="text-center">Destinatarios</th>
          </tr>
        </thead>
        <tbody>
          {listRetiros.length > 0 &&
            listRetiros.map((item, key) => (
              <tr key={key}>
                <td className="text-center">
                  <Link to={`/withdrawals/${item.number_withdrawal_order}`}>
                    {item.number_withdrawal_order}
                  </Link>
                </td>
                <td className="text-center">{item.state}</td>
                <td className="text-center">{item.date_order}</td>
                <td>{item.remitter?.identity_document}</td>
                <td>{item.remitter?.fullname}</td>
                <td>{item.address}</td>
                <td>{item.office}</td>
                <td>{item.city}</td>
                <td>{item.remitter?.telephone}</td>
                <td className="text-center">{item.count_orders}</td>
              </tr>
            ))}
          {!isLoading && listRetiros.length === 0 && (
            <tr>
              <td colSpan={10}>No hay registros disponibles</td>
            </tr>
          )}
          {isLoading && listRetiros.length === 0 && (
            <tr>
              <td colSpan={10}>Buscando...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableRetiro;

import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import FormChangePassword from "components/Admin/Users/FormChangePassword";
import { useParams } from "react-router-dom";

const EditUser = () => {
  const { userId } = useParams();
  return (
    <Layout titlePanel="Administrar" modulo="administrar_users">
      <div className="pl-3 pr-3">
        <CardRetiro title="Editar usuario">
          <FormChangePassword userId={userId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default EditUser;

import React from "react";
import { Modal, Table, Button } from "react-bootstrap";
import TbodyEstado from "./TbodyEstado";
import { useSelector } from "react-redux";

const ModalListado = ({
  showIsModal,
  setShowIsModal,
  handleCloseModal,
  listado,
}) => {
  const { dataTransportOrder } = useSelector((state) => state.transportOrders);

  return (
    <>
      <Modal
        show={showIsModal}
        onHide={handleCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
      >
        <Modal.Body className="pl-4 pr-4 pt-5">
          <Table
            bordered
            size="sm"
            className="table-global table-estados-orden"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Estado</th>
                <th>Usuario</th>
                <th>Fecha</th>
                <th>Observaciones</th>
              </tr>
            </thead>
            <tbody>
              {dataTransportOrder?.listEstado?.length > 0 &&
                dataTransportOrder?.listEstado.map((list, index) => {
                  return <TbodyEstado key={index} {...list} indice={index} />;
                })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalListado;

import { useState } from "react";
import {
  Form,
  Col,
  Card,
  Row,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { getDataOrden } from "actions/transportOrdersAction";
import { addOrdenes } from "actions/guiaDespachoAction";
import { useDispatch } from "react-redux";
import { formatFloat } from "config/formatInput";
import Tabla from "./Tabla";
import {
  onPaste,
  validateOnlyOrden,
  validateOnlyNumber,
} from "utils/validationInput";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";

const Detalle = ({
  register,
  getValues,
  setValue,
  toast,
  listado_totales,
  control,
  setValorDescuento,
}) => {
  const dispatch = useDispatch();
  const [habilitar, setHabilitar] = useState(true);
  const [habilitarDesc, setHabilitarDesc] = useState(true);
  const [totalUnitario, setTotalUnitario] = useState("");
  const [totalDscto, setTotalDscto] = useState("");
  const [totalMonto, setTotalMonto] = useState("");

  const searchOrden = async (event) => {
    event.preventDefault();

    try {
      if (
        getValues("rut")?.length === 0 &&
        getValues("fecha_documento") === undefined &&
        getValues("forma_pago_id") === "" &&
        getValues("traslado_id") === "" &&
        getValues("despacho_id") === "" &&
        getValues("sucursal_id") === "" &&
        getValues("comuna_id") === "" &&
        getValues("direccion_destino")?.length === 0 &&
        getValues("camion_id") === "" &&
        getValues("rut_conductor") === ""
      ) {
        toast.error("Debe completar el encabezado y la configuración", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }

      const numero = getValues("numero_orden");

      if (numero === "") {
        toast.error("Debe ingresar el número de orden", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }

      if (
        listado_totales.ordenes.filter((orden) => orden.numero_orden === numero)
          .length > 0
      ) {
        toast.error("El número de orden ya existe en el detalle", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }

      const response = await dispatch(getDataOrden(numero));
      setHabilitar(true);
      setHabilitarDesc(true);
      if (response.status) {
        const { data } = response;

        setValue("descripcion", data.descripcion);
        setValue("unitario", data.valor_neto);
        setTotalUnitario(data.valor_neto);
        setValue("total_orden", data.total);
        setValue("valor_neto_orden", data.valor_neto);
        setValue("cantidad", 1);
        setValue(
          "monto_descuento",
          data.descuento === "0.00" ? "" : data.descuento
        );
        setTotalDscto(data.descuento === "0.00" ? "" : data.descuento);
        setValue(
          "tipo_descuento",
          data.descuento?.length > 0 && data.descuento !== "0.00" ? "$" : ""
        );
        setValue(
          "total",
          (data.valor_neto - data.descuento) * parseInt(getValues("cantidad"))
        );
        setTotalMonto(
          (data.valor_neto - data.descuento) * parseInt(getValues("cantidad"))
        );
        setValue("exento", true);
        setHabilitar(false);

        setHabilitarDesc(data.descuento === "0.00" ? false : true);
      } else {
        setValue("descripcion", "");
        setValue("unitario", "");
        setTotalUnitario("");
        setValue("total_orden", "");
        setValue("valor_neto_orden", "");
        setValue("cantidad", 1);
        setValue("monto_descuento", "");
        setTotalDscto("");
        setValue("tipo_descuento", "");
        setValue("total", "");
        setTotalMonto("");
        setValue("exento", false);
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        return false;
      }
    } catch (error) {
      toast.error("Hubo un error, intente nuevamente", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const addDetalle = (event) => {
    event.preventDefault();

    if (
      getValues("tipo_descuento").length > 0 &&
      getValues("monto_descuento").length === 0
    ) {
      toast.error("Debe ingresar el descuento ó cambiar la selección", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    setHabilitarDesc(false);
    if (
      getValues("numero_orden") !== "" &&
      getValues("descripcion") !== "" &&
      getValues("unitario") !== "" &&
      getValues("total") !== "" &&
      getValues("cantidad") !== ""
    ) {
      let subtotal = getValues("exento")
        ? parseFloat(getValues("valor_neto_orden"))
        : parseFloat(getValues("total_orden"));
      let monto_descto = 0;

      if (
        getValues("monto_descuento") > 0 &&
        getValues("tipo_descuento") !== ""
      ) {
        monto_descto = formatFloat(getValues("monto_descuento"));
        if (
          getValues("monto_descuento") > 0 &&
          getValues("tipo_descuento") === "%"
        ) {
          monto_descto = Math.round(
            formatFloat(subtotal) * (monto_descto / 100)
          );
        }
        setHabilitarDesc(true);
      }

      const data = {
        numero_orden: getValues("numero_orden"),
        descripcion: getValues("descripcion"),
        unitario: formatFloat(getValues("unitario")),
        cantidad: getValues("cantidad"),
        monto_descuento:
          getValues("monto_descuento").length === 0
            ? 0
            : formatFloat(getValues("monto_descuento")),
        tipo_descuento: getValues("tipo_descuento"),
        total: formatFloat(getValues("total")),
        exento: getValues("exento"),
        bodega: getValues("bodega"),
        total_orden: formatFloat(getValues("total")),
        total_descuento:
          getValues("monto_descuento").length === 0 ? 0 : monto_descto,
      };

      const result = dispatch(addOrdenes(data));
      if (result) {
        resetForm();
        setHabilitar(true);
      }
    } else {
      toast.error("Falta algunos datos por completar", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const resetForm = () => {
    setValue("numero_orden", "");
    setValue("descripcion", "");
    setValue("unitario", "");
    setValue("cantidad", "");
    setValue("monto_descuento", "");
    setValue("tipo_descuento", "");
    setValue("total", "");
    setValue("exento", false);
    setTotalUnitario("");
    setTotalDscto("");
    setTotalMonto("");
  };

  const changeExento = (event) => {
    if (event.target.checked) {
      setValue("unitario", formatFloat(getValues("valor_neto_orden")));
      setValue(
        "total",
        formatFloat(getValues("valor_neto_orden")) *
          parseInt(getValues("cantidad"))
      );
    } else {
      setValue("unitario", formatFloat(getValues("total_orden")));
      setValue(
        "total",
        formatFloat(getValues("total_orden")) * parseInt(getValues("cantidad"))
      );
    }
  };

  const changeCount = (event) => {
    let subtotal = getValues("exento")
      ? parseFloat(getValues("unitario"))
      : parseFloat(getValues("total_orden"));
    let monto_descto = 0;

    if (
      getValues("monto_descuento") > 0 &&
      getValues("tipo_descuento") !== ""
    ) {
      monto_descto = getValues("monto_descuento");

      if (
        getValues("monto_descuento") > 0 &&
        getValues("tipo_descuento") === "%"
      ) {
        monto_descto = Math.round(subtotal * (monto_descto / 100));
      }
    }

    let total = (subtotal - monto_descto) * parseInt(getValues("cantidad"));
    setValue("total", total);
    setTotalMonto(total);
  };

  const onKeyDownHandler = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (event.target.value.length > 0) {
        await searchOrden(event);
      }
    } else {
      setValue("descripcion", "");
      setValue("unitario", "");
      setTotalUnitario("");
      setValue("total_orden", "");
      setValue("valor_neto_orden", "");
      setValue("cantidad", 1);
      setValue("monto_descuento", "");
      setTotalDscto("");
      setValue("tipo_descuento", "");
      setValue("total", "");
      setTotalMonto("");
      setValue("exento", false);
      setHabilitar(true);
      setHabilitarDesc(true);
    }
  };

  return (
    <Card border="Light">
      <Card.Header as="h6" className="bg-warn-blue text-dark">
        Detalle
      </Card.Header>
      <Card.Body className="bg-soft-blue">
        <Row>
          <Col lg={2} className="pr-1">
            <Form.Group controlId="numero_orden">
              <Form.Label>Código</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  aria-label="Número de orden"
                  aria-describedby="número de orden"
                  name="numero_orden"
                  ref={register}
                  onKeyDown={(event) => onKeyDownHandler(event)}
                  onKeyPress={(event) => validateOnlyOrden(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={15}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    onClick={(event) => searchOrden(event)}
                  >
                    Buscar
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col lg={3} className="pl-1 pr-1">
            <Form.Group controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                name="descripcion"
                ref={register}
                readOnly={habilitar}
              />
            </Form.Group>
          </Col>
          <Col lg={1} className="pl-1 pr-1">
            <Form.Group controlId="cantidad">
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type="text"
                name="cantidad"
                defaultValue="1"
                ref={register}
                onChange={(event) => changeCount(event)}
                readOnly={habilitar}
                onKeyPress={(event) => validateOnlyNumber(event)}
                onPaste={(event) => onPaste(event)}
              />
            </Form.Group>
          </Col>
          <Col lg={1} className="pl-1 pr-1">
            <Form.Group controlId="unitario">
              <Form.Label>Unitario</Form.Label>
              <Controller
                control={control}
                name="unitario"
                render={(props) => (
                  <NumericFormat
                    allowNegative={false}
                    className="form-control"
                    decimalScale={2}
                    name={props.name}
                    id={props.name}
                    fixedDecimalScale={false}
                    isAllowed={({ floatValue }) => {
                      // Define your validation rules here
                      return true;
                    }}
                    onPaste={(e) => e.preventDefault()}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    value={totalUnitario}
                    //defaultValue={ordenInfo.valor_declarado_carga}
                    onValueChange={(values) => {
                      props.onChange(values.floatValue);
                      setTotalUnitario(values.floatValue);
                      changeCount(values.floatValue);
                    }}
                    readOnly={habilitar}
                    maxLength={15}
                  />
                )}
                autoComplete="off"
              />
            </Form.Group>
          </Col>
          <Col className="pl-1 pr-1">
            <Form.Group controlId="monto_descuento">
              <Form.Label>Descuento</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    name="tipo_descuento"
                    ref={register}
                    disabled={habilitarDesc}
                    onChange={(event) => changeCount(event)}
                  >
                    <option value=""></option>
                    <option value="%">%</option>
                    <option value="$">$</option>
                  </Form.Control>
                </InputGroup.Prepend>
                <Controller
                  control={control}
                  name="monto_descuento"
                  render={(props) => (
                    <NumericFormat
                      allowNegative={false}
                      className="form-control"
                      decimalScale={2}
                      name={props.name}
                      id={props.name}
                      fixedDecimalScale={false}
                      isAllowed={({ floatValue }) => {
                        // Define your validation rules here
                        return true;
                      }}
                      onPaste={(e) => e.preventDefault()}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      value={totalDscto}
                      //defaultValue={ordenInfo.valor_declarado_carga}
                      onValueChange={(values) => {
                        props.onChange(values.floatValue);
                        setTotalDscto(values.floatValue);
                        changeCount(values.floatValue);
                      }}
                      readOnly={habilitarDesc}
                      maxLength={15}
                    />
                  )}
                  autoComplete="off"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col className="pl-1 pr-1">
            <Form.Group controlId="total">
              <Form.Label>Total</Form.Label>
              <Controller
                control={control}
                name="total"
                render={(props) => (
                  <NumericFormat
                    allowNegative={false}
                    className="form-control"
                    decimalScale={2}
                    name={props.name}
                    id={props.name}
                    fixedDecimalScale={false}
                    isAllowed={({ floatValue }) => {
                      // Define your validation rules here
                      return true;
                    }}
                    onPaste={(e) => e.preventDefault()}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    value={totalMonto}
                    //defaultValue={ordenInfo.valor_declarado_carga}
                    onValueChange={(values) => {
                      props.onChange(values.floatValue);
                      setTotalMonto(values.floatValue);
                    }}
                    readOnly
                    maxLength={15}
                  />
                )}
                autoComplete="off"
              />

              <Form.Control
                type="hidden"
                name="total_orden"
                ref={register}
                readOnly
              />
              <Form.Control
                type="hidden"
                name="valor_neto_orden"
                ref={register}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col className="d-flex flex-row pl-1 pr-1">
            <div className="d-flex flex-column align-items-center mr-3">
              <label className="form-check-label" htmlFor="exento">
                Exento
              </label>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input check-guia"
                  name="exento"
                  id="exento"
                  ref={register}
                  disabled={habilitar}
                  onChange={(event) => changeExento(event)}
                />
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <label className="form-check-label" htmlFor="bodega">
                Bodega
              </label>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input check-guia"
                  id="bodega"
                  name="bodega"
                  ref={register}
                  defaultChecked
                  disabled
                />
              </div>
            </div>
          </Col>
          <Col>
            <Button
              variant="warning"
              disabled={habilitar}
              type="submit"
              className="mt-3"
              onClick={(event) => addDetalle(event)}
            >
              Insertar
            </Button>
          </Col>
        </Row>
        <Tabla
          ordenes={listado_totales?.ordenes}
          setValue={setValue}
          setValorDescuento={setValorDescuento}
        />
      </Card.Body>
    </Card>
  );
};

export default Detalle;

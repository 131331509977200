import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/Countries/Filter";
import country from "components/Admin/Columnas/Country";
import { useContries } from "hooks/Admin/Countries/useContries";

const ListCountry = () => {
  const { state, actions } = useContries();

  const { contryIsLoading } = state;

  const { getAll, filteredItems, handleRowClicked } = actions;

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_countries">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de países">
          <DataTableCustomer
            columns={country.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={contryIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListCountry;

import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import { useFormActivity } from "hooks/Admin/Activities/useFormActivity";
import { validateOnlyLetter, onPaste } from "utils/validationInput";
import { Link } from "react-router-dom";

const Formulario = ({ toast, datos, paisesAll, activityId, countryId }) => {
  const { actions, formElement } = useFormActivity(countryId);

  const { getAtivityById } = actions;

  const { isLoading, register, handleSubmit, errors, onSubmit } = formElement;

  useEffect(() => {
    if (activityId !== null) {
      getAtivityById(activityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  return (
    <Form id="frmGiro" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col xs={12}>
              <Form.Group controlId="description">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  defaultValue={datos?.description || ""}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.description && (
                  <Form.Text className="text-danger">
                    {errors?.description?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to={`/admin/countries/${countryId}/activities`}>
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : activityId
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  filteredOrdenes,
  clearTitleEstado,
  calcularTotalSelected,
  cleanListadoOrden,
} from "actions/transportOrdersAction";
import { isClearNomina, updateTitleBtn } from "actions/payrollAction";
import { handlePasteOnlyOrden, validateOnlyOrden } from "utils/validationInput";

const OrdenSearch = ({ toast, estados }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm();

  const onSubmit = async (data, e) => {
    if (data?.numero_orden.toString() === "0") {
      toast.error("Ingrese el número de orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValue("numero_orden", "");
      return false;
    }

    dispatch(cleanListadoOrden(true));
    dispatch(calcularTotalSelected());

    let ordenFormat = data?.numero_orden.replace("'", "-");
    setValue("numero_orden", ordenFormat);

    const information = {
      numero_orden: ordenFormat,
      check: false,
      search_multiple: false,
      generarPDF: false,
      module: "nomina",
    };

    const response = await dispatch(filteredOrdenes(information, "nomina"));

    if (response.status) {
      const { estado_id } = response;
      let estadoID = estados.find((item) => item.id === estado_id);

      document.getElementById("estado_id").value = estadoID?.slug;

      if (estadoID?.slug === "en_transito") {
        dispatch(updateTitleBtn("RECIBIDO SUCURSAL", false));
        dispatch(clearTitleEstado(""));
      } else if (estadoID?.slug === "pendiente_de_despacho_sucursal") {
        dispatch(updateTitleBtn("GENERAR NOMINA", true));
        dispatch(clearTitleEstado("CLIENTE"));
      } else if (estadoID?.slug === "pendiente_de_despacho") {
        dispatch(updateTitleBtn("GENERAR NOMINA", true));
        dispatch(clearTitleEstado(""));
      } else if (
        estadoID?.slug === "pendiente_de_entrega" ||
        estadoID?.slug === "entregada"
      ) {
        dispatch(updateTitleBtn("", false));
        dispatch(clearTitleEstado(""));
      } else if (estadoID?.slug === "nulas") {
        dispatch(updateTitleBtn("", false));
        dispatch(clearTitleEstado(""));
        document.getElementById("estado_id").value = 1;
        toast.error("La orden se encuentra con estatus Anulada", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        dispatch(updateTitleBtn("", false));
        dispatch(clearTitleEstado(""));
        dispatch(cleanListadoOrden(true));
        document.getElementById("estado_id").value = 1;
        toast.error("Verique el estado de la orden", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    dispatch(isClearNomina());
  };

  return (
    <Col xs={12}>
      <Form
        inline
        className="pl-3"
        onSubmit={handleSubmit(onSubmit)}
        id="frmBusquedaOrden"
        role="form"
      >
        <Form.Label className="my-1 mr-2" htmlFor="numero_orden">
          Buscar OT
        </Form.Label>
        <Form.Control
          type="text"
          name="numero_orden"
          ref={register({ required: { value: true, message: "Requerido" } })}
          maxLength={20}
          onKeyPress={(event) => validateOnlyOrden(event)}
          onPaste={(event) => handlePasteOnlyOrden(event)}
        />
        {errors.numero_orden && (
          <Form.Text className="text-danger">
            {errors?.numero_orden?.message}
          </Form.Text>
        )}
        <Button type="submit" variant="warning" className="ml-2">
          MOSTRAR
        </Button>
      </Form>
    </Col>
  );
};

export default OrdenSearch;

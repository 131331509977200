import { combineReducers } from "redux";
import users from "reducers/admin/userReducer";
import orders from "reducers/ordersReducer";
import customers from "reducers/admin/customerReducer";
import nominas from "reducers/nominaReducer";
import metodoPagos from "reducers/metodoPagoReducer";
import rendir from "reducers/rendirReducer";
import config from "reducers/configReducer";
import retiros from "reducers/retiroReducer";
import roles from "reducers/admin/roleReducer";
import trucks from "reducers/admin/truckReducer";
import cities from "reducers/admin/cityReducer";
import drivesUser from "reducers/admin/driveReducer";
import offices from "reducers/admin/officeReducer";
import facturacion from "reducers/facturacionReducer";
import guiaDespacho from "reducers/guiaDespachoReducer";
//Admin
import accounts from "reducers/admin/depositAccountReducer";
import correlatives from "reducers/admin/correlativeReducer";

import home from "reducers/homeReducer";
import solutions from "reducers/solutionsOrdenReducer";
import dataBasicInvoice from "reducers/basicFacturaReducer";
import companies from "reducers/admin/companyReducer";
import countries from "reducers/admin/countryReducer";
import activities from "reducers/admin/activityReducer";
import parentCities from "reducers/admin/parentCityReducer";
import parentOffices from "reducers/admin/parentOfficeReducer";
import taxes from "reducers/admin/taxtReducer";

import transportOrders from "reducers/transportOrdersReducer";
import payrolls from "reducers/payrollReducer";

export default combineReducers({
  users,
  orders,
  customers,
  nominas,
  metodoPagos,
  rendir,
  config,
  retiros,
  accounts,
  home,
  roles,
  trucks,
  cities,
  drivesUser,
  offices,
  facturacion,
  guiaDespacho,
  correlatives,
  solutions,
  dataBasicInvoice,
  companies,
  countries,
  activities,
  parentCities,
  parentOffices,
  taxes,
  transportOrders,
  payrolls,
});

import API from "config/config-api";
import {
  LIST_DATA_TRUCK,
  SELECTED_ROWS_TRUCK,
  DATA_ACTIVE_TRUCK,
  LOADING_TRUCK,
} from "types/admin/truckType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_TRUCK, payload));
};

export const getListTruck = (typeSearch) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.trucks.list(typeSearch));

    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_TRUCK, data));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addTruck = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.trucks.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editTruck = (body, truckId) => async (dispatch, getState) => {
  try {
    let response = await API.put(endPoints.trucks.edit(truckId), body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchTruckById = (truckId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.trucks.getOne(truckId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRowsTruck = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_TRUCK, payload));
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listTrucks, truckData } = getState().trucks;

    const body = {
      type: "status",
      option: truckData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.trucks.edit(id), body);

    const { status, message } = response.data;

    if (response.status) {
      const listAll = listTrucks.map((truck) => {
        if (truck.id === id) {
          return { ...truck, active: !truck.active };
        }
        return truck;
      });

      const payload = {
        data: {
          ...truckData,
          active: !truckData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(DATA_ACTIVE_TRUCK, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteTruck = (id) => async (dispatch, getState) => {
  try {
    const { listTrucks } = getState().trucks;

    let response = await API.delete(endPoints.trucks.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listTrucks.filter((truck) => truck.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_ACTIVE_TRUCK, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getListUserByTruckId = (truckId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.trucks.listUsers(truckId));
    const { status, message, data } = response.data;
    return {
      status,
      message,
      data,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

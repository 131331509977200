import { Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Nuevo from "assets/images/configurar/plus.png";
import Editar from "assets/images/configurar/edit.png";
import Eliminar from "assets/images/configurar/delete.png";
import Padlock from "assets/images/configurar/padlock.png";
import VehicleIcon from "assets/images/configurar/vehicle.png";
import SwitchToogle from "components/Layouts/SwitchToogle";
import {
  validateOnlyLetter,
  handlePasteOnlyLetter,
} from "utils/validationInput";
import { useUser } from "hooks/Admin/Users/useUser";
import { Link } from "react-router-dom";

const Filter = ({ filterText, onFilter, toast, setClearTable }) => {
  const { state, actions } = useUser();
  const { userDatos } = state;
  const { handleDelete, handleActivarDes } = actions;

  return (
    <>
      <Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
        <Col>
          <Col lg={6} className="pl-0 pr-0">
            <Form.Control
              id="search"
              type="text"
              placeholder="Ingresa el nombre o apellido del usuario"
              aria-label="Ingresa el nombre o apellido del usuario"
              value={filterText}
              onChange={onFilter}
              onKeyPress={(event) => validateOnlyLetter(event)}
              onPaste={(event) => handlePasteOnlyLetter(event)}
            />
          </Col>
          <span className="fs-12">
            **Nombre o apellido del usuario se utiliza para la búsqueda y
            modificación de los datos
          </span>
        </Col>
        <Col className="text-right">
          <Form.Group>
            {userDatos === null && (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-crear">Crear</Tooltip>}
              >
                <Link to="/admin/users/create">
                  <Button variant="warning" type="button" className="mr-2">
                    <img src={Nuevo} alt="Nuevo" className="img-conf" />
                  </Button>
                </Link>
              </OverlayTrigger>
            )}
            {userDatos !== null && (
              <>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-editar">Editar</Tooltip>}
                >
                  <Link to={`/admin/users/${userDatos?.id}/edit`}>
                    <Button variant="warning" type="button" className="mr-2">
                      <img src={Editar} alt="Editar" className="img-conf" />
                    </Button>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-eliminar">Eliminar</Tooltip>}
                >
                  <Button
                    variant="warning"
                    type="button"
                    className="mr-2"
                    onClick={(event) => handleDelete(event)}
                  >
                    <img src={Eliminar} alt="Eliminar" className="img-conf" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-clave">Cambiar Contraseña</Tooltip>
                  }
                >
                  <Link to={`/admin/users/${userDatos?.id}/change-password`}>
                    <Button variant="warning" type="button" className="mr-2">
                      <img
                        src={Padlock}
                        alt="Cambiar Clave"
                        className="img-conf"
                      />
                    </Button>
                  </Link>
                </OverlayTrigger>
                {userDatos?.name_rol === "Conductor" && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-asignar">Asignar Vehículo</Tooltip>
                    }
                  >
                    <Link to={`/admin/users/${userDatos?.id}/assign-trucks`}>
                      <Button variant="warning" type="button" className="mr-2">
                        <img
                          src={VehicleIcon}
                          alt="Asignar Vehículo"
                          className="img-assign"
                        />
                      </Button>
                    </Link>
                  </OverlayTrigger>
                )}
                <SwitchToogle
                  handleActive={handleActivarDes}
                  data={userDatos}
                />
              </>
            )}
          </Form.Group>
        </Col>
      </Col>
    </>
  );
};

export default Filter;

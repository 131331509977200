import { useDispatch, useSelector } from "react-redux";
import { getListUserDriverAll } from "actions/admin/driveAction";

export const useDrive = () => {
  const dispatch = useDispatch();
  const { listUserDrives, driveIsLoading } = useSelector(
    (state) => state.drivesUser
  );

  const getAll = async () => {
    dispatch(getListUserDriverAll("drivers"));
  };

  const filteredItems = (filterText) => {
    return listUserDrives.filter(
      (item) =>
        item.full_name &&
        item.full_name.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const state = {
    listUserDrives,
    driveIsLoading,
  };

  const actions = {
    getAll,
    filteredItems,
  };

  return {
    state,
    actions,
  };
};

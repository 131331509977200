import { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { BiCalendar } from "react-icons/bi";

registerLocale("es", es);

const DetailDocumentPayable = ({
  register,
  control,
  changeTypePayment,
  isDisable,
  isEdit,
  errors,
  isValidDocument,
}) => {
  const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl
        name="fecha_orden_c"
        className="fecha_cancelation"
        value={value}
        readOnly
      />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group">
          <label htmlFor="cancellation_date">Fecha Cancelación</label>
          <Controller
            control={control}
            name="cancellation_date"
            render={(props) => (
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Seleccione"
                onChange={(e) => props.onChange(e)}
                selected={props.value}
                className="form-control"
                name="cancellation_date"
                id="cancellation_date"
                maxDate={new Date()}
                locale="es"
                customInput={<ExampleCustomInputFrom />}
                readOnly={isValidDocument}
              />
            )}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label htmlFor="payment_type">Tipo de pago</label>
          <select
            className="form-control"
            id="payment_type"
            name="payment_type"
            ref={register}
            disabled={isValidDocument}
          >
            <option value="Total">Total</option>
            <option value="Parcial">Parcial</option>
          </select>
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label htmlFor="total">Monto Total</label>
          <input
            type="text"
            className="form-control"
            id="total"
            name="total"
            ref={register}
            readOnly={isDisable}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label htmlFor="remaining_amount">Monto Restante</label>
          <input
            type="text"
            className="form-control"
            id="remaining_amount"
            name="remaining_amount"
            ref={register}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default DetailDocumentPayable;

import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { entregarOrden, assignOrdenCaja } from "actions/transportOrdersAction";
import { useForm } from "react-hook-form";

export const useDelivertOtherOrder = () => {
  const dispatch = useDispatch();
  const { listEntregada } = useSelector((state) => state.transportOrders);
  const { metodo_pago } = useSelector((state) => state.metodoPagos);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { listParentCities } = useSelector((state) => state.parentCities);
  const { register, handleSubmit, errors, getValues, setValue, control } =
    useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [isVisible, setIsVisible] = useState(true);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    setIsLoading(true);
    setIsVisible(false);
    if (!data.hasOwnProperty("ordenes")) {
      toast.error("No tiene ordenes por retirar", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return false;
    }

    let { ordenes } = data;

    const listadoDocumentacion = ordenes.filter(
      (orden) =>
        orden.documentacion_id === "" && orden.forma_pago !== "Cuenta Corriente"
    );
    const listadoNro = ordenes.filter(
      (orden) =>
        orden.documentacion_id === "" && orden.forma_pago !== "Cuenta Corriente"
    );
    const listadoRut = ordenes.filter((orden) => orden.rut_recepcion === "");
    const listadoName = ordenes.filter(
      (orden) => orden.nombre_recepcion === ""
    );
    const listadoPago = ordenes.filter(
      (orden) =>
        orden.metodo_pago_id === "" && orden.forma_pago !== "Cuenta Corriente"
    );

    if (
      listadoDocumentacion.length > 0 ||
      listadoNro.length > 0 ||
      listadoRut.length > 0 ||
      listadoName.length > 0 ||
      listadoPago.length > 0
    ) {
      toast.error("Debe de completar el detalle de las ordenes", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return false;
    }

    if (!companyDataBasic?.invoice) {
      const listadoNroDocumento = ordenes.filter(
        (orden) => orden.nro_documento === ""
      );
      if (listadoNroDocumento.length > 0) {
        toast.error("Debe de completar los números de documentos", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        return false;
      }
    }

    const porPagar = ordenes.filter(
      (orden) =>
        orden.forma_pago === "Por pagar" &&
        parseInt(orden.documentacion_id) === 1
    );
    const infoOrders = ordenes?.map((item) => {
      const name_pago = metodo_pago.find(
        (pago) => pago?.id === Number(item.metodo_pago_id)
      );

      return {
        ...item,
        metodo_pago: name_pago?.description ?? "",
      };
    });

    if (companyDataBasic?.invoice) {
      if (porPagar.length > 0) {
        const information = {
          ordenes: infoOrders,
          rut_remitente: porPagar[0].rut_remitente,
          rut_destinatario: porPagar[0].rut_destinatario,
        };
        setHidden(true);
        setIsVisible(true);
        setInfoData(information);
      } else {
        const porPagar = infoOrders.filter(
          (orden) =>
            orden.forma_pago === "Por pagar" &&
            parseInt(orden.documentacion_id) === 2
        );

        const datosFactura = {
          ordenes: infoOrders,
          factura: {
            rut_factura: porPagar?.length > 0 ? porPagar[0].rut_remitente : "",
          },
        };

        await funcEntregar(datosFactura);
      }
    } else {
      const porPagar = infoOrders.filter(
        (orden) =>
          orden.forma_pago === "Por pagar" &&
          parseInt(orden.documentacion_id) === 2
      );

      const datosFactura = {
        ordenes: infoOrders,
        factura: {
          rut_factura: porPagar?.length > 0 ? porPagar[0].rut_remitente : "",
        },
      };
      await funcEntregar(datosFactura);
    }
  };

  const funcEntregar = async (information) => {
    let response = await dispatch(entregarOrden(information, "otros"));
    setIsLoading(false);
    if (response.status) {
      const body = {
        fecha_entrega: response.fecha_entrega,
        ordens: information.ordenes.map((item) => item.orden_flete_id),
      };

      await dispatch(assignOrdenCaja(body));

      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      document.getElementById("frmEntregaIntermedio").reset();
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const state = {
    isLoading,
    hidden,
    infoData,
    listEntregada,
    metodo_pago,
    listParentCities,
    companyDataBasic,
    isVisible,
  };
  const actions = {
    funcEntregar,
    setLoading: setIsLoading,
    setHidden,
    setInfoData,
    addOrdenAction: funcEntregar,
  };

  const formElement = {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  };

  return {
    actions,
    formElement,
    state,
  };
};

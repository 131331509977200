import {
  LIST_DATA_TRUCK,
  SELECTED_ROWS_TRUCK,
  DATA_ACTIVE_TRUCK,
  LOADING_TRUCK,
} from "types/admin/truckType";

const INITIAL_STATE = {
  listTrucks: [],
  truckIsLoading: true,
  truckData: null,
};

const camionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_TRUCK:
      return {
        ...state,
        truckIsLoading: action.payload.loading,
        truckData: action.payload.data,
      };
    case LIST_DATA_TRUCK:
      return {
        ...state,
        listTrucks: action.payload,
        truckIsLoading: false,
      };
    case SELECTED_ROWS_TRUCK:
      return {
        ...state,
        truckData: action.payload.data,
      };
    case DATA_ACTIVE_TRUCK:
      return {
        ...state,
        truckData: action.payload.data,
        listTrucks: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default camionReducer;

import API from "config/config-api";
import {
  LIST_DATA_PARENT_CITY,
  SELECTED_ROWS_PARENT_CITY,
  DATA_ACTIVE_PARENT_CITY,
  LOADING_PARENT_CITY,
} from "types/admin/parentCityType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_PARENT_CITY, payload));
};

export const getListParentCities =
  (country_id, parent_city) => async (dispatch, getState) => {
    try {
      let response = await API.get(
        endPoints.parentCity.list(country_id, parent_city)
      );
      const { status, message, data } = response.data;
      dispatch(requestSuccess(LIST_DATA_PARENT_CITY, data));
      return { status: status, data: data, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_PARENT_CITY, payload));
};

export const addParentCity = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.parentCity.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editParentCity =
  (body, parentCity) => async (dispatch, getState) => {
    try {
      let response = await API.put(endPoints.parentCity.edit(parentCity), body);
      const { status, message } = response.data;

      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listParentCities, parentCityData } = getState().parentCities;

    const body = {
      type: "status",
      option: parentCityData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.parentCity.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listParentCitiesUpdate = listParentCities.map((account) => {
        if (account.id === id) {
          return { ...account, active: !parentCityData.active };
        }
        return account;
      });

      const payload = {
        data: {
          ...parentCityData,
          active: !parentCityData.active,
        },
        listAll: listParentCitiesUpdate,
      };

      dispatch(requestSuccess(DATA_ACTIVE_PARENT_CITY, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteParentCity = (id) => async (dispatch, getState) => {
  try {
    const { listParentCities } = getState().parentCities;

    let response = await API.delete(endPoints.parentCity.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listParentCities.filter((account) => account.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_ACTIVE_PARENT_CITY, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchParentCityById =
  (parentCityId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.parentCity.getOne(parentCityId));

      const { status, message, data } = response.data;

      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };

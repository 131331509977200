import API from "config/config-api";
import { LIST_DATA_DRIVE } from "types/admin/driveType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getListUserDriverAll =
  (type_rol) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.user.list(type_rol, "all"));
      const { status, message, data } = response.data;
      dispatch(requestSuccess(LIST_DATA_DRIVE, data));
      return { status: status, data: data, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

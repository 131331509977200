const Correlativo = {
  columnas: [
    {
      name: "Módulo",
      selector: (row, i) => row.title,
      sortable: true,
    },
    {
      name: "Correlativo Inicial",
      selector: (row, i) => row.value_initial,
      sortable: true,
    },
    {
      name: "Correlativo Actual",
      selector: (row, i) => row.value_current,
      sortable: true,
    },
    {
      name: "Últ. Actualización",
      selector: (row, i) => row.updated_at,
      sortable: true,
    },
  ],
};

export default Correlativo;

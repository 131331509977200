import React, { Fragment, useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import TableOrden from "./TableOrden";
import { useDispatch } from "react-redux";
import { searchOrdenesInd } from "actions/transportOrdersAction";
import TitlePrincipal from "../../Layouts/TitlePrincipal";
import { handlePasteOnlyOrden, validateOnlyOrden } from "utils/validationInput";

const DetalleTabla = ({
  register,
  errors,
  getValues,
  setValue,
  toast,
  isLoad,
  control,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const addOrden = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (getValues("numero_orden").length === 0) {
      toast.error("Ingrese el número de orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    let ordenFormat = getValues("numero_orden")?.replace("'", "-");
    setValue("numero_orden", ordenFormat);

    const response = await dispatch(searchOrdenesInd(ordenFormat, "entrega"));

    if (!response.status) {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    } else {
      setValue("numero_orden", "");
    }
    setLoading(false);
  };

  const onKeyOrden = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      addOrden(event);
    }
  };

  return (
    <Fragment>
      <TitlePrincipal title="INGRESO DE DATOS" />
      <Form.Row>
        <Col md={3} sm={6}>
          <Form.Group as={Col} controlId="numero_orden">
            <Form.Label>N° de Orden</Form.Label>
            <Form.Control
              type="text"
              name="numero_orden"
              ref={register}
              maxLength={15}
              onKeyDown={(event) => onKeyOrden(event)}
              onKeyPress={(event) => validateOnlyOrden(event)}
              onPaste={(event) => handlePasteOnlyOrden(event)}
            />
          </Form.Group>
        </Col>
        <Col md={3} sm={6}>
          <Form.Group as={Col} className="mt-4">
            <Button
              variant="warning"
              disabled={isLoading}
              type="button"
              size="sm"
              block
              onClick={(event) => addOrden(event)}
            >
              {isLoading ? "Agregando..." : "AGREGAR"}
            </Button>
          </Form.Group>
        </Col>
      </Form.Row>
      <TableOrden register={register} toast={toast} control={control} />

      <Form.Row className="m-3">
        <Col className="text-right">
          <Button variant="warning" type="submit" disabled={isLoad}>
            {isLoad ? "Procesando..." : "ENTREGAR"}
          </Button>
        </Col>
      </Form.Row>
    </Fragment>
  );
};

export default DetalleTabla;

export const listCountry = [
    {
        id: 1,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Afghanistan",
        isoCode: "AF",
        phoneCode: "93",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 2,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Aland Islands",
        isoCode: "AX",
        phoneCode: "+358-18",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 3,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Albania",
        isoCode: "AL",
        phoneCode: "355",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 4,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Algeria",
        isoCode: "DZ",
        phoneCode: "213",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 5,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "American Samoa",
        isoCode: "AS",
        phoneCode: "+1-684",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 6,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Andorra",
        isoCode: "AD",
        phoneCode: "376",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 7,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Angola",
        isoCode: "AO",
        phoneCode: "244",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 8,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Anguilla",
        isoCode: "AI",
        phoneCode: "+1-264",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 9,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Antarctica",
        isoCode: "AQ",
        phoneCode: "672",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 10,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Antigua And Barbuda",
        isoCode: "AG",
        phoneCode: "+1-268",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 11,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Argentina",
        isoCode: "AR",
        phoneCode: "54",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 12,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Armenia",
        isoCode: "AM",
        phoneCode: "374",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 13,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Aruba",
        isoCode: "AW",
        phoneCode: "297",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 14,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Australia",
        isoCode: "AU",
        phoneCode: "61",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 15,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Austria",
        isoCode: "AT",
        phoneCode: "43",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 16,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Azerbaijan",
        isoCode: "AZ",
        phoneCode: "994",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 17,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "The Bahamas",
        isoCode: "BS",
        phoneCode: "+1-242",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 18,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bahrain",
        isoCode: "BH",
        phoneCode: "973",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 19,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bangladesh",
        isoCode: "BD",
        phoneCode: "880",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 20,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Barbados",
        isoCode: "BB",
        phoneCode: "+1-246",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 21,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Belarus",
        isoCode: "BY",
        phoneCode: "375",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 22,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Belgium",
        isoCode: "BE",
        phoneCode: "32",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 23,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Belize",
        isoCode: "BZ",
        phoneCode: "501",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 24,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Benin",
        isoCode: "BJ",
        phoneCode: "229",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 25,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bermuda",
        isoCode: "BM",
        phoneCode: "+1-441",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 26,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bhutan",
        isoCode: "BT",
        phoneCode: "975",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 27,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bolivia",
        isoCode: "BO",
        phoneCode: "591",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 28,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bosnia and Herzegovina",
        isoCode: "BA",
        phoneCode: "387",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 29,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Botswana",
        isoCode: "BW",
        phoneCode: "267",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 30,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bouvet Island",
        isoCode: "BV",
        phoneCode: "0055",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 31,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Brazil",
        isoCode: "BR",
        phoneCode: "55",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 32,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "British Indian Ocean Territory",
        isoCode: "IO",
        phoneCode: "246",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 33,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Brunei",
        isoCode: "BN",
        phoneCode: "673",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 34,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bulgaria",
        isoCode: "BG",
        phoneCode: "359",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 35,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Burkina Faso",
        isoCode: "BF",
        phoneCode: "226",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 36,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Burundi",
        isoCode: "BI",
        phoneCode: "257",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 37,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cambodia",
        isoCode: "KH",
        phoneCode: "855",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 38,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cameroon",
        isoCode: "CM",
        phoneCode: "237",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 39,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Canada",
        isoCode: "CA",
        phoneCode: "1",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 40,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cape Verde",
        isoCode: "CV",
        phoneCode: "238",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 41,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cayman Islands",
        isoCode: "KY",
        phoneCode: "+1-345",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 42,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Central African Republic",
        isoCode: "CF",
        phoneCode: "236",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 43,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Chad",
        isoCode: "TD",
        phoneCode: "235",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 44,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Chile",
        isoCode: "CL",
        phoneCode: "56",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 45,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "China",
        isoCode: "CN",
        phoneCode: "86",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 46,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Christmas Island",
        isoCode: "CX",
        phoneCode: "61",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 47,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cocos (Keeling) Islands",
        isoCode: "CC",
        phoneCode: "61",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 48,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Colombia",
        isoCode: "CO",
        phoneCode: "57",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 49,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Comoros",
        isoCode: "KM",
        phoneCode: "269",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 50,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Congo",
        isoCode: "CG",
        phoneCode: "242",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 51,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Democratic Republic of the Congo",
        isoCode: "CD",
        phoneCode: "243",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 52,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cook Islands",
        isoCode: "CK",
        phoneCode: "682",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 53,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Costa Rica",
        isoCode: "CR",
        phoneCode: "506",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 54,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cote D'Ivoire (Ivory Coast)",
        isoCode: "CI",
        phoneCode: "225",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 55,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Croatia",
        isoCode: "HR",
        phoneCode: "385",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 56,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cuba",
        isoCode: "CU",
        phoneCode: "53",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 57,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Cyprus",
        isoCode: "CY",
        phoneCode: "357",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 58,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Czech Republic",
        isoCode: "CZ",
        phoneCode: "420",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 59,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Denmark",
        isoCode: "DK",
        phoneCode: "45",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 60,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Djibouti",
        isoCode: "DJ",
        phoneCode: "253",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 61,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Dominica",
        isoCode: "DM",
        phoneCode: "+1-767",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 62,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Dominican Republic",
        isoCode: "DO",
        phoneCode: "+1-809 and 1-829",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 63,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "East Timor",
        isoCode: "TL",
        phoneCode: "670",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 64,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Ecuador",
        isoCode: "EC",
        phoneCode: "593",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 65,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Egypt",
        isoCode: "EG",
        phoneCode: "20",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 66,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "El Salvador",
        isoCode: "SV",
        phoneCode: "503",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 67,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Equatorial Guinea",
        isoCode: "GQ",
        phoneCode: "240",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 68,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Eritrea",
        isoCode: "ER",
        phoneCode: "291",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 69,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Estonia",
        isoCode: "EE",
        phoneCode: "372",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 70,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Ethiopia",
        isoCode: "ET",
        phoneCode: "251",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 71,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Falkland Islands",
        isoCode: "FK",
        phoneCode: "500",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 72,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Faroe Islands",
        isoCode: "FO",
        phoneCode: "298",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 73,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Fiji Islands",
        isoCode: "FJ",
        phoneCode: "679",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 74,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Finland",
        isoCode: "FI",
        phoneCode: "358",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 75,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "France",
        isoCode: "FR",
        phoneCode: "33",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 76,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "French Guiana",
        isoCode: "GF",
        phoneCode: "594",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 77,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "French Polynesia",
        isoCode: "PF",
        phoneCode: "689",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 78,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "French Southern Territories",
        isoCode: "TF",
        phoneCode: "262",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 79,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Gabon",
        isoCode: "GA",
        phoneCode: "241",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 80,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Gambia The",
        isoCode: "GM",
        phoneCode: "220",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 81,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Georgia",
        isoCode: "GE",
        phoneCode: "995",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 82,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Germany",
        isoCode: "DE",
        phoneCode: "49",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 83,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Ghana",
        isoCode: "GH",
        phoneCode: "233",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 84,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Gibraltar",
        isoCode: "GI",
        phoneCode: "350",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 85,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Greece",
        isoCode: "GR",
        phoneCode: "30",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 86,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Greenland",
        isoCode: "GL",
        phoneCode: "299",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 87,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Grenada",
        isoCode: "GD",
        phoneCode: "+1-473",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 88,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guadeloupe",
        isoCode: "GP",
        phoneCode: "590",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 89,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guam",
        isoCode: "GU",
        phoneCode: "+1-671",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 90,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guatemala",
        isoCode: "GT",
        phoneCode: "502",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 91,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guernsey and Alderney",
        isoCode: "GG",
        phoneCode: "+44-1481",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 92,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guinea",
        isoCode: "GN",
        phoneCode: "224",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 93,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guinea-Bissau",
        isoCode: "GW",
        phoneCode: "245",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 94,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Guyana",
        isoCode: "GY",
        phoneCode: "592",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 95,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Haiti",
        isoCode: "HT",
        phoneCode: "509",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 96,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Heard Island and McDonald Islands",
        isoCode: "HM",
        phoneCode: "672",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 97,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Honduras",
        isoCode: "HN",
        phoneCode: "504",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 98,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Hong Kong S.A.R.",
        isoCode: "HK",
        phoneCode: "852",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 99,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Hungary",
        isoCode: "HU",
        phoneCode: "36",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 100,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Iceland",
        isoCode: "IS",
        phoneCode: "354",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 101,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "India",
        isoCode: "IN",
        phoneCode: "91",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 102,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Indonesia",
        isoCode: "ID",
        phoneCode: "62",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 103,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Iran",
        isoCode: "IR",
        phoneCode: "98",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 104,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Iraq",
        isoCode: "IQ",
        phoneCode: "964",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 105,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Ireland",
        isoCode: "IE",
        phoneCode: "353",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 106,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Israel",
        isoCode: "IL",
        phoneCode: "972",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 107,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Italy",
        isoCode: "IT",
        phoneCode: "39",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 108,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Jamaica",
        isoCode: "JM",
        phoneCode: "+1-876",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 109,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Japan",
        isoCode: "JP",
        phoneCode: "81",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 110,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Jersey",
        isoCode: "JE",
        phoneCode: "+44-1534",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 111,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Jordan",
        isoCode: "JO",
        phoneCode: "962",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 112,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Kazakhstan",
        isoCode: "KZ",
        phoneCode: "7",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 113,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Kenya",
        isoCode: "KE",
        phoneCode: "254",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 114,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Kiribati",
        isoCode: "KI",
        phoneCode: "686",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 115,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "North Korea",
        isoCode: "KP",
        phoneCode: "850",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 116,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "South Korea",
        isoCode: "KR",
        phoneCode: "82",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 117,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Kuwait",
        isoCode: "KW",
        phoneCode: "965",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 118,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Kyrgyzstan",
        isoCode: "KG",
        phoneCode: "996",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 119,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Laos",
        isoCode: "LA",
        phoneCode: "856",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 120,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Latvia",
        isoCode: "LV",
        phoneCode: "371",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 121,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Lebanon",
        isoCode: "LB",
        phoneCode: "961",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 122,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Lesotho",
        isoCode: "LS",
        phoneCode: "266",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 123,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Liberia",
        isoCode: "LR",
        phoneCode: "231",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 124,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Libya",
        isoCode: "LY",
        phoneCode: "218",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 125,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Liechtenstein",
        isoCode: "LI",
        phoneCode: "423",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 126,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Lithuania",
        isoCode: "LT",
        phoneCode: "370",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 127,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Luxembourg",
        isoCode: "LU",
        phoneCode: "352",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 128,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Macau S.A.R.",
        isoCode: "MO",
        phoneCode: "853",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 129,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "North Macedonia",
        isoCode: "MK",
        phoneCode: "389",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 130,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Madagascar",
        isoCode: "MG",
        phoneCode: "261",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 131,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Malawi",
        isoCode: "MW",
        phoneCode: "265",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 132,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Malaysia",
        isoCode: "MY",
        phoneCode: "60",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 133,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Maldives",
        isoCode: "MV",
        phoneCode: "960",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 134,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mali",
        isoCode: "ML",
        phoneCode: "223",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 135,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Malta",
        isoCode: "MT",
        phoneCode: "356",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 136,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Man (Isle of)",
        isoCode: "IM",
        phoneCode: "+44-1624",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 137,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Marshall Islands",
        isoCode: "MH",
        phoneCode: "692",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 138,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Martinique",
        isoCode: "MQ",
        phoneCode: "596",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 139,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mauritania",
        isoCode: "MR",
        phoneCode: "222",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 140,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mauritius",
        isoCode: "MU",
        phoneCode: "230",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 141,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mayotte",
        isoCode: "YT",
        phoneCode: "262",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 142,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mexico",
        isoCode: "MX",
        phoneCode: "52",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 143,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Micronesia",
        isoCode: "FM",
        phoneCode: "691",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 144,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Moldova",
        isoCode: "MD",
        phoneCode: "373",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 145,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Monaco",
        isoCode: "MC",
        phoneCode: "377",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 146,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mongolia",
        isoCode: "MN",
        phoneCode: "976",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 147,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Montenegro",
        isoCode: "ME",
        phoneCode: "382",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 148,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Montserrat",
        isoCode: "MS",
        phoneCode: "+1-664",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 149,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Morocco",
        isoCode: "MA",
        phoneCode: "212",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 150,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Mozambique",
        isoCode: "MZ",
        phoneCode: "258",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 151,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Myanmar",
        isoCode: "MM",
        phoneCode: "95",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 152,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Namibia",
        isoCode: "NA",
        phoneCode: "264",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 153,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Nauru",
        isoCode: "NR",
        phoneCode: "674",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 154,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Nepal",
        isoCode: "NP",
        phoneCode: "977",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 155,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Bonaire, Sint Eustatius and Saba",
        isoCode: "BQ",
        phoneCode: "599",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 156,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Netherlands",
        isoCode: "NL",
        phoneCode: "31",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 157,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "New Caledonia",
        isoCode: "NC",
        phoneCode: "687",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 158,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "New Zealand",
        isoCode: "NZ",
        phoneCode: "64",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 159,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Nicaragua",
        isoCode: "NI",
        phoneCode: "505",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 160,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Niger",
        isoCode: "NE",
        phoneCode: "227",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 161,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Nigeria",
        isoCode: "NG",
        phoneCode: "234",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 162,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Niue",
        isoCode: "NU",
        phoneCode: "683",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 163,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Norfolk Island",
        isoCode: "NF",
        phoneCode: "672",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 164,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Northern Mariana Islands",
        isoCode: "MP",
        phoneCode: "+1-670",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 165,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Norway",
        isoCode: "NO",
        phoneCode: "47",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 166,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Oman",
        isoCode: "OM",
        phoneCode: "968",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 167,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Pakistan",
        isoCode: "PK",
        phoneCode: "92",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 168,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Palau",
        isoCode: "PW",
        phoneCode: "680",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 169,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Palestinian Territory Occupied",
        isoCode: "PS",
        phoneCode: "970",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 170,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Panama",
        isoCode: "PA",
        phoneCode: "507",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 171,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Papua new Guinea",
        isoCode: "PG",
        phoneCode: "675",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 172,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Paraguay",
        isoCode: "PY",
        phoneCode: "595",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 173,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Peru",
        isoCode: "PE",
        phoneCode: "51",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 174,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Philippines",
        isoCode: "PH",
        phoneCode: "63",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 175,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Pitcairn Island",
        isoCode: "PN",
        phoneCode: "870",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 176,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Poland",
        isoCode: "PL",
        phoneCode: "48",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 177,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Portugal",
        isoCode: "PT",
        phoneCode: "351",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 178,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Puerto Rico",
        isoCode: "PR",
        phoneCode: "+1-787 and 1-939",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 179,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Qatar",
        isoCode: "QA",
        phoneCode: "974",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 180,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Reunion",
        isoCode: "RE",
        phoneCode: "262",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 181,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Romania",
        isoCode: "RO",
        phoneCode: "40",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 182,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Russia",
        isoCode: "RU",
        phoneCode: "7",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 183,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Rwanda",
        isoCode: "RW",
        phoneCode: "250",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 184,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint Helena",
        isoCode: "SH",
        phoneCode: "290",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 185,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint Kitts And Nevis",
        isoCode: "KN",
        phoneCode: "+1-869",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 186,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint Lucia",
        isoCode: "LC",
        phoneCode: "+1-758",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 187,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint Pierre and Miquelon",
        isoCode: "PM",
        phoneCode: "508",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 188,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint Vincent And The Grenadines",
        isoCode: "VC",
        phoneCode: "+1-784",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 189,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint-Barthelemy",
        isoCode: "BL",
        phoneCode: "590",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 190,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saint-Martin (French part)",
        isoCode: "MF",
        phoneCode: "590",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 191,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Samoa",
        isoCode: "WS",
        phoneCode: "685",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 192,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "San Marino",
        isoCode: "SM",
        phoneCode: "378",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 193,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Sao Tome and Principe",
        isoCode: "ST",
        phoneCode: "239",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 194,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Saudi Arabia",
        isoCode: "SA",
        phoneCode: "966",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 195,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Senegal",
        isoCode: "SN",
        phoneCode: "221",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 196,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Serbia",
        isoCode: "RS",
        phoneCode: "381",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 197,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Seychelles",
        isoCode: "SC",
        phoneCode: "248",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 198,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Sierra Leone",
        isoCode: "SL",
        phoneCode: "232",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 199,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Singapore",
        isoCode: "SG",
        phoneCode: "65",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 200,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Slovakia",
        isoCode: "SK",
        phoneCode: "421",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 201,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Slovenia",
        isoCode: "SI",
        phoneCode: "386",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 202,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Solomon Islands",
        isoCode: "SB",
        phoneCode: "677",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 203,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Somalia",
        isoCode: "SO",
        phoneCode: "252",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 204,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "South Africa",
        isoCode: "ZA",
        phoneCode: "27",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 205,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "South Georgia",
        isoCode: "GS",
        phoneCode: "500",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 206,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "South Sudan",
        isoCode: "SS",
        phoneCode: "211",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 207,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Spain",
        isoCode: "ES",
        phoneCode: "34",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 208,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Sri Lanka",
        isoCode: "LK",
        phoneCode: "94",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 209,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Sudan",
        isoCode: "SD",
        phoneCode: "249",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 210,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Suriname",
        isoCode: "SR",
        phoneCode: "597",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 211,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Svalbard And Jan Mayen Islands",
        isoCode: "SJ",
        phoneCode: "47",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 212,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Swaziland",
        isoCode: "SZ",
        phoneCode: "268",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 213,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Sweden",
        isoCode: "SE",
        phoneCode: "46",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 214,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Switzerland",
        isoCode: "CH",
        phoneCode: "41",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 215,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Syria",
        isoCode: "SY",
        phoneCode: "963",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 216,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Taiwan",
        isoCode: "TW",
        phoneCode: "886",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 217,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Tajikistan",
        isoCode: "TJ",
        phoneCode: "992",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 218,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Tanzania",
        isoCode: "TZ",
        phoneCode: "255",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 219,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Thailand",
        isoCode: "TH",
        phoneCode: "66",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 220,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Togo",
        isoCode: "TG",
        phoneCode: "228",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 221,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Tokelau",
        isoCode: "TK",
        phoneCode: "690",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 222,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Tonga",
        isoCode: "TO",
        phoneCode: "676",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 223,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Trinidad And Tobago",
        isoCode: "TT",
        phoneCode: "+1-868",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 224,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Tunisia",
        isoCode: "TN",
        phoneCode: "216",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 225,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Turkey",
        isoCode: "TR",
        phoneCode: "90",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 226,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Turkmenistan",
        isoCode: "TM",
        phoneCode: "993",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 227,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Turks And Caicos Islands",
        isoCode: "TC",
        phoneCode: "+1-649",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 228,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Tuvalu",
        isoCode: "TV",
        phoneCode: "688",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 229,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Uganda",
        isoCode: "UG",
        phoneCode: "256",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 230,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Ukraine",
        isoCode: "UA",
        phoneCode: "380",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 231,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "United Arab Emirates",
        isoCode: "AE",
        phoneCode: "971",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 232,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "United Kingdom",
        isoCode: "GB",
        phoneCode: "44",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 233,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "United States",
        isoCode: "US",
        phoneCode: "1",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 234,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "United States Minor Outlying Islands",
        isoCode: "UM",
        phoneCode: "1",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 235,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Uruguay",
        isoCode: "UY",
        phoneCode: "598",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 236,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Uzbekistan",
        isoCode: "UZ",
        phoneCode: "998",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 237,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Vanuatu",
        isoCode: "VU",
        phoneCode: "678",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 238,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Vatican City State (Holy See)",
        isoCode: "VA",
        phoneCode: "379",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 239,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Venezuela",
        isoCode: "VE",
        phoneCode: "58",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 240,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Vietnam",
        isoCode: "VN",
        phoneCode: "84",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 241,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Virgin Islands (British)",
        isoCode: "VG",
        phoneCode: "+1-284",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 242,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Virgin Islands (US)",
        isoCode: "VI",
        phoneCode: "+1-340",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 243,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Wallis And Futuna Islands",
        isoCode: "WF",
        phoneCode: "681",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 244,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Western Sahara",
        isoCode: "EH",
        phoneCode: "212",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 245,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Yemen",
        isoCode: "YE",
        phoneCode: "967",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 246,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Zambia",
        isoCode: "ZM",
        phoneCode: "260",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 247,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Zimbabwe",
        isoCode: "ZW",
        phoneCode: "263",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 248,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Kosovo",
        isoCode: "XK",
        phoneCode: "383",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 249,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Curaçao",
        isoCode: "CW",
        phoneCode: "599",
        geoDistributionInfo: null,
        active: true
    },
    {
        id: 250,
        createdAt: "2023-08-22T17:01:29.592Z",
        updatedAt: "2023-08-22T17:01:29.592Z",
        deletedAt: null,
        name: "Sint Maarten (Dutch part)",
        isoCode: "SX",
        phoneCode: "1721",
        geoDistributionInfo: null,
        active: true
    }
]
import React, { useEffect } from "react";
import { Row, Button, Col } from "react-bootstrap";
import Layout from "../../components/Layouts/Layout";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const baseURL = process.env.REACT_APP_API_URL;

const PreviewRendir = (props) => {
  const { name_pdf_orden } = useSelector((state) => state.orders);
  const history = useHistory();

  useEffect(() => {
    if (name_pdf_orden.length === 0) {
      history.push("/transport-orders/search");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Orden de flete manual" modulo="orders_search">
      <Row>
        <Col xs={12} className="mb-3 mt-3 text-right">
          <Link to="/transport-orders/search">
            <Button variant="warning" type="button">
              Regresar
            </Button>
          </Link>
        </Col>
        <Col xs={12}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              title="Preview Consulta de Ordenes"
              className="embed-responsive-item"
              src={`${baseURL}/transport-orders/export/pdf/${name_pdf_orden}`}
              type="application/pdf"
              width="100%"
              height="300px"
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default PreviewRendir;

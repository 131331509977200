import {
  LIST_DATA_CITY,
  SELECTED_ROWS_CITY,
  ACTIVE_CITY,
  LOADING_CITY,
} from "types/admin/cityType";

const INITIAL_STATE = {
  listCities: [],
  cityIsLoading: true,
  cityData: null,
};

const cityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_CITY:
      return {
        ...state,
        cityIsLoading: action.payload.loading,
        cityData: action.payload.data,
      };
    case LIST_DATA_CITY:
      return {
        ...state,
        listCities: action.payload,
        cityIsLoading: false,
      };
    case SELECTED_ROWS_CITY:
      return {
        ...state,
        cityData: action.payload.data,
      };
    case ACTIVE_CITY:
      return {
        ...state,
        cityData: action.payload.data,
        listCities: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default cityReducer;

import { useState } from "react";
import {
  validateTokenLink,
  resetPasswordByToken,
} from "actions/admin/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

export const useResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState("");
  const [dangerMessage, setDangerMessage] = useState({
    status: true,
    message: "",
  });
  const [validToken, setValidToken] = useState({
    status: true,
    message: "",
  });
  const [loadingSearch, setloadingSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [typeInput, setTypeInput] = useState(false);
  const [typeInputRep, setTypeInputRep] = useState(false);

  const { dataBasic, listDataBasicFacturacion } = useSelector(
    (state) => state.dataBasicInvoice
  );

  const { register, handleSubmit, errors, getValues } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const body = {
        ...data,
        token: getTokenFromQuery(),
      };
      let response = await dispatch(resetPasswordByToken(body));

      setLoading(false);
      setDangerMessage({ status: response.status, message: response.message });
      setIsSuccess(response.status);

      if (response.status) {
        setTimeout(() => {
          history.push("/");
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setDangerMessage({
        status: false,
        message: "Hubo un error, intente más tarde",
      });
    }
  };

  const getTokenFromQuery = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("token");
  };

  const getVerifyToken = async () => {
    setloadingSearch(true);
    const token = getTokenFromQuery();

    const result = await dispatch(validateTokenLink({ token }));

    setData(result.status ? result?.data : "");
    setValidToken({
      status: result.status,
      message: !result.status ? result?.message : "",
    });
    setloadingSearch(false);
  };

  const goToLogin = () => {
    if (window.history.length > 0) {
      // Cambia la URL actual sin añadir una nueva entrada en el historial
      window.history.replaceState({}, "", "/");
    }
    window.location.href = "/";
  };

  const validatePassword = (value) => {
    if (value !== getValues("password")) {
      return "Las contraseña deben ser iguales";
    }
  };

  const state = {
    dataBasic,
    listDataBasicFacturacion,
    data,
    typeInput,
    typeInputRep,
    isSuccess,
    validToken,
    loadingSearch,
  };

  const formElement = {
    register,
    handleSubmit,
    onSubmit,
    errors,
    loading,
    dangerMessage,
    getValues,
  };

  const actions = {
    getVerifyToken,
    setTypeInput,
    setTypeInputRep,
    goToLogin,
    validatePassword,
  };

  return {
    state,
    formElement,
    actions,
  };
};

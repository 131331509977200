import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getListCountry,
  changeStatus,
  deleteCountry,
  getSelectedRows,
  getLoading,
  searchCountryById,
} from "actions/admin/countryAction";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useContries = () => {
  const dispatch = useDispatch();
  const { listCountries, contryIsLoading, countryData } = useSelector(
    (state) => state.countries
  );

  const [paisesAll, setPaisesAll] = useState([]);

  const getAll = async () => {
    dispatch(getSelectedRows(null));
    dispatch(getLoading(true));
    const result = await dispatch(getListCountry("all"));
    setPaisesAll(result?.data);
  };

  const getCountry = async (countryId) => {
    if (!countryId || countryId === null) {
      return;
    }

    if (countryData === null) {
      await dispatch(searchCountryById(countryId));
    }
  };

  const handleActivarDes = () => {
    if (countryData !== null) {
      const statusName = countryData.active === true ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} el país?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(countryData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (countryData !== null) {
      Swal.fire({
        title: "¿Desea eliminar el país?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteCountry(countryData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listCountries.filter(
      (item) =>
        item?.name &&
        item?.name.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRows(rows));
  };

  const state = {
    listCountries,
    contryIsLoading,
    countryData,
    paisesAll,
  };

  const actions = {
    getAll,
    handleActivarDes,
    handleDelete,
    filteredItems,
    handleRowClicked,
    getCountry,
  };

  return {
    state,
    actions,
  };
};

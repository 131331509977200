import React from "react";
import { Col, Form } from "react-bootstrap";
import { onPaste, validateOnlyOrden } from "utils/validationInput";

const Filter = ({ filterText, onFilter, setClearTable }) => {
  return (
    <>
      <Col lg={12} className="pl-0 mb-2">
        <Col className="pl-0">
          <Col lg={6} className="pl-0 pr-0">
            <Form.Control
              id="search"
              type="text"
              placeholder="Ingresa el número de folio"
              aria-label="Ingresa el número de folio"
              value={filterText}
              onChange={onFilter}
              autoComplete="off"
              onKeyPress={(event) => validateOnlyOrden(event)}
              onPaste={(event) => onPaste(event)}
            />
          </Col>
        </Col>
      </Col>
    </>
  );
};

export default Filter;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addParentCity,
  editParentCity,
  searchParentCityById,
} from "actions/admin/parentCityAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormParentCity = (countryId) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      name: "",
      city_id: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataParentCity, setDataParentCity] = useState(null);
  const { parentCityData } = useSelector((state) => state.parentCities);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      name: data?.name?.toUpperCase(),
      country_id: countryId,
    };

    if (dataParentCity?.id) {
      response = await dispatch(editParentCity(body, dataParentCity?.id));
    } else {
      response = await dispatch(addParentCity(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);
      history.push(`/admin/countries/${countryId}/parent-cities`);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getParentCityById = async (parentCityId) => {
    let data = parentCityData;

    if (parentCityId !== null) {
      if (parentCityData === null) {
        const result = await dispatch(searchParentCityById(parentCityId));
        data = result?.data;
      }
    }
    reset({
      name: data?.name ?? "",
    });

    setDataParentCity(parentCityId !== null ? data ?? null : null);
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };

  const actions = {
    getParentCityById,
  };

  return {
    formElement,
    actions,
  };
};

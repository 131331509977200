import React from "react";
import descargar from "assets/images/descargar.png";
import { downloadDocumento } from "utils/downloadFile";
import { useDispatch } from "react-redux";
import { searchDocument } from "actions/facturacionAction";

const Descargar = (row) => {
  const dispatch = useDispatch();

  const obtenerDocumento = async (event, row) => {
    event.preventDefault();

    if (row.url_documento.length === 0) {
      const body = {
        tipo_documento: "factura",
        numero_documento: row.numero_folio,
      };
      await dispatch(searchDocument(body));
    } else {
      let url = row.url_documento;
      /**if(row.url_documento.includes("http")){
				url = row.url_documento.replace("http", "https")
			}**/
      let data = {
        url: url,
        numero_documento: row.numero_folio,
      };

      downloadDocumento(data);
    }
  };

  return (
    <>
      <button
        style={{ height: "37px" }}
        className="btn btn-xs pb-0 pt-1 mb-0"
        key={row.numero_documento}
        onClick={(event) => obtenerDocumento(event, row)}
      >
        <img src={descargar} height="30" alt="Descargar Factura" />
      </button>
    </>
  );
};

export default Descargar;

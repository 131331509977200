import API from "config/config-api";
import {
  LIST_DATA_CITY,
  SELECTED_ROWS_CITY,
  ACTIVE_CITY,
  LOADING_CITY,
} from "types/admin/cityType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_CITY, payload));
};

export const getListCities = (typeSearch) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.city.list(typeSearch));
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_CITY, data));
    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_CITY, payload));
};

export const addCity = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.city.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editCity = (body, cityId) => async (dispatch, getState) => {
  try {
    let response = await API.put(endPoints.city.edit(cityId), body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listCities, cityData } = getState().cities;

    const body = {
      type: "status",
      option: cityData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.city.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = listCities.map((city) => {
        if (city.id === id) {
          return { ...city, active: !cityData?.active };
        }
        return city;
      });

      const payload = {
        data: {
          ...cityData,
          active: !cityData?.active,
        },
        listAll,
      };

      dispatch(requestSuccess(ACTIVE_CITY, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteCity = (id) => async (dispatch, getState) => {
  try {
    const { listCities } = getState().cities;

    let response = await API.delete(endPoints.city.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listCities.filter((city) => city.id !== id),
        data: null,
      };

      dispatch(requestSuccess(ACTIVE_CITY, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchCityById = (cityId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.city.getOne(cityId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

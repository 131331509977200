import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import GlobalSearch from "components/Nomina/GlobalSearch";
import TableOrden from "components/Nomina/TableOrden";
import OrdenSearch from "components/Nomina/OrdenSearch";
import Viajes from "components/Nomina/Viajes";
import { useDispatch, useSelector } from "react-redux";
import {
  getListPayrollAll,
  officeNomina,
  createdNomina,
} from "actions/payrollAction";
import { getListTruck } from "actions/admin/truckAction";
import {
  getDataInitialOrder,
  receiveOrden,
  cleanListadoOrden,
  calcularTotalSelected,
  resetSearchOrden,
} from "actions/transportOrdersAction";
import { getListCities } from "actions/admin/cityAction";

import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import LoadingSearch from "components/Utilidades/LoadingSearch";
import TitlePrincipal from "components/Layouts/TitlePrincipal";

const CreatedNomina = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  const { loadingSearch, listOrden, estados } = useSelector(
    (state) => state.transportOrders
  );
  const { listCities } = useSelector((state) => state.cities);
  const { btnNomina, isActiveNomina } = useSelector((state) => state.payrolls);

  const [isLoading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    getDataInitial();
    dispatch(getListPayrollAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data, e) => {
    try {
      setLoading(true);

      let { ordenes } = data;

      let dataOrden = ordenes.filter((orden) => orden !== false);

      if (dataOrden.length === 0) {
        toast.error("Seleccione una orden", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      const estadoId = document.getElementById("estado_id").value;

      let information = {};
      let fecha_viaje;

      if (document.getElementById("fecha_viaje")) {
        let fechaD = document.getElementById("fecha_viaje").value.split("/");
        fecha_viaje = fechaD[2] + "-" + fechaD[1] + "-" + fechaD[0];
      }

      if (
        estadoId === "pendiente_de_despacho" ||
        estadoId === "pendiente_de_despacho_sucursal"
      ) {
        const cantConductores = data.conductor_id.filter(
          (conductor) => conductor !== ""
        ).length;
        const cantCamiones = data.camion_id.filter(
          (camion) => camion !== ""
        ).length;

        if (cantConductores === 0 && cantCamiones === 0) {
          toast.error("Seleccione un conductor y un camión", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return false;
        }

        let vehiculos = [];
        if (cantConductores === cantCamiones) {
          let destino_sucursal = document.querySelector(".sucursal_des");

          destino_sucursal =
            destino_sucursal.options[destino_sucursal.selectedIndex].text;

          vehiculos = data.conductor_id.map((conductor, key) => {
            return {
              conductor_id: conductor,
              camion_id: data.camion_id[0],
              fecha_viaje: new Date(),
              destino_id: destino_sucursal,
            };
          });
        } else {
          toast.error("Verifique los conductores y camiones", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return false;
        }

        information = {
          ordenes: dataOrden,
          fecha_viaje,
          vehiculos,
        };
      }

      let response;

      if (estadoId === "pendiente_de_despacho") {
        response = await dispatch(createdNomina(information));
      } else if (estadoId === "en_transito") {
        const infoSend = {
          ordenes: dataOrden,
          sucursal_id: document.getElementById("sucursal_origen_id").value,
        };

        response = await dispatch(receiveOrden(infoSend));
      } else if (estadoId === "pendiente_de_despacho_sucursal") {
        response = await dispatch(officeNomina(information));
      }

      if (response && response.status) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        dispatch(cleanListadoOrden());
        if (
          estadoId === "pendiente_de_despacho" ||
          estadoId === "pendiente_de_despacho_sucursal"
        ) {
          dispatch(calcularTotalSelected());
          //setValue("numero_nomina", response.numero_nomina)
        }
      } else {
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
      setLoading(false);
    } catch (error) {
      toast.error("Hubo un error al procesar las ordenes", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const getDataInitial = async () => {
    if (estados.length === 0) {
      await dispatch(getDataInitialOrder());
    }

    if (listCities.length === 0) {
      await dispatch(getListCities("active"));
    }

    dispatch(resetSearchOrden());

    dispatch(getListTruck("active"));
  };

  return (
    <Layout titlePanel="Crear nómina" modulo="crear_nomina">
      <TitlePrincipal title="BUSCAR NÓMINAS" />
      <Row className="bg-gray panel-contenedor--info">
        <GlobalSearch />
        <OrdenSearch toast={toast} estados={estados} />
      </Row>
      {loadingSearch && <LoadingSearch />}

      {!loadingSearch && (
        <Form onSubmit={handleSubmit(onSubmit)} className="mb-5">
          <Row className="mt-2 bg-soft-blue">
            <TableOrden
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              toast={toast}
              btnNomina={btnNomina}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
            />
          </Row>
          {!isSearch &&
            listOrden.length > 0 &&
            btnNomina.title.length !== 0 &&
            (btnNomina.isVisible || !isActiveNomina) && (
              <Row className="mt-2 bg-soft-blue">
                <Col xs={12}>
                  <Row className="panel-contenedor--info pt-3">
                    <Col xs={12} className="bg-gray border-panel pt-3 pb-2">
                      {btnNomina.isVisible && (
                        <Viajes
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          toast={toast}
                          getValues={getValues}
                          isActiveNomina={isActiveNomina}
                        />
                      )}
                      {!isActiveNomina && (
                        <Col xs={12} className="text-right pt-3 pb-5 mt-5">
                          <Button
                            variant="warning"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading ? "Procesando..." : btnNomina.title}
                          </Button>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          <ToastContainer />
        </Form>
      )}
    </Layout>
  );
};

export default CreatedNomina;

import { useEffect } from "react";
import { Row, Form, Col, Button, Card, InputGroup } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  validateOnlyNumber,
  onPaste,
  validateOnlyLetter,
} from "utils/validationInput";
import { useFormCompany } from "hooks/Admin/Companies/useFormCompany";
import Dropzone from "components/Admin/Dropzone";

const Formulario = ({ companyId }) => {
  const { state, actions, formElement } = useFormCompany();

  const {
    isLoading,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
  } = formElement;

  const {
    setCheckFact,
    onFileAdded,
    cargarActivities,
    validatePhone,
    setDataInitialCompany,
    getRootProps,
    getInputProps,
    onDeleteFile,
    validateRUT,
    validateMail,
    setDataInitialActivity,
    setResetPhone,
  } = actions;

  const {
    checkFact,
    file,
    activities,
    codeCountry,
    isValidLogo,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    listCountries,
    dataCompany,
    companyDataBasic,
  } = state;

  useEffect(() => {
    setDataInitialCompany(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    setDataInitialActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCompany]);

  useEffect(() => {
    if (companyDataBasic !== null && dataCompany !== null) {
      setResetPhone(dataCompany, companyDataBasic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCompany, companyDataBasic]);

  return (
    <Form id="frmCasaMatriz" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="rut">
                <Form.Label>RUT</Form.Label>
                <Controller
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control"
                      id="rut"
                      name="rut"
                      value={props.value}
                      maxLength={12}
                      //defaultValue={datos?.rut || ""}
                      //readOnly={matrizIsEdit ? true : false}
                      onChange={(value) => {
                        props.onChange(value);
                        setValue(value.target.value ?? "");
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9Kk.-]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      //onBlur={(event) => handleBlurRut(event, setError)}
                    />
                  )}
                  control={control}
                  name="rut"
                  rules={{
                    required: { value: true, message: "Requerido" },
                    validate: validateRUT,
                  }}
                />
                {errors.rut && (
                  <Form.Text className="text-danger">
                    {errors?.rut?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="legal_name">
                <Form.Label>Razón social</Form.Label>
                <Form.Control
                  type="text"
                  name="legal_name"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  //defaultValue={datos?.legal_name || ""}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={150}
                />
                {errors.legal_name && (
                  <Form.Text className="text-danger">
                    {errors?.legal_name?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="country_id">
                <Form.Label>País</Form.Label>
                <Form.Control
                  as="select"
                  name="country_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onChange={(event) => cargarActivities(event)}
                >
                  <option value="">Seleccione</option>
                  {listCountries?.length > 0 &&
                    listCountries?.map((country) => (
                      <option
                        key={country.id}
                        value={country.id}
                        data-code={country.phone_code}
                      >
                        {country.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.country_id && (
                  <Form.Text className="text-danger">
                    {errors?.country_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="activity_id">
                <Form.Label>Giro</Form.Label>
                <Form.Control
                  as="select"
                  name="activity_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {activities?.length > 0 &&
                    activities
                      ?.filter((item) => item.active)
                      ?.map((activity) => (
                        <option key={activity.id} value={activity.id}>
                          {activity.description}
                        </option>
                      ))}
                </Form.Control>
                {errors.activity_id && (
                  <Form.Text className="text-danger">
                    {errors?.activity_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="address">
                <Form.Label>Dirección</Form.Label>
                <textarea
                  name="address"
                  id="address"
                  rows={4}
                  className="form-control"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                />
                {errors.address && (
                  <Form.Text className="text-danger">
                    {errors?.address?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="logo">
                <Form.Label>Logo</Form.Label>
                {isValidLogo && (
                  <>
                    <Dropzone
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      isDragActive={isDragActive}
                      isFocused={isFocused}
                      isDragAccept={isDragAccept}
                      isDragReject={isDragReject}
                      acceptedFiles={file}
                      onFileAdded={onFileAdded}
                      onDeleteFile={onDeleteFile}
                    />
                    {errors.logo && (
                      <Form.Text className="text-danger">
                        {errors?.logo?.message}
                      </Form.Text>
                    )}
                  </>
                )}
                {!isValidLogo && (
                  <div className="mt-2 alert alert-secondary p-2 d-flex bg-photo-success">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "scale-down",
                        cursor: "pointer",
                      }}
                      src={file[0]?.path}
                      alt="Logo"
                    />
                    <p
                      className="bg-pointer"
                      onClick={() => onDeleteFile(null)}
                    >
                      Haga click <br />
                      para cambiar el logo
                    </p>
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="invoice">
                <Form.Label>Facturación</Form.Label>
                <div className="mt-2">
                  <Form.Check
                    inline
                    label="Si"
                    name="invoice"
                    type="radio"
                    id={`inline-si`}
                    onChange={() => setCheckFact("si")}
                    checked={checkFact === "si" ? true : false}
                    //disabled
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="invoice"
                    type="radio"
                    id={`inline-no`}
                    onChange={() => setCheckFact("no")}
                    checked={checkFact === "no" ? true : false}
                    //disabled
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="mt-4">
              <h5>Datos del representante</h5>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="type_customer">
                <Form.Label>Tipo de cliente</Form.Label>
                <Form.Control as="select" name="type_customer" ref={register}>
                  <option value="">Seleccione</option>
                  <option value="Jurídico">Jurídico</option>
                  <option value="Natural">Natural</option>
                </Form.Control>
                {errors.type_customer && (
                  <Form.Text className="text-danger">
                    {errors?.type_customer?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="rut_legal_agent">
                <Form.Label>RUT</Form.Label>
                <Controller
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control"
                      id="rut_legal_agent"
                      name="rut_legal_agent"
                      value={props.value}
                      maxLength={12}
                      // defaultValue={datos?.rut_legal_agent || ""}
                      //readOnly={matrizIsEdit ? true : false}
                      onChange={(value) => {
                        props.onChange(value);
                        setValue(value.target.value ?? "");
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9Kk.-]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      //onBlur={(event) => handleBlurRut(event, setError)}
                    />
                  )}
                  control={control}
                  name="rut_legal_agent"
                  rules={{
                    required: { value: true, message: "Requerido" },
                    validate: validateRUT,
                  }}
                />
                {errors.rut_legal_agent && (
                  <Form.Text className="text-danger">
                    {errors?.rut_legal_agent?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="name_legal_agent">
                <Form.Label>Nombres</Form.Label>
                <Form.Control
                  type="text"
                  name="name_legal_agent"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 3, message: "Mínimo 3 caracteres" },
                  })}
                  // defaultValue={datos?.name_legal_agent || ""}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={100}
                />
                {errors.name_legal_agent && (
                  <Form.Text className="text-danger">
                    {errors?.name_legal_agent?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="surname_legal_agent">
                <Form.Label>Apellidos</Form.Label>
                <Form.Control
                  type="text"
                  name="surname_legal_agent"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  //defaultValue={datos?.surname_legal_agent || ""}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={100}
                />
                {errors.surname_legal_agent && (
                  <Form.Text className="text-danger">
                    {errors?.surname_legal_agent?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="phone_legal_agent">
                <Form.Label>Teléfono</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {codeCountry}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="phone_legal_agent"
                    //defaultValue={datos?.phone_legal_agent || ""}
                    maxLength={15}
                    disabled={codeCountry === "" ? true : false}
                    ref={register({
                      minLength: { value: 9, message: "Mínimo 9 dígitos" },
                      required: { value: true, message: "Requerido" },
                      //eslint-disable-next-line
                      pattern: {
                        value: /^([0-9]+)$/,
                        message: "Teléfono inválido",
                      },
                      validate: validatePhone,
                    })}
                    autoComplete="off"
                    onKeyPress={(event) => validateOnlyNumber(event)}
                    onPaste={(event) => onPaste(event)}
                  />
                </InputGroup>

                {errors.phone_legal_agent && (
                  <Form.Text className="text-danger">
                    {errors?.phone_legal_agent?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Form.Group controlId="email_legal_agent">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  name="email_legal_agent"
                  className="text-lowercase"
                  ref={register({
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                    validate: validateMail,
                  })}
                  //defaultValue={datos?.email_legal_agent || ""}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.email_legal_agent && (
                  <Form.Text className="text-danger">
                    {errors?.email_legal_agent?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group as={Col}>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="pl-5 pr-5"
            >
              {isLoading
                ? "Procesando..."
                : companyId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

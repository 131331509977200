import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import { Card } from "react-bootstrap";
import FormSearch from "components/Facturacion/Search/FormSearch";
import CurrentBillList from "components/Facturacion/Search/CurrentBillList";
import { ToastContainer } from "react-toastify";

import { useListInvoicePayment } from "hooks/Facturation/useListInvoicePayment";
import ReactPaginate from "react-paginate";

const SearchListInvoices = () => {
  const { state, actions, formElement } = useListInvoicePayment();

  const {
    startDate,
    endDate,
    loading,
    enabledDate,
    resultListInvoice,
    isLoadingSearch,
    loadingExcel,
    loadingPDF,
    isViewDetail,
    estadoText,
    pageCount,
    pageOffset,
  } = state;

  const {
    setStartDate,
    setEndDate,
    changeRangeDate,
    downloadFile,
    onChangeViewColumn,
    handlePageChange,
    getDataInitial,
  } = actions;

  const { register, handleSubmit, onSubmit } = formElement;

  useEffect(() => {
    getDataInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      titlePanel="Búsqueda de Facturación cliente"
      modulo="busqueda_factura_corriente"
    >
      <div className="row">
        <div className="col-12 mx-auto">
          <Card className="mb-5 mt-3 card-retiro shadow-sm">
            <Card.Header as="h6" className="card-header-white">
              Búsqueda de Factura Cuenta Corriente
            </Card.Header>
            <Card.Body className="">
              <FormSearch
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                startDate={startDate}
                endDate={endDate}
                loading={loading}
                enabledDate={enabledDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                changeRangeDate={changeRangeDate}
                onChangeViewColumn={onChangeViewColumn}
              />

              <div className="row">
                <div className="col-12">
                  <CurrentBillList
                    listDocuments={resultListInvoice}
                    isLoading={isLoadingSearch}
                    isViewDetail={isViewDetail}
                    estadoText={estadoText}
                  />

                  <ReactPaginate
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={pageOffset}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="pl-4 pr-4 mr-3 mt-4 mb-4 btn btn-outline-warning"
                    disabled={resultListInvoice?.length > 0 ? false : true}
                    onClick={(event) => downloadFile(event, "excel")}
                  >
                    {loadingExcel ? "Descargando..." : "Descargar en Excel"}
                  </button>
                  <button
                    type="button"
                    className="pl-4 pr-4 mt-4 mb-4 btn btn-outline-warning"
                    disabled={resultListInvoice?.length > 0 ? false : true}
                    onClick={(event) => downloadFile(event, "pdf")}
                  >
                    {loadingPDF ? "Descargando..." : "Descargar en PDF"}
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <ToastContainer />
    </Layout>
  );
};

export default SearchListInvoices;

import { Row, Button, Col, ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import Layout from "components/Layouts/Layout";
import TicketOrden from "components/Orders/PDF/TicketOrden";
import TicketCorrelativo from "components/Orders/PDF/TicketCorrelativo";
import { withRouter, Link } from "react-router-dom";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { getDataTicketCorrelativoOrden } from "actions/transportOrdersAction";
import { useDispatch } from "react-redux";

let valueInitial = 0;

const PreviewCorrelativos = (props) => {
  const {
    match: { params: numero },
  } = props;
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(10);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const getDataOrden = async () => {
    setLoading((loading) => true);
    let response = await dispatch(
      getDataTicketCorrelativoOrden(numero?.numero)
    );

    setData((data) => response?.data);
    setLoading((loading) => false);
    valueInitial = 80;
    clearInterval(startAnimation);
  };

  function startAnimation() {
    let timerId = setInterval(() => {
      valueInitial = valueInitial + 10;
      if (valueInitial < 80) {
        setCount((count) => valueInitial);
      } else {
        clearInterval(timerId);
      }
    }, 1000);
  }

  useEffect(() => {
    valueInitial = 0;
    startAnimation();
    getDataOrden();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numero?.numero]);

  return (
    <Layout titlePanel="Orden de flete manual" modulo="orders_search">
      <Row>
        <Col xs={12} className="mb-3 mt-3 text-right">
          <Link to={`/transport-orders/${numero?.numero}/edit`}>
            <Button variant="warning" type="button">
              Regresar
            </Button>
          </Link>
        </Col>
        {loading && (
          <Col xs={12}>
            <ProgressBar animated now={count} />
            <p className="text-center">
              {loading ? "Cargando ticket de la orden..." : ""}
            </p>
          </Col>
        )}
        <Col xs={12}>
          <div style={{ minHeight: "100vh", width: "100%" }}>
            <PDFViewer
              style={{ width: "100%", height: "90vh", border: "none" }}
              className="pdf-ticket"
            >
              {data?.length > 0 && (
                <Document>
                  {data?.map((item, key) => {
                    if (!item?.isTicketCorrelativo) {
                      return <TicketOrden key={key} {...item} />;
                    } else {
                      return (
                        <TicketCorrelativo
                          key={key}
                          numero_correlativo={item?.numero_correlativo}
                        />
                      );
                    }
                  })}
                </Document>
              )}
            </PDFViewer>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default withRouter(PreviewCorrelativos);

import {
  LISTADO_DATA_PARENT_OFFICE,
  SELECTED_ROWS_PARENT_OFFICE,
  LOADING_PARENT_OFFICE,
  ACTIVE_PARENT_OFFICE,
} from "types/admin/parentOfficeType";

const INITIAL_STATE = {
  listParentOffice: [],
  parentOfficeIsLoading: true,
  parentOfficeData: null,
};

const parentOfficeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_PARENT_OFFICE:
      return {
        ...state,
        parentOfficeIsLoading: action.payload.loading,
        parentOfficeData: action.payload.data,
      };
    case LISTADO_DATA_PARENT_OFFICE:
      return {
        ...state,
        listParentOffice: action.payload,
        parentOfficeIsLoading: false,
      };
    case SELECTED_ROWS_PARENT_OFFICE:
      return {
        ...state,
        parentOfficeData: action.payload.data,
      };
    case ACTIVE_PARENT_OFFICE:
      return {
        ...state,
        parentOfficeData: action.payload.data,
        listParentOffice: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default parentOfficeReducer;

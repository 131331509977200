import { useState, forwardRef, useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Button,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  getListadoOrdenes,
  getHistorico,
  selectedOption,
  resetListOrdenes,
} from "actions/facturacionAction";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import dayjs from "dayjs";
import { isAfter, getDateYMD, isBefore, isSame } from "utils/formatFecha";
import { BiCalendar } from "react-icons/bi";
import {
  handlePasteOnlyNumber,
  validateOnlyNumber,
} from "utils/validationInput";

registerLocale("es", es);

const Busqueda = ({ toast }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, getValues } = useForm({
    defaultValues: {
      fecha_desde: new Date(),
      fecha_hasta: new Date(),
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      ...data,
      desde: dayjs(getValues("fecha_desde")).format("YYYY-MM-DD"),
      hasta: dayjs(getValues("fecha_hasta")).format("YYYY-MM-DD"),
      generarFiles: false,
    };
    if (body.desde > body.hasta) {
      toast.error("La fecha de inicio no puede ser mayor a la fecha final", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return false;
    }

    if (data.facturacion_mes === "mensual") {
      response = await dispatch(getListadoOrdenes(body));

      if (response?.data?.ordenes?.length === 0) {
        toast.error("No hay ordenes disponibles por facturar", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      response = await dispatch(getHistorico(body));
    }

    if (!response.status) {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }

    setIsLoading(false);
  };

  const handleFacturacion = (type) => {
    dispatch(selectedOption());
  };

  const validateStartDateBlur = (props) => {
    const dateStart = getDateYMD(document.querySelector("#fecha_desde").value);

    if (dateStart.error) {
      toast.error(dateStart.message, { position: toast.POSITION.TOP_RIGHT });
      props.onChange(new Date());
      return false;
    }

    if (
      !isSame(dateStart.dateSelected, dayjs(getValues("fecha_hasta"))) &&
      isAfter(dateStart.dateSelected, dayjs(getValues("fecha_hasta")))
    ) {
      toast.error("La fecha inicial no puede ser superior a la fecha final", {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.onChange(new Date());
      document.querySelector("#fecha_hasta").value =
        dayjs().format("DD/MM/YYYY");
      return false;
    }
  };

  const validateEndDateBlur = (props) => {
    const dataEnd = getDateYMD(document.querySelector("#fecha_hasta").value);

    if (dataEnd.error) {
      toast.error(dataEnd.message, { position: toast.POSITION.TOP_RIGHT });
      props.onChange(new Date());
      return false;
    }

    if (
      !isSame(
        dataEnd.dateSelected,
        dayjs(getValues("fecha_desde")).format("YYYY-MM-DD")
      ) &&
      isBefore(dataEnd.dateSelected, dayjs(getValues("fecha_desde")))
    ) {
      toast.error("La fecha final no puede ser inferior a la fecha inicial", {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.onChange(new Date());
      return false;
    }
  };

  useEffect(() => {
    dispatch(resetListOrdenes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl name="fecha_orden_c" value={value} readOnly />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
    <InputGroup>
      <FormControl name="fecha_orden_c" value={value} readOnly />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <Form id="frmFacturacion" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light mr-4 ml-4 mt-4">
        <Card.Header as="h6" className="bg-warn-blue text-dark">
          FACTURACIÓN
        </Card.Header>
        <Card.Body className="bg-soft-blue pl-5 pr-5">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Text className="text-dark pb-4 pt-3 text-size">
                Busca facturaciones ingresando el código interno o la fecha.
              </Form.Text>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="codigo_interno">
                <Form.Label>Código interno</Form.Label>
                <Form.Control
                  type="text"
                  name="codigo_interno"
                  autoFocus
                  autoComplete="off"
                  maxLength={5}
                  //defaultValue={datos?.codigo_interno || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => handlePasteOnlyNumber(event)}
                />
                {errors.codigo_interno && (
                  <Form.Text className="text-danger">
                    {errors?.codigo_interno?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={3} md={3} sm={12}>
              <Form.Group controlId="fecha_desde">
                <Form.Label>Desde</Form.Label>
                <Controller
                  control={control}
                  name="fecha_desde"
                  render={(props) => (
                    <DatePicker
                      locale="es"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/MM/yyyy"
                      onChange={(e) => props.onChange(e)}
                      selected={props.value}
                      className="form-control"
                      id="fecha_desde"
                      name="fecha_desde"
                      autoComplete="off"
                      maxDate={new Date()}
                      onCalendarClose={() => validateStartDateBlur(props)}
                      customInput={<ExampleCustomInputFrom />}
                    />
                  )}
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
                {errors.fecha_desde && (
                  <Form.Text className="text-danger">
                    {errors?.fecha_desde?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={3} md={3} sm={12}>
              <Form.Group controlId="fecha_hasta">
                <Form.Label>Hasta</Form.Label>
                <Controller
                  control={control}
                  name="fecha_hasta"
                  render={(props) => (
                    <DatePicker
                      locale="es"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/MM/yyyy"
                      onChange={(e) => props.onChange(e)}
                      selected={props.value}
                      className="form-control"
                      id="fecha_hasta"
                      name="fecha_hasta"
                      autoComplete="off"
                      maxDate={new Date()}
                      onCalendarClose={() => validateEndDateBlur(props)}
                      customInput={<ExampleCustomInputTo />}
                    />
                  )}
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
                {errors.fecha_hasta && (
                  <Form.Text className="text-danger">
                    {errors?.fecha_hasta?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Text className="text-dark pb-3 text-size text-weigth">
                Visualizar
              </Form.Text>
            </Col>
            <Col>
              <Form.Group as={Col}>
                <div>
                  <Form.Check
                    inline
                    label="Facturación del mes"
                    type="radio"
                    value="mensual"
                    id="facturacion_del_mes"
                    name="facturacion_mes"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                    })}
                    onChange={() => handleFacturacion("mensual")}
                    defaultChecked
                  />
                  <Form.Check
                    inline
                    label="Facturación Histórico"
                    type="radio"
                    value="historico"
                    id="facturacion_historico"
                    name="facturacion_mes"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                    })}
                    onChange={() => handleFacturacion("historico")}
                  />
                </div>
                {errors.facturacion_mes && (
                  <Form.Text className="text-danger">
                    {errors?.facturacion_mes?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-right mt-3">
              <Form.Group as={Col}>
                <Button
                  variant="warning"
                  type="submit"
                  disabled={isLoading}
                  className="pl-5 pr-5"
                >
                  {isLoading ? "Procesando..." : "Buscar"}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default Busqueda;

import { Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParentCity } from "hooks/Admin/ParentCities/useParentCity";
import Nuevo from "assets/images/configurar/plus.png";
import Editar from "assets/images/configurar/edit.png";
import Eliminar from "assets/images/configurar/delete.png";
import Oficina from "assets/images/configurar/oficina.png";
import SwitchToogle from "components/Layouts/SwitchToogle";
import {
  validateOnlyLetter,
  handlePasteOnlyLetter,
} from "utils/validationInput";
import { Link, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const Filter = ({ filterText, onFilter, toast, setClearTable }) => {
  const { countryId } = useParams();
  const { state, actions } = useParentCity();
  const { parentCityData } = state;
  const { handleDelete, handleActivarDes } = actions;

  return (
    <>
      <Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
        <Col>
          <Col lg={6} className="pl-0 pr-0">
            <Form.Control
              id="search"
              type="text"
              placeholder="Ingresa el nombre del estado"
              aria-label="Ingresa el nombre del estado"
              value={filterText}
              onChange={onFilter}
              onKeyPress={(event) => validateOnlyLetter(event)}
              onPaste={(event) => handlePasteOnlyLetter(event)}
            />
          </Col>
          <span className="fs-12">
            **Nombre del estado se utiliza para la búsqueda y modificación de
            los datos
          </span>
        </Col>
        <Col className="text-right">
          <Form.Group>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-crear">Regresar</Tooltip>}
            >
              <Link to={`/admin/countries`}>
                <Button variant="secondary" type="button" className="mr-2">
                  <BiArrowBack />
                </Button>
              </Link>
            </OverlayTrigger>
            {parentCityData === null && (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-crear">Crear</Tooltip>}
              >
                <Link to={`/admin/countries/${countryId}/parent-cities/create`}>
                  <Button variant="warning" type="button" className="mr-2">
                    <img src={Nuevo} alt="Nuevo" className="img-conf" />
                  </Button>
                </Link>
              </OverlayTrigger>
            )}
            {parentCityData !== null && (
              <>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-editar">Editar</Tooltip>}
                >
                  <Link
                    to={`/admin/countries/${countryId}/parent-cities/${parentCityData?.id}/edit`}
                  >
                    <Button variant="warning" type="button" className="mr-2">
                      <img src={Editar} alt="Editar" className="img-conf" />
                    </Button>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-eliminar">Eliminar</Tooltip>}
                >
                  <Button
                    variant="warning"
                    type="button"
                    className="mr-2"
                    onClick={(event) => handleDelete(event)}
                  >
                    <img src={Eliminar} alt="Eliminar" className="img-conf" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-oficinas">Comuna</Tooltip>}
                >
                  <Link
                    to={`/admin/countries/${countryId}/parent-cities/${parentCityData?.id}/parent-offices`}
                  >
                    <Button variant="warning" type="button">
                      <img src={Oficina} alt="Oficinas" className="img-conf" />
                    </Button>
                  </Link>
                </OverlayTrigger>
                <SwitchToogle
                  handleActive={handleActivarDes}
                  data={parentCityData}
                />
              </>
            )}
          </Form.Group>
        </Col>
      </Col>
    </>
  );
};

export default Filter;

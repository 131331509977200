import { validarPermiso } from "../Permission/checkPermission";

export const formatGetOrden = (
  ordenes,
  moduleName,
  sucursalIdUser,
  configuracion
) => {
  try {
    let data = ordenes;
    let message = "";
    let estatus = true;

    if (typeof moduleName !== "undefined" && moduleName === "nomina") {
      if (
        !validarPermiso(configuracion, "editar_orden_flete") &&
        data?.length > 0
      ) {
        if (
          data[0]["sucursal_origen_id"] === sucursalIdUser &&
          data[0]["estado_id"] !== 1
        ) {
          data = [];
          message = "No tiene permiso de visualizar la orden";
          estatus = false;
        }
      }
    }

    let destino_nomina = "";

    if (data?.length > 0) {
      destino_nomina = "";
      if (
        typeof data[0]["sucursal_destino"] !== "undefined" &&
        data[0]["sucursal_destino"] !== ""
      ) {
        destino_nomina = data[0]["sucursal_destino"];
      }

      message = "Encontrado con éxito";
      estatus = true;
    }
    return { data, destino_nomina, estatus, message };
  } catch (error) {
    return {
      data: [],
      destino_nomina: "",
      estatus: false,
      message: "Hubo un error al visualizar los resultados",
    };
  }
};

const Activity = {
  columnas: [
    {
      name: "Nombre",
      selector: (row, i) => row.description,
      sortable: true,
    },
    {
      name: "Activo",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: true,
    },
  ],
};

export default Activity;

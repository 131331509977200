import React from "react";
import { Form } from "react-bootstrap";
//import { formatMonto } from "../../config/formatInput"
import { formatNumberCurrency } from "../../utils/validationInput";

const TableOrden = ({
  indice,
  numero_orden,
  fecha_orden_search,
  cliente_remitente,
  oficina_origen,
  oficina_destino,
  sucursal_origen,
  sucursal_destino,
  cliente_destinatario,
  direccion_destinatario,
  total_bultos,
  total_kilos,
  tipo_envio,
  forma_pago,
  total,
  check,
  id,
  disabledCheck,
  register,
  isActiveNomina,
  isVisible,
}) => {
  return (
    <tr>
      {!isActiveNomina && isVisible && (
        <td>
          <Form.Group id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              name={`ordenes[${indice}]`}
              defaultValue={id}
              checked={check}
              onChange={(event) => disabledCheck(event)}
              ref={register}
            />
          </Form.Group>
        </td>
      )}
      <td>{numero_orden}</td>
      <td>{fecha_orden_search}</td>
      <td>{cliente_remitente}</td>
      <td>{oficina_origen}</td>
      <td>{oficina_destino}</td>
      <td>{sucursal_origen}</td>
      <td>{sucursal_destino}</td>
      <td>{cliente_destinatario}</td>
      <td>{direccion_destinatario}</td>
      <td>{total_bultos}</td>
      <td>{formatNumberCurrency(total_kilos)}</td>
      <td>{tipo_envio}</td>
      <td>{forma_pago}</td>
      <td>{total}</td>
    </tr>
  );
};

export default TableOrden;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updatedRemitenteRetiro } from "../../actions/retiroAction";

export const useEditRetiro = (detRetiro) => {
  const dispatch = useDispatch();
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { register, handleSubmit, errors, setValue, control, getValues } =
    useForm({
      defaultValues: {
        sucursal_origen_id: detRetiro?.sucursal_id,
        comuna_origen_id: detRetiro?.comuna_id,
      },
    });
  const [isEditFormRemitente, setIsEditFormRemitente] = useState(true);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    let sucursal_origen = document.querySelector("#sucursal_origen_id");
    let comuna_origen = document.querySelector("#comuna_origen_id");

    let body = {
      ...data,
      comuna_name:
        sucursal_origen?.options[sucursal_origen.selectedIndex]?.text,
      sucursal_name: comuna_origen?.options[comuna_origen.selectedIndex]?.text,
      telefono_remitente: `${companyDataBasic?.country?.code}${data?.telefono_remitente}`,
    };

    const response = await dispatch(updatedRemitenteRetiro(body));
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      setIsEditFormRemitente(true);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setLoading(false);
  };

  const handleEditRemitente = (detRetiro, cargarOficinas) => {
    //register({ name: "sucursal_origen_id" })
    //setValue("sucursal_origen_id", detRetiro?.sucursal_id)
    //cargarOficinas(undefined, "origen", detRetiro?.sucursal_id)
    setIsEditFormRemitente(!isEditFormRemitente);
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    errors,
    handleEditRemitente,
    isEditFormRemitente,
    control,
    setValue,
    loading,
    getValues,
  };
};

import { useDispatch, useSelector } from "react-redux";
import {
  getListCompaniesAll,
  changeStatus,
  getSelectedRowsCompany,
  deleteCompany,
  sendEmailForVerified,
  getLoading,
} from "actions/admin/companyAction";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useCompany = () => {
  const dispatch = useDispatch();
  const { listCompanies, companyIsLoading, companyData, companyBtnActive } =
    useSelector((state) => state.companies);

  const getAll = async () => {
    dispatch(getLoading(true));
    dispatch(getListCompaniesAll());
  };

  const handleActivarDes = () => {
    if (companyData !== null) {
      const statusName = companyData.active ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} la casa matriz?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(companyData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (companyData !== null) {
      Swal.fire({
        title: "¿Desea eliminar la casa matriz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteCompany(companyData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const sendEmailAdmin = () => {
    if (companyData !== null) {
      Swal.fire({
        title: "¿Está seguro que desea enviar el e-mail de verificación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(sendEmailForVerified(companyData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listCompanies.filter(
      (item) =>
        item?.legal_name &&
        item?.legal_name.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRowsCompany(rows));
  };

  const actions = {
    filteredItems,
    handleRowClicked,
    getAll,
    handleActivarDes,
    handleDelete,
    sendEmailAdmin,
  };

  const state = {
    listCompanies,
    companyIsLoading,
    companyData,
    companyBtnActive,
  };
  return {
    state,
    actions,
  };
};

import API from "config/config-api";
import {
  LOGIN,
  LOGOUT,
  LIST_DATA_USER,
  DATA_ACTIVE_USER,
  SELECTED_ROWS_USER,
  LOADING_USER,
} from "types/admin/userType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_USER, payload));
};

export const login = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.auth.login, body);

    const { data, status, message, token } = response?.data;

    if (status) {
      let userData = JSON.parse(atob(data));

      const user = {
        email: body.email,
        name: userData.name,
        token: token,
        permissions: userData.permissions,
        office_id: userData.office_id,
        city_id: userData.city_id,
        country: userData.country,
      };

      localStorage.setItem("user", JSON.stringify(user));
      dispatch(requestSuccess(LOGIN, userData.name));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: "Error al iniciar sesión" };
  }
};

export const loadUser = () => (dispatch, getState) => {
  try {
    const serializedData = JSON.parse(localStorage.getItem("user"));
    if (serializedData === null) {
      return undefined;
    }
    dispatch(requestSuccess(LOGIN, JSON.parse(localStorage.getItem("user"))));
  } catch (error) {
    return undefined;
  }
};

export const logout = (body) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.auth.logout);

    if (response.status) {
      localStorage.clear();
      dispatch(requestSuccess(LOGOUT, null));
    }
    return { status: response.status, message: response.message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getListUser =
  (typeRol, typeFilter) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.user.list(typeRol, typeFilter));
      const { status, message, data } = response.data;
      dispatch(requestSuccess(LIST_DATA_USER, data));
      return { status: status, data: data, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    const { listUsers } = getState().users;

    let response = await API.delete(endPoints.user.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: [...listUsers].filter((user) => user.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_ACTIVE_USER, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_USER, payload));
};

export const addUser = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.user.add, body, true);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editUser = (body, userId) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.user.edit(userId), body, true);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listUsers, userDatos } = getState().users;

    const body = {
      type: "status",
      option: userDatos?.active ? "desactivado" : "activado",
    };

    let response = await API.post(endPoints.user.edit(id), body);
    const { status, message } = response.data;

    if (status) {
      const listAll = listUsers.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            active: !userDatos.active,
          };
        }
        return user;
      });

      const payload = {
        data: {
          ...userDatos,
          active: !userDatos?.active,
        },
        listAll,
      };

      dispatch(requestSuccess(DATA_ACTIVE_USER, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const changePassword = (body, userId) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.user.passwordChange(userId), body);
    const { status, message } = response.data;
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addVehicleUser = (body, userId) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.user.assignVehicle(userId), body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const removeVehicleUser =
  (body, userId) => async (dispatch, getState) => {
    try {
      let response = await API.post(endPoints.user.removeVehicle(userId), body);
      const { status, message } = response.data;

      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const validateTokenLink = (body) => async (dispatch, getState) => {
  try {
    let { status, message, data } = await API.post(
      endPoints.auth.verifyAuthorized,
      body
    );

    return { status, message, data };
  } catch (error) {
    return { status: false, message: "Error al validar el usuario" };
  }
};

export const resetPasswordByToken = (body) => async (dispatch, getState) => {
  try {
    let { status, message } = await API.post(
      endPoints.auth.resetPassword,
      body
    );

    return { status, message };
  } catch (error) {
    return { status: false, message: "Error al verificar el usuario" };
  }
};

export const getListUserByCamionId =
  (camionId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.user.listUserByCamionId(camionId));

      return {
        status: response?.status,
        message: response?.message,
        data: response?.data,
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const searchUserById = (userId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.user.getOne(userId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

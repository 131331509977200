import {
  LIST_DATA_COMPANY,
  SELECTED_ROWS_COMPANY,
  ACTIVE_COMPANY,
  LOADING_COMPANY,
  DATA_MASTER_COMPANY,
} from "types/admin/companyType";

const INITIAL_STATE = {
  listCompanies: [],
  companyIsLoading: true,
  companyData: null,
  companyDataBasic: null,
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_COMPANY:
      return {
        ...state,
        companyIsLoading: action.payload.loading,
        companyData: action.payload.data,
      };
    case LIST_DATA_COMPANY:
      return {
        ...state,
        listCompanies: action.payload,
        companyIsLoading: false,
      };
    case SELECTED_ROWS_COMPANY:
      return {
        ...state,
        companyData: action.payload.data,
      };
    case ACTIVE_COMPANY:
      return {
        ...state,
        companyData: action.payload.data,
        listCompanies: action.payload.listAll,
      };
    case DATA_MASTER_COMPANY:
      return {
        ...state,
        companyDataBasic: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;

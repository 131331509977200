import { Button, Modal, Row, Col, Form, InputGroup } from "react-bootstrap";
import Progress from "components/Layouts/Progress";
import { useDocumentFactura } from "hooks/MetodoPago/useDocumentFactura";
import {
  handlePasteOnlyNumber,
  handlePasteOnlyLetter,
  validateOnlyLetter,
  validateOnlyNumber,
} from "utils/validationInput";
import { Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";

const ModalDocumentPayment = ({
  hidden,
  handleClose,
  toast,
  data,
  loadingPago,
  setLoadingPago,
  setOpenForm,
  setValue,
  getDatInitial,
}) => {
  const {
    cargarCiudades,
    isSpinner,
    sucursales,
    register,
    handleSubmit,
    errors,
    onSubmit,
    bloquearInput,
    selectCliente,
    ciudades,
    searchClienteFactura,
    onKeyDownFactura,
    isActivoRut,
    isProcesando,
    control,
    companyDataBasic,
    validatePhone,
  } = useDocumentFactura(
    toast,
    data,
    loadingPago,
    setLoadingPago,
    setOpenForm,
    getDatInitial
  );

  return (
    <Modal show={hidden} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <h6 className="mb-0">Factura a nombre de:</h6>
      </Modal.Header>
      <Modal.Body>
        <Form id="frmDocumentPayment" onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-4">
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12} className="mb-1">
                    <Form.Group
                      as={Col}
                      controlId="type_cliente_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Cliente</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(event) => selectCliente(event)}
                        name="type_cliente_factura"
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                      >
                        <option value="">Seleccione</option>
                        <option value="remitente">Remitente</option>
                        <option value="destinatario">Destinatario</option>
                        <option value="otros">Otros</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 pb-3">
              <Col sm={12}>
                <h6>Datos básicos</h6>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12} className="mb-1">
                    <Form.Group
                      as={Col}
                      controlId="rut_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>RUT</Form.Label>
                      <Controller
                        autoComplete="off"
                        render={(props) => (
                          <MaskedInput
                            mask={RutTextMask}
                            className="form-control"
                            id="rut_factura"
                            name="rut_factura"
                            value={props.value}
                            maxLength={12}
                            onChange={(value) => {
                              props.onChange(value);
                            }}
                            //onBlur={(event) => handleBlurRut(event, setError)}
                            onBlur={(event) => searchClienteFactura(event)}
                            onKeyDown={(event) => onKeyDownFactura(event)}
                            readOnly={isActivoRut}
                          />
                        )}
                        control={control}
                        name="cliente_rut"
                        rules={{
                          required: { value: true, message: "Requerido" },
                        }}
                      />
                      {errors.rut_factura && (
                        <Form.Text className="text-danger">
                          {errors?.rut_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12}>
                    <Form.Group
                      as={Col}
                      controlId="razon_social_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Razón Social</Form.Label>
                      <Form.Control
                        type="text"
                        name="razon_social_factura"
                        readOnly={bloquearInput}
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                        onKeyPress={(event) => validateOnlyLetter(event)}
                        maxLength={150}
                        onPaste={(event) => handlePasteOnlyLetter(event)}
                      />
                      {errors.razon_social_factura && (
                        <Form.Text className="text-danger">
                          {errors?.razon_social_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12} className="mb-1">
                    <Form.Group
                      as={Col}
                      controlId="giro_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Giro</Form.Label>
                      <Form.Control
                        type="text"
                        name="giro_factura"
                        readOnly={bloquearInput}
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                        onKeyPress={(event) => validateOnlyLetter(event)}
                        maxLength={70}
                        onPaste={(event) => handlePasteOnlyLetter(event)}
                      />
                      {errors.giro_factura && (
                        <Form.Text className="text-danger">
                          {errors?.giro_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12}>
                    <Form.Group
                      as={Col}
                      controlId="contacto_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Contacto</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            {companyDataBasic?.country?.code}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="text"
                          name="contacto_factura"
                          readOnly={bloquearInput}
                          maxLength={15}
                          disabled={
                            companyDataBasic?.country?.code === ""
                              ? true
                              : false
                          }
                          ref={register({
                            minLength: {
                              value: 9,
                              message: "Mínimo 9 dígitos",
                            },
                            required: { value: true, message: "Requerido" },
                            //eslint-disable-next-line
                            pattern: {
                              value: /^([0-9]+)$/,
                              message: "Teléfono inválido",
                            },
                            validate: validatePhone,
                          })}
                          autoComplete="off"
                          onKeyPress={(event) => validateOnlyNumber(event)}
                          onPaste={(event) => handlePasteOnlyNumber(event)}
                        />
                      </InputGroup>
                      {errors.contacto_factura && (
                        <Form.Text className="text-danger">
                          {errors?.contacto_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12} className="mb-1">
                    <Form.Group
                      as={Col}
                      controlId="email_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        name="email_factura"
                        readOnly={bloquearInput}
                        ref={register({
                          required: { value: true, message: "Requerido" },
                          //eslint-disable-next-line
                          pattern: {
                            value:
                              /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                            message: "Email inválido",
                          },
                        })}
                        maxLength={70}
                      />
                      {errors.email_factura && (
                        <Form.Text className="text-danger">
                          {errors?.email_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12}>
                    <Form.Group
                      as={Col}
                      controlId="direccion_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        type="text"
                        name="direccion_factura"
                        readOnly={bloquearInput}
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                      />
                      {errors.direccion_factura && (
                        <Form.Text className="text-danger">
                          {errors?.direccion_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12} className="mb-1">
                    <Form.Group
                      as={Col}
                      controlId="ciudad_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Ciudad</Form.Label>
                      <Form.Control
                        as="select"
                        disabled={bloquearInput}
                        onChange={(event) => cargarCiudades(event)}
                        name="ciudad_factura"
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                      >
                        <option value="">Seleccione</option>
                        {sucursales.length > 0 &&
                          sucursales.map((sucursal) => (
                            <option key={sucursal.id} value={sucursal.id}>
                              {sucursal.name}
                            </option>
                          ))}
                      </Form.Control>
                      {errors.ciudad_factura && (
                        <Form.Text className="text-danger">
                          {errors?.ciudad_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <Row>
                  <Col xs={12}>
                    <Form.Group
                      as={Col}
                      controlId="comuna_factura"
                      className="mb-0 pl-0 pr-0"
                    >
                      <Form.Label>Comuna</Form.Label>
                      <Form.Control
                        as="select"
                        disabled={bloquearInput}
                        name="comuna_factura"
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                      >
                        <option value="">Seleccione</option>
                        {ciudades.length > 0 &&
                          ciudades.map((ciudad) => (
                            <option key={ciudad.id} value={ciudad.id}>
                              {ciudad.name}
                            </option>
                          ))}
                      </Form.Control>
                      {errors.comuna_factura && (
                        <Form.Text className="text-danger">
                          {errors?.comuna_factura?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {isSpinner && (
            <div className="pb-4 text-center">
              <Progress />
              <p className="font-italic">
                Procesando la información, espere unos minutos
              </p>
            </div>
          )}
          <Modal.Footer>
            <Button
              className="pl-5 pr-5"
              variant="warning"
              disabled={isProcesando}
              onClick={() => handleClose()}
            >
              Cerrar
            </Button>
            <Button
              type="submit"
              className="pl-5 pr-5"
              variant="warning"
              disabled={isProcesando}
            >
              {isProcesando ? "Procesando..." : "Procesar"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDocumentPayment;

import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import FormAddConductor from "components/Admin/Users/Conductors/FormAddConductor";
import { onPaste, validateOnlyLetter } from "utils/validationInput";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { useFormUser } from "hooks/Admin/Users/useFormUser";
import { Link } from "react-router-dom";

const Formulario = ({ datos, userId }) => {
  const { state, actions, validPicture, formElement } = useFormUser();

  const { register, handleSubmit, errors, getValues, control, setValue } =
    formElement;

  const {
    isLoading,
    offices,
    typeRol,
    file,
    typeInput,
    typeInputRep,
    isChangePhoto,
    listRole,
    listCities,
    dataUser,
  } = state;

  const {
    getLoadOffice,
    handleFile,
    isVisiblePanel,
    onSubmit,
    changeRol,
    setTypeInput,
    setTypeInputRep,
    setFile,
    validatePassword,
    setDataInicial,
  } = actions;

  useEffect(() => {
    setDataInicial(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Form id="frmUsuario" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  autoFocus
                  defaultValue={datos?.name || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={100}
                />
                {errors.name && (
                  <Form.Text className="text-danger">
                    {errors?.name?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="surname">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="surname"
                  defaultValue={datos?.surname || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={100}
                />
                {errors.surname && (
                  <Form.Text className="text-danger">
                    {errors?.surname?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="city_id">
                <Form.Label>Sucursal</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(event) => getLoadOffice(event)}
                  name="city_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {listCities?.length > 0 &&
                    listCities?.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.city_id && (
                  <Form.Text className="text-danger">
                    {errors?.city_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="office_id">
                <Form.Label>Comuna</Form.Label>
                <Form.Control
                  as="select"
                  name="office_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {offices.length > 0 &&
                    offices.map((office) => (
                      <option key={office.id} value={office.id}>
                        {office.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.office_id && (
                  <Form.Text className="text-danger">
                    {errors?.office_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="name_rol">
                <Form.Label>Rol</Form.Label>
                <Form.Control
                  as="select"
                  name="name_rol"
                  onChange={(event) => changeRol(event)}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {listRole?.length > 0 &&
                    listRole?.map((rol) => (
                      <option key={rol.id} value={rol.name}>
                        {rol.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.name_rol && (
                  <Form.Text className="text-danger">
                    {errors?.name_rol?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  defaultValue={datos?.email || ""}
                  disabled={userId !== null ? true : false}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                  })}
                  maxLength={100}
                />
                {errors.email && (
                  <Form.Text className="text-danger">
                    {errors?.email?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          {userId === null && (
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Form.Group controlId="password">
                  <Form.Label>Contraseña</Form.Label>
                  <div className="mb-3 p-relative">
                    <Form.Control
                      type={!typeInput ? "password" : "text"}
                      name="password"
                      ref={register({
                        required: { value: true, message: "Requerido" },
                        minLength: { value: 9, message: "Mínimo 9 caracteres" },
                        maxLength: {
                          value: 15,
                          message: "Máximo 15 caracteres",
                        },
                        validate: validatePassword,
                        //eslint-disable-next-line
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,15}[^'\s]/,
                          message:
                            "La contraseña debe tener: al menos 9 caracteres, 1 número, 1 carácter en minúscula, 1 carácter en mayúscula y 1 carácter especial",
                        },
                      })}
                      maxLength={15}
                    />
                    <div
                      className="visible-password"
                      onClick={() => setTypeInput(!typeInput)}
                    >
                      {!typeInput ? (
                        <BiHide style={{ fontSize: "20px" }} />
                      ) : (
                        <BiShow style={{ fontSize: "20px" }} />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <Form.Text className="text-danger">
                      {errors?.password?.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group controlId="repPassword">
                  <Form.Label>Repetir contraseña</Form.Label>
                  <div className="mb-3 p-relative">
                    <Form.Control
                      type={!typeInputRep ? "password" : "text"}
                      name="repPassword"
                      ref={register({
                        required: { value: true, message: "Requerido" },
                        minLength: { value: 8, message: "Mínimo 8 caracteres" },
                        maxLength: {
                          value: 15,
                          message: "Máximo 15 caracteres",
                        },
                        validate: (value) => {
                          if (value === getValues()["password"]) {
                            return true;
                          } else {
                            return "Las claves no coinciden";
                          }
                        },
                      })}
                      maxLength={15}
                    />
                    <div
                      className="visible-password"
                      onClick={() => setTypeInputRep(!typeInputRep)}
                    >
                      {!typeInputRep ? (
                        <BiHide style={{ fontSize: "20px" }} />
                      ) : (
                        <BiShow style={{ fontSize: "20px" }} />
                      )}
                    </div>
                  </div>
                  {errors.repPassword && (
                    <Form.Text className="text-danger">
                      {errors?.repPassword?.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
          {typeRol === "Conductor" && (
            <FormAddConductor
              register={register}
              datos={dataUser}
              errors={errors}
              control={control}
              setValue={setValue}
              setFile={setFile}
              file={file}
              coIsEdit={userId !== null ? true : false}
              isVisiblePanel={isVisiblePanel}
              isChangePhoto={isChangePhoto}
              handleFile={handleFile}
              validPicture={validPicture}
            />
          )}
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to="/admin/users">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : userId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

import {
  LIST_DATA_DEPOSIT_ACCOUNT,
  SELECTED_ROWS_DEPOSIT_ACCOUNT,
  DATA_ACTIVE_DEPOSIT_ACCOUNT,
  LOADING_DEPOSIT_ACCOUNT,
} from "types/admin/depositAccountType";

const INITIAL_STATE = {
  listAccount: [],
  accountIsLoading: true,
  accountData: null,
};

const DepositAccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_DEPOSIT_ACCOUNT:
      return {
        ...state,
        accountIsLoading: action.payload.loading,
        accountData: action.payload.data,
      };
    case LIST_DATA_DEPOSIT_ACCOUNT:
      return {
        ...state,
        listAccount: action.payload,
        accountIsLoading: false,
      };
    case SELECTED_ROWS_DEPOSIT_ACCOUNT:
      return {
        ...state,
        accountData: action.payload.data,
      };
    case DATA_ACTIVE_DEPOSIT_ACCOUNT:
      return {
        ...state,
        accountData: action.payload.data,
        listAccount: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default DepositAccountReducer;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataInitialOrder,
  createdOrder,
  searchOrdenByNro,
  resetDataTransportOrder,
  resetDataOrderCreate,
} from "actions/transportOrdersAction";
import { getListParentCities } from "actions/admin/parentCityAction";
import { getListCities } from "actions/admin/cityAction";
import { getFechaFormat } from "utils/formatFecha";
import { validarRut } from "utils/rut";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { defaultValuesForm } from "helpers/dataBasicOrder";
import { useDetailPackage } from "hooks/Orders/useDetailPackage";

export const useCreateTransportOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [validateForm, setValidateForm] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [titleModulo, setTitleModulo] = useState("");
  const [infoData, setInfoData] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [statusValidate, setStatusValidate] = useState(validateForm);
  const [bloquear, setBloquear] = useState(false);
  const [listPackages, setListPackages] = useState([]);
  const [loadingPago, setLoadingPago] = useState(false);
  const [totalesTransportOrder, setTotalesTransportOrder] = useState({
    total_bultos: 0,
    total_kilos: 0,
    total_metro_cubico: 0,
    subtotal_orden: 0,
    valor_neto: 0,
    valor_iva: 0,
    total: 0,
  });

  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
  } = useForm({ defaultValues: defaultValuesForm });

  const { listCities } = useSelector((state) => state.cities);
  const { dataOrders, initialOrden, disabledBtnCrear } = useSelector(
    (state) => state.orders
  );
  const { city_id, office_id } = useSelector((state) => state.users);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const {
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    formaPagos,
    visibleCarga,
    dataTransportOrder,
  } = useSelector((state) => state.transportOrders);
  const { listParentCities } = useSelector((state) => state.parentCities);
  const { editDetalleCargaWithPrice } = useDetailPackage();

  const getDatInitial = async () => {
    const updateValues = {
      ...defaultValuesForm,
      city_origin_id: city_id,
      office_origin_id: office_id,
    };
    dispatch(resetDataOrderCreate());
    reset({ ...updateValues });
    await dispatch(getDataInitialOrder());
    await dispatch(getListCities("active"));
    dispatch(resetDataTransportOrder());

    /**dispatch(cleanOrden());
     **/
    setValue("codigo_interno", "no");
    setValue("tipo_cliente", "remitente");
    setBloquear(true);
    //setValue("city_origin_id", city_id);
    /* setValue("city_origin_id", city_id);
    setValue("office_origin_id", city_id);**/

    await dispatch(
      getListParentCities(companyDataBasic?.country?.id, "active")
    );
  };

  const handleOrderNumberBlur = (event) => {
    const nro_order = event.target.value.trim().replaceAll(" ", "");
    setValue("order_number", nro_order);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (data?.type_price === "with_price") {
        if (
          parseInt(document.querySelector("#forma_pago_id").value) === 2 &&
          document.querySelector("#documentacion_id_pago").value === ""
        ) {
          toast.error("Seleccione el tipo de documento de pago", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return false;
        }
      }

      if (listPackages?.length === 0) {
        toast.error("Mínimo 1 detalle de la carga", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      const findVisible = listPackages?.findIndex((item) => item.visible);

      if (findVisible !== -1) {
        toast.error(
          `La carga ${
            findVisible + 1
          } esta abierta para editar, debe guardar el cambio para continuar`,
          { position: toast.POSITION.TOP_RIGHT }
        );
        setLoading(false);
        return false;
      }

      let isValid = validarRut(data?.cliente_rut);
      if (!isValid.status) {
        toast.error(`${isValid.message} del cliente`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      isValid = validarRut(data?.rut_remitente);
      if (!isValid.status) {
        toast.error(`${isValid.message} del remitente`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      isValid = validarRut(data?.rut_destinatario);
      if (!isValid.status) {
        toast.error(`${isValid.message} del destinatario`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      let pago_descripcion = document.querySelector("#forma_pago_id");
      const cityOrigin = listCities?.find(
        (item) =>
          item.id === Number(document.querySelector("#city_origin_id").value)
      );
      const officeOrigin = cityOrigin?.offices?.find(
        (item) =>
          item.id === Number(document.querySelector("#office_origin_id").value)
      );

      const resultSearchOrden = await dispatch(
        searchOrdenByNro(data.order_number)
      );

      if (resultSearchOrden?.status) {
        toast.error("El número de orden existe en el sistema", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }

      const information = {
        ...data,
        fecha_orden: getFechaFormat(getValues("fecha_orden")),
        city_origin_id: document.querySelector("#city_origin_id").value,
        office_origin_id: document.querySelector("#office_origin_id").value,
        city_destination_id: document.querySelector("#city_destination_id")
          .value,
        office_destination_id: document.querySelector("#office_destination_id")
          .value,
        forma_pago_id: getValues("forma_pago_id"),
        documentacion_id_pago: getValues("documentacion_id_pago"),
        numero_documento: getValues("numero_documento"),
        forma_pago_descripcion:
          pago_descripcion.options[pago_descripcion.selectedIndex].text,
        numero_orden: data.order_number.toString().replaceAll(" ", "").trim(),
        telefono_avisas: `${companyDataBasic?.country?.code}${data?.telefono_avisas}`,
        city_origin_name: cityOrigin?.name,
        office_origin_name: officeOrigin?.name,
      };

      //await addOrdenAction(information, data);
      setTitleModulo("FACTURA A NOMBRE DE:");

      if (
        parseInt(data?.forma_pago_id) === 2 &&
        parseInt(data?.documentacion_id_pago) === 1 &&
        companyDataBasic?.invoice
      ) {
        setInfoData(information);
        setHidden(true);
        setIsVisible(true);
      } else {
        await addOrdenAction(information);
      }
      setLoading(false);
    } catch (err) {
      toast.error("Hubo un error al guardar la orden de flete", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const addOrdenAction = async (information) => {
    let response = await dispatch(createdOrder(information));
    setLoading(false);
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });

      setTimeout(() => {
        history.push(`/transport-orders/${information.numero_orden}/edit`);
      }, 1000);
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const handleblockPaidInput = (event) => {
    const { value } = event.target;
    let checked = value === "no_price" ? true : false;

    if (value === "no_price") {
      setValue("forma_pago_id", "");
      setValue("documentacion_id_pago", "");
      setValue("forma_pagodescuento_id", "No");
      setValue("total_carga", "No");

      setValue("valor_neto", "");
      setValue("valor_iva", "");
      setValue("total", "");
      setValue("total_bultos", "");
      setValue("total_kilos", "");
      setValue("total_metro_cubico", "");
      setValue("subtotal_orden", "");
      setValidateForm(false);
      /**Verifica que el cliente no sea cuenta corriente */
      if (document.querySelector("#codigo_interno_nro").value === "") {
        document.querySelector("#forma_pago_id").value = 1;
      } else {
        document.querySelector("#forma_pago_id").value = 3;
      }
      setBloquear(true);
    } else {
      setValidateForm(true);
    }
    if (dataOrders?.cargas?.length > 0) {
      //dispatch(editDetalleCargaWithPrice(checked));
      editDetalleCargaWithPrice(checked, listPackages);
    }

    /**if(document.querySelector("#codigo_interno_nro").value===""){
            document.querySelector("#forma_pago_id").disabled = checked
        }**/

    // document.querySelector("#documentacion_id_pago").disabled = checked
    // document.querySelector("#descuento").disabled = checked
    // document.querySelector("#total_carga").readOnly = checked
    // document.querySelector("#largo").disabled = checked
    // document.querySelector("#ancho").disabled = checked
    // document.querySelector("#alto").disabled = checked
    // document.querySelector("#nro_kilos").readOnly = checked
  };

  const handleCloseModalPayment = () => {
    setOpenForm(false);
  };

  const handleTipoDoc = (event) => {
    let pago_descripcion = document.querySelector(".change_documentacion_id");

    pago_descripcion =
      pago_descripcion.options[pago_descripcion.selectedIndex].text;

    if (pago_descripcion.toLowerCase() === "sin documento") {
      setStatusValidate((statusValidate) => false);
      setValue("numero_documento_carga", "");
    } else {
      setStatusValidate((statusValidate) => true);
    }
  };

  (function () {
    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
      params = params || { bubbles: true, cancelable: true, detail: undefined };
      var evt = document.createEvent("submit");
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  })();

  const formElement = {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    setValue,
    control,
  };

  const state = {
    validateForm,
    isLoading,
    titleModulo,
    infoData,
    hidden,
    isVisible,
    dataOrders,
    initialOrden,
    disabledBtnCrear,
    openForm,
    statusValidate,
    visibleCarga,
    bloquear,
    companyDataBasic,

    listCities,
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    formaPagos,
    listPackages,
    totalesTransportOrder,
    loadingPago,
    listParentCities,
    city_id,
    office_id,
    dataTransportOrder,
  };

  const actions = {
    handleblockPaidInput,
    setLoading,
    onSubmit,
    setHidden,
    addOrdenAction,
    getDatInitial,
    handleCloseModalPayment,
    setOpenForm,
    handleTipoDoc,
    handleOrderNumberBlur,
    setListPackages,
    setTotalesTransportOrder,
    setLoadingPago,
    setBloquear,
  };

  return {
    actions,
    formElement,
    state,
  };
};

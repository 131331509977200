import Layout from "../../components/Layouts/Layout";
import { Card } from "react-bootstrap";
import ModalSpinner from "../../components/Custom/Spinner/ModalSpinner";
import DetailClient from "../../components/Facturacion/Cancelation/DetailClient";

import { useCancelationPayment } from "../../hooks/Facturation/useCancelationPayment";
import { useParams, Link } from "react-router-dom";
import { formatMonto } from "../../config/formatInput";

const DetailPaymentInvoice = () => {
  const { payment_id } = useParams();

  const { state, actions, formElement } = useCancelationPayment(payment_id);

  const { cancelationPayment, isLoadingPayment } = state;

  const { getDocumentClient } = actions;

  const { register, handleSubmit, onSubmit } = formElement;

  return (
    <Layout
      titlePanel="Detalle de Pago de la Factura Cliente"
      modulo="crear_pagos_factura"
    >
      <div className="row">
        <div className="col-11 mx-auto">
          <Card className="mb-5 mt-3 card-retiro shadow-sm">
            <Card.Header className="card-header-white card-header__flex">
              <h5 className="mb-0">
                Detalle del Pago - {cancelationPayment?.estado}{" "}
              </h5>
              <div className="text-right">
                <Link to="/invoicing/search">
                  <button type="button" className="btn btn-outline-warning">
                    Regresar
                  </button>
                </Link>
              </div>
            </Card.Header>
            <Card.Body className="">
              <form onSubmit={handleSubmit(onSubmit)} id="frmPayment">
                <DetailClient
                  register={register}
                  getDocumentClient={getDocumentClient}
                  data={cancelationPayment}
                  isEdit={true}
                />
                <h6 className="mt-4">Detalle de los pagos</h6>
                <div className="row">
                  <div className="col-10">
                    <table className="table table-bordered table-sm table-invoice__doc">
                      <thead>
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Tipo Pago</th>
                          <th scope="col">Monto Abonado</th>
                          <th scope="col">Monto Restante</th>
                          <th scope="col">Monto Cancelado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cancelationPayment?.listDetailPayment?.length > 0 &&
                          cancelationPayment?.listDetailPayment?.map(
                            (item, key) => (
                              <tr key={key}>
                                <td>{item.fecha}</td>
                                <td>{item.tipo_pago}</td>
                                <td>{formatMonto(item.monto_abono)}</td>
                                <td>{formatMonto(item.monto_restante)}</td>
                                <td>{formatMonto(item.monto_cancelado)}</td>
                              </tr>
                            )
                          )}
                        {cancelationPayment?.listDetailPayment?.length ===
                          0 && (
                          <tr>
                            <td colSpan={5}>No hay documentos disponible</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <h6 className="mt-4">Detalle de las formas de pago</h6>
                <div className="row">
                  <div className="col-10">
                    <table className="table table-bordered table-sm table-invoice__doc">
                      <thead>
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Cuenta</th>
                          <th scope="col">Tipo</th>
                          <th scope="col">Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cancelationPayment?.listDocumentPendient?.length > 0 &&
                          cancelationPayment?.listDocumentPendient?.map(
                            (item, key) => (
                              <tr key={key}>
                                <td>{item.fecha}</td>
                                <td>{item.cuenta}</td>
                                <td>{item.tipo_pago}</td>
                                <td>{formatMonto(item.monto)}</td>
                              </tr>
                            )
                          )}
                        {cancelationPayment?.listDetailPayment?.length ===
                          0 && (
                          <tr>
                            <td colSpan={5}>No hay documentos disponible</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>

      {isLoadingPayment && (
        <ModalSpinner
          spinnerShow={isLoadingPayment}
          description="Buscando el detalle del pago..."
        />
      )}
    </Layout>
  );
};

export default DetailPaymentInvoice;

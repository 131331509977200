import React from "react";

import { Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import VerdanaRegular from "assets/fonts/Verdana.ttf";
import VerdanaRegularItalic from "assets/fonts/Verdana-Italic.ttf";
import VerdanaBold from "assets/fonts/Verdana-Bold.ttf";
import VerdanaBoldItalic from "assets/fonts/Verdana-Bold-Italic.ttf";

// Register font
Font.register({
  family: "Verdana",
  fonts: [
    {
      src: VerdanaRegular,
    },
    {
      src: VerdanaBold,
      fontWeight: "bold",
    },
    {
      src: VerdanaBoldItalic,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: VerdanaRegularItalic,
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Verdana",
  },
  container: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    marginBottom: 0,
  },
  columnHeader: {
    width: "20%",
    border: "1px solid #a3a3a3",
    backgroundColor: "#939393",
    color: "white",
    marginBottom: 0,
    fontSize: 9,
    fontWeight: 700,
    textAlign: "center",
    padding: "4px 1px",
  },
  columnBody: {
    width: "20%",
    border: "1px solid #a3a3a3",
    color: "black",
    marginBottom: 0,
    padding: "4px",
    background: "#fff",
    fontSize: 9,
    fontWeight: 400,
  },
  imagen: {
    width: "80px",
    height: "40px",
  },
  title: {
    width: "70%",
    fontSize: 17,
    fontWeight: 700,
    paddingLeft: "30px",
  },
  header: {
    marginBottom: 10,
    height: "40px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "97%",
    margin: "30px auto 0",
  },
});

const ReportListByNominate = ({ listRetiros, companyDataBasic }) => {
  return (
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.imagen} src={companyDataBasic?.logo} />
        <Text style={styles.title}>Nómina de retiros</Text>
      </View>
      <View style={{ width: "97%", margin: "30px auto 0" }}>
        <View style={styles.container}>
          <Text style={{ ...styles.columnHeader, width: "10%" }}>
            N° retiro
          </Text>
          <Text style={{ ...styles.columnHeader, width: "10%" }}>Estado</Text>
          <Text style={{ ...styles.columnHeader, width: "15%" }}>
            RUT Remitente
          </Text>
          <Text style={styles.columnHeader}>Remitente</Text>
          <Text style={{ ...styles.columnHeader, width: "10%" }}>Móvil</Text>
          <Text style={styles.columnHeader}>Placa</Text>
          <Text style={styles.columnHeader}>Dirección Ret.</Text>
          <Text style={styles.columnHeader}>Comuna Ret.</Text>
          <Text style={styles.columnHeader}>Detalle</Text>
        </View>
        <View>
          {listRetiros.length > 0 &&
            listRetiros.map((item, key) => (
              <View key={key} style={styles.container}>
                <Text style={{ ...styles.columnBody, width: "10%" }}>
                  {item.number_withdrawal_order}
                </Text>
                <Text style={{ ...styles.columnBody, width: "10%" }}>
                  {item.state}
                </Text>
                <Text style={{ ...styles.columnBody, width: "15%" }}>
                  {item.remitter?.identity_document}
                </Text>
                <Text style={styles.columnBody}>
                  {item?.remitter?.fullname}
                </Text>
                <Text style={{ ...styles.columnBody, width: "10%" }}>
                  {item?.mobile_internal_number}
                </Text>
                <Text style={styles.columnBody}>{item?.plate_number}</Text>
                <Text style={styles.columnBody}>{item?.address}</Text>
                <Text style={styles.columnBody}>{item?.city}</Text>
                <Text style={styles.columnBody}>{item?.observation}</Text>
              </View>
            ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportListByNominate;

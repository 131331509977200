import { useDispatch, useSelector } from "react-redux";
import {
  getListTruck,
  changeStatus,
  deleteTruck,
  getSelectedRowsTruck,
  getLoading,
} from "actions/admin/truckAction";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useTruck = () => {
  const dispatch = useDispatch();
  const { listTrucks, truckIsLoading, truckData } = useSelector(
    (state) => state.trucks
  );

  const getAll = (typeSearch) => {
    dispatch(getLoading(true));
    dispatch(getListTruck(typeSearch));
    dispatch(getSelectedRowsTruck(null));
    window.scrollTo(0, 0);
  };

  const handleActivarDes = () => {
    if (truckData !== null) {
      const statusName = truckData.active ? "desactivado" : "activado";
      Swal.fire({
        title: `¿Desea ${statusName} la placa?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(truckData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (truckData !== null) {
      Swal.fire({
        title: "¿Desea eliminar la placa?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteTruck(truckData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listTrucks.filter(
      (item) =>
        item.plate_number &&
        item.plate_number.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRowsTruck(rows));
  };

  const state = {
    listTrucks,
    truckIsLoading,
    truckData,
  };

  const actions = {
    getAll,
    handleDelete,
    handleActivarDes,
    filteredItems,
    handleRowClicked,
  };

  return {
    state,
    actions,
  };
};

export const formatCreateDataOrdenFlete = (data) => {
  let infoOrden = {
    comments: data?.comments,
    numero_orden: data?.numero_orden,
    fecha_orden: data?.fecha_orden,
    tipo_envio_id: data?.shipment_type_id,
    total_bultos: data?.total_bultos,
    email_notification: data?.email_notification,
    forma_pago_id:
      typeof data?.forma_pago_id === "undefined" ? null : data?.forma_pago_id,
    valor_declarado_carga:
      typeof data?.valor_declarado_carga === "undefined"
        ? null
        : data?.valor_declarado_carga,
    numero_documento_carga:
      typeof data?.numero_documento_carga === "undefined"
        ? null
        : data?.numero_documento_carga,
    email: data?.email,
    documentacion_id:
      typeof data?.documentacion_id === "undefined"
        ? null
        : data?.documentacion_id,
    documentacion_id_pago:
      typeof data?.documentacion_id_pago === "undefined"
        ? null
        : data?.documentacion_id_pago,
    forma_pago_descripcion:
      typeof data?.forma_pago_descripcion === "undefined"
        ? null
        : data?.forma_pago_descripcion,
    offices: [
      {
        type: "origen",
        office_id: data?.office_origin_id,
        sucursal_id: data?.city_origin_id,
      },
      {
        type: "destino",
        office_id: data?.office_destination_id,
        sucursal_id: data?.city_destination_id,
      },
    ],
    numero_documento: data?.numero_documento ?? "",
  };

  let clientes = [
    {
      type: "cliente_principal",
      rut: data?.cliente_rut,
      isPrincipal: true,
      razon_social: data?.razon_social,
      codigo_interno: data?.codigo_interno_nro ?? "",
    },
    {
      type: "remitente",
      rut: data?.rut_remitente,
      isPrincipal: false,
      razon_social: data?.razon_social_remitente,
      codigo_interno:
        data?.cliente_rut === data?.rut_remitente
          ? data?.codigo_interno_nro
          : "",
    },
    {
      type: "destinatario",
      rut: data?.rut_destinatario,
      isPrincipal: false,
      direccion: data?.direccion_destinatario,
      telefono: data?.telefono_avisas,
      razon_social: data?.razon_social_destinatario,
      codigo_interno:
        data?.cliente_rut === data?.razon_social_destinatario
          ? data?.codigo_interno_nro
          : "",
    },
  ];

  infoOrden["clientes"] = clientes;

  let detCargas = [];
  for (let index in data?.cargas) {
    let carga = data?.cargas[index];

    detCargas.push({
      high: typeof carga?.alto === "undefined" ? null : carga?.alto,
      width: typeof carga?.ancho === "undefined" ? null : carga?.ancho,
      long: typeof carga?.largo === "undefined" ? null : carga?.largo,
      quantity_package:
        typeof carga?.bulto === "undefined" ? null : carga?.bulto,
      quantity_kg: typeof carga?.peso === "undefined" ? null : carga?.peso,
      quantity_cubic_meters:
        typeof carga?.metraje_cubico === "undefined"
          ? null
          : carga?.metraje_cubico,
      net_value: data?.type_price === "no_price" ? 0 : carga?.valor_neto,
      id: carga?.id,
      description: carga?.contenido,
    });
  }

  infoOrden["cargas"] = detCargas;

  let payment = {
    type_price: data?.type_price,
    discount: data?.type_price === "no_price" ? "No" : data?.descuento,
    subtotal: data?.type_price === "no_price" ? null : data?.subtotal_orden,
    tax_value: data?.type_price === "no_price" ? null : data?.valor_iva,
    net_value: data?.type_price === "no_price" ? null : data?.valor_neto,
    total: data?.type_price === "no_price" ? null : data?.total,
  };

  infoOrden["payment"] = payment;

  infoOrden["cliente_factura"] = {
    rut: data?.factura?.rut_factura ?? data?.rut_remitente,
    city: data?.factura?.city_origin_name ?? data?.city_origin_name,
    office: data?.factura?.office_origin_name ?? data?.office_origin_name,
  };

  if (data?.factura?.contacto_factura) {
    infoOrden["cliente_factura"]["phone_number"] =
      data?.factura?.contacto_factura;
  }
  if (data?.factura?.direccion_factura) {
    infoOrden["cliente_factura"]["address"] = data?.factura?.direccion_factura;
  }
  if (data?.factura?.email_factura) {
    infoOrden["cliente_factura"]["email"] = data?.factura?.email_factura;
  }
  if (data?.factura?.giro_factura) {
    infoOrden["cliente_factura"]["tax_draft"] = data?.factura?.giro_factura;
  }

  if (data?.factura?.razon_social_factura) {
    infoOrden["cliente_factura"]["full_name"] =
      data?.factura?.razon_social_factura;
  }

  infoOrden["total_bultos"] = detCargas.reduce(
    (acumulado, bultos) => acumulado + Number(bultos.quantity_package),
    0
  );

  return infoOrden;
};

export const formatEditDataOrdenFlete = (data) => {
  let infoOrden = {
    observacion: data?.comments,
    numero_orden: data?.order_number,
    fecha_orden: data?.fecha_orden,
    tipo_envio_id: data?.tipo_envio_id,
    total_bultos: data?.total_bultos,
    email_notification: data?.email_notification,
    forma_pago_descripcion:
      typeof data?.forma_pago_descripcion === "undefined"
        ? null
        : data?.forma_pago_descripcion,
    comunas: [
      {
        type: "origen",
        office_id: data?.office_origin_id,
        city_id: data?.city_origin_id,
      },
      {
        type: "destino",
        office_id: data?.office_destination_id,
        city_id: data?.city_destination_id,
      },
    ],
    documentacion_id_pago:
      typeof data?.documentacion_id_pago === "undefined"
        ? null
        : data?.documentacion_id_pago,
    numero_documento: data?.numero_documento ?? "",
  };

  let clientes = [
    {
      type: "remitente",
      data: {
        rut: data?.rut_remitente,
        razon_social: data?.razon_social_remitente,
        codigo_interno:
          data?.cliente_rut === data?.rut_remitente
            ? data?.codigo_interno_nro
            : "",
      },
      principal: data?.cliente_rut === data?.rut_remitente ? true : false,
    },
    {
      type: "destinatario",
      data: {
        rut: data?.rut_destinatario,
        direccion: data?.direccion_destinatario,
        telefono: data?.telefono_avisas,
        razon_social: data?.razon_social_destinatario,
        codigo_interno:
          data?.cliente_rut === data?.razon_social_destinatario
            ? data?.codigo_interno_nro
            : "",
      },
      principal: data?.cliente_rut === data?.rut_destinatario ? true : false,
    },
  ];

  infoOrden["clientes"] = clientes;

  let detCargas = [];
  for (let index in data?.cargas) {
    let carga = data?.cargas[index];

    detCargas.push({
      alto: typeof carga?.alto === "undefined" ? null : carga?.alto,
      ancho: typeof carga?.ancho === "undefined" ? null : carga?.ancho,
      largo: typeof carga?.largo === "undefined" ? null : carga?.largo,
      bulto: typeof carga?.bulto === "undefined" ? null : carga?.bulto,
      peso: typeof carga?.peso === "undefined" ? null : carga?.peso,
      metraje_cubico:
        typeof carga?.metraje_cubico === "undefined"
          ? null
          : carga?.metraje_cubico,
      total: data?.type_price === "no_price" ? 0 : carga?.valor_neto,
      id: carga?.id,
      contenido: carga?.contenido,
    });
  }

  infoOrden["cargas"] = detCargas;

  let payment = {
    type_price: data?.type_price,
    descuento: data?.type_price === "no_price" ? "No" : data?.descuento,
    subtotal_orden:
      data?.type_price === "no_price" ? null : data?.subtotal_orden,
    valor_iva: data?.type_price === "no_price" ? null : data?.valor_iva,
    valor_neto: data?.type_price === "no_price" ? null : data?.valor_neto,
    total: data?.type_price === "no_price" ? null : data?.total,
    forma_pago_id:
      typeof data?.forma_pago_id === "undefined" ? null : data?.forma_pago_id,
    valor_declarado_carga:
      typeof data?.valor_declarado_carga === "undefined"
        ? null
        : data?.valor_declarado_carga,
    numero_documento_carga:
      typeof data?.numero_documento_carga === "undefined"
        ? null
        : data?.numero_documento_carga,
    documentacion_id:
      typeof data?.documentacion_id === "undefined"
        ? null
        : data?.documentacion_id,
    documentacion_id_pago:
      typeof data?.documentacion_id_pago === "undefined"
        ? null
        : data?.documentacion_id_pago,
  };

  infoOrden["payment"] = payment;

  infoOrden["cliente_factura"] = {
    rut: data?.rut_remitente,
  };

  infoOrden["sucursal_name"] = data?.sucursal_name;

  return infoOrden;
};

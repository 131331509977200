import { useState, useEffect } from "react";
import { Form, Card, Row, Col } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import FormAsignar from "components/Retiros/Asignar/FormAsignar";
import {
  getRetiroByUser,
  asignarMovil,
  filtradoRetiro,
} from "actions/retiroAction";
import { getListTruck } from "actions/admin/truckAction";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { validateOnlyNumber, onPaste } from "utils/validationInput";
import ReactPaginate from "react-paginate";

const AssignRetiro = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setLoading] = useState(false);
  const { retirosAll, isLoadingAsignar } = useSelector(
    (state) => state.retiros
  );
  const { listTrucks } = useSelector((state) => state.trucks);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filterText, setFilterText] = useState("");

  const onSubmit = async (data, e) => {
    setLoading(true);

    let listadoRetiro = data?.ordenes.filter((ret) => ret !== false);

    if (listadoRetiro.length > 0) {
      const information = {
        camion_id: data.camion_id,
        description: data.detalle,
        type_assign: "asignar",
        numero_retiros: listadoRetiro,
      };

      let response = await dispatch(asignarMovil(information));
      if (response.status) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        e.target.reset();
        getDataInitial(0);
      } else {
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } else {
      toast.error("Debe seleccionar los retiros", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
  };

  const getDataInitial = async (page) => {
    const response = await dispatch(
      getRetiroByUser({ type_assign: "assign" }, page + 1)
    );

    if (response?.status) {
      setPageCount(response?.countPaginate ?? 0);
      setPageOffset(page);
    }

    if (listTrucks?.length === 0) {
      await dispatch(getListTruck("active"));
    }
  };

  useEffect(() => {
    getDataInitial(pageOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset]);

  const handlePageChange = async (event) => {
    // when its content is loaded in useEffect.
    setPageOffset(event.selected);
  };

  const onFilter = (e) => {
    setFilterText(e.target.value);

    dispatch(filtradoRetiro(e.target.value));
  };

  return (
    <Layout titlePanel="Asignar Vehículo" modulo="asignar-retiros">
      <div className="pl-3 pr-3">
        <Row>
          <Col xs={12}>
            <Form id="frmAsignar" onSubmit={handleSubmit(onSubmit)}>
              <CardRetiro title="ASIGNACIÓN DE RETIROS">
                <FormAsignar
                  register={register}
                  errors={errors}
                  isLoading={isLoading}
                  listTrucks={listTrucks}
                />
              </CardRetiro>
              <Card body className="card-retiro shadow-sm">
                <div className="row fsearch-retiro mt-5">
                  <Col lg={4} md={4} sm={6} className="text-right">
                    <Form.Group>
                      <Form.Control
                        id="search"
                        type="text"
                        placeholder="Ingresa N° de retiro"
                        aria-label="Ingresa N° de retiro"
                        value={filterText}
                        onChange={onFilter}
                        autoComplete="off"
                        maxLength={20}
                        onKeyPress={(event) => validateOnlyNumber(event)}
                        onPaste={(event) => onPaste(event)}
                      />
                    </Form.Group>
                  </Col>
                </div>
                <div className="table-responsive table-retiro-responsive">
                  <table className="table table-bordered table-sm bg-white table-retiro">
                    <thead className="bg-warn-blue">
                      <tr>
                        <th className="text-center"></th>
                        <th className="text-center">N° Retiro</th>
                        <th className="text-center">Fecha</th>
                        <th className="text-center">Estado</th>
                        <th className="text-center" style={{ width: "300px" }}>
                          Direccion Retiro
                        </th>
                        <th className="text-center">Ciudad Ret.</th>
                        <th className="text-center">RUT Remitente</th>
                        <th className="text-center">Remitente</th>
                        <th className="text-center">Destinatarios</th>
                      </tr>
                    </thead>
                    <tbody>
                      {retirosAll?.length > 0 &&
                        retirosAll?.map((row, key) => (
                          <tr key={key}>
                            <td>
                              <Form.Group id="formGridCheckbox">
                                <Form.Check
                                  type="checkbox"
                                  name={`ordenes[${key}]`}
                                  defaultValue={row.number_withdrawal_order}
                                  defaultChecked={false}
                                  ref={register}
                                />
                              </Form.Group>
                            </td>
                            <td className="text-center">
                              {row.number_withdrawal_order}
                            </td>
                            <td className="text-center">{row.date_order}</td>
                            <td className="text-center">{row.state}</td>
                            <td>{row.address}</td>
                            <td>{row.city}</td>
                            <td>{row.remitter?.identity_document}</td>
                            <td>{row.remitter?.fullname}</td>
                            <td>{row.count_orders}</td>
                          </tr>
                        ))}
                      {!isLoadingAsignar && retirosAll.length === 0 && (
                        <tr>
                          <td colSpan={9}>No hay registros disponibles</td>
                        </tr>
                      )}
                      {isLoadingAsignar && retirosAll.length === 0 && (
                        <tr>
                          <td colSpan={9}>Buscando...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <ReactPaginate
                  previousLabel="Anterior"
                  nextLabel="Siguiente"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={pageOffset}
                />
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default AssignRetiro;

import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import {
  validateOnlyOrdenPackage,
  onPaste,
  validateOnlyLetter,
} from "utils/validationInput";
import { useFormDepositAccount } from "hooks/Admin/DepositAccount/useFormDepositAccount";
import { Link } from "react-router-dom";

const Formulario = ({ accountId }) => {
  const { formElement, state, actions } = useFormDepositAccount();

  const { isLoading, register, handleSubmit, errors, onSubmit, setValue } =
    formElement;

  const { listCities, dataAccount } = state;

  const { getAccountById } = actions;

  useEffect(() => {
    getAccountById(accountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    setValue("city_id", dataAccount?.city_id ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCities]);

  return (
    <Form id="frmCuenta" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="city_id">
                <Form.Label>Sucursal</Form.Label>
                <Form.Control as="select" name="city_id" ref={register}>
                  <option value="">Seleccione</option>
                  {listCities?.length > 0 &&
                    listCities?.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.city_id && (
                  <Form.Text className="text-danger">
                    {errors?.city_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="name_bank">
                <Form.Label>Banco</Form.Label>
                <Form.Control
                  type="text"
                  name="name_bank"
                  ref={register}
                  maxLength={100}
                  //defaultValue={datos?.name_bank || ""}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => onPaste(event)}
                  className="text-uppercase"
                />
                {errors.name_bank && (
                  <Form.Text className="text-danger">
                    {errors?.name_bank?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="account_number">
                <Form.Label>Nro de cuenta</Form.Label>
                <Form.Control
                  type="text"
                  name="account_number"
                  ref={register}
                  maxLength={32}
                  //defaultValue={datos?.account_number || ""}
                  onKeyPress={(event) => validateOnlyOrdenPackage(event)}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.account_number && (
                  <Form.Text className="text-danger">
                    {errors?.account_number?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to="/admin/deposit-accounts">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : accountId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

import { Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Nuevo from "assets/images/configurar/plus.png";
import Editar from "assets/images/configurar/edit.png";
import Eliminar from "assets/images/configurar/delete.png";
import SwitchToogle from "components/Layouts/SwitchToogle";
import { validateOnlyLetterNumberG, onPaste } from "utils/validationInput";
import { useTruck } from "hooks/Admin/Trucks/useTruck";
import { Link } from "react-router-dom";

const Filter = ({ filterText, onFilter, toast, setClearTable }) => {
  const { state, actions } = useTruck();
  const { truckData } = state;
  const { handleDelete, handleActivarDes } = actions;

  return (
    <>
      <Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
        <Col>
          <Col lg={6} className="pl-0 pr-0">
            <Form.Control
              id="search"
              type="text"
              placeholder="Ingresa el número de la placa"
              aria-label="Ingresa el número de la placa"
              value={filterText}
              onChange={onFilter}
              onKeyPress={(event) => validateOnlyLetterNumberG(event)}
              onPaste={(event) => onPaste(event)}
            />
          </Col>
          <span className="fs-12">
            **Número de placa se utiliza para la búsqueda y modificación de los
            datos
          </span>
        </Col>
        <Col className="text-right">
          <Form.Group>
            {truckData === null && (
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-crear">Crear</Tooltip>}
              >
                <Link to="/admin/trucks/create">
                  <Button variant="warning" type="button" className="mr-2">
                    <img src={Nuevo} alt="Nuevo" className="img-conf" />
                  </Button>
                </Link>
              </OverlayTrigger>
            )}
            {truckData !== null && (
              <>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-editar">Editar</Tooltip>}
                >
                  <Link to={`/admin/trucks/${truckData?.id}/edit`}>
                    <Button variant="warning" type="button" className="mr-2">
                      <img src={Editar} alt="Editar" className="img-conf" />
                    </Button>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-eliminar">Eliminar</Tooltip>}
                >
                  <Button
                    variant="warning"
                    type="button"
                    onClick={(event) => handleDelete(event)}
                  >
                    <img src={Eliminar} alt="Eliminar" className="img-conf" />
                  </Button>
                </OverlayTrigger>
                <SwitchToogle
                  handleActive={handleActivarDes}
                  data={truckData}
                />
              </>
            )}
          </Form.Group>
        </Col>
      </Col>
    </>
  );
};

export default Filter;

import { Modal, Button } from "react-bootstrap";
import ClienteDestinatario from "components/Retiros/Nuevo/Destino/ClienteDestinatario";
import DetalleCarga from "components/Retiros/Nuevo/Destino/DetalleCarga";

const ModalDestino = ({ show, onHide, state, actions, formElement }) => {
  const { infoDestino } = state;
  const { handleSubmit, onSubmit } = formElement;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Destinatario
        </Modal.Title>
      </Modal.Header>
      <form id="frmDestino" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <ClienteDestinatario
            state={state}
            actions={actions}
            formElement={formElement}
          />
          <DetalleCarga
            actions={actions}
            formElement={formElement}
            cargas={infoDestino?.cargas}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={onHide}>
            Cerrar
          </Button>
          <Button type="submit" className="btn btn-warning">
            Guardar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalDestino;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addActivity,
  searchActivityById,
  editActivity,
} from "actions/admin/activityAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormActivity = (countryId) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    reset,
    //getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      nombre: "",
    },
  });

  const { activityData } = useSelector((state) => state.activities);
  const [dataActivity, setDataActivity] = useState(null);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let response;

    const body = {
      ...data,
      country_id: countryId,
    };

    if (dataActivity?.id) {
      response = await dispatch(editActivity(body, dataActivity?.id));
    } else {
      response = await dispatch(addActivity(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);

      setTimeout(() => {
        history.push(`/admin/countries/${countryId}/activities`);
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getAtivityById = async (activityId, countryId) => {
    let data = activityData;
    if (activityId !== null) {
      if (activityData === null) {
        const result = await dispatch(searchActivityById(activityId));
        data = result?.data;
      }
    }
    reset({
      description: data?.description ?? "",
    });

    setDataActivity(activityId !== null ? data ?? null : null);
  };

  const actions = {
    getAtivityById,
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
  };

  const state = {
    activityData,
    dataActivity,
  };

  return {
    actions,
    formElement,
    state,
  };
};

import { BiX } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { formatMonto } from "../../../config/formatInput";

const DocumentPendient = ({
  listDocumentPendient,
  listDocumentPendientTable,
  addItemDocument,
  register,
  deleteItemDocument,
  isEdit,
  isValidDocument,
}) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor="formGroupExampleInput">Documentos pendientes</label>
        {!isEdit && (
          <div className="input-group mb-3">
            <select
              className="form-control"
              id="document_pendient"
              name="document_pendient"
              ref={register}
              disabled={isValidDocument}
            >
              <option value="">-Seleccione-</option>
              {listDocumentPendient.length > 0 &&
                listDocumentPendient.map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.numero_folio}
                  </option>
                ))}
            </select>
            <div className="input-group-append">
              <button
                className="btn btn-primary btn-sm"
                type="button"
                id="button-addon2"
                onClick={() => addItemDocument("add")}
                disabled={isValidDocument}
              >
                <FaPlus />
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <table className="table table-bordered table-sm table-invoice__doc">
          <thead>
            <tr>
              <th># Documento</th>
              <th>Fecha</th>
              <th>Monto</th>
              {!isEdit && <th></th>}
            </tr>
          </thead>
          <tbody>
            {listDocumentPendientTable?.length > 0 &&
              listDocumentPendientTable?.map((item, key) => (
                <tr key={key}>
                  <td>{item.numero_folio}</td>
                  <td>{item.fecha}</td>
                  <td>{formatMonto(item.monto)}</td>
                  {!isEdit && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm p-0"
                        onClick={() => deleteItemDocument(item)}
                      >
                        <BiX style={{ fontSize: "20px" }} />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            {listDocumentPendientTable.length === 0 && (
              <tr>
                <td colSpan={3}>No hay documentos disponible</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DocumentPendient;

import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Customers/Formulario";

const CreateRole = () => {
  return (
    <Layout titlePanel="Administrar" modulo="administrar_customers">
      <div className="pl-3 pr-3">
        <CardRetiro title="Crear cliente">
          <Formulario customerId={null} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default CreateRole;

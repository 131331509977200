import { useState } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";

const ModalSelectNroCuenta = ({
  handleOpenCloseCta,
  openCloseCta,
  cliente,
  selectCodeClient,
}) => {
  const [value, setValue] = useState("-");
  const [error, setError] = useState(false);

  const handleSubmitForm = () => {
    setError(false);

    if (value === "-") {
      setError(true);
      return false;
    }
    selectCodeClient(value);
  };

  return (
    <Modal show={openCloseCta} onHide={handleOpenCloseCta} centered>
      <Modal.Header closeButton>
        <h6>Códigos internos asociados al cliente</h6>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <Form.Group
              as={Col}
              controlId="internal_number_select"
              className="mb-0"
            >
              <Form.Label>Código interno</Form.Label>
              <Form.Control
                as="select"
                name="numero_cuenta_select"
                value={value}
                onChange={(event) => setValue(event.target.value)}
              >
                <option value="">Seleccione</option>
                {cliente?.account_numbers?.length > 0 &&
                  cliente?.account_numbers?.map((cta) => (
                    <option key={cta.id} value={cta.internal_number}>
                      {cta.internal_number}
                    </option>
                  ))}
              </Form.Control>
              {error && (
                <Form.Text className="text-danger">Requerido</Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} className="mt-5 mb-4 text-center">
            <Button
              className="pl-5 pr-5"
              variant="warning"
              onClick={() => handleSubmitForm()}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSelectNroCuenta;

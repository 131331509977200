import { useDispatch, useSelector } from "react-redux";
import {
  getListCustomers,
  getSelected,
  changeStatus,
  deleteCustomer,
  getLoading,
} from "actions/admin/customerAction";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useCustomer = () => {
  const dispatch = useDispatch();
  const { listCustomers, customerIsLoading, customerData, customerBtnActive } =
    useSelector((state) => state.customers);

  const getAll = () => {
    dispatch(getLoading(true));
    dispatch(getListCustomers());
  };

  const filteredItems = (filterText) => {
    return listCustomers.filter(
      (item) =>
        item.customer?.full_name &&
        item.customer?.full_name
          .toUpperCase()
          .includes(filterText.toUpperCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelected(rows));
  };

  const handleDelete = () => {
    if (customerData !== null) {
      Swal.fire({
        title: "¿Desea eliminar el cliente?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(deleteCustomer(customerData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleActivarDes = () => {
    if (customerData !== null) {
      const statusName = customerData.active ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} el cliente?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(customerData.id));
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const state = {
    listCustomers,
    customerIsLoading,
    customerData,
    customerBtnActive,
  };

  const actions = {
    getAll,
    filteredItems,
    handleRowClicked,
    handleDelete,
    handleActivarDes,
  };

  return {
    state,
    actions,
  };
};

import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import CardRetiro from "components/Layouts/CardRetiro";
import Encabezado from "components/Despacho/Encabezado";
import Configuracion from "components/Despacho/Configuracion";
import Detalle from "components/Despacho/Detalle";
import TipoOperacion from "components/Despacho/TipoOperacion";
import Totales from "components/Despacho/Totales";
import { useForm } from "react-hook-form";
import {
  getListado,
  createdGuia,
  resetDataOrdenes,
} from "actions/guiaDespachoAction";
import { useDispatch, useSelector } from "react-redux";
import download from "assets/images/download_small.png";
import { downloadDocumento } from "utils/downloadFile";
import Nuevo from "assets/images/configurar/plus.png";
import { getListCities } from "actions/admin/cityAction";
import { getListTruck } from "actions/admin/truckAction";
import { getListUserDriverAll } from "actions/admin/driveAction";

const Guide = ({ toast }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, control, getValues } =
    useForm();
  const { traslados, despachos, forma_pagos, listado_totales } = useSelector(
    (state) => state.guiaDespacho
  );
  const { listTrucks } = useSelector((state) => state.trucks);
  const { listCities } = useSelector((state) => state.cities);
  const { listUserDrives } = useSelector((state) => state.drivesUser);
  const [loading, setLoading] = useState(false);
  const [emitida, setEmitida] = useState(false);
  const [result, setResult] = useState({});
  const [valorDescuento, setValorDescuento] = useState("");

  useEffect(() => {
    getListInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListInitial = async () => {
    dispatch(getListado());
    if (listCities.length === 0) {
      await dispatch(getListCities("active"));
    }
    await dispatch(getListTruck("active"));
    await dispatch(getListUserDriverAll("drivers", "active"));
    dispatch(resetDataOrdenes());
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    setLoading(true);
    if (listado_totales.ordenes.length === 0) {
      toast.error("Debe ingresar las órdenes", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    if (data?.totals < 0) {
      toast.error(
        "Para generar una guía de despacho el valor del total debe ser positivo",
        { position: toast.POSITION.TOP_RIGHT }
      );
      setLoading(false);
      return false;
    }

    const body = {
      ...data,
      fecha_documento: document.querySelector("#fecha_documento").value,
      fecha_vencimiento: document.querySelector("#fecha_vencimiento").value,
      razon_social: document.querySelector("#razon_social").value,
      direccion_cliente: document.querySelector("#direccion").value,
    };

    let response = await dispatch(createdGuia(body));

    if (response.status) {
      setResult(response.data);
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      setEmitida(true);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setLoading(false);
  };

  const handleDownload = (event) => {
    event.preventDefault();
    downloadDocumento(result);
  };

  return (
    <Form id="frmDespacho" onSubmit={handleSubmit(onSubmit)}>
      <div className="pl-3 pr-3">
        <Row>
          <Col xs={12}>
            <CardRetiro title="GUÍA DE DESPACHO">
              <Row>
                <Col xs={6}>
                  <Encabezado
                    register={register}
                    errors={errors}
                    control={control}
                    forma_pagos={forma_pagos}
                    toast={toast}
                    setValue={setValue}
                  />
                </Col>
                <Col xs={6}>
                  <Configuracion
                    register={register}
                    errors={errors}
                    traslados={traslados}
                    despachos={despachos}
                    listCities={listCities}
                    listTrucks={listTrucks}
                    listUserDrives={listUserDrives}
                    setValue={setValue}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mt-5">
                  <Detalle
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    toast={toast}
                    listado_totales={listado_totales}
                    control={control}
                    setValorDescuento={setValorDescuento}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4} className="mt-5">
                  <TipoOperacion
                    register={register}
                    getValues={getValues}
                    toast={toast}
                    setValue={setValue}
                    control={control}
                    valorDescuento={valorDescuento}
                    setValorDescuento={setValorDescuento}
                  />
                </Col>
                <Col md={8} className="mt-5">
                  <Totales register={register} setValue={setValue} />
                </Col>
              </Row>
              <Row>
                <Col className="mt-5 text-right mb-4">
                  {emitida ? (
                    <>
                      <Button
                        variant="warning"
                        type="button"
                        className="mr-2"
                        onClick={(event) => handleDownload(event)}
                      >
                        <img src={download} alt="Descargar documento" />
                      </Button>
                      <Button variant="warning" href="/guide">
                        <img
                          src={Nuevo}
                          alt="Crear nueva factura"
                          className="btn-nuevo-guia"
                        />
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="warning"
                      type="submit"
                      className="mr-2 pt-2 pb-2"
                    >
                      {loading ? "Procesando..." : "Emitir documento"}
                    </Button>
                  )}
                </Col>
              </Row>
            </CardRetiro>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default Guide;

import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Users/Formulario";

const CreateRole = () => {
  return (
    <Layout titlePanel="Administrar" modulo="administrar_users">
      <div className="pl-3 pr-3">
        <CardRetiro title="Crear usuario">
          <Formulario userId={null} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default CreateRole;

const TableOrdenRecibidas = ({ listOrdensReceive, isLoadingReceive }) => {
  return (
    <div className="row">
      <div className="col-12 mx-auto mb-5">
        <div className="table-responsive">
          <table className="table-global mt-4 table table-sm table-bordered">
            <thead className="bg-warn-blue">
              <tr>
                <th className="text-center" style={{ width: "90px" }}>
                  Número Orden
                </th>
                <th className="text-center" style={{ width: "150px" }}>
                  Sucursal Origen
                </th>
                <th className="text-center" style={{ width: "150px" }}>
                  Remitente
                </th>
                <th className="text-center" style={{ width: "150px" }}>
                  Sucursal Destino
                </th>
                <th className="text-center" style={{ width: "150px" }}>
                  Destinatario
                </th>
                <th className="text-center" style={{ width: "150px" }}>
                  Sucursal Recibida
                </th>
                <th className="text-center" style={{ width: "50px" }}>
                  Bulto
                </th>
                <th className="text-center" style={{ width: "50px" }}>
                  Cant Recibida Destino
                </th>
                <th className="text-center" style={{ width: "50px" }}>
                  Bulto Otra Sucursal
                </th>
                <th className="text-center" style={{ width: "50px" }}>
                  Faltante
                </th>
              </tr>
            </thead>
            <tbody
              className={
                listOrdensReceive?.length > 0 ? "text-center" : "text-left"
              }
            >
              {listOrdensReceive.length > 0 &&
                listOrdensReceive.map((item, key) => (
                  <tr key={key} className={item.trColor}>
                    <td>{item.numero_orden}</td>
                    <td>{item.sucursal_origen}</td>
                    <td>{item.remitente}</td>
                    <td>{item.sucursal_destino}</td>
                    <td>{item.destinatario}</td>
                    <td>{item.otra_oficina}</td>
                    <td>{item.cantidad_bultos}</td>
                    <td>{item.bulto_recibido}</td>
                    <td>{item.bulto_otra_sucursal}</td>
                    <td>{item.bultos_faltante}</td>
                  </tr>
                ))}
              {listOrdensReceive.length === 0 && (
                <tr>
                  <td colSpan={10}>
                    {isLoadingReceive
                      ? "Buscando datos..."
                      : "No hay registros disponibles"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableOrdenRecibidas;

import { useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Button,
  Card,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Nuevo from "assets/images/configurar/plus.png";
import Eliminar from "assets/images/configurar/delete.png";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
//import SwitchToogle from "components/Layouts/SwitchToogle";
import {
  validateOnlyNumber,
  onPaste,
  handlePasteOnlyNumber,
  handlePasteOnlyLetter,
  validateOnlyLetter,
  validateOnlyLetterNumber,
  validateOnlyRUT,
} from "utils/validationInput";
import { useFormCustomer } from "hooks/Admin/Customers/useFormCustomer";
import { Link } from "react-router-dom";

const Formulario = ({ datos, customerId }) => {
  const { formElement, state, actions } = useFormCustomer();

  const { register, handleSubmit, errors, onSubmit, control } = formElement;

  const {
    isLoading,
    isAccountNumber,
    listAccountNumber,
    companyDataBasic,
    isReadOnly,
    formCustomer,
  } = state;

  const {
    validatePhone,
    setValue,
    setIsAccountNumberIsCodigo,
    setDataInicial,
    handleBlurSearchRut,
    handleDownSearchRut,
    addAccountNumber,
    deleteAccountNumber,
    //handleActiveAccount,
    setResetPhone,
  } = actions;

  useEffect(() => {
    setDataInicial(customerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (companyDataBasic !== null && formCustomer !== null) {
      setResetPhone(formCustomer, companyDataBasic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCustomer, companyDataBasic]);

  return (
    <Form id="frmCliente" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="rut">
                <Form.Label>RUT</Form.Label>
                <Controller
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control"
                      id="rut"
                      name="rut"
                      value={props.value}
                      maxLength={12}
                      onChange={(value) => {
                        props.onChange(value);
                        setValue(value.target.value ?? "");
                      }}
                      onBlur={(event) =>
                        handleBlurSearchRut(event.target.value)
                      }
                      onKeyDown={(event) => handleDownSearchRut(event)}
                      onKeyPress={(event) => validateOnlyRUT(event)}
                      readOnly={formCustomer === null ? false : true}
                    />
                  )}
                  control={control}
                  name="rut"
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
                {errors.rut && (
                  <Form.Text className="text-danger">
                    {errors?.rut?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="full_name">
                <Form.Label>Nombre y Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="full_name"
                  readOnly={isReadOnly}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  //defaultValue={datos?.full_name || ""}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => handlePasteOnlyLetter(event)}
                  maxLength={100}
                  className="text-uppercase"
                />
                {errors.full_name && (
                  <Form.Text className="text-danger">
                    {errors?.full_name?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="phone_number">
                <Form.Label>Teléfono</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {companyDataBasic?.country?.code}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    // defaultValue={datos?.phone_number || ""}
                    maxLength={15}
                    readOnly={isReadOnly}
                    disabled={
                      companyDataBasic?.country?.code === "" ? true : false
                    }
                    ref={register({
                      minLength: { value: 9, message: "Mínimo 9 dígitos" },
                      required: { value: true, message: "Requerido" },
                      //eslint-disable-next-line
                      pattern: {
                        value: /^([0-9]+)$/,
                        message: "Teléfono inválido",
                      },
                      validate: validatePhone,
                    })}
                    autoComplete="off"
                    onKeyPress={(event) => validateOnlyNumber(event)}
                    onPaste={(event) => handlePasteOnlyNumber(event)}
                  />
                </InputGroup>
                {errors.phone_number && (
                  <Form.Text className="text-danger">
                    {errors?.phone_number?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  className="text-lowercase"
                  readOnly={isReadOnly}
                  ref={register({
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  //defaultValue={datos?.email || ""}
                  onPaste={(event) => onPaste(event)}
                />
                {errors.email && (
                  <Form.Text className="text-danger">
                    {errors?.email?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="address">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  readOnly={isReadOnly}
                  //defaultValue={datos?.address || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                />
                {errors.address && (
                  <Form.Text className="text-danger">
                    {errors?.address?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group controlId="tax_draft">
                <Form.Label>Rubro</Form.Label>
                <Form.Control
                  type="text"
                  name="tax_draft"
                  ref={register}
                  readOnly={isReadOnly}
                  //defaultValue={datos?.tax_draft || ""}
                  onKeyPress={(event) => validateOnlyLetterNumber(event)}
                  onPaste={(event) => onPaste(event)}
                  maxLength={50}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Group className="row-radio">
                <Form.Label>El cliente tiene código interno:</Form.Label>
                <div className="ml-3">
                  <Form.Check
                    inline
                    label="Si"
                    name="group1"
                    type="radio"
                    id={`inline-si`}
                    checked={isAccountNumber === "si" ? true : false}
                    onChange={() => setIsAccountNumberIsCodigo("si")}
                    disabled={isReadOnly}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type="radio"
                    id={`inline-no`}
                    checked={isAccountNumber === "no" ? true : false}
                    onChange={() => setIsAccountNumberIsCodigo("no")}
                    disabled={isReadOnly}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          {isAccountNumber === "si" && (
            <>
              <Row>
                <Col lg={5} md={5} sm={12} className="pr-0">
                  <Form.Group controlId="fullName">
                    <Form.Label>
                      Ingrese el código interno del cliente:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="account_number_customer"
                      ref={register}
                      //readOnly={isReadOnly}
                      //defaultValue={datos?.tax_draft || ""}
                      onKeyPress={(event) => validateOnlyNumber(event)}
                      onPaste={(event) => handlePasteOnlyNumber(event)}
                      maxLength={4}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={2} sm={12} className="pt-4">
                  <Form.Group controlId="email">
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-crear">Agregar</Tooltip>}
                    >
                      <Button
                        variant="warning"
                        type="button"
                        className="btn-md"
                        onClick={() => addAccountNumber()}
                      >
                        <img src={Nuevo} alt="Nuevo" className="img-conf" />
                      </Button>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <table className="table table-bordered table-sm bg-white">
                      <thead>
                        <tr>
                          <th scope="col">Códigos internos</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {listAccountNumber.length > 0 ? (
                          listAccountNumber.map((account, index) => {
                            return (
                              <tr key={account.id}>
                                <td>{account.internal_number}</td>
                                <td>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-eliminar">
                                        Eliminar
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="danger"
                                      type="button"
                                      onClick={() =>
                                        deleteAccountNumber(account)
                                      }
                                    >
                                      <img
                                        src={Eliminar}
                                        alt="Eliminar"
                                        className="img-conf"
                                      />
                                    </Button>
                                  </OverlayTrigger>
                                  {/**!account?.isNew && (
                                    <SwitchToogle
                                      handleActive={handleActiveAccount}
                                      data={account}
                                    />
                                  )**/}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={2}>No hay código interno asignado</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to="/admin/customers">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : customerId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

import { useEffect, useState } from "react";
import { Row, Col, Nav, Navbar, NavDropdown, Accordion } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ItemWithSubmenu from "components/Layouts/Menu/ItemWithSubmenu";
import ItemOnly from "components/Layouts/Menu/ItemOnly";

import { FaChevronLeft } from "react-icons/fa";

import { loadUser, logout } from "actions/admin/userAction";
import { getLoadConfigCompany } from "actions/admin/companyAction";
import { getPermissionsUser } from "actions/configAction";

import user from "assets/images/user.png";
import { listMenuOriginal } from "helpers/listMenu";
import { usePermission } from "hooks/Permission/usePermission";
import { ToastContainer } from "react-toastify";

const version = process.env.REACT_APP_VERSION;

const Layout = ({
  titlePanel,
  titleUser,
  children,
  history,
  location,
  isIcon = false,
  ruta,
  modulo,
}) => {
  const [classHome, setClassHome] = useState("bg-fondo-primary");
  const [visibleMenu, setVisibleMenu] = useState(false);
  const { validarPermiso } = usePermission();
  const [activeKey, setActiveKey] = useState("0");
  const [menuAll, setMenuAll] = useState([]);

  const { name } = useSelector((state) => state.users);
  const { configuracion } = useSelector((state) => state.config);
  const { companyDataBasic } = useSelector((state) => state.companies);

  const dispatch = useDispatch();

  useEffect(() => {
    if (configuracion.length === 0) {
      dispatch(getPermissionsUser());
      dispatch(getLoadConfigCompany());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuracion?.length]);

  useEffect(() => {
    dispatch(loadUser());

    if (location.pathname === "/home") {
      setClassHome("bg-fondo-home");
    } else {
      setClassHome("bg-fondo-primary");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    listMenuOriginal.forEach((element) => {
      if (element.permission === false) {
        if (element?.children?.length === 0) {
          const permissionAll = element.permissionGroup
            .map((item) => !validarPermiso(item))
            .filter((item) => item === false);

          element.permission = permissionAll?.length === 0 ? true : false;
        } else {
          const permissionAll = element?.permissionGroup
            .map((item) => !validarPermiso(item))
            .filter((item) => item === true);

          element.permission = permissionAll?.length > 0 ? true : false;
        }
      }
    });

    const menuUpdate = listMenuOriginal?.filter((item) => item?.permission);

    setMenuAll(menuUpdate);
    const moduleSelected = menuUpdate?.findIndex((item) =>
      item?.module.includes(modulo)
    );

    if (moduleSelected !== -1) {
      setActiveKey(moduleSelected?.toString());
    } else {
      setActiveKey("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutUser = async (event) => {
    event.preventDefault();
    const response = await dispatch(logout());
    if (response.status) {
      history.push("/");
      window.location.reload();
    }
  };

  const getNameUser = () => {
    return (
      <>
        <img src={user} alt="user" />{" "}
        <span className="text-white"> {name}</span>
      </>
    );
  };

  const hiddenMenu = () => {
    setVisibleMenu(!visibleMenu);
  };

  const getSelectedBanner = (tabSelected) => {
    setActiveKey(tabSelected);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          id="sidebar"
          className={`menu-left col-sm-4 col-md-3 col-lg-2 p-0 ${
            visibleMenu ? "active" : ""
          }`}
        >
          <div className="menu-left--image">
            {companyDataBasic !== null && (
              <Link to="/home" className="logo-link">
                <img
                  src={companyDataBasic?.logo}
                  alt="Logo"
                  className="logo--menu"
                />
              </Link>
            )}
          </div>
          <div className="sidebar-menu">
            {menuAll.length > 0 &&
              menuAll.map((menu, key) => (
                <Accordion
                  key={key}
                  activeKey={activeKey}
                  onSelect={(event) => getSelectedBanner(event)}
                  className="accordion-quote"
                >
                  {menu?.children?.length > 0 ? (
                    <ItemWithSubmenu
                      key={menu?.id}
                      index={key}
                      activeKey={activeKey}
                      items={menu?.children}
                      title={menu?.title}
                      icon={menu?.icon}
                      location={location}
                      enabled={menu?.enabled}
                    />
                  ) : (
                    <ItemOnly
                      key={menu?.id}
                      index={key}
                      activeKey={activeKey}
                      title={menu?.title}
                      icon={menu?.icon}
                      href={menu?.href}
                      enabled={menu?.enabled}
                    />
                  )}
                </Accordion>
              ))}
          </div>
          <div className="div-copyright text-white text-center">
            <p className="mb-0">© 2021 You-Box - Santiago de Chile</p>
            <p className="mb-2">{version}</p>
          </div>
        </div>
        <div
          id="content"
          className={`${
            visibleMenu ? "col-12" : "col-sm-8 col-md-9 col-lg-10"
          }  pb-1 pl-0 pr-0 ${classHome}`}
        >
          <div className="container-fluid ">
            <Row className="pb-1">
              <Col xs={12} className="p-0 menu-right--title-panel">
                <Navbar bg="primary" variant="dark" className="pt-3 pb-3">
                  <button
                    type="button"
                    id="sidebarCollapse"
                    className={`btn btn-info`}
                    onClick={() => hiddenMenu()}
                  >
                    <svg viewBox="0 0 49 49">
                      <g
                        id="Grupo_518"
                        data-name="Grupo 518"
                        transform="translate(-507.835 -34.938)"
                      >
                        <g id="Grupo_517" data-name="Grupo 517">
                          <g
                            id="Grupo_91"
                            data-name="Grupo 91"
                            transform="translate(-20 -31)"
                          >
                            <rect
                              id="Rectángulo_179"
                              data-name="Rectángulo 179"
                              width="31"
                              height="3"
                              rx="1.5"
                              transform="translate(541 71)"
                              fill="#fff"
                            />
                            <rect
                              id="Rectángulo_180"
                              data-name="Rectángulo 180"
                              width="44"
                              height="3"
                              rx="1.5"
                              transform="translate(528 86)"
                              fill="#fff"
                            />
                            <rect
                              id="Rectángulo_181"
                              data-name="Rectángulo 181"
                              width="44"
                              height="3"
                              rx="1.5"
                              transform="translate(528 101)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                        <g
                          id="Icon_feather-arrow-left"
                          data-name="Icon feather-arrow-left"
                          transform="translate(501.735 29.417)"
                        >
                          <path
                            id="Trazado_624"
                            data-name="Trazado 624"
                            d="M16.583,18H7.5"
                            transform="translate(0 -5.959)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.8"
                          />
                          <path
                            id="Trazado_625"
                            data-name="Trazado 625"
                            d="M12.041,16.583,7.5,12.041,12.041,7.5"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.8"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <Navbar.Brand className="pl-5 d-flex">
                    {isIcon && (
                      <Link to={`/${ruta}`} className="text-white mr-3">
                        <FaChevronLeft />
                      </Link>
                    )}
                    <p className="title-panel p-0">{titlePanel}</p>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto pr-5">
                      <NavDropdown
                        title={getNameUser()}
                        id="basic-nav-dropdown"
                        className="text-white"
                      >
                        <NavDropdown.Item
                          href="#"
                          onClick={(event) => logoutUser(event)}
                        >
                          Cerrar sesión
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>
            {children}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default withRouter(Layout);

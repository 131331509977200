import API from "config/config-api";
import {
  LIST_DATA_COMPANY,
  SELECTED_ROWS_COMPANY,
  ACTIVE_COMPANY,
  LOADING_COMPANY,
  DATA_MASTER_COMPANY,
} from "types/admin/companyType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_COMPANY, payload));
};

export const getListCompaniesAll = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.company.list);
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_COMPANY, data));
    return { status: status, data: data, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRowsCompany = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_COMPANY, payload));
};

export const addCompany = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.company.add, body, true);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editCompany = (body, companyId) => async (dispatch, getState) => {
  try {
    let response = await API.post(
      endPoints.company.edit(companyId),
      body,
      true
    );
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchCompanyById = (companyId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.company.getOne(companyId));

    const { status, message, data } = response.data;

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listCompanies, companyData } = getState().companies;

    const body = {
      type: "status",
      option: companyData?.active ? "desactivado" : "activado",
    };

    let response = await API.post(endPoints.company.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = listCompanies.map((matriz) => {
        if (matriz.id === id) {
          return {
            ...matriz,
            active: !matriz?.active,
          };
        }
        return matriz;
      });

      const payload = {
        data: {
          ...companyData,
          active: !companyData?.active,
        },
        listAll,
      };

      dispatch(requestSuccess(ACTIVE_COMPANY, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteCompany = (id) => async (dispatch, getState) => {
  try {
    const { listCompanies } = getState().companies;

    let response = await API.delete(endPoints.company.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: [...listCompanies].filter((company) => company.id !== id),
        data: null,
      };

      dispatch(requestSuccess(ACTIVE_COMPANY, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getConfigCompany = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.company.config);
    const { status, message, data } = response.data;
    localStorage.setItem("company", JSON.stringify(data));
    dispatch(requestSuccess(DATA_MASTER_COMPANY, data));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getLoadConfigCompany = () => async (dispatch, getState) => {
  try {
    const serializedData = JSON.parse(localStorage.getItem("company"));
    if (serializedData?.hasOwnProperty("country")) {
      dispatch(requestSuccess(DATA_MASTER_COMPANY, serializedData));
    } else {
      let response = await API.get(endPoints.company.config);
      const { status, message, data } = response.data;
      localStorage.setItem("company", JSON.stringify(data));
      dispatch(requestSuccess(DATA_MASTER_COMPANY, data));
      return { status, message };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const sendEmailForVerified =
  (casaMatrizId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.company.sendEmail(casaMatrizId));
      const { status, message } = response.data;
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import { useTruck } from "hooks/Admin/Trucks/useTruck";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/Trucks/Filter";
import truck from "components/Admin/Columnas/truck";

const ListTrucks = () => {
  const { state, actions } = useTruck();
  const { truckIsLoading } = state;
  const { getAll, filteredItems, handleRowClicked } = actions;

  useEffect(() => {
    getAll("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_trucks">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de camiones">
          <DataTableCustomer
            columns={truck.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={truckIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListTrucks;

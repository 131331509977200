import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/Cities/Filter";
import sucursal from "components/Admin/Columnas/Sucursal";
import { useCities } from "hooks/Admin/Cities/useCities";

const ListCities = () => {
  const { state, actions } = useCities();
  const { cityIsLoading } = state;
  const { getAll, filteredItems, handleRowClicked } = actions;

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_cities">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de sucursales">
          <DataTableCustomer
            columns={sucursal.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={cityIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListCities;

import API from "config/config-api";
import APIV1 from "config/api";
import {
  LIST_DATA_INITIAL_ORDEN,
  DATA_SEARCH_ORDEN,
  LIST_ORDER_INCOMPLETE,
  UPDATE_ORDEN_INCOMPLETE,
  LOADING_SEARCH_ORDERS_NOM,
  FILTERED_ORDERS,
  CLEAN_NAME_DESTINO_NOM,
  CLEAN_SELECTED_ORDEN_NOM,
  TOTAL_SELECTED_ORDEN_NOM,
  UPDATE_SELECTED_ORDEN_NOM,
  PREVIEW_PDF_ORDENES,
  SEARCH_ORDEN_RECEIVE,
  CLEAR_TOTAL_ORDEN_ENTREGA,
  UPDATE_CLIENTE_ORDENES_INPUT,
  UPDATE_INPUT_DOCUMENTO,
  UPDATE_TOTAL_MONTO,
  UPDATE_CLIENTE_ORDENES,
  SEARCH_CLIENTE_ORDENES,
  CANCEL_ORDEN,
  GENERAR_NOTA_CREDITO,
  DISABLED_GENERATE_DOCUMENT,
} from "types/transportOrdersType";

import {
  formatCreateDataOrdenFlete,
  formatEditDataOrdenFlete,
} from "utils/OrdenFlete/DataCreated";
import { formatFloat } from "config/formatInput";
import { validarPermiso } from "utils/Permission/checkPermission";
import endPoints from "routes/";
import { getPhone } from "utils/globals";
import { formatGetOrden } from "utils/OrdenFlete/DataSearchOrden";
import { objectoEntregar, objectoDirecto } from "utils/formatEntrega";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getDataInitialOrder = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.orders.getData);
    const { status, message, data } = response.data;

    if (status) {
      const payload = {
        documentacionsCarga: data?.documentacionsCarga,
        documentacionsPago: data?.documentacionsPago,
        tipoEnvios: data?.tipoEnvio,
        formaPagos: data?.formaPago,
        estados: data?.estados,
        estadosAll: data?.estadosAll,
        documentacionsPagoSinB1: data?.documentacionsCarga.filter(
          (item) => item?.id !== 8
        ),
        estadoRetiros: data?.estadoRetiros,
      };
      dispatch(requestSuccess(LIST_DATA_INITIAL_ORDEN, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const resetDataOrderCreate = () => async (dispatch, getState) => {
  const payload = {
    data: null,
    initial: false,
    visibleCarga: true,
    visibleDownload: false,
  };

  dispatch(requestSuccess(DATA_SEARCH_ORDEN, payload));
};

export const createdOrder = (body) => async (dispatch, getState) => {
  try {
    const dataOrden = {
      ...body,
      valor_declarado_carga: formatFloat(body.valor_declarado_carga),
      valor_iva: formatFloat(body.valor_iva),
      valor_neto: formatFloat(body.valor_neto),
      total: formatFloat(body.total),
      subtotal_orden: formatFloat(body.subtotal_orden),
      numero_orden: body.numero_orden.toString().replaceAll(" ", "").trim(),
    };

    let infoOrden = formatCreateDataOrdenFlete(dataOrden);

    let response = await API.post(endPoints.orders.add, infoOrden);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchOrden = (order_number) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.orders.getOne(order_number));

    const { status, data } = response.data;
    let dataTransportOrder = null;
    if (status) {
      const { companyDataBasic } = getState().companies;

      const lastState = data?.listEstado?.find((item) => item.active);

      dataTransportOrder = {
        fecha_orden_format: data?.fecha_orden,
        fecha_orden: Date.parse(data?.fecha_orden_format),
        city_origin_id: data?.sucursal_origen_id,
        order_number: data?.numero_orden,
        office_origin_id: data?.oficina_origen_id,
        city_destination_id: data?.sucursal_destino_id,
        office_destination_id: data?.oficina_destino_id,
        shipment_type_id: data?.tipo_envio_id,
        user_name: lastState?.user ?? "",
        estado_orden: lastState?.descripcion ?? "",
        cliente_existe: "creado",
        codigo_interno: data?.codigo_interno,
        codigo_interno_nro: data?.codigo_interno_nro,
        cliente_rut: data?.cliente_rut,
        razon_social: data?.razon_social,
        tipo_cliente: data?.tipo_cliente,
        rut_remitente: data?.rut_remitente,
        razon_social_remitente: data?.razon_social_remitente,
        rut_destinatario: data?.rut_destinatario,
        razon_social_destinatario: data?.razon_social_destinatario,
        direccion_destinatario: data?.direccion_destinatario,
        telefono_avisas: getPhone(data?.telefono_avisas, companyDataBasic),
        documentacion_id: data?.documentacion_id,
        numero_documento_carga: data?.numero_documento_carga,
        valor_declarado_carga: data?.valor_declarado_carga,
        with_price: "",
        nro_bultos: "",
        nro_kilos: "",
        largo: "",
        ancho: "",
        alto: "",
        nro_m3: "",
        detalle_carga: "",
        total_carga: "",
        email_notification: data?.email_notification,
        comments: data?.comments,
        cargas: data?.cargas,
        total_bultos: data?.total_bultos,
        total_kilos: data?.total_kilos,
        total_metro_cubico: data?.total_metro_cubico,
        subtotal_orden: data?.subtotal,
        descuento: data?.descuento,
        valor_neto: data?.valor_neto,
        valor_iva: data?.valor_iva,
        nro_nomina: data?.nro_nomina,
        total: data?.total,
        forma_pago_id: data?.forma_pago_id,
        documentacion_id_pago:
          data?.documentation_payments?.documentation_id ?? "",
        numero_documento: data?.documentation_payments?.document_number ?? "",
        rut_factura: data?.documentation_payments?.customer?.rut ?? "",
        razon_social_factura:
          data?.documentation_payments?.customer?.full_name ?? "",
        listEstado: data?.listEstado,
        documentation_payments: data?.documentation_payments,
        rendicion: data?.rendicion,
        nota_creditos: data?.nota_creditos,
      };

      const visibleDownload =
        data?.documentation_payments === null
          ? false
          : data?.documentation_payments?.document_number === "B-1"
          ? false
          : true;
      const payload = {
        data: dataTransportOrder,
        initial: false,
        visibleCarga: true,
        visibleDownload,
        dataLast: dataTransportOrder,
      };

      dispatch(requestSuccess(DATA_SEARCH_ORDEN, payload));
    }
    return {
      status: response.status,
      message: response.message,
      data: dataTransportOrder,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const resetDataTransportOrder = () => async (dispatch, getState) => {
  const payload = {
    data: null,
    initial: true,
    visibleCarga: true,
    visibleDownload: false,
  };

  dispatch(requestSuccess(DATA_SEARCH_ORDEN, payload));
};

export const searchOrdenByNro =
  (order_number) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.orders.getOne(order_number));
      const { status, message } = response?.data;
      return {
        status,
        message,
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };

//Búsqueda incompleta
export const getOrdenListIncomplete =
  (body, page) => async (dispatch, getState) => {
    try {
      let response = await API.post(endPoints.orders.incompletes(page), body);

      let { message, status, data } = response.data;
      let infoList = [];

      for (let index in data) {
        let { clientes, oficinas, cargas } = data[index];
        infoList.push(data[index]);
        delete infoList[index].clientes;
        delete infoList[index].oficinas;
        infoList[index].remitente = clientes.find(
          (item) => item.tipo_cliente === "remitente"
        );
        infoList[index].destinatario = clientes.find(
          (item) => item.tipo_cliente === "destinatario"
        );
        infoList[index].origen = oficinas.find(
          (item) => item.tipo_oficina === "origen"
        );
        infoList[index].destino = oficinas.find(
          (item) => item.tipo_oficina === "destino"
        );
        infoList[index].cant_bulto = cargas.reduce(
          (acumulado, item) => acumulado + parseFloat(formatFloat(item.bulto)),
          0
        );
      }

      const payload = {
        listOrderIncomplete: infoList,
        isLoadingOrderIncomplete: false,
      };
      dispatch(requestSuccess(LIST_ORDER_INCOMPLETE, payload));

      return { status, message };
    } catch (error) {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );
      return { status: false, message: error };
    }
  };

export const getOrdenListIncompleteInitial =
  (value) => (dispatch, getState) => {
    dispatch(
      requestSuccess(LIST_ORDER_INCOMPLETE, {
        listOrderIncomplete: [],
        isLoadingOrderIncomplete: value,
      })
    );
  };

export const updateOrdenIncomplete =
  (body, numero_orden) => async (dispatch, getState) => {
    try {
      const { listOrderIncomplete } = getState().transportOrders;

      let information = {
        ...body,
        valor_declarado_carga: formatFloat(body.valor_declarado_carga),
        valor_iva: formatFloat(body.valor_iva),
        valor_neto: formatFloat(body.valor_neto),
        total: formatFloat(body.total),
        subtotal_orden: formatFloat(body.subtotal_orden),
      };

      let response = await API.post(
        endPoints.orders.updateIncomplete(numero_orden),
        information
      );

      let { message, status } = response.data;

      if (status) {
        let listOrdens = listOrderIncomplete;

        if (information?.total !== 0) {
          listOrdens = listOrderIncomplete.filter(
            (item) => item.numero_orden !== numero_orden
          );
        }

        const payload = { listOrdens };

        dispatch(requestSuccess(UPDATE_ORDEN_INCOMPLETE, payload));
      }

      return { status, message };
    } catch (error) {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );
      return { status: false, message: error };
    }
  };

export const filteredOrdenesRange =
  (busqueda, isModulo, page = 1) =>
  async (dispatch, getState) => {
    dispatch(requestSuccess(LOADING_SEARCH_ORDERS_NOM, []));
    const { city_id } = getState().users;
    const { name_pendiente_sucursal } =
      getState().transportOrders?.title_destino;
    let estado_id = 0;
    try {
      const { configuracion } = getState().config;

      let response = await API.post(
        endPoints.orders.searchGlobal(page),
        busqueda
      );

      const { status, message, data } = response.data;
      let resMessage = message;
      let respStatus = status;

      if (status) {
        const result = formatGetOrden(
          data.orders,
          busqueda?.module,
          city_id,
          configuracion
        );

        let nameDestino = result?.destino_nomina ?? "";
        resMessage = result?.message;
        respStatus = result?.estatus;

        if (isModulo === "nomina") {
          nameDestino =
            name_pendiente_sucursal.length === 0
              ? result?.destino_nomina
              : name_pendiente_sucursal;
        }
        const payload = {
          orders: result?.data,
          destino_nomina: nameDestino,
          name_pdf: data.name_pdf ?? "",
          dataSearch: busqueda,
          disabledSearch: result?.data.length === 0 ? true : false,
          name_excel: data.name_excel ?? "",
        };

        dispatch(requestSuccess(FILTERED_ORDERS, payload));
        estado_id = result?.data.length > 0 ? result?.data[0].estado_id : "";
      } else {
        const title = getState().transportOrders?.title_destino;
        const payload = {
          orders: [],
          destino_nomina: title,
          name_pdf: "",
          dataSearch: busqueda,
          disabledSearch: true,
          name_excel: "",
        };
        dispatch(requestSuccess(FILTERED_ORDERS, payload));
      }
      return {
        status: respStatus,
        message: resMessage,
        estado_id,
        countPaginate: response?.total_paginate,
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const clearTitleEstado = (name) => (dispatch, getState) => {
  const destino = getState().transportOrders.title_destino;

  const destino_nomina = {
    ...destino,
    name_pendiente_sucursal: name,
  };

  dispatch(requestSuccess(CLEAN_NAME_DESTINO_NOM, destino_nomina));
};

export const exportarExcel = () => async (dispatch, getState) => {
  try {
    const { listOrden } = getState().transportOrders;
    if (listOrden.length > 0) {
      let data = listOrden.map((item) => {
        return {
          numero_orden: item.numero_orden,
          estado: item.estado,
          fecha_orden: item.fecha_orden_search,
          cliente_remitente: item.cliente_remitente,
          oficina_origen: item.oficina_origen,
          oficina_destino: item.oficina_destino,
          sucursal_origen: item.sucursal_origen,
          sucursal_destino: item.sucursal_destino,
          cliente_destinatario: item.cliente_destinatario,
          direccion_destinatario: item.direccion_destinatario,
          total_bultos: item.total_bultos,
          total_kilos: item.total_kilos,
          tipo_envio: item.tipo_envio,
          forma_pago: item.forma_pago,
          total: item.total_excel,
          usuario: item.usuario,
          documento_pago: item?.documento_pago,
          nro_documento_pago: item?.nro_documento_pago,
          usuario_entrego_app_mobile: item.usuario_entrego_app_mobile,
          camion_app_mobile: item.camion_app_mobile,
          numero_nomina: item?.numero_nomina,
        };
      });

      let response = await APIV1.putFile(endPoints.orders.searchExcel, {
        list_ordenes: data,
      });

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `listado-ordenes-fletes.xlsx`;
        a.click();
      });
    }
  } catch (error) {}
};

export const receiveOrden = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.payroll.transit, body);
    const { status, message } = response.data;
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const cleanListadoOrden = (isClean) => (dispatch, getState) => {
  let listado = [];
  const { dataNomina, titleDestino } = getState().payrolls;
  if (dataNomina.ordens && dataNomina.ordens.length > 0 && !isClean) {
    listado = dataNomina.ordens;
  }

  const payload = {
    totales: {},
    listadoOrden: listado,
    //title: true,
    destino_nomina: titleDestino || "",
    name_pdf: "",
    name_excel: "",
  };
  dispatch(requestSuccess(CLEAN_SELECTED_ORDEN_NOM, payload));
};

export const calcularTotalSelected = (isCheck) => (dispatch, getState) => {
  const data = getState().transportOrders.listOrden;
  if (data && data.length > 0) {
    const listado = data.filter((orden) => orden.check === true);

    let totalBulto = listado.reduce(
      (acumulado, bultos) => acumulado + parseFloat(bultos.total_bultos),
      0
    );
    let totalKilo = listado.reduce(
      (acumulado, kilos) => acumulado + parseFloat(kilos.total_kilos),
      0
    );
    let total = listado.reduce((acumulado, total) => {
      const valueTotal = total.total_excel === null ? 0 : total.total_excel;
      return acumulado + parseFloat(valueTotal);
    }, 0);
    const totalSelectedOrden = {
      total_bultos: totalBulto,
      total_kilos: totalKilo,
      cantidad_orden: listado.length,
      total,
    };

    dispatch(requestSuccess(TOTAL_SELECTED_ORDEN_NOM, totalSelectedOrden));
  }
};

export const filteredOrdenes =
  (busqueda, isModulo) => async (dispatch, getState) => {
    dispatch(requestSuccess(LOADING_SEARCH_ORDERS_NOM, []));

    const { name_pendiente_sucursal } =
      getState().transportOrders.title_destino;
    let estado_id = 0;
    try {
      let response = await API.post(
        endPoints.payroll.searchGlobal(1),
        busqueda
      );
      const { status, message, data } = response.data;
      if (status) {
        let nameDestino = "";
        if (isModulo === "nomina") {
          nameDestino =
            name_pendiente_sucursal.length === 0
              ? data.destino_nomina
              : name_pendiente_sucursal;
        }
        const payload = {
          orders: data.orders,
          destino_nomina: nameDestino,
          name_pdf: data.name_pdf,
          dataSearch: busqueda,
          disabledSearch: data.orders.length === 0 ? true : false,
          name_excel: data.name_excel,
        };
        dispatch(requestSuccess(FILTERED_ORDERS, payload));
        estado_id = data.orders[0].estado_id;
      } else {
        const title = getState().orders.title_destino;
        const payload = {
          orders: [],
          destino_nomina: title,
          name_pdf: "",
          dataSearch: busqueda,
          disabledSearch: true,
          name_excel: "",
        };
        dispatch(requestSuccess(FILTERED_ORDERS, payload));
      }
      return {
        status,
        message,
        estado_id,
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const selectedOrdens = (status) => (dispatch, getState) => {
  const listado = getState().transportOrders.listOrden;
  const payload = listado.map((orden) => {
    return {
      ...orden,
      check: status,
    };
  });
  dispatch(requestSuccess(UPDATE_SELECTED_ORDEN_NOM, payload));
};

export const updatedSelectedOrdens = (id, status) => (dispatch, getState) => {
  const listado = getState().transportOrders.listOrden;
  const payload = listado.map((orden) => {
    if (orden.id === parseInt(id)) {
      return {
        ...orden,
        check: status,
      };
    }
    return orden;
  });
  dispatch(requestSuccess(UPDATE_SELECTED_ORDEN_NOM, payload));
};

//Recibir el paquetes
export const getStatusOrdenReceiveOtherOffice =
  (body) => async (dispatch, getState) => {
    try {
      let information = {
        numero_package: body?.numero_package,
      };

      let response = await API.post(
        endPoints.orders.searchPackage,
        information
      );

      let { message, status, data } = response?.data;

      return { status, message, data };
    } catch (error) {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );
      return { status: false, message: error };
    }
  };

export const receiveOrdenOtherOffice = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.orders.receivePackage, body);

    let { message, status, data } = response?.data;

    return { status, message, data };
  } catch (error) {
    dispatch(
      requestSuccess(LIST_ORDER_INCOMPLETE, {
        listOrderIncomplete: [],
        isLoadingOrderIncomplete: false,
      })
    );
    return { status: false, message: error };
  }
};

export const exportPdf = () => async (dispatch, getState) => {
  try {
    const { listOrden } = getState().orders;
    if (listOrden.length > 0) {
      let body = listOrden.map((item) => {
        return {
          numero_orden: item.numero_orden,
          estado: item.estado,
          fecha_orden: item.fecha_orden_search,
          cliente_remitente: item.cliente_remitente,
          sucursal_origen: item.sucursal_origen,
          sucursal_destino: item.sucursal_destino,
          cliente_destinatario: item.cliente_destinatario,
          direccion_destinatario: item.direccion_destinatario,
          total_bultos: item.total_bultos,
          total_kilos: item.total_kilos,
          tipo_envio: item.tipo_envio,
          forma_pago: item.forma_pago,
          total: item.total,
          usuario: item.usuario,
        };
      });

      let response = await API.post(endPoints.orders.exportPdf, {
        list_ordenes: body,
      });
      const { status, message, data } = response?.data;
      const payload = { pdf_name: data };
      dispatch(requestSuccess(PREVIEW_PDF_ORDENES, payload));
      return { status, message };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const resetSearchOrden =
  (busqueda, isModulo, page = 1) =>
  async (dispatch, getState) => {
    const payload = {
      orders: [],
      destino_nomina: "",
      name_pdf: "",
      dataSearch: null,
      disabledSearch: true,
      name_excel: "",
    };
    dispatch(requestSuccess(FILTERED_ORDERS, payload));
  };

export const getOrdenReceive = (body, page) => async (dispatch, getState) => {
  try {
    let payload = {
      data: [],
      isLoadingReceive: true,
      infoReceive: {
        receive: 0,
        completed: 0,
        incomplete: 0,
        otherOffice: 0,
        orderToCome: 0,
      },
    };
    dispatch(requestSuccess(SEARCH_ORDEN_RECEIVE, payload));

    let response = await API.post(endPoints.orders.received(page), body);
    const { status, data: dataReceive } = response.data;
    if (status) {
      let data = dataReceive;

      let receive = 0,
        completed = 0,
        incomplete = 0,
        otherOffice = 0,
        orderToCome = 0;

      for (let key in data) {
        if (!data[key].orden_por_llegar && !data[key].recibido_otra_oficina) {
          receive++;
        }

        if (
          data[key].cantidad_bultos_restante === 0 &&
          data[key].bulto_otra_sucursal === 0
        ) {
          completed++;
          data[key].trColor = "success-tr";
        }

        if (data[key].orden_por_llegar) {
          orderToCome++;
          data[key].trColor = "background-muted";
        }

        if (
          data[key].cantidad_bultos_restante > 0 &&
          data[key].bulto_otra_sucursal > 0
        ) {
          incomplete++;
          data[key].trColor = "red-tr";
          data[key].cantidad_bultos_restante =
            data[key].cantidad_bultos - data[key].cantidad_bultos_restante;
        }

        if (
          data[key].recibido_otra_oficina &&
          data[key].city_origin_id !== Number(body.sucursal_id)
        ) {
          otherOffice++;
          data[key].trColor = "warning-tr";
          data[key].cantidad_bultos_restante =
            data[key].cantidad_bultos - data[key].cantidad_bultos_restante;
        }

        if (data[key].trColor === "success-tr") {
          data[key].cantidad_bultos_restante = data[key].cantidad_bultos;
        }
      }

      let infoReceive = {
        receive,
        completed,
        incomplete,
        otherOffice,
        orderToCome,
      };

      payload = { data, isLoadingReceive: false, infoReceive };

      dispatch(requestSuccess(SEARCH_ORDEN_RECEIVE, payload));
    } else {
      payload = {
        data: [],
        isLoadingReceive: false,
        infoReceive: {
          receive: 0,
          completed: 0,
          incomplete: 0,
          otherOffice: 0,
          orderToCome: 0,
        },
      };
      dispatch(requestSuccess(SEARCH_ORDEN_RECEIVE, payload));
    }

    return {
      status: response.status,
      message: response.message,
      countPaginate: response.total_paginate,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getOrdenReceiveInitial = (body, page) => (dispatch, getState) => {
  let payload = {
    data: [],
    isLoadingReceive: false,
    infoReceive: {
      receive: 0,
      completed: 0,
      incomplete: 0,
      otherOffice: 0,
      orderToCome: 0,
    },
  };
  dispatch(requestSuccess(SEARCH_ORDEN_RECEIVE, payload));
};

export const getDataTicketCorrelativoOrden =
  (numero_orden) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.orders.getPackages(numero_orden));
      const { status, message, data } = response.data;
      return {
        status,
        message,
        data,
      };
    } catch (error) {
      return { status: false, message: error, data: [] };
    }
  };

export const getDataOrden = (numero) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.guide.getOrder(numero));
    const { status, message, data } = response?.data;
    return {
      status,
      message,
      data,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const resetListadoOrdenes = () => (dispatch, getState) => {
  const payload = { ordenes: [], entregadas: [] };
  dispatch(requestSuccess(CLEAR_TOTAL_ORDEN_ENTREGA, payload));
};

export const updateEntregarOrden =
  (orden_flete_id, param, valor) => (dispatch, getState) => {
    const { listOrdenEntregar } = getState().orders;

    const updateOrden = listOrdenEntregar.map((orden) => {
      if (parseInt(orden.orden_flete_id) === parseInt(orden_flete_id)) {
        return {
          ...orden,
          [param]: valor,
        };
      }
      return orden;
    });

    const ordenDistintContado = updateOrden.filter(
      (item) =>
        item.seleccion === true &&
        item.forma_pago !== "Contado" &&
        item.forma_pago !== "Cuenta Corriente"
    );

    let visibleMetodoPago = ordenDistintContado.length > 0 ? true : false;

    const payload = {
      updateOrden,
      visibleMetodoPago,
    };

    dispatch(requestSuccess(UPDATE_CLIENTE_ORDENES_INPUT, payload));
  };

export const repetirSeleccion = () => (dispatch, getState) => {
  const { listOrdenEntregar } = getState().orders;

  const check = listOrdenEntregar.find(
    (orden) => orden.forma_pago === "Por pagar"
  );

  const updateOrden = listOrdenEntregar.map((orden) => {
    if (orden.forma_pago === "Por pagar") {
      return {
        ...orden,
        documentacion_id: check.documentacion_id,
        nro_documento: check.nro_documento,
      };
    }
    return orden;
  });
  dispatch(requestSuccess(UPDATE_INPUT_DOCUMENTO, updateOrden));
};

export const totalMontoSeleccion = () => (dispatch, getState) => {
  const { listOrdenEntregar } = getState().orders;
  let totalMonto = 0;
  const porPagar = listOrdenEntregar.filter(
    (orden) => orden.forma_pago === "Por pagar" && orden.seleccion === true
  );

  if (porPagar.length > 0) {
    totalMonto = porPagar.reduce(
      (acumulado, orden) => acumulado + parseFloat(orden.total),
      0
    );
  }

  dispatch(requestSuccess(UPDATE_TOTAL_MONTO, totalMonto));
};

export const searchOrdenesInd =
  (numero_orden, type_search) => async (dispatch, getState) => {
    try {
      const { listOrdenEntregar } = getState().orders;

      const isExiste = listOrdenEntregar.filter(
        (orden) => orden.numero_orden === numero_orden
      );

      if (isExiste.length === 0) {
        let response = await API.post(
          endPoints.delivery.searchOrder(numero_orden),
          {
            type_search,
          }
        );
        const { status, message, data } = response.data;
        if (status) {
          let save = true;

          if (type_search === "ordenes_por_pagar") {
            let check = listOrdenEntregar.filter(
              (orden) => orden.documentacion_id_pago_id !== ""
            );
            if (check.length > 0) {
              if (
                data.documentacion_id_pago_id !== "" &&
                parseInt(check[0].documentacion_id_pago_id) !==
                  parseInt(data.documentacion_id_pago_id)
              ) {
                save = false;
                return {
                  status: false,
                  message: "Debe ser del mismo tipo de documento",
                };
              }
            }
          }
          const payload = {
            details: data,
            entregadas: [],
          };

          if (save) {
            dispatch(requestSuccess(UPDATE_CLIENTE_ORDENES, payload));
          }
        }
        return { status, message };
      } else {
        return { status: false, message: "Ya existe en la lista" };
      }
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const entregarOrden =
  (body, type, ordenes) => async (dispatch, getState) => {
    try {
      let information;
      if (type === "otros") {
        information = objectoEntregar(body.ordenes);
      } else {
        information = objectoDirecto(body);
      }

      const infoSend = {
        ...information,
        factura: {
          ...body.factura,
          codigo_interno: "",
        },
        ordenes,
      };

      let response = await API.post(endPoints.delivery.add, infoSend);
      const { status, message, data } = response.data;
      if (status) {
        const payload = {
          entregadas: data.documentos,
          ordenes: [],
          visibleMetodoPago: false,
        };
        dispatch(requestSuccess(CLEAR_TOTAL_ORDEN_ENTREGA, payload));
      }
      return {
        status,
        message,
        fecha_entrega: data?.fecha_entrega ?? "",
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };

/*********************Modulo Entregar / Devolver*********************/

export const searchClienteOrdenes = (rut) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.delivery.customer, { rut });
    const { status, message, data } = response.data;

    if (status) {
      const payload = {
        listOrdenEntregar: data?.ordenes,
      };

      dispatch(requestSuccess(SEARCH_CLIENTE_ORDENES, payload));
    }
    return {
      status,
      message,
      nombre_destinatario: data?.nombre_destinatario ?? "",
      id: data?.id ?? null,
      cantidad: data?.ordenes?.length ?? 0,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const devolverOrden = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.delivery.returnOrder, body);
    const { status, message } = response.data;
    if (status) {
      const payload = {
        ordenes: [],
        entregadas: [],
      };
      dispatch(requestSuccess(CLEAR_TOTAL_ORDEN_ENTREGA, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editedOrden = (ordenId, body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "editar_orden_flete")) {
      return {
        status: false,
        message: "No tiene permiso de editar la orden de flete",
      };
    } else {
      const data = {
        ...body,
        valor_declarado_carga: body.valor_declarado_carga,
        valor_iva: body.valor_iva,
        valor_neto: body.valor_neto,
        total: body.total,
        subtotal_orden: body.subtotal_orden,
      };

      let infoOrden = formatEditDataOrdenFlete(data);

      let response = await API.post(
        endPoints.orders.update(ordenId),
        infoOrden
      );
      const { status, message } = response.data;
      return { status, message };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const cancelOrden = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "anular_orden_flete")) {
      return {
        status: false,
        message: "No tiene permiso de anular la orden de flete",
      };
    } else {
      let factura = body.factura;
      if (Object.keys(body.factura).length > 0) {
        factura = {
          rut: body.factura?.rut_factura,
          razon_social: body.factura?.razon_social_factura,
          giro: body.factura?.giro_factura,
          telefono: body.factura?.contacto_factura,
          email: body.factura?.email_factura,
          direccion: body.factura?.direccion_factura,
          city_origin_name: body.factura?.city_origin_name,
          office_origin_name: body.factura?.office_origin_name,
        };
      }

      const dataSend = {
        numero_orden: body.order_number,
        factura,
        motivo_anular: body.factura.motivo_anular,
      };

      let response = await API.post(endPoints.orders.cancel, dataSend);
      const { status, message, data } = response.data;
      if (status) {
        const payload = {
          user_name: data.info_estado.user_name,
          estado_orden: data.info_estado.descripcion,
          listEstado: data.listEstado,
        };
        dispatch(requestSuccess(CANCEL_ORDEN, payload));
      }
      return { status, message };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const generarNotaCredito = (body) => async (dispatch, getState) => {
  try {
    const { dataTransportOrder } = getState().transportOrders;

    const data = {
      factura: {
        rut: body.factura?.rut_factura,
        razon_social: body.factura?.razon_social_factura,
        giro: body.factura?.giro_factura,
        telefono: body.factura?.contacto_factura,
        email: body.factura?.email_factura,
        direccion: body.factura?.direccion_factura,
        sucursal_id: body.factura?.city_origin_name,
        comuna_id: body.factura?.office_origin_name,
      },
      documentacion_id_pago: dataTransportOrder?.documentacion_id_pago,
      forma_pago_id: dataTransportOrder?.forma_pago_id,
      numero_folio: dataTransportOrder?.numero_documento,
    };

    let response = await API.post(endPoints.orders.creditNote, data);
    const { status, message } = response.data;
    if (status) {
      const payload = {
        ...body,
        documentacion_id_pago: "",
        numero_documento: "",
        total: body.total,
        codigo_interno_nro: body.codigo_interno_nro,
        isGenerateDocument: true,
      };

      dispatch(requestSuccess(GENERAR_NOTA_CREDITO, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const assignOrdenCaja = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.orders.assignAccountability, body);

    return { status: response.status, message: response.message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const generateDoc = (body) => async (dispatch, getState) => {
  try {
    const { dataTransportOrder } = getState().transportOrders;

    let response = await API.post(endPoints.orders.generateDoc, body);
    const { status, message, data } = response.data;
    if (status) {
      let dataOrder = dataTransportOrder;

      const payload = {
        ...dataOrder,
        isGenerateDocument: false,
        numero_documento: data.numero_documento,
        url_cedible: data.url,
      };

      dispatch(requestSuccess(DISABLED_GENERATE_DOCUMENT, payload));
    }

    return {
      status,
      message,
      numero_documento: data.numero_documento,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

const Role = {
  columnas: [
    {
      name: "Rol",
      selector: (row, i) => row.name,
      sortable: true,
    },
    {
      name: "Cant permisos",
      selector: (row, i) => row.permissions.length,
      sortable: true,
    },
  ],
};

export default Role;

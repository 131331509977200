import {
  LIST_DATA_INICIO,
  UPDATED_TITLE_BOTON,
  SEARCH_NOMINA,
  CLEAR_NOMINA_STATUS,
  SAVE_NOMINA,
  CHANGE_IMPRESION,
} from "../types/nominaType";

const INITIAL_STATE = {
  listNominas: [],
  btnNomina: {
    title: "GENERAR NOMINA",
    isVisible: true,
  },
  isVisiblePanel: {
    saveNomina: false,
    receiveOffice: false,
  },
  dataNomina: {},
  titleDestino: "",
  isActiveNomina: false,
  typeImpresion: "carta",
};

const payrollReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_DATA_INICIO:
      return {
        ...state,
        listNominas: action.payload,
        //dataNomina: {}
      };
    case UPDATED_TITLE_BOTON:
      return {
        ...state,
        btnNomina: action.payload,
      };
    case SEARCH_NOMINA:
      return {
        ...state,
        dataNomina: action.payload.dataNomina,
        titleDestino: action.payload.titleDestino,
        isActiveNomina: action.payload.isActive,
        btnNomina: {
          title: "GENERAR NOMINA",
          isVisible: true,
        },
      };
    case CLEAR_NOMINA_STATUS:
      return {
        ...state,
        dataNomina: action.payload.dataNomina,
        titleDestino: action.payload.titleDestino,
        isActiveNomina: action.payload.isActiveNomina,
      };
    case SAVE_NOMINA:
      return {
        ...state,
        dataNomina: action.payload.dataNomina,
        titleDestino: action.payload.titleDestino,
        isActiveNomina: action.payload.isActiveNomina,
        listNominas: action.payload.nominas,
      };
    case CHANGE_IMPRESION:
      return {
        ...state,
        typeImpresion: action.payload,
      };
    default:
      return state;
  }
};

export default payrollReducer;

import API from "config/config-api";
import APIV1 from "config/api";
import {
  LISTA_DATA_SUCURSAL,
  CLEAN_DATA_SUCURSAL,
  LISTADO_DEPOSITO,
  LISTADO_INICIO_DEPOSITO,
  CHANGE_VALUE_INPUT,
  SAVE_LISTADO_DEPOSITO,
  REABRIR_CAJA,
  ADD_OBSERVACIONES,
} from "types/rendirType";
import {
  totales,
  formatMonto,
  formatFloat,
  fomartDate,
} from "config/formatInput";
import { validarPermiso } from "utils/Permission/checkPermission";
import endPoints from "routes/";

const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getCuentaTipoDeposito = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;
    const isCheck = !validarPermiso(configuracion, "cerrar_caja_sucursales");
    let response;

    if (!isCheck) {
      response = await API.get(endPoints.accountability.listInitial);
    } else {
      response = await API.get(endPoints.accountability.user);
    }

    const { status, message, data } = response.data;

    dispatch(requestSuccess(LISTADO_INICIO_DEPOSITO, data));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getListadoSucursal = (body) => async (dispatch, getState) => {
  try {
    const { listadoCuentaDeposito } = getState().rendir;

    let response = await API.post(endPoints.accountability.orders, body);
    const { status, message, data } = response.data;
    if (status && !data?.verified) {
      let {
        ventas,
        recepcion,
        isExiste,
        depositos,
        diferencia,
        total_depositos,
        isOpen,
        observaciones,
        idRendirCaja,
        edicionManual,
      } = data;

      const listadoVenta = {
        tbody: ventas,
        tfoot: totales(ventas),
      };

      const listadoRecepcion = {
        tbody: recepcion,
        tfoot: totales(recepcion),
      };

      const total =
        listadoVenta?.tfoot?.totalCtdo + listadoRecepcion?.tfoot?.totalPorPagar;

      const totalDiferencia = isExiste ? diferencia : total;
      let isPermisoEliminarDeposito = isOpen === false ? false : true;
      if (isExiste === 1) {
        const { configuracion } = getState().config;
        isPermisoEliminarDeposito = !validarPermiso(
          configuracion,
          "eliminar_deposito_caja_cerrada"
        );
      }

      let cajasList = listadoCuentaDeposito.filter(
        (x) => x.sucursal_id === Number(body.sucursal_id)
      );

      const payload = {
        listadoVenta,
        listadoRecepcion,
        rendirCtdo: formatMonto(listadoVenta?.tfoot?.totalCtdo || 0),
        rendirPorPagar: formatMonto(
          listadoRecepcion?.tfoot?.totalPorPagar || 0
        ),
        rendirTotal: formatMonto(total),
        diferencia: totalDiferencia,
        depositos: depositos || [],
        total_depositos: isExiste ? total_depositos : 0,
        disabled:
          parseFloat(totalDiferencia) === 0
            ? true
            : totalDiferencia < 0
            ? true
            : false,
        isCajaCerrada: isOpen,
        isPermisoEliminarDeposito,
        listadoObservaciones: observaciones,
        idRendirCaja,
        isInitial: ventas.length > 0 || recepcion.length > 0 ? false : true,
        isExiste,
        edicionManual,
        search: {
          sucursal_id: body.sucursal_id,
          fecha: body.fecha,
        },
        cajasListSucursal: cajasList,
      };

      dispatch(requestSuccess(LISTA_DATA_SUCURSAL, payload));
    }

    return { status, message, data: data?.id ?? null };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getCashAccountability = (id) => async (dispatch, getState) => {
  try {
    const { listAccount } = getState().accounts;

    let sucursal_id_rendir = "";
    let fecha_rendir = "";

    let response = await API.get(endPoints.accountability.getOne(id));
    const { status, message, data } = response.data;
    if (status) {
      let {
        ventas,
        recepcion,
        isExiste,
        depositos,
        diferencia,
        total_depositos,
        isOpen,
        observaciones,
        idRendirCaja,
        edicionManual,
        sucursal_id,
        fecha,
      } = data;
      sucursal_id_rendir = sucursal_id;
      fecha_rendir = fecha;

      const listadoVenta = {
        tbody: ventas,
        tfoot: totales(ventas),
      };

      const listadoRecepcion = {
        tbody: recepcion,
        tfoot: totales(recepcion),
      };

      const total =
        listadoVenta?.tfoot?.totalCtdo + listadoRecepcion?.tfoot?.totalPorPagar;

      const totalDiferencia = isExiste ? diferencia : total;
      let isPermisoEliminarDeposito = isOpen === false ? false : true;
      if (isExiste === 1) {
        const { configuracion } = getState().config;
        isPermisoEliminarDeposito = !validarPermiso(
          configuracion,
          "eliminar_deposito_caja_cerrada"
        );
      }

      let cajasList = listAccount.filter(
        (x) => x.sucursal_id === Number(sucursal_id)
      );

      const payload = {
        listadoVenta,
        listadoRecepcion,
        rendirCtdo: formatMonto(listadoVenta?.tfoot?.totalCtdo || 0),
        rendirPorPagar: formatMonto(
          listadoRecepcion?.tfoot?.totalPorPagar || 0
        ),
        rendirTotal: formatMonto(total),
        diferencia: totalDiferencia,
        depositos: depositos || [],
        total_depositos: isExiste ? total_depositos : 0,
        disabled:
          parseFloat(totalDiferencia) === 0
            ? true
            : totalDiferencia < 0
            ? true
            : false,
        isCajaCerrada: isOpen,
        isPermisoEliminarDeposito,
        listadoObservaciones: observaciones,
        idRendirCaja,
        isInitial: false,
        isExiste,
        edicionManual,
        search: {
          sucursal_id,
          fecha,
        },
        cajasListSucursal: cajasList,
      };

      dispatch(requestSuccess(LISTA_DATA_SUCURSAL, payload));
    }

    return {
      status,
      message,
      data: {
        sucursal_id: sucursal_id_rendir,
        fecha: fecha_rendir,
      },
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const cleanListadoSucursal = (body) => (dispatch, getState) => {
  const payload = {
    listadoVenta: [],
    listadoRecepcion: [],
    depositos: [],
    listadoObservaciones: [],
    edicionManual: [],
    search: { sucursal_id: null, fecha: new Date() },
  };
  dispatch(requestSuccess(CLEAN_DATA_SUCURSAL, payload));
};

export const addDeposito = (body) => (dispatch, getState) => {
  const { listadoDepositos, diferencia } = getState().rendir;

  const { monto } = body;
  const depositos = [...listadoDepositos, body];
  const total = depositos.reduce(
    (acumulado, data) => acumulado + parseFloat(data.monto),
    0
  );
  const diferenciaTotal = Math.abs(diferencia - monto);

  const disabled = diferenciaTotal === 0 ? true : false;
  const payload = {
    depositos,
    totalMonto: total,
    diferencia: diferenciaTotal,
    disabledAdd: disabled,
  };

  dispatch(requestSuccess(LISTADO_DEPOSITO, payload));
};

export const addRendirCaja = (body) => async (dispatch, getState) => {
  try {
    const dataSend = {
      ...body,
      total_contado: formatFloat(body.total_contado),
      total_depositos: formatFloat(body.total_depositos),
      total_por_pagar: formatFloat(body.total_por_pagar),
      totales: formatFloat(body.totales),
      diferencia: formatFloat(body.diferencia),
    };

    let response = await API.post(endPoints.accountability.add, dataSend);
    const { status, message, data } = response.data;
    if (response.status) {
      //const { configuracion } = getState().config
      const { diferencia } = getState().rendir;

      //let isPermisoEliminarDeposito = !validarPermiso(configuracion, "eliminar_deposito_caja_cerrada")

      const payload = {
        isCajaCerrada: false,
        isPermisoEliminarDeposito: false,
        isExiste: true,
        isInitial: false,
        idRendirCaja: data.idRendirCaja,
        observaciones: data.observaciones,
        listadoDepositos: data.depositos,
        disabledAdd: diferencia > 0 ? false : true,
      };
      dispatch(requestSuccess(REABRIR_CAJA, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const dataSearch = (name, valor, isFecha) => (dispatch, getState) => {
  const { busqueda } = getState().rendir;
  if (isFecha) {
    while (valor.indexOf("/") !== -1) {
      valor = valor.replace("/", "C");
    }
  }

  const payload = {
    ...busqueda,
    [name]: valor,
  };

  dispatch(requestSuccess(CHANGE_VALUE_INPUT, payload));
};

export const searchGlobalRendicion = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.accountability.searchGlobal, body);
    const { status, message } = response.data;
    if (status) {
      const payload = {
        ...body,
        desde: fomartDate(body.desde),
        hasta: fomartDate(body.hasta),
      };

      dispatch(requestSuccess(CHANGE_VALUE_INPUT, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const removeDeposito =
  (index, deposito_id) => async (dispatch, getState) => {
    try {
      const { listadoDepositos, rendirTotal } = getState().rendir;
      const depositos = listadoDepositos.filter((deposito, i) => i !== index);
      const depDelete = listadoDepositos.find((deposito, i) => i === index);

      const montoDeposito = depositos.reduce(
        (acumulado, dep) => acumulado + parseFloat(dep.monto),
        0
      );
      const montoDiferencia = formatFloat(rendirTotal) - montoDeposito;

      const payload = {
        listado: depositos,
        total: montoDeposito,
        diferencia: montoDiferencia,
        disabledAdd: montoDiferencia > 0 ? false : true,
      };

      if (
        depDelete.hasOwnProperty("deposito_id") &&
        depDelete.hasOwnProperty("rendir_id")
      ) {
        const body = {
          deposito_id: depDelete.deposito_id,
          id: depDelete.rendir_id,
        };
        let response = await API.post(
          endPoints.accountability.deleteDeposit,
          body
        );
        const { status, message } = response.data;
        if (status) {
          dispatch(requestSuccess(SAVE_LISTADO_DEPOSITO, payload));
        }
        return { status, message };
      } else {
        dispatch(requestSuccess(SAVE_LISTADO_DEPOSITO, payload));
        return { status: true, message: "Se ha eliminado correctamente" };
      }
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const exportarExcelCaja = (body) => async (dispatch, getState) => {
  try {
    const { isCajaCerrada } = getState().rendir;

    if (isCajaCerrada === false) {
      let response = await APIV1.putFile(endPoints.accountability.export, body);

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `rendicion-caja.xlsx`;
        a.click();
      });
    }
  } catch (error) {}
};

export const reabrirCajaCerrada = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;
    const isCheck = validarPermiso(configuracion, "reabrir_caja");

    if (isCheck) {
      let response = await API.post(endPoints.accountability.reopen, body);
      const { status, message, data } = response.data;
      if (status) {
        const { diferencia } = getState().rendir;
        const payload = {
          isCajaCerrada: true,
          isPermisoEliminarDeposito: true,
          isExiste: true,
          isInitial: false,
          idRendirCaja: data.idRendirCaja,
          observaciones: data.observaciones,
          listadoDepositos: data.depositos,
          disabledAdd: diferencia > 0 ? false : true,
        };

        dispatch(requestSuccess(REABRIR_CAJA, payload));
      }
      return { status, message };
    } else {
      return {
        status: false,
        message: "No tiene permiso para abrir la caja",
      };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const createdObservacion = (body) => async (dispatch, getState) => {
  try {
    const { idRendirCaja } = getState().rendir;

    let response = await API.post(
      endPoints.accountability.comment(idRendirCaja),
      {
        comentarios: body,
      }
    );
    const { status, message, data } = response.data;
    if (status) {
      const payload = data;

      dispatch(requestSuccess(ADD_OBSERVACIONES, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addParentOffice,
  editParentOffice,
  searchParentOfficeById,
} from "actions/admin/parentOfficeAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormParentOffice = (countryId, parentCityId) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      name: "",
      parent_city_id: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataParentOffice, setDataParentOffice] = useState(null);

  const { parentOfficeData } = useSelector((state) => state.parentOffices);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      name: data?.name?.toUpperCase(),
      parent_city_id: parentCityId,
    };

    if (dataParentOffice !== null) {
      response = await dispatch(editParentOffice(body, dataParentOffice?.id));
    } else {
      response = await dispatch(addParentOffice(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);
      history.push(
        `/admin/countries/${countryId}/parent-cities/${parentCityId}/parent-offices`
      );
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getParentOfficeById = async (parentCityId) => {
    let data = parentOfficeData;

    if (parentCityId !== null) {
      if (parentOfficeData === null) {
        const result = await dispatch(searchParentOfficeById(parentCityId));
        data = result?.data;
      }
    }
    reset({
      name: data?.name ?? "",
    });

    setDataParentOffice(parentCityId !== null ? data ?? null : null);
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };

  const actions = {
    getParentOfficeById,
  };

  return {
    formElement,
    actions,
  };
};

import { Button, Col, Card, Badge, Accordion, Row } from "react-bootstrap";

const Cajas = ({ detalle, title, icono, searchCaja }) => {
  return (
    <Card body className="card-retiro shadow-sm">
      <div className="d-flex">
        <div>
          <img src={icono} alt="Ejemplo" className="icono-cajas" />
        </div>
        <div className="pl-4 w-100">
          <h4 className="mb-3 text-celeste pl-2 fs-22">{title}</h4>

          <Row>
            {Object.keys(detalle).length > 0 ? (
              Object.keys(detalle).map((key, value) => (
                <Col xs={12} key={key}>
                  <Accordion>
                    <Card className="tab-cajas">
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="0"
                          className="w-100"
                        >
                          <div className="d-flex justify-content-between">
                            <h6 className="mb-0">{key}</h6>{" "}
                            <p className="mb-0">
                              <Badge variant="danger">
                                {detalle[key].length}
                              </Badge>
                            </p>
                          </div>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <ul className="list-unstyled">
                            {detalle[key].map((det, clave) => (
                              <li key={clave}>
                                <Button
                                  variant="link"
                                  onClick={() => searchCaja(det)}
                                >{`Día: ${det.fecha}`}</Button>
                              </li>
                            ))}
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              ))
            ) : (
              <Col xs={12}>
                <p className="text-muted">No hay registros disponible</p>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default Cajas;

import API from "config/config-api";
import { INICIO_DATA } from "types/homeType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getCountRetiro = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.home.getData);
    const { status, message, data } = response.data;

    dispatch(requestSuccess(INICIO_DATA, data));

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

import { useState } from "react";
import { Form, Col, Card, Row } from "react-bootstrap";

const rut_cacem = process.env.REACT_APP_RUT_CACEM;

const Configuracion = ({
  register,
  errors,
  traslados,
  despachos,
  listCities,
  listUserDrives,
  listTrucks,
  setValue,
}) => {
  const [comunas, setComunas] = useState([]);
  const [nombreConductor, setNombreConductor] = useState("");

  const cargarComunas = (event) => {
    const id = event.target.value;
    if (id !== "") {
      const detOficinas = listCities.find((s) => s.id === parseInt(id));
      let comunas =
        detOficinas && detOficinas.offices ? detOficinas.offices : [];

      let detComuna = comunas;
      if (comunas.length > 0) {
        detComuna = comunas.filter((c) => c.is_activo !== 0);
      }

      setComunas(detComuna);
    }
  };

  const selectedName = (event) => {
    const nombre =
      event.target.options[event.target.selectedIndex].dataset.conductor;
    setNombreConductor(nombre);
  };

  const cargaRut = (event) => {
    setValue("rut_transportista", event.target.value === "" ? "" : rut_cacem);
  };

  return (
    <Card border="Light">
      <Card.Header as="h6" className="bg-warn-blue text-dark">
        CONFIGURACION
      </Card.Header>
      <Card.Body className="bg-soft-blue">
        <Row>
          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="traslado_id">
              <Form.Label>Traslado</Form.Label>
              <Form.Control
                as="select"
                name="traslado_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
              >
                <option value="">Seleccione</option>
                {traslados.length > 0 &&
                  traslados.map((traslado) => (
                    <option key={traslado.id} value={traslado.id}>
                      {traslado.description}
                    </option>
                  ))}
              </Form.Control>
              {errors.traslado_id && (
                <Form.Text className="text-danger">
                  {errors?.traslado_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="despacho_id">
              <Form.Label>Despacho</Form.Label>
              <Form.Control
                as="select"
                name="despacho_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
              >
                <option value="">Seleccione</option>
                {despachos.length > 0 &&
                  despachos.map((despacho) => (
                    <option key={despacho.id} value={despacho.id}>
                      {despacho.description}
                    </option>
                  ))}
              </Form.Control>
              {errors.despacho_id && (
                <Form.Text className="text-danger">
                  {errors?.despacho_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="sucursal_id">
              <Form.Label>Sucursal destino</Form.Label>
              <Form.Control
                as="select"
                name="sucursal_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onChange={(event) => cargarComunas(event)}
              >
                <option value="">Seleccione</option>
                {listCities.length > 0 &&
                  listCities.map((sucursal) => (
                    <option key={sucursal.id} value={sucursal.id}>
                      {sucursal.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.sucursal_id && (
                <Form.Text className="text-danger">
                  {errors?.sucursal_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="comuna_id">
              <Form.Label>Comuna</Form.Label>
              <Form.Control
                as="select"
                name="comuna_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
              >
                <option value="">Seleccione</option>
                {comunas.length > 0 &&
                  comunas.map((comuna) => (
                    <option key={comuna.id} value={comuna.id}>
                      {comuna.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.comuna_id && (
                <Form.Text className="text-danger">
                  {errors?.comuna_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Group controlId="direccion_destino">
              <Form.Label>Dirección destino</Form.Label>
              <Form.Control
                type="text"
                name="direccion_destino"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
              />
              {errors.direccion_destino && (
                <Form.Text className="text-danger">
                  {errors?.direccion_destino?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>

          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="rut_conductor">
              <Form.Label>RUT del Conductor</Form.Label>
              <Form.Control
                as="select"
                name="rut_conductor"
                onChange={(event) => selectedName(event)}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
              >
                <option value="">Seleccione</option>
                {listUserDrives.length > 0 &&
                  listUserDrives.map((conductor) => (
                    <option
                      key={conductor.id}
                      value={conductor.id}
                      data-conductor={conductor.full_name}
                    >
                      {conductor.rut}
                    </option>
                  ))}
              </Form.Control>
              {errors.rut_conductor && (
                <Form.Text className="text-danger">
                  {errors?.rut_conductor?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>

          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="nombre_conductor">
              <Form.Label>Nombre del Conductor</Form.Label>
              <Form.Control
                type="text"
                name="nombre_conductor"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                readOnly
                defaultValue={nombreConductor}
              />
              {errors.nombre_conductor && (
                <Form.Text className="text-danger">
                  {errors?.nombre_conductor?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>

          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="camion_id">
              <Form.Label>Patente</Form.Label>
              <Form.Control
                as="select"
                name="camion_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onChange={(event) => cargaRut(event)}
              >
                <option value="">Seleccione</option>
                {listTrucks?.length > 0 &&
                  listTrucks?.map((patente) => (
                    <option key={patente.id} value={patente.id}>
                      {patente.plate_number}
                    </option>
                  ))}
              </Form.Control>
              {errors.camion_id && (
                <Form.Text className="text-danger">
                  {errors?.camion_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Form.Group controlId="rut_transportista">
              <Form.Label>RUT del transportista</Form.Label>
              <Form.Control
                type="text"
                name="rut_transportista"
                ref={register}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Configuracion;

import { useEffect } from "react";
import { Table } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Taxes/Formulario";
import { useTaxes } from "hooks/Admin/Taxes/useTaxes";

const ListCorrelatives = () => {
  const { getAll, taxData, listTaxes, taxtIsLoading } = useTaxes();

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_taxes">
      <div className="pl-3 pr-3">
        <CardRetiro title="Impuesto">
          <Formulario datos={taxData} loading={taxtIsLoading} />
          {!taxtIsLoading && listTaxes.length > 0 && (
            <div className="row justify-content-md-center mt-5">
              <div className="col-4">
                <Table bordered size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Valor</th>
                      <th>Última actualización</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listTaxes?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.value}</td>
                        <td>{item?.updated_at}</td>
                      </tr>
                    ))}
                    {taxtIsLoading && listTaxes.length === 0 && (
                      <tr>
                        <td colspan="3">Cargando...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListCorrelatives;

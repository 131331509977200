import { useMemo, useEffect } from "react";
import Eliminar from "../../assets/images/configurar/delete.png";
import { Button } from "react-bootstrap";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "10px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgb(157 182 232)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Dropzone = ({
  getRootProps,
  getInputProps,
  isDragActive,
  isFocused,
  isDragAccept,
  isDragReject,
  acceptedFiles,
  onFileAdded,
  onDeleteFile,
}) => {
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      acceptedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "13px", marginBottom: 0 }}>
              Arrastra o carga tu archivo aquí (.png, .jpg, .webp)
            </p>
            <p style={{ fontSize: "13px", marginBottom: 0 }}>
              tamaño máximo: 120kb
            </p>
          </div>
        )}
      </div>
      {acceptedFiles?.map((file, index) => (
        <div className="mt-2 alert alert-secondary p-2 d-flex" key={index}>
          <img
            style={{
              width: "40px",
              height: "35px",
            }}
            src={URL.createObjectURL(file)}
            alt="Logo"
          />
          <p className="font-weight-normal ml-3 mb-0 text-truncate flex-grow-1">
            {file?.name}{" "}
          </p>
          <Button
            variant="warning"
            type="button"
            className="mr-2 btn-xs"
            onClick={() => onDeleteFile(file)}
          >
            <img src={Eliminar} alt="Eliminar" className="img-conf" />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default Dropzone;

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Filter from "components/Admin/Companies/Filter";
import company from "components/Admin/Columnas/Company";
import { useCompany } from "hooks/Admin/Companies/useCompany";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";

const ListCompany = () => {
  const { state, actions } = useCompany();

  const { filteredItems, handleRowClicked, getAll } = actions;

  const { companyIsLoading } = state;

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_company">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de casas matrices">
          <DataTableCustomer
            columns={company.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={companyIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListCompany;

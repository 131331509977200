import { Accordion, Card } from "react-bootstrap";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { MdOutlineArrowDropUp } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

const ItemWithSubmenu = ({
  title,
  icon,
  items,
  index,
  activeKey,
  children,
  location,
}) => {
  const CustomerHead = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    return (
      <div
        className={`accordion-header ${
          activeKey === eventKey ? "accordion-header__selected" : ""
        }`}
        onClick={decoratedOnClick}
      >
        <div className="accordion-title"> {children}</div>
        {activeKey === eventKey ? (
          <MdOutlineArrowDropUp style={{ fontSize: "28px" }} />
        ) : (
          <MdOutlineArrowDropDown style={{ fontSize: "28px" }} />
        )}
      </div>
    );
  };

  const selected = (item) => {
    if (location?.pathname === item?.href) {
      return "nav-item-menu__selected";
    } /**else if (item?.otherRoute.startsWith(location.pathname)) {
      return "nav-item-menu__selected";
    }**/
    return "";
  };

  return (
    <Card className="menu-card">
      <CustomerHead as={Card.Header} eventKey={`${index}`}>
        <img src={icon} alt={title} />
        <p>{title}</p>
      </CustomerHead>
      <Accordion.Collapse eventKey={`${index}`}>
        <Card.Body>
          {items?.length > 0 &&
            items?.map((item) => (
              <Link
                key={item?.id}
                to={item?.href}
                className={`nav-item-menu ${selected(item)}`}
              >
                <span className="nav-text">{item?.title}</span>
              </Link>
            ))}
        </Card.Body>
      </Accordion.Collapse>
      <div className="pt-0 mt-1 nav-link">
        <hr className="mt-0 mb-0 border-hr" />
      </div>
    </Card>
  );
};

export default ItemWithSubmenu;

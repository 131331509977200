import { useDispatch, useSelector } from "react-redux";
import {
  getListUser,
  deleteUser,
  changeStatus,
  getSelectedRows,
  getLoading,
} from "actions/admin/userAction";
import { getListRole } from "actions/admin/roleAction";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useUser = () => {
  const dispatch = useDispatch();
  const { listUsers, userIsLoading, userDatos, email } = useSelector(
    (state) => state.users
  );
  const { roleAll } = useSelector((state) => state.roles);

  const getAllInitial = () => {
    dispatch(getLoading(true));
    dispatch(getListUser("all", "all"));
    dispatch(getSelectedRows(null));
    dispatch(getListRole("active"));
  };

  const handleDelete = () => {
    if (userDatos !== null) {
      if (email !== userDatos.email) {
        Swal.fire({
          title: "¿Desea eliminar el usuario?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await dispatch(deleteUser(userDatos.id));
            if (response.status) {
              toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
      } else {
        toast.error("El mismo usuario no se puede eliminar", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleActivarDes = (event) => {
    if (userDatos !== null) {
      if (email !== userDatos.email) {
        const statusName = userDatos.active ? "desactivar" : "activar";
        Swal.fire({
          title: `¿Desea ${statusName} el usuario?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await dispatch(changeStatus(userDatos?.id));
            if (response.status) {
              toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        });
      } else {
        toast.error("El mismo usuario no se puede eliminar", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleAssignVehicle = (event) => {
    if (userDatos !== null) {
      if (userDatos.nombre_rol !== "Conductor") {
        toast.error(
          "Solo se puede asignar vehículo a usuario con rol Conductor",
          { position: toast.POSITION.TOP_RIGHT }
        );
        return false;
      } else {
      }
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const filteredItems = (filterText) => {
    return listUsers.filter(
      (item) =>
        item.full_name &&
        item.full_name
          .toString()
          ?.toLowerCase()
          .includes(filterText.toString()?.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRows(rows));
  };

  const state = {
    listUsers,
    userIsLoading,
    userDatos,
    roleAll,
    email,
  };

  const actions = {
    getAllInitial,
    handleAssignVehicle,
    handleActivarDes,
    handleDelete,
    filteredItems,
    handleRowClicked,
  };

  return {
    state,
    actions,
  };
};

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addCity, editCity, searchCityById } from "actions/admin/cityAction";
import { getListParentCities } from "actions/admin/parentCityAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormCity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      parent_city_id: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataCity, setDataCity] = useState(null);

  const { cityData } = useSelector((state) => state.cities);
  const { listParentCities } = useSelector((state) => state.parentCities);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      ...data,
      name: data?.name?.toUpperCase(),
    };

    if (dataCity !== null) {
      response = await dispatch(editCity(body, dataCity?.id));
    } else {
      response = await dispatch(addCity(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);

      setTimeout(() => {
        history.push(`/admin/cities`);
      }, 1000);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
    setIsLoading(false);
  };

  const getCityById = async (cityId) => {
    let data = cityData;
    await dispatch(getListParentCities(1, "active"));

    if (cityId !== null) {
      if (cityData === null) {
        const result = await dispatch(searchCityById(cityId));
        data = result?.data;
      }
    }

    reset({
      parent_city_id: data?.parent_city_id ?? "",
      name: data?.name ?? "",
    });

    setDataCity(cityId !== null ? data ?? null : null);
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
  };

  const actions = {
    getCityById,
  };

  const state = {
    dataCity,
    listParentCities,
  };

  return {
    formElement,
    actions,
    state,
  };
};

export const getPhone = (phoneNumber, matrizInfoBasic) => {
  let telefono = "";
  if (matrizInfoBasic?.country?.code) {
    if (
      phoneNumber !== null &&
      phoneNumber?.startsWith(matrizInfoBasic?.country?.code)
    ) {
      telefono = phoneNumber?.slice(matrizInfoBasic?.country?.code?.length);
    } else {
      telefono = phoneNumber;
    }
  }
  return telefono;
};

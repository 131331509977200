import { Col, Form } from "react-bootstrap";

const Permisos = ({
  list,
  index,
  register,
  permissions,
  validatePermission,
}) => {
  const isCheckPermission = (namePermission) => {
    if (permissions?.length > 0) {
      const verified = permissions.find(
        (permission) => permission.name === namePermission
      );
      if (verified) {
        return true;
      }
    }

    return false;
  };

  return (
    <Col lg={4} key={index} className="mb-3">
      <Form.Label className="mb-2">{list?.title}</Form.Label>
      {list?.options.map((permission, indice) => (
        <Form.Group key={permission.id} className="mb-1">
          <Form.Check
            type="checkbox"
            name={`permissions[${permission.id}]`}
            id={`permissions[${permission.id}]`}
            label={permission.name_view}
            defaultValue={permission.name}
            defaultChecked={isCheckPermission(permission.name)}
            onChange={(event) => validatePermission(event)}
            ref={register}
          />
        </Form.Group>
      ))}
    </Col>
  );
};

export default Permisos;

import React, { useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import PanelDetalle from "../../../components/Layouts/PanelDetalle";
import { useDispatch, useSelector } from "react-redux";
import { createdObservacion } from "../../../actions/rendirAction";
import ModalHistorico from "./ModalHistorico";

const DetalleObservacion = ({
  register,
  errors,
  toast,
  getValues,
  setValue,
}) => {
  const [isCreatedObs, setIsCreatedObs] = useState(false);
  const [showIsModal, setShowIsModal] = useState(false);
  const dispatch = useDispatch();
  const { isExiste, listadoObservaciones } = useSelector(
    (state) => state.rendir
  );

  const addObservacion = async (event) => {
    event.preventDefault();
    setIsCreatedObs(true);
    if (getValues("comentarios").length === 0) {
      toast.error("Debe ingresar la observación", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsCreatedObs(false);
      return false;
    }
    const response = await dispatch(
      createdObservacion(getValues("comentarios"))
    );
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      setValue("comentarios", "");
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }

    setIsCreatedObs(false);
  };

  const historial = (event) => {
    event.preventDefault();

    setShowIsModal(true);
  };

  const handleCloseModal = () => {
    setShowIsModal(false);
  };
  console.log("listadoObservaciones", listadoObservaciones);

  return (
    <>
      <Row className="bg-soft-blue mt-5">
        <Col md={10} sm={12}>
          <PanelDetalle
            title="Historial de Observaciones"
            subtitle=""
            check={false}
          >
            <Form.Row className="pt-3 bg-gray">
              <Col lg md={5} xs={4}>
                <Form.Group as={Col} controlId="comentarios" className="pr-1">
                  <Form.Label>Comentario</Form.Label>
                  <Form.Control
                    defaultValue={""}
                    as="textarea"
                    name="comentarios"
                    rows={4}
                    ref={register}
                  />
                </Form.Group>
              </Col>
              <Col md={3} sm={12}>
                {isExiste && (
                  <Form.Group as={Col} className="mt-3 pl-0">
                    <Button
                      variant="warning"
                      type="button"
                      block
                      onClick={(event) => addObservacion(event)}
                    >
                      {isCreatedObs ? "Procesando..." : "Comentar"}
                    </Button>
                    {listadoObservaciones.length > 0 && (
                      <Button
                        variant="warning"
                        type="button"
                        block
                        onClick={(event) => historial(event)}
                      >
                        Ver Historial
                      </Button>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Form.Row>
          </PanelDetalle>
        </Col>
      </Row>
      {showIsModal && (
        <ModalHistorico
          showIsModal={showIsModal}
          setShowIsModal={setShowIsModal}
          handleCloseModal={handleCloseModal}
          listado={listadoObservaciones}
        />
      )}
    </>
  );
};

export default DetalleObservacion;

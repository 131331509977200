import { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { BiX } from "react-icons/bi";
import { isAfter, getDateYMD } from "../../../utils/formatFecha";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { formatNumberCurrency } from "../../../utils/validationInput";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { BiCalendar } from "react-icons/bi";
import { NumericFormat } from "react-number-format";
registerLocale("es", es);

const DetailPayment = ({
  register,
  listPaymentMethods,
  listDetailPayment,
  addItemPayment,
  deleteItemPayment,
  cuentas_depositos,
  control,
  isEdit,
  setTotalPagar,
  isValid,
  totalPagar,
}) => {
  const validateDateBlur = (props, event) => {
    const { message, error, dateSelected } = getDateYMD(event.target.value);

    if (error) {
      toast.error(message, { position: toast.POSITION.TOP_RIGHT });
      props.onChange(new Date());
      return false;
    }

    if (isAfter(dateSelected, dayjs())) {
      toast.error(
        "La fecha ingresada no puede ser superior a la fecha actual",
        { position: toast.POSITION.TOP_RIGHT }
      );
      props.onChange(new Date());
      return false;
    }
  };

  const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl
        name="fecha_orden_c"
        className="date_payment_to"
        value={value}
        readOnly
      />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <>
      <h6 className="mt-4">Detalle de las formas de pago</h6>
      {!isEdit && (
        <div className="row mt-4">
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="date_payment">Fecha</label>
              <Controller
                control={control}
                name="date_payment"
                render={(props) => (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Seleccione"
                    onChange={(e) => props.onChange(e)}
                    selected={props.value}
                    className="form-control"
                    name="date_payment"
                    id="date_payment"
                    locale="es"
                    maxDate={new Date()}
                    onBlur={(e) => validateDateBlur(props, e)}
                    customInput={<ExampleCustomInputFrom />}
                    readOnly={isValid}
                    maxLength={15}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="deposit_account">Cuenta Deposito</label>
              <select
                className="form-control"
                id="deposit_account"
                name="deposit_account"
                ref={register}
                disabled={isValid}
              >
                <option value="">-Seleccione-</option>
                {cuentas_depositos.length > 0 &&
                  cuentas_depositos.map((deposito) => (
                    <option
                      key={deposito.id}
                      value={deposito.id}
                    >{`${deposito.name_bank} - ${deposito.account_number}`}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="payment_methods">Tipo</label>
              <select
                className="form-control"
                id="payment_methods"
                name="payment_methods"
                ref={register}
                disabled={isValid}
              >
                <option value="">-Seleccione-</option>
                {listPaymentMethods.length > 0 &&
                  listPaymentMethods.map((item, key) => (
                    <option key={key} value={item.id}>
                      {item.description}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="monto">Monto</label>
              <Controller
                control={control}
                name="monto"
                defaultValue={totalPagar}
                render={(props) => (
                  <NumericFormat
                    allowNegative={false}
                    className="form-control"
                    decimalScale={2}
                    name={props.name}
                    id={props.name}
                    fixedDecimalScale={false}
                    isAllowed={({ floatValue }) => {
                      // Define your validation rules here
                      return true;
                    }}
                    onPaste={(e) => e.preventDefault()}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    value={props.value}
                    //defaultValue={ordenInfo.valor_declarado_carga}
                    onValueChange={(values) => {
                      props.onChange(values.floatValue);
                      setTotalPagar(values.floatValue);
                    }}
                    readOnly={isValid}
                    maxLength={15}
                  />
                )}
                autoComplete="off"
              />
              {/**<input 
					    	type="text" 
					    	className="form-control" 
					    	id="monto"
					    	name="monto"
					    	ref={register}
					    /> */}
            </div>
          </div>
          <div className="col-2">
            <div className="form-group mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => addItemPayment("add")}
                disabled={isValid}
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-10">
          <table className="table table-bordered table-sm table-invoice__doc">
            <thead>
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Cuenta</th>
                <th scope="col">Tipo</th>
                <th scope="col">Monto</th>
                {!isEdit && <th scope="col">Opciones</th>}
              </tr>
            </thead>
            <tbody>
              {listDetailPayment.length > 0 &&
                listDetailPayment.map((item, key) => (
                  <tr key={key}>
                    <td>{item.fecha}</td>
                    <td>{item.cuenta}</td>
                    <td>{item.tipo_pago}</td>
                    <td>{formatNumberCurrency(item.monto)}</td>
                    {!isEdit && (
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm p-0"
                          onClick={() => deleteItemPayment(item)}
                        >
                          <BiX style={{ fontSize: "20px" }} />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              {listDetailPayment.length === 0 && (
                <tr>
                  <td colSpan={5}>No hay documentos disponible</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DetailPayment;

import { listCountry } from "helpers/listCountry";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addCountry } from "actions/admin/countryAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormContry = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      country_id: "",
      phone_code: "",
      iso_code: "",
    },
  });

  const { countryData } = useSelector((state) => state.countries);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    const paisSelected = listCountry.find(
      (pais) => pais.id === Number(data?.country_id)
    );

    if (!paisSelected) {
      toast.error("Se genero un error al guardar el país", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return;
    }

    const body = {
      phone_code: `+${paisSelected?.phoneCode}` ?? "",
      name: paisSelected.name ?? "",
      iso_code: paisSelected?.isoCode,
    };

    let response = await dispatch(addCountry(body));

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);
      setTimeout(() => {
        history.push("/admin/countries");
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const changePais = async (e, valor) => {
    const id = e !== undefined ? e.target.value : valor;

    const paisSelected = listCountry.find((item) => item.id === Number(id));

    setValue("phone_code", `+${paisSelected.phoneCode}` ?? "");
    setValue("iso_code", paisSelected.isoCode ?? "");
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
  };

  const actions = {
    changePais,
  };

  const state = {
    countryData,
    listCountry,
  };

  return {
    formElement,
    actions,
    state,
  };
};

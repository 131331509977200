import {
  LISTAR_DATA_PAGO,
  DISABLED_METODO_PAGO,
} from "../types/metodoPagoType";

const INITIAL_STATE = {
  metodo_pago: [],
  disabledBtn: true,
};

const metodoPagoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTAR_DATA_PAGO:
      return {
        ...state,
        metodo_pago: action.payload,
      };
    case DISABLED_METODO_PAGO:
      return {
        ...state,
        disabledBtn: action.payload,
      };
    default:
      return state;
  }
};

export default metodoPagoReducer;

import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CardRetiro from "components/Layouts/CardRetiro";
import Table from "components/Despacho/Historial/Table";

import { useDispatch, useSelector } from "react-redux";
import { listadoGuia } from "actions/guiaDespachoAction";

const Historial = () => {
  const dispatch = useDispatch();
  const { listado_guia, cargandoData } = useSelector(
    (state) => state.guiaDespacho
  );

  useEffect(() => {
    dispatch(listadoGuia());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pl-3 pr-3">
      <Row>
        <Col xs={12}>
          <CardRetiro title="HISTORIAL DE GUÍA DE DESPACHO">
            <Table data={listado_guia} isCargando={cargandoData} />
          </CardRetiro>
        </Col>
      </Row>
    </div>
  );
};

export default Historial;

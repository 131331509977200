import { useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { useTaxes } from "hooks/Admin/Taxes/useTaxes";
import {
  validateOnlyNumber,
  handlePasteOnlyNumber,
} from "utils/validationInput";

const Formulario = ({ datos, loading }) => {
  const {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    setDataInicial,
  } = useTaxes();

  useEffect(() => {
    setDataInicial(datos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos]);

  return (
    <Form id="frmImpuesto" onSubmit={handleSubmit(onSubmit)}>
      <Row className="justify-content-md-center">
        <Col lg={4} md={12} sm={12}>
          <Form.Group controlId="value">
            <Form.Label>Tasa del impuesto</Form.Label>
            <Form.Control
              type="text"
              name="value"
              ref={register({
                required: { value: true, message: "Requerido" },
                minLength: { value: 2, message: "Mínimo 2 dígitos" },
              })}
              defaultValue={datos?.value || ""}
              readOnly={loading}
              maxLength={2}
              onKeyPress={(event) => validateOnlyNumber(event)}
              onPaste={(event) => handlePasteOnlyNumber(event)}
            />
            {errors.value && (
              <Form.Text className="text-danger">
                {errors?.value?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col lg={4} md={12} sm={12} className="text-right mt-3">
          <Button
            variant="warning"
            type="submit"
            disabled={loading || isLoading}
            className="pl-5 pr-5 "
          >
            {isLoading
              ? !loading
                ? "Procesando..."
                : "Guardar"
              : datos !== null
              ? "Modificar"
              : "Guardar"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

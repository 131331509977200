import React, { Fragment, useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { getListUserByTruckId } from "actions/admin/truckAction";
import Vehiculos from "./Vehiculos";

const Viajes = ({ register, errors, setValue, toast, getValues }) => {
  const dispatch = useDispatch();
  const { conductores, dataNomina, isActiveNomina } = useSelector(
    (state) => state.payrolls
  );
  const { destino_nomina } = useSelector((state) => state.transportOrders);

  const { listTrucks } = useSelector((state) => state.trucks);
  const [conductorAll, setConductorAll] = useState([
    {
      name: "conductores-1",
      list: [],
    },
    {
      name: "conductores-2",
      list: [],
    },
    {
      name: "conductores-3",
      list: [],
    },
  ]);

  const InitialData = () => {
    const initial = [
      {
        fecha_viaje: new Date(),
        camion_id: "",
        conductor_id: "",
        destino_id: destino_nomina,
        initial_btn: true,
      },
    ];
    if (Object.keys(dataNomina).length > 0) {
      return dataNomina.vehiculos;
    }
    return initial;
  };

  const [detConductores, setDetConductores] = useState([]);

  useEffect(() => {
    const items = InitialData();
    setDetConductores(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveNomina]);

  const addConductor = (id) => {
    if (detConductores.length < 3) {
      const nuevo = {
        fecha_viaje: new Date(),
        camion_id: document.getElementById("camion_id[0]").value,
        conductor_id: "",
        destino_id: destino_nomina,
        initial_btn: false,
      };
      setDetConductores([...detConductores, nuevo]);
    } else {
      toast.error("Solo 2 conductores adicionales", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const removeConductor = (index) => {
    const data = getValues();

    let listConductores = [];
    for (let key in detConductores) {
      if (parseInt(key) !== index) {
        let conductor = detConductores[key];

        listConductores.push({
          ...conductor,
          conductor_id: data?.conductor_id[key],
          camion_id: data?.camion_id[key],
        });
      }
    }

    setDetConductores([...listConductores]);
  };

  const onChangeCamion = async (event, index) => {
    const { value } = event.target;
    //const data = getValues();

    if (value) {
      const result = await dispatch(getListUserByTruckId(value));

      setConductorAll([
        ...conductorAll?.map((item) => {
          return {
            name: item?.name,
            list: result?.data,
          };
        }),
      ]);
    }

    let listConductores = [];

    for (let key in detConductores) {
      let conductor = detConductores[key];

      listConductores.push({
        ...conductor,
        conductor_id: "",
        camion_id: value,
      });
    }

    setDetConductores((detConductores) => listConductores);
  };

  const onChangeConductor = (event, index) => {
    let value = event.target.value;
    let listConductores = [];

    const existe = detConductores.find(
      (item) => item.conductor_id.toString() === value.toString()
    );

    if (existe) {
      toast.error("Ya fue seleccionado", {
        position: toast.POSITION.TOP_RIGHT,
      });
      value = "";
    }

    for (let key in detConductores) {
      let conductor = detConductores[key];

      listConductores.push({
        ...conductor,
        conductor_id:
          parseInt(key) === parseInt(index)
            ? value
            : detConductores[key].conductor_id,
      });
    }

    setDetConductores((detConductores) => listConductores);
  };

  return (
    <Fragment>
      <Form.Row>
        <Col>
          <Form.Group as={Col} controlId="fecha_viaje" className="pr-0">
            <p className="font-weight-normal">
              Asigna el conductor o los conductores
            </p>
          </Form.Group>
        </Col>
      </Form.Row>
      {detConductores.map((item, index) => (
        <Vehiculos
          key={index}
          index={index}
          item={item}
          errors={errors}
          camiones={listTrucks}
          conductores={conductores}
          addConductor={addConductor}
          setValue={setValue}
          register={register}
          removeConductor={removeConductor}
          isActiveNomina={isActiveNomina}
          onChangeCamion={onChangeCamion}
          onChangeConductor={onChangeConductor}
          conductorAll={conductorAll}
        />
      ))}
    </Fragment>
  );
};

export default Viajes;

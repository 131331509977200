import { LIST_DATA_DRIVE, LOADING_DRIVE } from "types/admin/driveType";

const INITIAL_STATE = {
  listUserDrives: [],
  driveIsLoading: true,
};

const driveReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_DATA_DRIVE:
      return {
        ...state,
        listUserDrives: action.payload,
        driveIsLoading: false,
      };
    case LOADING_DRIVE:
      return {
        ...state,
        driveIsLoading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default driveReducer;

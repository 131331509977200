import { Row, Form, Col, Card } from "react-bootstrap";

import { useSelector } from "react-redux";
import Mensual from "./Mensual";
import Historico from "./Historico";
import LoadingSearch from "components/Utilidades/LoadingSearch";

const Busqueda = ({ toast }) => {
  const {
    listOrdenes,
    opcionVisualizar,
    isLoadingSearch,
    listHistorico,
    resultView,
    hiddenBtn,
    cliente,
  } = useSelector((state) => state.facturacion);

  return (
    <Card border="Light mt-5 mr-4 ml-4">
      <Card.Body className="bg-soft-blue minHeigthSinResultado">
        {isLoadingSearch && <LoadingSearch />}
        {!isLoadingSearch && resultView && (
          <Row className="row-sin-resultado">
            <Col lg={12} md={12} sm={12}>
              <Form.Text className="text-dark pb-4 pt-3 text-center fs-25">
                Sin Resultado
              </Form.Text>
            </Col>
          </Row>
        )}
        {!isLoadingSearch &&
          opcionVisualizar === "mensual" &&
          listOrdenes.length > 0 && (
            <Mensual
              listOrdenes={listOrdenes}
              toast={toast}
              cliente={cliente}
            />
          )}
        {!isLoadingSearch &&
          opcionVisualizar === "historico" &&
          listHistorico.length > 0 && (
            <Historico
              data={listHistorico}
              isCargando={isLoadingSearch}
              hiddenBtn={hiddenBtn}
              cliente={cliente}
            />
          )}
      </Card.Body>
    </Card>
  );
};

export default Busqueda;

import React from "react";
import { Card, Col } from "react-bootstrap";
import more from "assets/images/more.svg";

const Information = ({ title, total, icono }) => {
  return (
    <Col lg={4}>
      <Card body className="card-retiro shadow-sm">
        <div className="d-flex">
          <div>
            <img src={icono} alt={title} />
          </div>
          <div className="pl-4">
            <p className="mb-0">{title}</p>
            <p className="mb-0 font-weight-bold h5 text-celeste">{total}</p>
            <p className="mb-0 font-weight-bold text-celeste">{title}</p>
            <p className="mb-0 text-muted fs-12">En sistema</p>
          </div>
        </div>
        <p className="mt-0 p-0 mb-0 text-right">
          <img src={more} alt="Ver más" />
        </p>
      </Card>
    </Col>
  );
};

export default Information;

import React, { useEffect, useState, forwardRef } from "react";
import { Form, Button, Col, InputGroup, FormControl } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getListadoSucursal, cleanListadoSucursal } from "actions/rendirAction";
import { getListAccount } from "actions/admin/depositAccountAction";
import { usePermission } from "hooks/Permission/usePermission";
import dayjs from "dayjs";
import { isAfter, getDateYMD } from "utils/formatFecha";
import { BiCalendar } from "react-icons/bi";
import { useHistory } from "react-router-dom";
const defaultValues = {
  fecha: new Date(),
};
registerLocale("es", es);

const Search = ({ toast, setIsLoadingSearch, searchData, isEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, setValue, control } = useForm({
    defaultValues,
  });
  const [isBuscar, setIsBuscar] = useState(false);
  const { validarPermiso } = usePermission();

  const { city_id } = useSelector((state) => state.users);
  const { listCities } = useSelector((state) => state.cities);

  const onSubmit = async (data, e) => {
    setIsBuscar(true);
    setIsLoadingSearch(true);
    dispatch(cleanListadoSucursal());

    if (typeof data.sucursal_id === "undefined") {
      data.sucursal_id = city_id;
    }

    const body = {
      ...data,
      fecha: dayjs(data?.fecha).format("YYYY-MM-DD"),
    };

    const response = await dispatch(getListadoSucursal(body));

    if (!response.status) {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    } else {
      setIsBuscar(false);
      setIsLoadingSearch(false);
      if (response.data !== null) {
        history.push(`/cash-accountability/${response.data}`);
      } else {
        await dispatch(getListAccount("active", data.sucursal_id));
      }
    }
  };

  useEffect(() => {
    if (searchData?.sucursal_id === null) {
      if (city_id !== "") {
        setValue("sucursal_id", city_id);
      }
    } else {
      setValue("sucursal_id", searchData?.sucursal_id);

      let fechaSplit = searchData?.fecha?.split("-");
      let fecha = new Date(
        fechaSplit[2] + "-" + fechaSplit[1] + "-" + fechaSplit[0]
      );
      fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset());
      setValue("fecha", fecha);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCities]);

  const validateDateBlur = (props, event) => {
    const { message, error, dateSelected } = getDateYMD(event.target.value);

    if (error) {
      toast.error(message, { position: toast.POSITION.TOP_RIGHT });
      props.onChange(new Date());
      return false;
    }

    if (isAfter(dateSelected, dayjs())) {
      toast.error(
        "La fecha ingresada no puede ser superior a la fecha actual",
        { position: toast.POSITION.TOP_RIGHT }
      );
      props.onChange(new Date());
      return false;
    }
  };

  const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl
        name="fecha_orden_c"
        className="date_from"
        value={value}
        readOnly
      />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <Col xs={12}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        id="frmBusquedaGlobal"
        role="form"
      >
        <Form.Row className="pr-5 pl-5 pt-3 pb-4">
          <Col lg xs={6}>
            <Form.Group as={Col} controlId="sucursal_id" className="pr-1 pl-0">
              <Form.Label>Sucursal</Form.Label>
              <Form.Control
                as="select"
                name="sucursal_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                disabled={
                  !isEdit
                    ? validarPermiso("cambiar_sucursal")
                      ? false
                      : true
                    : true
                }
              >
                <option value="">Seleccione</option>
                {listCities.length > 0 &&
                  listCities.map((sucursal) => (
                    <option key={sucursal.id} value={sucursal.id}>
                      {sucursal.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.sucursal_id && (
                <Form.Text className="text-danger">
                  {errors?.sucursal_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg xs={6}>
            <Form.Group as={Col} controlId="fecha" className="pr-0">
              <Form.Label>Fecha</Form.Label>
              <Controller
                control={control}
                name="fecha"
                render={(props) => (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select date"
                    onChange={(e) => props.onChange(e)}
                    selected={props.value}
                    className="form-control"
                    id="fecha"
                    locale="es"
                    maxDate={new Date()}
                    disabled={isEdit}
                    onBlur={(e) => validateDateBlur(props, e)}
                    customInput={<ExampleCustomInputFrom />}
                  />
                )}
              />
              {errors.fecha && (
                <Form.Text className="text-danger">
                  {errors?.fecha?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>

          <Col>
            {isEdit === false && (
              <Form.Group as={Col} className="mt-4 pl-0">
                <Button
                  variant="warning"
                  type="submit"
                  block
                  disabled={isBuscar}
                >
                  {isBuscar ? "BUSCANDO..." : "MOSTRAR"}
                </Button>
              </Form.Group>
            )}
          </Col>
        </Form.Row>
      </Form>
    </Col>
  );
};

export default Search;

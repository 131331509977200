import React, { Fragment } from "react";
import { Form, Col } from "react-bootstrap";
import PanelDetalle from "components/Layouts/PanelDetalle";

const Observaciones = ({ register, errors, ordenInfo }) => {
  return (
    <Fragment>
      <div className="mb-2">
        <PanelDetalle title="E-MAIL" subtitle="" check={false}>
          <Form.Row className="pt-1 mb-0 ">
            <Col>
              <Form.Group
                as={Col}
                controlId="email_notification"
                className="mb-0"
              >
                <Form.Control
                  //defaultValue={ordenInfo.email}
                  as="input"
                  name="email_notification"
                  placeholder="Escribe e-mail de notificaciones"
                  ref={register({
                    //eslint-disable-next-line
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                      message: "Email inválido",
                    },
                  })}
                  maxLength={70}
                />
              </Form.Group>
              {errors.email_notification && (
                <Form.Text className="text-danger pl-3">
                  {errors?.email_notification?.message}
                </Form.Text>
              )}
            </Col>
          </Form.Row>
        </PanelDetalle>
      </div>
      <PanelDetalle title="OBSERVACIONES" subtitle="" check={false}>
        <Form.Row className="pt-1 mb-0 ">
          <Col>
            <Form.Group as={Col} controlId="comments" className="mb-0">
              <Form.Control
                as="textarea"
                name="comments"
                rows={2}
                placeholder="Escribe aquí tus observaciones"
                ref={register}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </PanelDetalle>
    </Fragment>
  );
};

export default Observaciones;

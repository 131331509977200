import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";

const ModalMessage = ({ handleClose, hidden }) => {
  return (
    <Modal show={hidden} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <h6>CLIENTE NO CREADO</h6>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <p>
              El Rut ó código interno ingresado no está registrado. Ingresar un
              Rut Válido o crea un nuevo cliente para continuar
            </p>
          </Col>
        </Row>
        <Modal.Footer>
          <Button
            className="pl-5 pr-5"
            variant="warning"
            onClick={() => handleClose()}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMessage;

import { forwardRef } from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { BiCalendar } from "react-icons/bi";

const ComponentCustomDate = forwardRef(({ value, onClick }, ref) => (
  <InputGroup className="mb-3">
    <FormControl name="fecha_orden_c" value={value} readOnly />
    <InputGroup.Append>
      <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
        <BiCalendar />
      </Button>
    </InputGroup.Append>
  </InputGroup>
));

export default ComponentCustomDate;

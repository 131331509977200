import React from "react";
import { Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCorrelative } from "hooks/Admin/Correlatives/useCorrelative";
//import Nuevo from "assets/images/configurar/plus.png"
import Editar from "assets/images/configurar/edit.png";
import Eliminar from "assets/images/configurar/delete.png";
import {
  validateOnlyLetter,
  handlePasteOnlyLetter,
} from "utils/validationInput";
import { Link } from "react-router-dom";

const Filter = ({ filterText, onFilter, toast, setClearTable }) => {
  const { state, actions } = useCorrelative();
  const { correlativeData } = state;
  const { handleDelete } = actions;

  return (
    <>
      <Col lg={12} className="d-flex justify-content-between pl-0 pr-0 mb-4">
        <Col>
          <Col lg={6} className="pl-0 pr-0">
            <Form.Control
              id="search"
              type="text"
              placeholder="Ingresa el nombre del módulo"
              aria-label="Ingresa el nombre del módulo"
              value={filterText}
              onChange={onFilter}
              onKeyPress={(event) => validateOnlyLetter(event)}
              onPaste={(event) => handlePasteOnlyLetter(event)}
              maxLength={30}
            />
          </Col>
          <span className="fs-12">
            **Nombre de módulo se utiliza para la búsqueda y modificación de los
            datos
          </span>
        </Col>
        <Col className="text-right">
          <Form.Group>
            {/**<Button variant="warning" type="button" className="mr-2" onClick={(event) => handleAdd(event)}>
					   <img src={Nuevo} alt="Nuevo" className="img-conf" />
					</Button>**/}
            {correlativeData !== null && (
              <>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-editar">Editar</Tooltip>}
                >
                  <Link to={`/admin/correlatives/${correlativeData?.id}`}>
                    <Button variant="warning" type="button" className="mr-2">
                      <img src={Editar} alt="Nuevo" className="img-conf" />
                    </Button>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-eliminar">Eliminar</Tooltip>}
                >
                  <Button
                    variant="warning"
                    type="button"
                    className="mr-2"
                    onClick={(event) => handleDelete(event)}
                  >
                    <img src={Eliminar} alt="Eliminar" className="img-conf" />
                  </Button>
                </OverlayTrigger>
              </>
            )}
          </Form.Group>
        </Col>
      </Col>
    </>
  );
};

export default Filter;

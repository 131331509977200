export const LIST_DATA_INITIAL_ORDEN = "LIST_DATA_INITIAL_ORDEN";
export const DATA_ADD_ORDEN = "DATA_ADD_ORDEN";
export const DATA_SEARCH_ORDEN = "DATA_SEARCH_ORDEN";
export const LIST_ORDER_INCOMPLETE = "LIST_ORDER_INCOMPLETE";
export const UPDATE_ORDEN_INCOMPLETE = "UPDATE_ORDEN_INCOMPLETE";
export const LOADING_SEARCH_ORDERS_NOM = "LOADING_SEARCH_ORDERS_NOM";
export const FILTERED_ORDERS = "FILTERED_ORDERS";
export const CLEAN_NAME_DESTINO_NOM = "CLEAN_NAME_DESTINO_NOM";
export const CLEAN_SELECTED_ORDEN_NOM = "CLEAN_SELECTED_ORDEN_NOM";
export const TOTAL_SELECTED_ORDEN_NOM = "TOTAL_SELECTED_ORDEN_NOM";
export const UPDATE_SELECTED_ORDEN_NOM = "UPDATE_SELECTED_ORDEN_NOM";
export const PREVIEW_PDF_ORDENES = "PREVIEW_PDF_ORDENES";
export const SEARCH_ORDEN_RECEIVE = "SEARCH_ORDEN_RECEIVE";
export const CLEAR_TOTAL_ORDEN_ENTREGA = "CLEAR_TOTAL_ORDEN_ENTREGA";
export const UPDATE_CLIENTE_ORDENES_INPUT = "UPDATE_CLIENTE_ORDENES_INPUT";
export const UPDATE_INPUT_DOCUMENTO = "UPDATE_INPUT_DOCUMENTO";
export const UPDATE_TOTAL_MONTO = "UPDATE_TOTAL_MONTO";
export const UPDATE_CLIENTE_ORDENES = "UPDATE_CLIENTE_ORDENES";
export const SEARCH_CLIENTE_ORDENES = "SEARCH_CLIENTE_ORDENES";
export const CANCEL_ORDEN = "CANCEL_ORDEN";
export const GENERAR_NOTA_CREDITO = "GENERAR_NOTA_CREDITO";
export const DISABLED_GENERATE_DOCUMENT = "DISABLED_GENERATE_DOCUMENT";

import { useState, useEffect } from "react";
import { Row, Button, Col, ProgressBar } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const baseURL = process.env.REACT_APP_API_URL;

const PreviewNomina = (props) => {
  const { typeImpresion } = useSelector((state) => state.payrolls);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(10);

  const {
    match: { params: numero },
  } = props;

  const redirectPage = () => {
    props.history.push("/payrolls/create");
  };

  function startAnimation() {
    let value = 0;

    let timerId = setInterval(() => {
      value = value + 10;
      if (value < 80) {
        setCount((count) => value);
      } else {
        clearInterval(timerId);
      }
    }, 1000);
  }

  useEffect(() => {
    if (companyDataBasic !== null) {
      let iframe = document.querySelector("#printFrame");
      iframe.onload = function () {
        setLoading(false);
        setCount(0);
        clearInterval(startAnimation);
      };
      startAnimation();
    }
  }, [companyDataBasic]);

  return (
    <Layout titlePanel="NOMINA DE CARGA" modulo="crear_nomina">
      <Row>
        <Col xs={12} className="mb-3 mt-3 text-right">
          <Button
            variant="warning"
            type="button"
            onClick={(event) => redirectPage(event)}
          >
            Regresar
          </Button>
        </Col>
        {loading && (
          <Col xs={12}>
            <ProgressBar animated now={count} />
            <p className="text-center">
              {loading ? "Cargando la nómina..." : ""}
            </p>
          </Col>
        )}
        {companyDataBasic !== null && (
          <Col xs={12}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                id="printFrame"
                className="embed-responsive-item"
                title="Carga Nomina"
                src={`${baseURL}/payrolls/print/${numero.numero}/${typeImpresion}/${companyDataBasic?.id}`}
                type="application/pdf"
                width="100%"
                height="300px"
              ></iframe>
            </div>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default withRouter(PreviewNomina);

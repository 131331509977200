import API from "config/config-api";
import { LIST_TAXES, ADD_TAX, LOADING_TAX } from "types/admin/taxType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_TAX, payload));
};

export const getListTaxes = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.tax.list);
    const { status, message, data } = response.data;
    const taxActive = data?.find((item) => item?.active === true);
    const payload = {
      list: data.filter((item) => !item.active),
      selected: taxActive ?? null,
      loading: false,
    };
    dispatch(requestSuccess(LIST_TAXES, payload));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addTax = (body) => async (dispatch, getState) => {
  try {
    const { listTaxes } = getState().taxes;

    let response = await API.post(endPoints.tax.add, body);
    const { status, message, data } = response.data;
    if (status) {
      if (listTaxes.length > 0) {
        const payload = {
          data: data,
          listAll: [data, ...listTaxes],
        };

        dispatch(requestSuccess(ADD_TAX, payload));
      }
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

import { LIST_TAXES, ADD_TAX, LOADING_TAX } from "types/admin/taxType";

const INITIAL_STATE = {
  listTaxes: [],
  taxtIsLoading: true,
  taxData: null,
};

const impuestoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_TAX:
      return {
        ...state,
        taxtIsLoading: action.payload.loading,
        taxData: action.payload.data,
      };
    case LIST_TAXES:
      return {
        ...state,
        listTaxes: action.payload.list,
        taxData: action.payload.selected,
        taxtIsLoading: action.payload.loading,
      };
    case ADD_TAX:
      return {
        taxData: action.payload.data,
        listTaxes: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default impuestoReducer;

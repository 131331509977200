import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import { useRole } from "hooks/Admin/Roles/useRole";
import role from "components/Admin/Columnas/Role";
import Filter from "components/Admin/Roles/Filter";

const ListRole = () => {
  const { state, actions } = useRole();
  const { roleIsLoading, filteredItems } = state;
  const { getAllInitial, handleRowClicked } = actions;

  useEffect(() => {
    getAllInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_roles">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de roles">
          <DataTableCustomer
            columns={role.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={roleIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListRole;

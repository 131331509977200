export const objectoEntregar = (dataOrdenes) => {
  let nuevoObjeto = {};

  dataOrdenes.forEach((orden) => {
    let id_document;
    let name_pago = "";
    let forma_pago_id = null;

    if (orden?.forma_pago === "Cuenta Corriente") {
      id_document = 1;
      name_pago = "Contado";
      forma_pago_id = orden?.forma_pago_id;
    } else if (
      orden?.forma_pago === "Por pagar" &&
      !orden.hasOwnProperty("documentacion_id")
    ) {
      id_document = 1;
      name_pago = "Contado";
      forma_pago_id = orden?.forma_pago_id;
    } else {
      id_document = orden?.documentacion_id;
      name_pago = orden?.forma_pago;
      forma_pago_id = orden?.forma_pago_id;
    }
    const name_forma_pago = name_pago.replace(" ", "");

    if (!nuevoObjeto.hasOwnProperty(orden.rut_recepcion)) {
      nuevoObjeto[orden.rut_recepcion] = {
        cliente: {
          rut: orden.rut_recepcion,
          nombre: orden.nombre_recepcion,
        },
        forma_pagos: {
          [name_forma_pago]: {
            documentacions: {
              [id_document]: {
                ordenes: [],
                pagos: [],
              },
            },
          },
        },
      };
    }

    if (
      !nuevoObjeto[orden.rut_recepcion].forma_pagos.hasOwnProperty(
        name_forma_pago
      )
    ) {
      nuevoObjeto[orden.rut_recepcion].forma_pagos[name_forma_pago] = {
        documentacions: {},
      };
    }

    if (
      !nuevoObjeto[orden.rut_recepcion].forma_pagos[
        name_forma_pago
      ].documentacions.hasOwnProperty(id_document)
    ) {
      nuevoObjeto[orden.rut_recepcion].forma_pagos[
        name_forma_pago
      ].documentacions[id_document] = {
        ordenes: [],
        pagos: [],
        forma_pago_id: null,
        nro_documento: null,
      };
    }

    nuevoObjeto[orden.rut_recepcion].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].ordenes.push(orden?.orden_flete_id);

    nuevoObjeto[orden.rut_recepcion].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].forma_pago_id = forma_pago_id;

    nuevoObjeto[orden.rut_recepcion].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].nro_documento = orden?.nro_documento;

    let total = orden?.total;

    while (total.toString().indexOf(".") !== -1) {
      total = total.replace(".", "");
    }

    nuevoObjeto[orden.rut_recepcion].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].pagos.push({
      metodo_pago_id: orden?.metodo_pago_id,
      nro_operacion: orden?.nro_operacion,
      monto: total,
      metodo_pago: orden?.metodo_pago,
    });
  });

  return { entregas: nuevoObjeto };
};

export const objectoDirecto = (data) => {
  let nuevoObjeto = {};

  data.ordenes.forEach((orden) => {
    let id_document;
    let name_pago = "";
    let forma_pago_id = null;

    if (
      orden?.forma_pago === "Cuenta Corriente" ||
      orden?.forma_pago === "Contado"
    ) {
      id_document = 1;
      name_pago = "Contado";
      forma_pago_id = orden?.forma_pago_id;
    } else if (
      orden?.forma_pago === "Por pagar" &&
      !orden.hasOwnProperty("documentacion_id")
    ) {
      id_document = 1;
      name_pago = "Contado";
      forma_pago_id = orden?.forma_pago_id;
    } else {
      id_document = orden?.documentacion_id;
      name_pago = orden?.forma_pago;
      forma_pago_id = orden?.forma_pago_id;
    }
    const name_forma_pago = name_pago.replace(" ", "");

    if (!nuevoObjeto.hasOwnProperty(data.rut_destinatario)) {
      nuevoObjeto[data.rut_destinatario] = {
        cliente: {
          rut: data.rut_destinatario,
          nombre: data.razon_social_destinatario,
        },
        forma_pagos: {
          [name_forma_pago]: {
            documentacions: {
              [id_document]: {
                ordenes: [],
                pagos: [],
              },
            },
          },
        },
      };
    }

    if (
      !nuevoObjeto[data.rut_destinatario].forma_pagos.hasOwnProperty(
        name_forma_pago
      )
    ) {
      nuevoObjeto[data.rut_destinatario].forma_pagos[name_forma_pago] = {
        documentacions: {},
      };
    }

    if (
      !nuevoObjeto[data.rut_destinatario].forma_pagos[
        name_forma_pago
      ].documentacions.hasOwnProperty(id_document)
    ) {
      nuevoObjeto[data.rut_destinatario].forma_pagos[
        name_forma_pago
      ].documentacions[id_document] = {
        ordenes: [],
        pagos: [],
        forma_pago_id: null,
        nro_documento: null,
      };
    }

    nuevoObjeto[data.rut_destinatario].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].ordenes.push(orden?.orden_flete_id);

    nuevoObjeto[data.rut_destinatario].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].forma_pago_id = forma_pago_id;

    nuevoObjeto[data.rut_destinatario].forma_pagos[
      name_forma_pago
    ].documentacions[id_document].nro_documento = orden?.nro_documento;

    if (name_forma_pago === "Porpagar") {
      nuevoObjeto[data.rut_destinatario].forma_pagos[
        name_forma_pago
      ].documentacions[id_document].pagos = data.pagos;
    }
  });

  return { entregas: nuevoObjeto };
};

import API from "config/config-api";
import {
  SEARCH_ORDEN_FECHA_CREACION,
  SEARCH_ORDEN_FECHA_ENTREGA,
  SEARCH_ORDEN_OFFICE,
  RESET_LISTADO_SOLUTIONS,
} from "types/solutionsOrdenType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const searchOrdenFechaCreacion =
  (body) => async (dispatch, getState) => {
    try {
      const { listOrdenesFechaCreacion } = getState().solutions;

      let response = await API.post(endPoints.solutions.search, body);
      const { status, message, data } = response.data;
      if (status) {
        let listado = listOrdenesFechaCreacion;
        if (data.estado.estado_id !== 5) {
          const orden = {
            id: data.id,
            fecha_orden: data.fecha_orden_format,
            oficina_origen: data.oficina_origen_name,
            sucursal_origen: data.sucursal_origen_name,
            oficina_destino: data.oficina_destino_name,
            sucursal_destino: data.sucursal_destino_name,
            numero_orden: data.numero_orden,
            total: data.total,
            fecha_orden_now: "",
            forma_pago_name: data.forma_pago_name,
            fecha_orden_format: data.fecha_orden,
          };

          const payload = {
            listOrdenesFechaCreacion: [...listado, orden],
          };

          dispatch(requestSuccess(SEARCH_ORDEN_FECHA_CREACION, payload));
        } else {
          return {
            status: false,
            message: "La orden de flete tiene estatus Anulada",
          };
        }
      }
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const saveDateUpdatedCreatedOrder =
  (body) => async (dispatch, getState) => {
    try {
      const data = body.map((item) => {
        return {
          numero_orden: item.numero_orden,
          fecha_orden_now: item.fecha_orden_now,
          type: "origen",
        };
      });

      let response = await API.post(endPoints.solutions.updateDateCreated, {
        ordenes: data,
      });
      const { status, message } = response.data;
      if (status) {
        const payload = {
          listOrdenesFechaCreacion: [],
        };

        dispatch(requestSuccess(SEARCH_ORDEN_FECHA_CREACION, payload));
      }
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const searchOrdenFechaEntrega = (body) => async (dispatch, getState) => {
  try {
    const { listOrdenesFechaEntrega } = getState().solutions;

    let response = await API.post(endPoints.solutions.searchDelivery, body);
    const { status, message, data } = response.data;
    if (status) {
      let listado = listOrdenesFechaEntrega;
      if (data.estado.estado_id === 6) {
        const orden = {
          id: data.id,
          fecha_orden: data.fecha_orden_format,
          fecha_entrega: data.fecha_entrega_format,
          oficina_destino: data.oficina_destino_name,
          sucursal_destino: data.sucursal_destino_name,
          numero_orden: body.numero_orden,
          total: data.total,
          fecha_entrega_now: "",
          forma_pago_name: data.forma_pago_name,
        };

        const payload = {
          listOrdenesFechaEntrega: [...listado, orden],
        };

        dispatch(requestSuccess(SEARCH_ORDEN_FECHA_ENTREGA, payload));
      } else {
        return {
          status: false,
          message,
        };
      }
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const saveDateUpdatedDeliveryOrden =
  (body) => async (dispatch, getState) => {
    try {
      const data = body.map((item) => {
        return {
          numero_orden: item.numero_orden,
          fecha_entrega_now: item.fecha_entrega_now,
        };
      });

      let response = await API.post(endPoints.solutions.updateDateDelivery, {
        ordenes: data,
      });
      const { status, message } = response.data;
      if (status) {
        const payload = {
          listOrdenesFechaEntrega: [],
        };

        dispatch(requestSuccess(SEARCH_ORDEN_FECHA_ENTREGA, payload));
      }
      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const searchOrdenOfficeOrder = (body) => async (dispatch, getState) => {
  try {
    const { listOrdenesOffice } = getState().solutions;

    let response = await API.post(endPoints.solutions.searchOffice, body);
    const { status, message, data } = response.data;

    if (status) {
      let listado = listOrdenesOffice;

      const orden = {
        id: data.id,
        fecha_orden: data.fecha_orden_format,
        oficina_origen: data.oficina_origen_name,
        sucursal_origen: data.sucursal_origen_name,
        oficina_destino: data.oficina_destino_name,
        sucursal_destino: data.sucursal_destino_name,
        numero_orden: data.numero_orden,
        total: data.total,
        type_office: "",
        sucursal_id_now: "",
        oficina_id_now: "",
        forma_pago_name: data.forma_pago_name,
      };

      const payload = {
        listOrdenesOffice: [...listado, orden],
      };

      dispatch(requestSuccess(SEARCH_ORDEN_OFFICE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const saveOfficeOrden = (body) => async (dispatch, getState) => {
  try {
    const data = body.map((item) => {
      return {
        numero_orden: item.numero_orden,
        sucursal_id_now: item.sucursal_id_now,
        oficina_id_now: item.oficina_id_now,
        type_office: item.type_office,
        change_data: true,
      };
    });

    let response = await API.post(endPoints.solutions.updateOffice, {
      ordenes: data,
    });
    const { status, message } = response.data;
    if (response.status) {
      const payload = {
        listOrdenesOffice: [],
      };

      dispatch(requestSuccess(SEARCH_ORDEN_OFFICE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const restListadoOrdenesSolutions = () => (dispatch, getState) => {
  const payload = {
    listOrdenesFechaCreacion: [],
    listOrdenesFechaEntrega: [],
    listOrdenesOffice: [],
  };

  dispatch(requestSuccess(RESET_LISTADO_SOLUTIONS, payload));
};

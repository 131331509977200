import { useState } from "react";

export const useTransportOrderOffice = () => {
  const [officeOrigin, setOfficeOrigin] = useState([]);
  const [officeDestination, setOfficeDestination] = useState([]);
  const [showIsModal, setShowIsModal] = useState(false);

  const loadOffices = (e, type, valor, listCities) => {
    const id = e !== undefined ? e.target.value : valor;
    if (id !== "") {
      const detOficinas = listCities.find((s) => s.id === parseInt(id));

      let offices =
        detOficinas && detOficinas.offices ? detOficinas.offices : [];

      let detOffices = offices;
      if (offices.length > 0) {
        detOffices = offices.filter((c) => c.active !== 0);
      }

      /**if (type === "origin") {
        setOfficeOrigin((officeOrigin) => detOffices);
      } else if (type === "destination") {
        setOfficeDestination((officeDestination) => detOffices);
      }**/
      return detOffices;
    }
  };

  const handleCloseModal = () => {
    setShowIsModal(false);
  };

  const showEstado = (event) => {
    event.preventDefault();
    setShowIsModal(true);
  };

  const actions = {
    showEstado,
    handleCloseModal,
    loadOffices,
    setShowIsModal,
    setOfficeOrigin,
    setOfficeDestination,
  };

  const state = {
    showIsModal,
    officeOrigin,
    officeDestination,
  };
  return {
    actions,
    state,
  };
};

import { useState } from "react";
import { Row, Form, Col, Button, Table } from "react-bootstrap";
import DetalleTablaMensual from "./DetalleTablaMensual";
import { useForm } from "react-hook-form";
import { addFacturacion } from "../../actions/facturacionAction";
import { useDispatch, useSelector } from "react-redux";
import download from "../../assets/images/download_small.png";
import Nuevo from "../../assets/images/configurar/plus.png";
import { downloadDocumento } from "../../utils/downloadFile";
import {
  validateOnlyNumber,
  handlePasteOnlyNumber,
} from "utils/validationInput";

const Mensual = ({ listOrdenes, toast, cliente }) => {
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const { companyDataBasic } = useSelector((state) => state.companies);
  const [isLoading, setIsLoading] = useState(false);
  const [emitida, setEmitida] = useState(false);
  const [result, setResult] = useState("");

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response = await dispatch(addFacturacion(data));

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      setEmitida(true);
      setResult({
        url: response.data.url,
        numero_documento: response.data.numero_folio,
      });
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }

    setIsLoading(false);
  };

  const handleDownload = (event) => {
    event.preventDefault();
    downloadDocumento(result);
  };

  return (
    <>
      <Form id="frmFacturacion" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h6 className="mt-3 mb-5">FACTURACIÓN DEL MES</h6>
            <Row>
              <Col lg={3}>
                <p className="pl-3 mr-2">
                  <span className="mr-2 font-weight-bold">RUT:</span>
                  <span>{cliente.rut}</span>
                </p>
              </Col>
              <Col lg={9}>
                <p className="pl-3 mr-2">
                  <span className="mr-2 font-weight-bold">Cliente:</span>
                  <span>{cliente.nombre}</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        {!companyDataBasic?.invoice && (
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-3">
              <Row>
                <Col lg={4}>
                  <Form.Group
                    as={Col}
                    controlId="numero_documento"
                    className="pl-3"
                  >
                    <Form.Label className="mr-2 font-weight-bold">
                      Número de documento
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="numero_documento"
                      ref={register({
                        required: { value: true, message: "Requerido" },
                      })}
                      onKeyPress={(event) => validateOnlyNumber(event)}
                      maxLength={25}
                      onPaste={(event) => handlePasteOnlyNumber(event)}
                    />
                    {errors.numero_documento && (
                      <Form.Text className="text-danger">
                        {errors?.numero_documento?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-5">
            <Table
              responsive
              size="sm"
              bordered
              id="table-orden"
              className="bg-gray-wran"
            >
              <thead>
                <tr>
                  <th style={{ width: "60px" }}>Número Orden</th>
                  <th style={{ width: "40px" }}>Fecha Orden</th>
                  <th style={{ width: "40px" }}>Fecha Entrega</th>
                  <th style={{ width: "60px" }}>Of. Origen</th>
                  <th style={{ width: "60px" }}>Of. Destino</th>
                  <th style={{ width: "60px" }}>Origen</th>
                  <th style={{ width: "60px" }}>Destino</th>
                  <th style={{ width: "60px" }}>Estado</th>
                  <th style={{ width: "40px" }}>Bultos</th>
                  <th style={{ width: "40px" }}>Kilos</th>
                  <th style={{ width: "60px" }}>Total</th>
                  <th style={{ width: "40px" }}>Entrega</th>
                </tr>
              </thead>
              <tbody>
                {listOrdenes.length > 0 ? (
                  listOrdenes.map((orden, index) => (
                    <DetalleTablaMensual key={index} {...orden} />
                  ))
                ) : (
                  <tr>
                    <td colSpan={11}>No hay registro disponibles</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right mt-3">
            <Form.Group as={Col}>
              {emitida ? (
                <>
                  {companyDataBasic?.invoice && (
                    <Button
                      variant="warning"
                      type="button"
                      className="mr-2"
                      onClick={(event) => handleDownload(event)}
                    >
                      <img src={download} alt="Descargar documento" />
                    </Button>
                  )}
                  <Button variant="warning" href="/invoicing">
                    <img
                      src={Nuevo}
                      alt="Crear nueva factura"
                      className="btn-nuevo-guia"
                    />
                  </Button>
                </>
              ) : (
                <Button
                  variant="warning"
                  type="submit"
                  disabled={isLoading}
                  className="pl-5 pr-5"
                >
                  {isLoading ? "Procesando..." : "Guardar"}
                </Button>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Mensual;

import {
  LISTAR_DATA_GUIA,
  ADD_ORDENES_GUIA,
  ADD_DESCUENTO_RECARGA,
  CREAR_GUIA_DESPACHO,
  LISTADO_GUIA_DESPACHO,
  CARGANDO_DATA,
  UPDATE_ORDENES_GUIA,
} from "types/guiaDespachoType";

const INITIAL_STATE = {
  traslados: [],
  despachos: [],
  sucursales: [],
  conductores: [],
  patentes: [],
  forma_pagos: [],
  listado_totales: {
    ordenes: [],
    subtotal: 0,
    total_neto: 0,
    total_iva: 0,
    totals: 0,
  },
  descuento_global: 0,
  recargo_global: 0,
  data_descuento: {},
  data_recargo: {},
  data_guia_despacho: {},
  listado_guia: [],
  cargandoData: false,
};

const guiaDespachoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTAR_DATA_GUIA:
      return {
        ...state,
        traslados: action.payload.traslados,
        despachos: action.payload.despachos,
        forma_pagos: action.payload.forma_pagos,
      };
    case ADD_ORDENES_GUIA:
      return {
        ...state,
        listado_totales: action.payload,
      };
    case ADD_DESCUENTO_RECARGA:
      return {
        ...state,
        listado_totales: action.payload.listado_totales,
        descuento_global: action.payload.descuento_global,
        recargo_global: action.payload.recargo_global,
        data_recargo: action.payload.data_recargo,
        data_descuento: action.payload.data_descuento,
      };
    case CREAR_GUIA_DESPACHO:
      return {
        ...state,
        data_guia_despacho: action.payload,
      };
    case LISTADO_GUIA_DESPACHO:
      return {
        ...state,
        listado_guia: action.payload,
        cargandoData: false,
      };
    case CARGANDO_DATA:
      return {
        ...state,
        cargandoData: action.payload,
      };
    case UPDATE_ORDENES_GUIA:
      return {
        ...state,
        listado_totales: action.payload,
        descuento_global: action.payload.descuento_global,
        recargo_global: action.payload.recargo_global,
        data_recargo: action.payload.data_recargo,
        data_descuento: action.payload.data_descuento,
      };
    default:
      return state;
  }
};

export default guiaDespachoReducer;

import React, { Fragment } from "react";
import { Form, Col, Table } from "react-bootstrap";
import TbodyOrden from "./TbodyOrden";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEntregarOrden,
  totalMontoSeleccion,
} from "actions/transportOrdersAction";

const TableOrden = ({ register, toast, control }) => {
  const dispatch = useDispatch();

  const { metodo_pago } = useSelector((state) => state.metodoPagos);

  const { listOrdenEntregar, documentacionsPago } = useSelector(
    (state) => state.transportOrders
  );

  const changeTotales = async (event, orden_flete_id) => {
    const param = event.target.name.split(".")[1];
    const valor = event.target.checked;
    dispatch(updateEntregarOrden(orden_flete_id, param, valor));
    dispatch(totalMontoSeleccion());
  };

  return (
    <Fragment>
      <Form.Row>
        <Col xs={12}>
          <Form.Group as={Col}>
            <Table bordered size="sm" responsive className="table-global mb-0">
              <thead className="bg-warn-blue">
                <tr>
                  <th>N° de Orden</th>
                  <th>Forma de Pago</th>
                  <th>Total</th>
                  <th>Documento</th>
                  <th>N° de Documento</th>
                  <th>RUT</th>
                  <th>Nombre</th>
                  <th>Método de Pago</th>
                  <th>N° Operación</th>
                </tr>
              </thead>
              <tbody>
                {listOrdenEntregar?.length > 0 ? (
                  listOrdenEntregar?.map((orden, index) => {
                    return (
                      <TbodyOrden
                        key={index}
                        index={index}
                        {...orden}
                        register={register}
                        documentacions={documentacionsPago?.filter(
                          (item) => item.description !== "Boleta-1"
                        )}
                        changeTotales={changeTotales}
                        metodo_pago={metodo_pago}
                        toast={toast}
                        control={control}
                      />
                    );
                  })
                ) : (
                  <tr className="tr-vacia">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Form.Group>
        </Col>
      </Form.Row>
    </Fragment>
  );
};

export default TableOrden;

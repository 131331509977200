import React from "react";

const TbodyEstado = ({
  descripcion,
  user,
  fecha,
  nomina,
  observaciones,
  indice,
}) => {
  return (
    <tr>
      <td>{indice + 1}</td>
      <td>{descripcion}</td>
      <td>{user}</td>
      <td>{fecha}</td>
      <td>{observaciones}</td>
    </tr>
  );
};

export default TbodyEstado;

import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import { useDepositAccount } from "hooks/Admin/DepositAccount/useDepositAccount";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/DepositAccounts/Filter";
import depositAccount from "components/Admin/Columnas/DepositAccount";

const ListTrucks = () => {
  const { state, actions } = useDepositAccount();
  const { accountIsLoading } = state;
  const { getAll, filteredItems, handleRowClicked } = actions;

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_deposit">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de cuentas depósito">
          <DataTableCustomer
            columns={depositAccount.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={accountIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListTrucks;

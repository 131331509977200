import API from "config/config-api";
import {
  LIST_DATA_COUNTRY,
  SELECTED_ROWS_COUNTRY,
  DATA_ACTIVE_COUNTRY,
  LOADING_COUNTRY,
} from "types/admin/countryType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_COUNTRY, payload));
};

export const getListCountry = (typeFilter) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.country.list(typeFilter));
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_COUNTRY, data));
    return { status: status, data: data, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addCountry = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.country.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_COUNTRY, payload));
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listCountries, countryData } = getState().countries;

    const body = {
      type: "status",
      option: countryData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.country.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = listCountries.map((country) => {
        if (country.id === id) {
          return { ...country, active: !countryData?.active };
        }
        return country;
      });

      const payload = {
        data: {
          ...countryData,
          active: !countryData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(DATA_ACTIVE_COUNTRY, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteCountry = (id) => async (dispatch, getState) => {
  try {
    const { listCountries } = getState().countries;

    let response = await API.delete(endPoints.country.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: [...listCountries].filter((country) => country.id !== id),
        data: null,
      };

      dispatch(requestSuccess(DATA_ACTIVE_COUNTRY, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchCountryById = (countryId) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.country.getOne(countryId));

    const { status, message, data } = response.data;
    const payload = {
      data: data !== null ? data : null,
    };
    dispatch(requestSuccess(SELECTED_ROWS_COUNTRY, payload));

    return { status, message, data };
  } catch (error) {
    return { status: false, message: error };
  }
};

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editRole, addRole, searchRoleById } from "actions/admin/roleAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const permissionsNotGlobal = [
  "configurar_casa_matriz",
  "configurar_paises",
  "configurar_roles",
];

export const useFormRole = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, getValues, setValue, reset } =
    useForm({
      defaultValues: {
        name: "",
        permissions: [],
      },
    });
  const [isLoading, setIsLoading] = useState(false);
  const [visibleRol, setVisibleRol] = useState("no");
  const [dataRol, setDataRol] = useState(null);
  const { listPermissions, rolData } = useSelector((state) => state.roles);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    const cantPermissions = data.permissions.filter(
      (permiso) => permiso !== false
    );
    if (cantPermissions.length > 0) {
      let response;
      const body = {
        name: data.name,
        permissions: cantPermissions,
        visible_other_companies: visibleRol === "no" ? false : true,
      };

      if (dataRol !== null) {
        response = await dispatch(editRole(body, dataRol?.id));
      } else {
        response = await dispatch(addRole(body));
      }

      if (response.status) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });

        setIsLoading(false);
        setTimeout(() => {
          history.push("/admin/roles");
        }, 1000);
      } else {
        setIsLoading(false);
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } else {
      setIsLoading(false);
      toast.error("Debe asignar los permisos", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validPermissionAdmin = () => {
    let { permissions } = getValues();

    if (permissions) {
      const updatePermission = [...permissions].map((item) => {
        if (item !== false && permissionsNotGlobal.includes(item)) {
          return false;
        }
        return item;
      });
      setValue("permisos", updatePermission);
    }
  };

  const validatePermission = (event) => {
    const { value, checked } = event.target;

    if (visibleRol === "si" && checked) {
      if (permissionsNotGlobal.includes(value)) {
        event.target.checked = false;
        toast.error(
          "Este módulo no esta permitido para visualizarse en las demás casas matrices",
          { position: toast.POSITION.TOP_RIGHT }
        );
        return false;
      }
    }
  };

  const getRolById = async (roleId) => {
    let data = rolData;

    if (roleId !== null) {
      if (rolData === null) {
        const result = await dispatch(searchRoleById(roleId));
        data = result?.data;
      }
    }

    reset({
      name: roleId !== null ? data?.name ?? "" : "",
    });

    setDataRol(roleId !== null ? data ?? null : null);

    if (data !== null) {
      const visible = data?.visible_other_companies
        ? data?.visible_other_companies
          ? "si"
          : "no"
        : "no";
      setVisibleRol(visible);
    }
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };

  const actions = {
    validPermissionAdmin,
    validatePermission,
    listPermissions,
    setVisibleRol,
    getRolById,
  };

  const state = {
    visibleRol,
    dataRol,
  };

  return {
    formElement,
    actions,
    state,
  };
};

import {
  LIST_DATA_ACTIVITY,
  SELECTED_ROWS_ACTIVITY,
  DATA_ACTIVE_ACTIVITY,
  LOADING_ACTIVITY,
} from "types/admin/activityType";

const INITIAL_STATE = {
  listActivities: [],
  activityIsLoading: true,
  activityData: null,
};

const activityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ACTIVITY:
      return {
        ...state,
        activityIsLoading: action.payload.loading,
        activityData: action.payload.data,
      };
    case LIST_DATA_ACTIVITY:
      return {
        ...state,
        listActivities: action.payload,
        activityIsLoading: false,
      };
    case SELECTED_ROWS_ACTIVITY:
      return {
        ...state,
        activityData: action.payload.data,
      };
    case DATA_ACTIVE_ACTIVITY:
      return {
        ...state,
        activityData: action.payload.data,
        listActivities: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default activityReducer;

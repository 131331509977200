import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/DepositAccounts/Formulario";
import { useParams } from "react-router-dom";

const EditTruck = () => {
  const { accountId } = useParams();

  return (
    <Layout titlePanel="Administrar" modulo="administrar_deposit">
      <div className="pl-3 pr-3">
        <CardRetiro title="Editar cuenta de depósito">
          <Formulario accountId={accountId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default EditTruck;

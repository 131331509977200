import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Companies/Formulario";
import { useParams } from "react-router-dom";

const EditCompany = () => {
  const { companyId } = useParams();
  return (
    <Layout titlePanel="Administrar" modulo="administrar_company">
      <div className="pl-3 pr-3">
        <CardRetiro title="Editar casa matriz">
          <Formulario companyId={companyId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default EditCompany;

import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

registerLocale("es", es);
const Viaje = ({
  index,
  item,
  camiones,
  conductores,
  addConductor,
  setValue,
  register,
  toast,
  removeConductor,
  isActiveNomina,
  onChangeCamion,
  onChangeConductor,
  conductorAll,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setValue(`conductor_id[${index}]`, item?.conductor_id ?? "");
    setValue(`camion_id[${index}]`, item?.camion_id ?? "");
    let fecha = new Date(item?.fecha_viaje);

    fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset());

    setStartDate(fecha);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Fragment>
      <Form.Row>
        <Col className="mb-0">
          <Form.Group
            as={Col}
            controlId={`fecha_viaje[${index}]`}
            className="pr-0 mb-0"
          >
            <Form.Label>Fecha Viaje</Form.Label>
            <DatePicker
              type="text"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="form-control"
              name={`fecha_viaje[${index}]`}
              id="fecha_viaje"
              ref={register}
              readOnly
              locale="es"
            />
          </Form.Group>
        </Col>
        <Col className="mb-0">
          <Form.Group
            as={Col}
            controlId={`camion_id[${index}]`}
            className="pr-1 pl-0 mb-0"
          >
            <Form.Label>Camión</Form.Label>
            <Form.Control
              as="select"
              name={`camion_id[${index}]`}
              defaultValue={item.camion_id || ""}
              ref={register}
              disabled={index > 0 ? true : false}
              onChange={(event) => onChangeCamion(event, index)}
            >
              <option value="">Seleccione</option>
              {camiones.length > 0 &&
                camiones.map((camion) => (
                  <option key={camion.id} value={camion.id}>
                    {camion.plate_number}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="mb-0">
          <Form.Group
            as={Col}
            controlId={`conductor_id[${index}]`}
            className="pr-1 pl-0 mb-0"
          >
            <Form.Label>Conductor</Form.Label>
            <Form.Control
              as="select"
              name={`conductor_id[${index}]`}
              defaultValue={item.conductor_id || ""}
              ref={register}
              onChange={(event) => onChangeConductor(event, index)}
            >
              <option value="">Seleccione</option>
              {conductorAll[index]?.list?.length > 0 &&
                conductorAll[index]?.list?.map((conductor) => (
                  <option key={conductor.user_id} value={conductor.user_id}>
                    {conductor.nombre}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="mb-0">
          <Form.Group
            as={Col}
            controlId={`destino_id[${index}]`}
            className="pr-1 pl-0 mb-0"
          >
            <Form.Label>Destino</Form.Label>
            <Form.Control
              type="text"
              name={`destino_id[${index}]`}
              defaultValue={item.destino_id || ""}
              ref={register}
              readOnly
            />
          </Form.Group>
        </Col>
        <Col className="mb-0">
          {item.initial_btn ? (
            <Form.Group as={Col} className="mb-0">
              <Button
                type="button"
                variant="outline-primary"
                className="mt-4 ml-2 btn-sm btn-block"
                onClick={() => addConductor()}
                toast={toast}
              >
                + Conductores
              </Button>
              <small className="form-text text-muted text-center">
                * No más de 2 conductores
              </small>
            </Form.Group>
          ) : (
            !isActiveNomina && (
              <Form.Group as={Col} className="mb-0 w-100">
                <Button
                  type="button"
                  variant="danger"
                  className="mt-4 btn-sm btn-block"
                  onClick={() => removeConductor(index)}
                  toast={toast}
                >
                  Eliminar
                </Button>
              </Form.Group>
            )
          )}
        </Col>
      </Form.Row>
    </Fragment>
  );
};

export default Viaje;

import { generateDoc } from "../../actions/transportOrdersAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useDocumentBoleta = (
  loadingPago,
  setLoadingPago,
  getDatInitial
) => {
  const dispatch = useDispatch();

  const generateDocumento = async (e, data, setValue) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Seguro desea generar el documento de pago?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPago(true);
        const body = {
          numero_orden: data?.order_number,
          documentacion_id: parseInt(data?.documentacion_id_pago),
          rut_remitente: data?.rut_remitente,
          factura: {},
        };
        let response = await dispatch(generateDoc(body));
        if (response.status) {
          setValue("numero_documento", response.numero_documento);
          /**const dataAssign = {
            fecha_entrega: dataTransportOrder.fecha_entrega_orden,
            ordens: [dataTransportOrder.ordenFleteId],
          };
          await dispatch(assignOrdenCaja(dataAssign));**/

          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getDatInitial(data?.order_number);
        } else {
          toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
        }
        setLoadingPago(false);
      }
    });
  };

  return {
    generateDocumento,
  };
};

import { toast } from "react-toastify";
import { isAfter, getDateYMD } from "utils/formatFecha";
import dayjs from "dayjs";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";

registerLocale("es", es);

export const useDateOrder = () => {
  const validateDateBlur = (props, value, id) => {
    const { message, error, dateSelected } = getDateYMD(
      document.querySelector(id).value
    );

    if (error) {
      toast.error(message, { position: toast.POSITION.TOP_RIGHT });
      props.onChange(new Date());
      return false;
    }

    if (isAfter(dateSelected, dayjs())) {
      toast.error(
        "La fecha ingresada no puede ser superior a la fecha actual",
        { position: toast.POSITION.TOP_RIGHT }
      );
      props.onChange(new Date());
      return false;
    }

    const yearLast = dayjs(dateSelected).year();
    const yearCurrent = dayjs().year();

    if (yearCurrent !== yearLast) {
      toast.error("No puede seleccionar fechas anteriores", {
        position: toast.POSITION.TOP_RIGHT,
      });
      props.onChange(new Date());
      return false;
    }
  };

  const restMounth = () => {
    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
  };

  return {
    validateDateBlur,
    restMounth,
  };
};

import dashboardImg from "assets/images/menu/dashboard.png";
import shopping_list from "assets/images/menu/shopping_list.png";
import nomina_v1 from "assets/images/menu/nomina_v1.png";
import package_receive_v1 from "assets/images/menu/package_receive_v1.png";
import guide_v1 from "assets/images/menu/guide_v1.png";
import delivery_v1 from "assets/images/menu/delivery_v1.png";
import calculator_v1 from "assets/images/menu/calculator_v1.png";
import document_v1 from "assets/images/menu/document_v1.png";
import boxes_v1 from "assets/images/menu/boxes_v1.png";
import manager_v1 from "assets/images/menu/manager_v1.png";

export const listMenuOriginal = [
  {
    id: 1,
    title: "Dashboard",
    href: "/home",
    icon: dashboardImg,
    module: ["dashboard"],
    children: [],
    permission: true,
    permissionGroup: ["crear_orden_flete"],
    enabled: true,
  },
  {
    id: 2,
    title: "Órdenes",
    href: "/home",
    icon: shopping_list,
    enabled: true,
    module: [
      "orders_create",
      "orders_search",
      "orden_receive",
      "orden_incomplete",
      "solutions",
    ],
    children: [
      {
        id: 11,
        title: "Orden de flete manual",
        href: "/transport-orders/create",
        module: "orders_create",
        initial: "/transport-orders",
        permission: false,
        permissionGroup: ["crear_orden_flete"],
      },
      {
        id: 12,
        title: "Consulta órdenes",
        href: "/transport-orders/search",
        initial: "",
        module: "orders_search",
        permission: false,
        permissionGroup: ["consultar_ordenes_fletes"],
      },
      {
        id: 13,
        title: "Órdenes recibidas",
        href: "/transport-orders/received",
        module: "orden_receive",
        initial: "",
        permission: false,
        permissionGroup: ["ordenes_recibidas"],
      },
      {
        id: 14,
        title: "Órdenes incompletas",
        href: "/transport-orders/incomplete",
        module: "orden_incomplete",
        initial: "",
        permission: false,
        permissionGroup: ["ordenes_recibidas"],
      },
      {
        id: 15,
        title: "Edición órdenes",
        href: "/transport-orders/solution",
        module: "solutions",
        permission: false,
        permissionGroup: [
          "edicion_orden_fecha_creacion",
          "edicion_orden_oficina",
          "edicion_orden_fecha_entrega",
        ],
      },
    ],
    permission: false,
    permissionGroup: [
      "crear_orden_flete",
      "consultar_ordenes_fletes",
      "ordenes_recibidas",
      "edicion_orden_fecha_creacion",
      "edicion_orden_oficina",
      "edicion_orden_fecha_entrega",
    ],
  },
  {
    id: 3,
    title: "Crear nomina",
    href: "/payrolls/create",
    icon: nomina_v1,
    module: ["crear_nomina"],
    enabled: true,
    children: [],
    permission: false,
    permissionGroup: ["crear_nomina"],
  },
  {
    id: 4,
    title: "Recibir bultos",
    href: "/transport-orders/package",
    icon: package_receive_v1,
    module: ["receive_ticket"],
    enabled: true,
    children: [],
    permission: false,
    permissionGroup: ["crear_nomina"],
  },
  {
    id: 5,
    title: "Guía despacho",
    href: "/guide",
    icon: guide_v1,
    module: ["guia_despacho"],
    children: [],
    permission: false,
    permissionGroup: ["guia_despacho"],
    enabled: true,
  },
  {
    id: 6,
    title: "Entregar / Devolver",
    href: "/transport-orders/deliver",
    icon: delivery_v1,
    module: ["entregar_devolver"],
    children: [],
    permission: false,
    permissionGroup: [
      "entregar_orden",
      "devolver_orden",
      "factura_a_credito",
      "ordenes_por_pagar",
    ],
    enabled: true,
  },
  {
    id: 7,
    title: "Rendición de caja",
    href: "/cash-accountability",
    icon: calculator_v1,
    module: ["cerrar_caja"],
    children: [],
    permission: false,
    permissionGroup: ["cerrar_caja"],
    enabled: true,
  },
  {
    id: 8,
    title: "Facturas / Pagos",
    href: "/home",
    icon: document_v1,
    module: [
      "facturacion_cliente",
      "busqueda_factura_corriente",
      "crear_pagos_factura",
    ],
    children: [
      {
        id: 81,
        title: "Facturación cliente",
        href: "/invoicing",
        module: "facturacion_cliente",
        permission: false,
        permissionGroup: ["facturacion_cliente"],
      },
      {
        id: 82,
        title: "Buscar facturas",
        href: "/invoicing/search",
        module: "busqueda_factura_corriente",
        permission: false,
        permissionGroup: ["busqueda_factura_corriente"],
      },
      {
        id: 83,
        title: "Registrar pagos",
        href: "/invoicing/create-payment",
        module: "crear_pagos_factura",
        permission: false,
        permissionGroup: ["crear_pagos_factura"],
      },
    ],
    permission: false,
    permissionGroup: [
      "facturacion_cliente",
      "busqueda_factura_corriente",
      "crear_pagos_factura",
    ],
  },
  {
    id: 9,
    title: "Retiros",
    href: "/home",
    icon: boxes_v1,
    module: [
      "create-retiro",
      "consulta-retiros",
      "asignar-retiros",
      "nominar-retiro",
    ],
    children: [
      {
        id: 91,
        title: "Crear retiro",
        href: "/withdrawals/create",
        module: "create-retiro",
        permissionGroup: ["crear_retiro"],
      },
      {
        id: 92,
        title: "Consultas retiro",
        href: "/withdrawals/search",
        module: "consulta-retiros",
        permissionGroup: ["consulta_retiro"],
      },
      {
        id: 93,
        title: "Asignar vehículo retiro",
        href: "/withdrawals/assign-vehicle",
        module: "asignar-retiros",
        permissionGroup: ["asignar_retiro"],
      },
      {
        id: 94,
        title: "Rebaja de retiro",
        href: "/withdrawals/nominate",
        module: "nominar-retiro",
        permissionGroup: ["nomina_retiro"],
      },
    ],
    permission: false,
    permissionGroup: [
      "crear_retiro",
      "consulta_retiro",
      "asignar_retiro",
      "nomina_retiro",
    ],
  },
  {
    id: 10,
    title: "Administrar",
    href: "/admin",
    icon: manager_v1,
    module: [
      "administrar_roles",
      "administrar_trucks",
      "administrar_deposit",
      "administrar_customers",
      "administrar_users",
      "administrar_drives",
      "administrar_countries",
      "administrar_cities",
      "administrar_company",
      "administrar_taxes",
    ],
    enabled: true,
    children: [
      {
        id: 95,
        title: "Roles",
        href: "/admin/roles",
        module: "administrar_roles",
        permissionGroup: ["configurar_roles"],
      },
      {
        id: 96,
        title: "Usuarios",
        href: "/admin/users",
        module: "administrar_users",
        permissionGroup: ["configurar_usuarios"],
      },
      {
        id: 97,
        title: "Países",
        href: "/admin/countries",
        module: "administrar_countries",
        permissionGroup: ["configurar_paises"],
      },
      {
        id: 98,
        title: "Casa Matriz",
        href: "/admin/companies",
        module: "administrar_company",
        permissionGroup: ["configurar_casa_matriz"],
      },
      {
        id: 99,
        title: "Sucursales",
        href: "/admin/cities",
        module: "administrar_cities",
        permissionGroup: ["configurar_sucursales"],
      },
      {
        id: 100,
        title: "Clientes",
        href: "/admin/customers",
        module: "administrar_customers",
        permissionGroup: ["configurar_cliente"],
      },
      {
        id: 101,
        title: "Placas",
        href: "/admin/trucks",
        module: "administrar_trucks",
        permissionGroup: ["configurar_placas"],
      },
      {
        id: 102,
        title: "Cuentas depósitos",
        href: "/admin/deposit-accounts",
        module: "administrar_deposit",
        permissionGroup: ["configurar_ctas_depositos"],
      },
      {
        id: 103,
        title: "Conductores",
        href: "/admin/users/drives",
        module: "administrar_drives",
        permissionGroup: ["configurar_conductores"],
      },
      {
        id: 104,
        title: "Correlativos",
        href: "/admin/correlatives",
        module: "administrar_correlatives",
        permissionGroup: ["configurar_correlativos"],
      },
      {
        id: 105,
        title: "Impuestos",
        href: "/admin/taxes",
        module: "administrar_taxes",
        permissionGroup: ["configurar_impuesto"],
      },
    ],
    permission: false,
    permissionGroup: [
      "configurar_roles",
      "configurar_usuarios",
      "configurar_sucursales",
      "configurar_cliente",
      "configurar_placas",
      "configurar_ctas_depositos",
      "configurar_conductores",
      "configurar_correlativos",
      "configurar_casa_matriz",
      "configurar_paises",
      "configurar_impuesto",
    ],
  },
];

import { useEffect } from "react";
import { Row, Form, Col, Button, Card } from "react-bootstrap";
import {
  validateOnlyLetter,
  handlePasteOnlyLetter,
} from "utils/validationInput";
import { useFormParentCity } from "hooks/Admin/ParentCities/useFormParentCity";
import { Link } from "react-router-dom";

const Formulario = ({ toast, datos, ciudades, parentCityId, countryId }) => {
  const { actions, formElement } = useFormParentCity(countryId);

  const { isLoading, register, handleSubmit, errors, onSubmit } = formElement;

  const { getParentCityById } = actions;

  useEffect(() => {
    getParentCityById(parentCityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentCityId]);

  return (
    <Form id="frmSucursal" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Form.Group controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  autoFocus
                  defaultValue={datos?.name || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                    minLength: { value: 5, message: "Mínimo 5 caracteres" },
                  })}
                  onKeyPress={(event) => validateOnlyLetter(event)}
                  onPaste={(event) => handlePasteOnlyLetter(event)}
                  maxLength={30}
                  className="text-uppercase"
                />
                {errors.name && (
                  <Form.Text className="text-danger">
                    {errors?.name?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to={`/admin/countries/${countryId}/parent-cities`}>
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading
                ? "Procesando..."
                : parentCityId !== null
                ? "Modificar"
                : "Guardar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default Formulario;

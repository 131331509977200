import React, { useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import Sucursales from "components/Orders/Sucursales";
import Clientes from "components/Orders/Clientes/Principal";
import DetalleCarga from "components/Orders/Carga/DetalleCarga";
import Observaciones from "components/Orders/Observaciones";
import Pagos from "components/Orders/Pagos";
import ModalSpinner from "components/Custom/Spinner/ModalSpinner";
import ModalOptionImpresion from "components/Orders/ModalOptionImpresion";
import { validarPermiso } from "utils/Permission/checkPermission";
import ModalAnular from "components/Orders/ModalAnular";
import ModalNota from "components/Orders/NotaCredito/ModalNota";
import ModalDocumentPayment from "components/Orders/ModalDocumentPayment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ModalFactura from "components/Factura/ModalFactura";
import { useEditTransportOrder } from "hooks/Orders/useEditTransportOrder";

const EditOrdenFlete = () => {
  const { order_number } = useParams();
  const { actions, formElement, state } = useEditTransportOrder();

  const {
    isLoadingInitial,
    companyDataBasic,
    dataTransportOrder,
    showModalPrint,
    isLoading,
    configuracion,
    hidden,
    titleModulo,
    nameModulo,
    openForm,
    loadingPago,
    showIsModal,
  } = state;
  const { register, handleSubmit, errors, onSubmit, getValues, setValue } =
    formElement;
  //const { onSubmit, getDatInitial, handleClosePrint } = actions;
  const {
    getDatInitial,
    handleClosePrint,
    handleShow,
    anularOrden,
    editOrdenAction,
    handleCloseModalPayment,
    setLoadingPago,
    handleGenerateModalNota,
    setShowIsModal,
    handleCloseModal,
    handleNotaCredito,
    getListParent,
    setOpenForm,
  } = actions;

  useEffect(() => {
    getDatInitial(order_number);
    register(
      { name: "cargas" },
      {
        required: { value: true, message: "Mínimo 1 detalle de carga" },
        validate: (value) => {
          return Array.isArray(value) ? value.length > 0 : !!value;
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_number]);

  useEffect(() => {
    getListParent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDataBasic]);

  const visibleBtnEdit = () => {
    if (
      dataTransportOrder?.estado_orden !== "Nulas" &&
      dataTransportOrder?.estado_orden !== "Migración Reine" &&
      dataTransportOrder?.estado_orden !== "Pendiente de retiro" &&
      dataTransportOrder?.estado_orden !== "Recibido en oficina destino" &&
      dataTransportOrder?.estado_orden !== "Vehículo asignado a retiro" &&
      dataTransportOrder?.estado_orden !== "Encomienda retirada" &&
      dataTransportOrder?.estado_orden !==
        "Encomienda no retirada por observación" &&
      dataTransportOrder?.estado_orden !== "Retiro aplazado" &&
      dataTransportOrder?.estado_orden !== "Pendiente" &&
      dataTransportOrder?.estado_orden !== "Asignado" &&
      dataTransportOrder?.estado_orden !== "Aplazado al dia siguiente" &&
      dataTransportOrder?.estado_orden !== "Realizado" &&
      dataTransportOrder?.estado_orden !== "Completado" &&
      dataTransportOrder?.estado_orden !== "No Realizado" &&
      dataTransportOrder?.estado_orden !== "Anulado" &&
      dataTransportOrder?.estado_orden !== "Finalizado"
    ) {
      return true;
    }
    return false;
  };

  const visibleBtnAnular = () => {
    if (dataTransportOrder?.estado_orden === "Nulas") {
      return true;
    }
    let disabledBtnAnular = !validarPermiso(
      configuracion,
      "anular_orden_flete"
    );

    return disabledBtnAnular;
  };

  return (
    <Layout
      titlePanel="Orden de flete manual"
      isIcon={false}
      ruta="home"
      modulo="orders_create"
    >
      <Form id="frmOrders" onSubmit={handleSubmit(onSubmit)}>
        <Sucursales
          formElement={formElement}
          stateOrden={state}
          initial={false}
          editOrden={true}
          actionsGlobal={actions}
        />
        <Clientes
          formElement={formElement}
          editOrden={true}
          stateOrden={state}
          companyDataBasic={companyDataBasic}
        />
        <DetalleCarga
          formElement={formElement}
          stateOrden={state}
          actionsOrden={actions}
          addOrden={false}
        />
        <Pagos
          formElement={formElement}
          actionsOrden={actions}
          stateOrden={state}
          editOrden={true}
          getDatInitial={getDatInitial}
        />
        <Observaciones register={register} errors={errors} />

        <Form.Row className="mb-5 ml-2 mr-2 mt-3">
          <Col md={9} sm={12} className="btn-group-orden">
            {companyDataBasic?.invoice &&
              (dataTransportOrder?.nota_creditos?.length > 0 ||
                (dataTransportOrder?.numero_documento?.length > 0 &&
                  dataTransportOrder?.numero_documento !== "B-1")) && (
                <Button
                  variant="outline-warning"
                  className="btn-orden mr-2"
                  type="button"
                  block
                  onClick={() => handleGenerateModalNota()}
                  disabled={false}
                >
                  NOTAS DE CRÉDITOS
                </Button>
              )}
            {dataTransportOrder?.estado_orden !== "Pendiente tarifario" && (
              <Button
                variant="outline-warning"
                className="btn-orden mr-2 mt-0"
                type="button"
                block
                onClick={() => handleShow()}
                disabled={visibleBtnAnular()}
              >
                ANULAR ORDEN
              </Button>
            )}
            <Button
              variant="outline-warning"
              className="btn-orden mr-2 mt-0"
              type="button"
              block
              onClick={(event) => handleClosePrint(event)}
            >
              IMPRIMIR ORDEN
            </Button>
            <a
              href="/transport-orders/create"
              className="btn btn-outline-warning btn-block btn-orden mr-1 mt-0"
              type="button"
            >
              CREAR NUEVA ORDEN
            </a>
          </Col>
          {visibleBtnEdit() && (
            <Col md={3} sm={12}>
              <Button
                className="mt-1"
                variant="warning"
                type="submit"
                block
                disabled={isLoading}
              >
                {isLoading ? "Procesando..." : "EDITAR"}
              </Button>
            </Col>
          )}
        </Form.Row>
      </Form>

      {hidden && (
        <ModalAnular
          title={titleModulo}
          nameModulo={nameModulo}
          stateOrder={state}
          actionsOrder={actions}
          funcFactura={anularOrden}
        />
      )}

      {hidden &&
      (!companyDataBasic?.invoice ||
        dataTransportOrder?.numero_documento?.length === 0 ||
        dataTransportOrder?.numero_documento === "B-1") ? (
        <ModalAnular
          title={titleModulo}
          nameModulo={nameModulo}
          stateOrder={state}
          actionsOrder={actions}
          funcFactura={anularOrden}
        />
      ) : (
        <ModalFactura
          title={titleModulo}
          nameModulo={nameModulo}
          stateOrder={state}
          actionsOrder={{
            ...actions,
            addOrdenAction:
              nameModulo === "orden" ? editOrdenAction : anularOrden,
          }}
        />
      )}

      {showIsModal && (
        <ModalNota
          showIsModal={showIsModal}
          setShowIsModal={setShowIsModal}
          handleCloseModal={handleCloseModal}
          listado={dataTransportOrder?.nota_creditos}
          handleNotaCredito={handleNotaCredito}
          documento={document.getElementById("numero_documento").value}
          disabledBtnAnular={false} //disabledBtnAnular}
        />
      )}

      {openForm && (
        <ModalDocumentPayment
          hidden={openForm}
          handleClose={handleCloseModalPayment}
          toast={toast}
          data={getValues()}
          loadingPago={loadingPago}
          setLoadingPago={setLoadingPago}
          setOpenForm={setOpenForm}
          setValue={setValue}
          getDatInitial={getDatInitial}
        />
      )}

      {showModalPrint && (
        <ModalOptionImpresion
          showModal={showModalPrint}
          handleClose={handleClosePrint}
          numero_orden={dataTransportOrder.order_number}
          isView={
            dataTransportOrder?.estado_orden !== "Migración Reine"
              ? true
              : false
          }
        />
      )}

      {isLoadingInitial && (
        <ModalSpinner
          spinnerShow={isLoadingInitial}
          description="Consultando la orden de flete..."
        />
      )}
    </Layout>
  );
};

export default EditOrdenFlete;

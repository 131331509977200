import {
  LIST_DATA_OFFICE,
  SELECTED_ROWS_OFFICE,
  ACTIVE_OFFICE,
  LOADING_OFFICE,
} from "types/admin/officeType";

const INITIAL_STATE = {
  listOffices: [],
  officeIsLoading: true,
  officeData: null,
};

const officeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_OFFICE:
      return {
        ...state,
        officeIsLoading: action.payload.loading,
        officeData: action.payload.data,
      };
    case LIST_DATA_OFFICE:
      return {
        ...state,
        listOffices: action.payload,
        officeIsLoading: false,
      };
    case SELECTED_ROWS_OFFICE:
      return {
        ...state,
        officeData: action.payload.data,
      };
    case ACTIVE_OFFICE:
      return {
        ...state,
        officeData: action.payload.data,
        listOffices: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default officeReducer;

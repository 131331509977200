import API from "config/config-api";
import {
  LIST_DATA_INICIO,
  UPDATED_TITLE_BOTON,
  SEARCH_NOMINA,
  CLEAR_NOMINA_STATUS,
  SAVE_NOMINA,
  CHANGE_IMPRESION,
} from "types/nominaType";
import { validarPermiso } from "../utils/Permission/checkPermission";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getListPayrollAll = () => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.payroll.list);
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LIST_DATA_INICIO, data));
    return { status: status, data: data, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchNomina = (numero_nomina) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.payroll.getOne(numero_nomina));
    const { status, data, message } = response.data;
    if (status) {
      const payload = {
        dataNomina: data,
        titleDestino: data.destino_nomina,
        isActive: true,
      };

      dispatch(requestSuccess(SEARCH_NOMINA, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const updateTitleBtn = (title, isVisible) => (dispatch, getState) => {
  dispatch(requestSuccess(UPDATED_TITLE_BOTON, { title, isVisible }));
};

export const isClearNomina = () => (dispatch, getState) => {
  const payload = {
    dataNomina: {},
    titleDestino: "",
    isActiveNomina: false,
  };
  dispatch(requestSuccess(CLEAR_NOMINA_STATUS, payload));
};

export const changeIsImpresion = (title) => (dispatch, getState) => {
  dispatch(requestSuccess(CHANGE_IMPRESION, title));
};

export const officeNomina = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.payroll.setDelivery, body);
    const { status, message, data } = response.data;
    if (status) {
      const { listOrden } = getState().transportOrders;
      const { listNominas } = getState().payrolls;
      const payload = {
        dataNomina: {
          ...body,
          numero_nomina: data.numero_nomina,
          fecha_viaje: data.fecha_viaje,
          ordens: listOrden.filter((orden) => orden.check === true),
        },
        titleDestino: body.destino_id,
        isActiveNomina: true,
        nominas: [
          ...listNominas,
          { id: data.numero_nomina, payroll_number: data.numero_nomina },
        ],
      };
      dispatch(requestSuccess(SAVE_NOMINA, payload));
    }
    return {
      status,
      message,
      numero_nomina: data?.numero_nomina,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const createdNomina = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "crear_nomina")) {
      return { status: false, message: "No tiene permiso de crear nominas" };
    } else {
      let response = await API.post(endPoints.payroll.add, body);
      const { status, message, data } = response.data;
      if (status) {
        const { listOrden } = getState().transportOrders;
        const { listNominas } = getState().payrolls;
        const payload = {
          dataNomina: {
            ...body,
            numero_nomina: data.numero_nomina,
            fecha_viaje: data.fecha_viaje,
            ordens: listOrden.filter((orden) => orden.check === true),
          },
          titleDestino: body.destino_id,
          isActiveNomina: true,
          nominas: [
            ...listNominas,
            { id: data.numero_nomina, payroll_number: data.numero_nomina },
          ],
        };

        dispatch(requestSuccess(SAVE_NOMINA, payload));
      }
      return {
        status,
        message,
        numero_nomina: data?.numero_nomina,
      };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

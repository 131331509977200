import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { phone } from "phone";
import { useForm } from "react-hook-form";
import { getPhone } from "utils/globals";
import { validarRut } from "utils/rut";
import { searchCustomer } from "actions/admin/customerAction";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useInvoice = () => {
  const dispatch = useDispatch();
  const { companyDataBasic } = useSelector((state) => state.companies);

  const [bloquearInput, setBloquearInput] = useState(true);
  const [isActivoRut, setIsActivoRut] = useState(true);
  const [ciudades, setCiudades] = useState([]);
  const [isProcesando, setIsProcesando] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const { register, handleSubmit, errors, setValue, control } = useForm();

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  const searchClienteFactura = async (event, infoData) => {
    if (event.target.value.length > 0) {
      await dataCliente(event.target.value, infoData);
    }
  };

  const onKeyDownFactura = async (event, infoData) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteFactura(event, infoData);
    }
  };

  const dataCliente = async (rut_factura, typeValue, infoData) => {
    if (rut_factura.length > 0) {
      const isValid = validarRut(rut_factura);
      if (!isValid.status) {
        toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
        return false;
      }
      if (rut_factura === RUT_MESON) {
        toast.error("No se puede procesar facturas con ese RUT", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
      const response = await dispatch(
        searchCustomer({ search_type: "rut_cliente", value: rut_factura })
      );

      let telefono = "";
      if (response.status) {
        if (
          response.data?.phone_number !== null &&
          response.data?.phone_number !== ""
        ) {
          telefono = getPhone(response.data?.phone_number, companyDataBasic);
        }
        setValue("rut_factura", response?.data?.rut);

        /**if(rut_factura===RUT_MESON){
                        if(getValues("type_cliente_factura")==="remitente"){
                            setValue("razon_social_factura", data.razon_social_remitente)
                        }else{
                            setValue("razon_social_factura", data.razon_social_destinatario)
                        }
                    }else{
                        setValue("razon_social_factura", response.cliente.nombre_completo)
                    }**/
        /**setValue("razon_social_factura", response.data?.full_name);
          setValue("giro_factura", response.data?.tax_draft);
          setValue("contacto_factura", telefono);
          setValue("email_factura", response.data?.email);
          setValue("direccion_factura", response.data?.email);**/
      }

      if (typeValue === "destinatario") {
        setValue(
          "razon_social_factura",
          infoData?.razon_social_destinatario ?? response?.data?.full_name
        );
        setValue(
          "contacto_factura",
          getPhone(
            infoData?.telefono_avisas ?? response?.data?.phone_number,
            companyDataBasic
          )
        );
        setValue(
          "direccion_factura",
          infoData?.direccion_destinatario ?? response?.data?.address
        );
        setValue("giro_factura", response?.data?.tax_draft);
        setValue("email_factura", response?.data?.email);
      } else if (typeValue === "remitente") {
        setValue(
          "razon_social_factura",
          infoData?.razon_social_remitente ?? response.data?.full_name
        );
        setValue("giro_factura", response?.data?.tax_draft);
        setValue("contacto_factura", telefono);
        setValue("email_factura", response?.data?.email);
        setValue("direccion_factura", response?.data?.address);
      } else {
        setValue(
          "razon_social_factura",
          infoData?.razon_social_remitente ?? response.data?.full_name
        );
        setValue("giro_factura", response?.data?.tax_draft);
        setValue("contacto_factura", telefono);
        setValue("email_factura", response?.data?.email);
        setValue("direccion_factura", response?.data?.address);
      }
      setBloquearInput(false);
    }
  };

  const resetInput = () => {
    setBloquearInput(true);
    setValue("razon_social_factura", "");
    setValue("giro_factura", "");
    setValue("contacto_factura", "");
    setValue("email_factura", "");
    setValue("direccion_factura", "");
    setValue("comuna_factura", "");
    setValue("ciudad_factura", "");
  };

  const selectCliente = async (event, infoOrder) => {
    const { value } = event.target;
    let rut_factura = "";
    resetInput();

    if (value === "otros") {
      rut_factura = "";
      setIsActivoRut(false);
      setBloquearInput(false);
      setValue("rut_factura", "");
    } else {
      rut_factura =
        value === "destinatario"
          ? infoOrder.rut_destinatario
          : infoOrder.rut_remitente;
      setValue("rut_factura", rut_factura);
      setIsActivoRut(true);
      await dataCliente(rut_factura, value, infoOrder);
    }
  };

  const formElement = {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
  };

  const state = {
    bloquearInput,
    isActivoRut,
    ciudades,
    isProcesando,
    isSpinner,
  };

  const actions = {
    validatePhone,
    setBloquearInput,
    setIsActivoRut,
    setCiudades,
    setIsProcesando,
    setIsSpinner,
    searchClienteFactura,
    onKeyDownFactura,
    selectCliente,
    dataCliente,
  };

  return {
    formElement,
    state,
    actions,
  };
};

import { useEffect, useState } from "react";
import { Form, Col, Table, Row, InputGroup } from "react-bootstrap";
import BodyCarga from "./BodyCarga";
import {
  handlePasteOnlyNumber,
  validateOnlyNumber,
} from "utils/validationInput";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const DetalleCarga = ({ orden, formElement, actions, state, isAdmin }) => {
  const { register, errors, setValue, control, getValues } = formElement;

  const { statusValidate, documentacionsCarga, formaPagos } = state;

  const { handleChangeTotal, handleTipoDoc } = actions;

  const [totalPagar, setTotalPagar] = useState("");

  useEffect(() => {
    setValue("documentacion_id", orden?.documentacion_id);
    setValue("forma_pago_id", orden?.forma_pago_id);
    setTotalPagar(orden.valor_declarado_carga);
    handleTipoDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orden?.documentacion_id]);

  return (
    <>
      <Form.Row>
        <Col>
          <hr />
          <h6>Detalle de la carga</h6>
        </Col>
      </Form.Row>
      <Row>
        <Col md sm={3} className="mb-0">
          <Form.Group controlId="documentacion_id" className="mb-1">
            <Form.Label>Tipo de Documento</Form.Label>
            <Form.Control
              as="select"
              name="documentacion_id"
              className="change_documentacion_id"
              defaultValue={orden?.documentacion_id}
              disabled={!isAdmin}
              ref={register({
                required: { value: true, message: "Requerido" },
              })}
              onChange={(event) => handleTipoDoc(event)}
            >
              <option value="">Seleccione</option>

              {documentacionsCarga.length > 0 &&
                documentacionsCarga.map((documents) => (
                  <option key={documents.id} value={documents.id}>
                    {documents.description}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md sm={3} className="mb-0">
          <Form.Group controlId="numero_documento_carga" className="mb-1">
            <Form.Label>Nro Documento(s)</Form.Label>
            <Form.Control
              type="text"
              name="numero_documento_carga"
              defaultValue={orden?.numero_documento_carga}
              ref={register({
                required: { value: statusValidate, message: "Requerido" },
              })}
              onKeyPress={(event) => validateOnlyNumber(event)}
              autoComplete="off"
              onPaste={(event) => handlePasteOnlyNumber(event)}
              readOnly={!statusValidate}
            />
            {errors?.numero_documento_carga && (
              <span className="text-danger">
                {errors?.numero_documento_carga?.message}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col md sm={3} className="mb-0">
          <Form.Group controlId="valor_declarado_carga" className="mb-1">
            <Form.Label>Valor declarado</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Controller
                control={control}
                name="valor_declarado_carga"
                rules={{ required: { value: true, message: "Requerido" } }}
                render={(props) => (
                  <NumericFormat
                    allowNegative={false}
                    className="form-control"
                    decimalScale={2}
                    name={props.name}
                    id={props.name}
                    fixedDecimalScale={false}
                    isAllowed={({ floatValue }) => {
                      // Define your validation rules here
                      return true;
                    }}
                    onPaste={(e) => e.preventDefault()}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    value={totalPagar}
                    defaultValue={orden.valor_declarado_carga}
                    onValueChange={(values) => {
                      props.onChange(values.floatValue);
                      setTotalPagar(values.floatValue);
                    }}
                    maxLength={15}
                  />
                )}
                autoComplete="off"
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md sm={3} className="mb-0">
          <Form.Group controlId="forma_pago_id" className="mb-1">
            <Form.Label>Forma de pago</Form.Label>
            <Form.Control
              as="select"
              name="forma_pago_id"
              disabled={!isAdmin}
              defaultValue={orden?.forma_pago_id}
              ref={register({
                required: { value: true, message: "Requerido" },
              })}
            >
              <option value="">Seleccione</option>

              {formaPagos.length > 0 &&
                formaPagos.map((pago) => (
                  <option key={pago.id} value={pago.id}>
                    {pago.description}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-1">
            <Table bordered size="sm" responsive className="table-global mb-0">
              <thead className="bg-warn-blue">
                <tr>
                  <th className="text-center">Alto (m)</th>
                  <th className="text-center">Ancho (m)</th>
                  <th className="text-center">Largo (m)</th>
                  <th className="text-center" style={{ width: "70px" }}>
                    M3
                  </th>
                  <th className="text-center">Cantidad</th>
                  <th className="text-center">Peso total (kg)</th>
                  <th className="text-center" style={{ width: "150px" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {orden?.cargas.length > 0 &&
                  orden?.cargas.map((item, key) => (
                    <BodyCarga
                      key={key}
                      index={key}
                      {...item}
                      register={register}
                      isAdmin={isAdmin}
                      handleChangeTotal={handleChangeTotal}
                      orden_flete_id={orden.orden_flete_id}
                      setValue={setValue}
                      getValues={getValues}
                      control={control}
                      errors={errors}
                    />
                  ))}
              </tbody>
            </Table>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default DetalleCarga;

import Layout from "components/Layouts/Layout";
import { Card } from "react-bootstrap";
import ModalSpinner from "components/Custom/Spinner/ModalSpinner";
import DocumentPendient from "components/Facturacion/Cancelation/DocumentPendient";
import DetailPayment from "components/Facturacion/Cancelation/DetailPayment";
import DetailClient from "components/Facturacion/Cancelation/DetailClient";
import DetailDocumentPayable from "components/Facturacion/Cancelation/DetailDocumentPayable";
import { ToastContainer } from "react-toastify";

import { useCancelationPayment } from "hooks/Facturation/useCancelationPayment";

const MultiplePago = () => {
  const { state, actions, formElement } = useCancelationPayment(null);

  const {
    cancelationPayment,
    listDocumentClient,
    metodo_pago,
    isDisable,
    listAccount,
    loading,
    isLoadingPayment,
    isValid,
    isValidDocument,
    totalPagar,
  } = state;

  const {
    addItemDocumentPayment,
    deleteItemDocumentPayment,
    addItemPaymentMethods,
    deleteItemPayment,
    getDocumentClient,
    changeTypePayment,
    setTotalPagar,
  } = actions;

  const { register, control, handleSubmit, onSubmit, errors } = formElement;

  return (
    <Layout
      titlePanel="Registrar Pagos de Factura Cliente"
      modulo="crear_pagos_factura"
    >
      <div className="row">
        <div className="col-11 mx-auto">
          <Card className="mb-5 mt-3 card-retiro shadow-sm">
            <Card.Header as="h6" className="card-header-white">
              Cancelación de Pago
            </Card.Header>
            <Card.Body className="">
              <form onSubmit={handleSubmit(onSubmit)} id="frmPayment">
                <DetailClient
                  register={register}
                  getDocumentClient={getDocumentClient}
                  data={cancelationPayment}
                  isEdit={false}
                />
                <h6 className="mt-4">Detalle de los documentos a pagar</h6>
                <div className="row mt-4">
                  <div className="col-6">
                    <DetailDocumentPayable
                      register={register}
                      control={control}
                      changeTypePayment={changeTypePayment}
                      isDisable={isDisable}
                      isEdit={false}
                      errors={errors}
                      isValidDocument={isValidDocument}
                    />
                  </div>

                  <div className="col-6">
                    <DocumentPendient
                      listDocumentPendient={listDocumentClient}
                      listDocumentPendientTable={
                        cancelationPayment?.listDocumentPendient
                      }
                      addItemDocument={addItemDocumentPayment}
                      register={register}
                      deleteItemDocument={deleteItemDocumentPayment}
                      isEdit={false}
                      isValidDocument={isValidDocument}
                    />
                  </div>
                </div>
                <DetailPayment
                  listPaymentMethods={metodo_pago}
                  listDetailPayment={cancelationPayment?.listDetailPayment}
                  addItemPayment={addItemPaymentMethods}
                  register={register}
                  deleteItemPayment={deleteItemPayment}
                  cuentas_depositos={listAccount}
                  control={control}
                  isEdit={false}
                  setTotalPagar={setTotalPagar}
                  isValid={isValid}
                  totalPagar={totalPagar}
                />

                <div className="row">
                  <div className="col-12 text-right mt-4">
                    <button type="button" className="btn btn-outline-warning">
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="btn btn-warning ml-2"
                      disabled={loading}
                    >
                      {loading ? "Procesando..." : "Procesar"}
                    </button>
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>

      <ToastContainer />

      {isLoadingPayment && (
        <ModalSpinner
          spinnerShow={isLoadingPayment}
          description="Buscando los datos del cliente..."
        />
      )}
    </Layout>
  );
};

export default MultiplePago;
